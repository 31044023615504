import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { COLOR_APP } from '../constants';
import { numberWithCommas } from '../constants/ShowFormat';


function DataPackageCard({ title, duration, price, backgroundColor, color, setChecked, currentChecked, icon, setPackageListData, id }) {



  return (
    <div style={{
      width: "100%",
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.1)",
      borderRadius: 6,
      padding: 14,
      backgroundColor: backgroundColor,
      color: color
    }}
      onClick={() => { setChecked(currentChecked); setPackageListData({ title, duration, price, id }) }}
    >
      <div style={{ fontWeight: "bold", fontSize: 18, display: "flex", justifyContent: "space-between" }}>
        <span>{numberWithCommas(parseFloat(title))} MB</span>
        <span>
          {icon}
        </span>
      </div>
      <div style={{ marginTop: 5, fontSize: 14 }}>{duration}</div>
      <div style={{ marginTop: 5, fontSize: 14 }}>{numberWithCommas(price)} LAK</div>
    </div>
  )
}

export default DataPackageCard

import { faArrowLeft, faDeleteLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BsCircleFill, BsCircle } from "react-icons/bs";
import { useMutation, useLazyQuery } from "@apollo/client";
import * as _ from "lodash";
import { BACKGROUND_COLOR_APP, CLICK_COLOR, COLOR_APP, COLOR_PIN, COLOR_PINE } from "../../constants";
import "./HomeProfilePin.css";
import { useTranslation } from "react-i18next";
import "./Home.css";
import { PIN_COMPARE } from "./graphql/mutation";
import { GET_USER } from "../graphql/query";
import CustomAppBar from "../../components/CustomAppBar";

function HomeProfilePin() {
  const [pinCompare] = useMutation(PIN_COMPARE);

  const [buttonClick, setButtonClick] = useState({
    button0: false,
    button1: false,
    button2: false,
    button3: false,
    button4: false,
    button5: false,
    button6: false,
    button7: false,
    button8: false,
    button9: false,
    button10: false,
  });
  //variables
  const history = useNavigate();
  const location = useLocation();
  const [count, setCount] = useState(0);
  const condition = new URLSearchParams(location.search).get("pinCode");
  const hintText = new URLSearchParams(location.search).get("hintText");
  const [pinCode, setPinCode] = useState("");
  const [havePin, setHavePin] = useState(condition ? condition : false);
  const [wrong, setWrong] = useState(false);
  const { t, i18n } = useTranslation();

  const [getUser, { data: userQueryData }] = useLazyQuery(GET_USER);

  useEffect(() => {
    setTimeout(() => {
      setButtonClick({ button0: false });
    }, 100);
    if (count == 4) {
      // TODO: Request to pinCompare
      if (havePin) {
        pinCompareReq(pinCode);
        return;
      }

      setTimeout(() => {
        history(`/home-profile-pin-confirm?oldCode=${pinCode}`);
      }, 500);
    }
  }, [count]);

  const pinCompareReq = async (pinCode) => {
    try {
      const pinCompareRes = await pinCompare({
        variables: {
          pinCode: pinCode,
        },
      });
      const resData = pinCompareRes["data"];
      if (_.isEmpty(resData)) return;

      const pinCompareResult = resData["pinCompare"]["success"];
      if (!pinCompareResult) {
        setTimeout(() => {
          setWrong(true);
          setCount(0);
          setPinCode("");
        }, 200);
        return;
      }

      history(`/home-profile-see-pin?hintText=${hintText}`);
    } catch (err) {
      console.log("err: ", err);
    }
  };

  //routes
  const _goBacktoHome = () => {
    history(-1);
  };

  const gotoForgotPin = () => {
    history('/home-profile-pin/forgot-pin')
  }

  return (
    <div className="wrapper" style={{backgroundColor: BACKGROUND_COLOR_APP}}>
      {/* <div className="home-header">
        <FontAwesomeIcon
          style={{
            marginLeft: 10,
            fontSize: "18",
            fontWeight: "bold",
          }}
          icon={faArrowLeft}
          onClick={() => _goBacktoHome()}
        />
      </div> */}

      <CustomAppBar
          gotoOtherPage={_goBacktoHome}
          title={"LOCA PAY"}
        />

      <div style={{ height: 14 }} />
      <div style={{ fontSize: 17, fontWeight: "bold", textAlign: "center" }}>
        {/* Please input PIN */}
        {t("setting-please")}
      </div>

      <div style={{ height: 10 }} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        {count >= 1 ? (
          <BsCircleFill
            fontSize={14}
            style={{ marginRight: 20 }}
            color={COLOR_APP}
          />
        ) : (
          <BsCircle
            fontSize={14}
            style={{ marginRight: 20 }}
            color={COLOR_APP}
          />
        )}
        {count >= 2 ? (
          <BsCircleFill
            fontSize={14}
            style={{ marginRight: 20 }}
            color={COLOR_APP}
          />
        ) : (
          <BsCircle
            fontSize={14}
            style={{ marginRight: 20 }}
            color={COLOR_APP}
          />
        )}
        {count >= 3 ? (
          <BsCircleFill
            fontSize={14}
            style={{ marginRight: 20 }}
            color={COLOR_APP}
          />
        ) : (
          <BsCircle
            fontSize={14}
            style={{ marginRight: 20 }}
            color={COLOR_APP}
          />
        )}
        {count >= 4 ? (
          <BsCircleFill fontSize={14} color={COLOR_APP} />
        ) : (
          <BsCircle fontSize={14} color={COLOR_APP} />
        )}
      </div>
      {havePin ? (
        <div style={{ marginTop: 10, textAlign: "center" }}>
          {/* Hint */}
          {t("setting-hint")}: {hintText}
        </div>
      ) : (
        <div />
      )}
      {wrong ? (
        <div style={{ textAlign: "center", color: "red" }}>
          {/* Your pin is not correct */}
          {t("setting-not-correct")}
        </div>
      ) : (
        <div />
      )}
      <div style={{ marginTop: 8 }} />
    {hintText ?  <div style={{
        color: COLOR_APP,
        fontSize: 14,
        textAlign: "center",
        textDecoration: "underline",
      }}
        onClick={() => gotoForgotPin()}
      >{t("forgot-pin")}</div>: <div/>}

      <div style={{ height: 20 }} />
      <div style={{ marginBottom: 20, position: "absolute", width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button0 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setButtonClick({ button0: true });
                setCount(count + 1);
              }
              setPinCode(pinCode + "1");
            }}
          >
            1
          </div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button1 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button1: true });
              }
              setPinCode(pinCode + "2");
            }}
          >
            2
          </div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button2 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button2: true });
              }
              setPinCode(pinCode + "3");
            }}
          >
            3
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: 11,
          }}
        >
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button3 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button3: true });
              }
              setPinCode(pinCode + "4");
            }}
          >
            4
          </div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button4 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button4: true });
              }
              setPinCode(pinCode + "5");
            }}
          >
            5
          </div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button5 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button5: true });
              }
              setPinCode(pinCode + "6");
            }}
          >
            6
          </div>
        </div>
        <div
          style={{
            display: "flex",

            justifyContent: "space-evenly",
            marginTop: 11,
          }}
        >
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button6 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button6: true });
              }
              setPinCode(pinCode + "7");
            }}
          >
            7
          </div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button7 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button7: true });
              }
              setPinCode(pinCode + "8");
            }}
          >
            8
          </div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button8 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button8: true });
              }
              setPinCode(pinCode + "9");
            }}
          >
            9
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: 11,
          }}
        >
          <div style={{ height: 88, width: 88 }}></div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button9 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button9: true });
              }
              setPinCode(pinCode + "0");
            }}
          >
            0
          </div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button10 ? CLICK_COLOR : "" }}
            onClick={() => {
              setButtonClick({ button10: true });
              if (count > 0) {
                setCount(count - 1);
              }
              const newCode = pinCode.slice(0, -1);
              setPinCode(newCode);
            }}
          >
            <FontAwesomeIcon icon={faDeleteLeft} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeProfilePin;

import React from 'react'
import { useTranslation } from 'react-i18next';
import '../pages/Home/Home.css';

function EmptyHistoryPayment() {

    const { t, i18n } = useTranslation();

    return (
        <div>
            <div className='box-shadow-sm' style={{
                marginTop: 10,
                marginRight: 26,
                marginLeft: 26,
                border: "solid 1px",
                height: 426,
                borderRadius: 6,
                border: "none"
            }}>
                <center>
                    <img style={{
                        width: 244,
                        height: 166,
                        marginTop: 126,
                    }}
                        src="/assets/images/no-info.png" />
                </center>
                <div style={{ marginTop: 40 }}>
                    <center style={{ color: "#4F4F4F" }}>
                        {/* No data */}
                        {t("home-no-data")}
                    </center>
                </div>
            </div>
        </div>
    )
}

export default EmptyHistoryPayment

import { gql } from "@apollo/client";

export const GET_USER = gql`
  query User($where: UserWhereInputOne!) {
    user(where: $where) {
      id
      sid
      firstName
      lastName
      countryCode
      gender
      email
      birthday
      phone
      passportNumber
      profileImage
      passportIssueData
      passportExpireDate
      fcmTopic
      pinCode
      pinHint
      defaultCard
      role
      userLang
      userStatus
      createdAt
      isBanned
      verifiedByCarSharing
    }
  }
`;

export const GET_CARDS = gql`
  query Query {
    cards {
      total
      data {
        id
        cardId
        cardName
        expireMonth
        expireYear
        brand
        createdAt
        updatedAt
        createdBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_SHORT_URL = gql`
query Query($expireUrl: String, $longUrl: String, $expireDate: String, $expireTime: String) {
  shortUrl(expireUrl: $expireUrl, longUrl: $longUrl, expireDate: $expireDate, expireTime: $expireTime) {
    url
  }
}
`;


export const GET_USER_FROM_TOKEN = gql`
query Query($token: String!) {
  reverifyResetPinData(token: $token) {
    firstName
    lastName
    userId
  }
}
`;

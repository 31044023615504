import { useLazyQuery } from "@apollo/client";
import {
  faArrowLeft, faCalendarAlt, faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BACKGROUND_COLOR_APP, USER_ID } from "../../constants";
import { numberWithCommas } from "../../constants/ShowFormat";
import { GET_CARDS, GET_PAYMENTS } from "./graphql/query";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Home.css";
import HomeLoading from "../../components/HomeLoading";
import _ from "lodash";
import { HEADER_HEIGHT } from "../../constants";
import HomeBottomNav from "../../components/HomeBottomNav";
import CustomAppBar from "../../components/CustomAppBar";

function HomeHistory() {
  const history = useNavigate();
  const { t, i18n } = useTranslation();

  const [
    getPayments,
    { data: paymentData, loading: paymentLoading },
  ] = useLazyQuery(GET_PAYMENTS);
  const [getCards, { data: cardQueryData }] = useLazyQuery(GET_CARDS);
  const userId = localStorage.getItem(USER_ID);
  const [creditCard, setCreditCard] = useState();
  const [cardData, setCardData] = useState();

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    getPayments({
      variables: {
        where: {
          userId: userId,
          status: "SUCCESS"
        },
        orderBy: "createdAt_DESC",
      },
    });

    getCards({
      variables: {
        where: {
          createdBy: userId,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (cardQueryData) {
      setCardData(cardQueryData.cards.data);
    }
  }, [cardQueryData]);


  const _searchByCreditCard = async ({ credit, date }) => {
    // TODO: startDate && endDate format
    // 2022-08-11T09:18:48
    const [sDate, eDate] = date;
    let startDate = null;
    let endDate = null;
    if (sDate != null) {
      startDate = moment(sDate).format("YYYY-MM-DD");
    }
    if (eDate != null) {
      endDate = moment(eDate).format("YYYY-MM-DD");
    }

    /**
     * Where prepare
     */
    let where = {
      userId: userId,
      status: "SUCCESS"
    };
    if (!_.isEmpty(credit)) {
      where["stripeCardId"] = credit;
    }
    if (startDate != null) {
      where["createdAt_gte"] = startDate;
    }
    if (endDate != null) {
      where["createdAt_lt"] = endDate;
    }

    getPayments({
      variables: {
        where: where,
        orderBy: "createdAt_DESC",
      },
    });
  };

  const _goBackHome = () => {
    history(-1);
  };

  const _gotoHistoryDetail = (data) => {
    let desc = data.bankName === 'TELBIZ' ? data.qrType : data.note
    history(
      `/home-history-details?id=${data.id}&createdAt=${data.createdAt}&price=${data.amount}&phone=${data.phone}&creditCard=${data.cardLast4digit}&note=${desc}&cardId=${data.stripeCardId}&startingPrice=${data.amount}&totalFees=${data.totalFees}&billNo=${data.billNo}&bankName=${data.bankName}&bankTansactionId=${data.bankTansactionId}&destMerchantName=${data.destMerchantName}&refNo=${data.refNo}&status=${data.status}&bankLogoUrl=${data.bankLogoUrl}&cardType=${data.cardType}&cardName=${data.cardHolderName}&brand=${data.cardType}&expireMonth=${data.expireMonth}&expireYear=${data.expireYear}&description=${desc}&cardNumber=${data.cardLast4digit}&qrString=${data.qrString}&packageAmount=${data.packageAmount}`
    );
  };

  if (paymentLoading) {
    return <HomeLoading />;
  }

  return (
    <div className="wrapper" style={{ backgroundColor: BACKGROUND_COLOR_APP }}>
      {/* <div className="home-header">
        <FontAwesomeIcon
          style={{
            marginLeft: 10,
            fontSize: "18",
            fontWeight: "bold",
            color: "#252A31",
          }}
          icon={faArrowLeft}
          onClick={() => _goBackHome()}
        />
      </div> */}
      <CustomAppBar
        gotoOtherPage={_goBackHome}
        title={"LOCA PAY"}
      />
      {/* ---------- */}
      <div>
        {/* <div
          style={{
            fontSize: 17,
            fontWeight: "bold",
            marginLeft: 12,
            marginTop: 26,
            marginBottom: 24,
          }}
        >
          {t("history")}
        </div> */}
        <div style={{
          fontSize: 18,
          fontWeight: "bold",
          marginLeft: 12,
          marginTop: 20,
          marginBottom: 13,
        }}>
          {t("activity")}
        </div>

        {/* <select >  */}
        <div
          style={{
            marginLeft: 12,
            marginRight: 12,
            marginBottom: 21,
          }}
        >
          <InputGroup className="mb-3">
            <InputGroup.Text style={{
              backgroundColor: "white",
              borderRight: "none",
              borderTopLeftRadius: 6,
              borderBottomLeftRadius: 6
            }}>
              <FontAwesomeIcon icon={faCreditCard} />
            </InputGroup.Text>
            <Form.Select
              style={{
                backgroundColor: "white",
                fontSize: 14,
                height: 39,
                borderLeft: "none",
                borderTopRightRadius: 6,
                borderTopRightRadius: 6
              }}
              value={creditCard}
              onChange={(e) => {
                _searchByCreditCard({
                  credit: e.target.value,
                  date: dateRange,
                });
                setCreditCard(e.target.value);
                e.preventDefault();
              }}
            >
              <option value="">{t("all-cards")}</option>
              {cardData &&
                cardData.map((card, index) => {
                  return (
                    <option value={card.id} key={index}>
                      {card.brand} &nbsp; **** **** **** {card.cardId}
                    </option>
                  );
                })}
            </Form.Select>
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroup.Text style={{
              backgroundColor: "white",
              borderRight: "none",
              borderTopLeftRadius: 6,
              borderBottomLeftRadius: 6,
              width: "10%"
            }}>
              <FontAwesomeIcon icon={faCalendarAlt} />
            </InputGroup.Text>
            <div style={{ width: "90%" }}>
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(dateRange) => {
                  setDateRange(dateRange);
                  _searchByCreditCard({ credit: creditCard, date: dateRange });
                }}
                isClearable={true}
                placeholderText={t("home-search-date")}
                id="dtp-filter-custom"
                autoComplete="off"
                dateFormat="dd/MM/yyyy"
                showYearDropdown
                yearDropdownItemNumber={93}
                scrollableYearDropdown
              />
            </div>
          </InputGroup>

        </div>

        {/* ---------  */}
        {/* Form  */}
        {paymentData &&
          paymentData.payments.data.map((payment, index) => {
            // console.log("payment: ", payment)
            return (
              <div key={index}>
                {/* Card Section Define */}
                {index === 0 ? (
                  <div
                    style={{
                      padding: 5,
                      paddingLeft: 5,
                      backgroundColor: "white",
                    }}
                  >
                    {moment(payment.createdAt).format("DD-MM-YYYY")}
                  </div>
                ) : (
                  // If not the first element, then compare if this card createdAt != previous card createdAt, begin new section
                  <div>
                    {moment(payment.createdAt).format("DD-MM-YYYY") !=
                      moment(
                        paymentData.payments.data[index - 1].createdAt
                      ).format("DD-MM-YYYY") && (
                        <div
                          style={{
                            padding: 5,
                            paddingLeft: 5,
                            backgroundColor: "white",
                          }}
                        >
                          {moment(payment.createdAt).format("DD-MM-YYYY")}
                        </div>
                      )}
                  </div>
                )}

                {/* Card */}
                <div onClick={() => _gotoHistoryDetail(payment)}>
                  <div
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      marginTop: 8,
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div>
                        <img
                          style={{
                            width: 35,
                            height: 35,
                            marginTop: 6,
                          }}
                          src={
                            payment.bankName == 'TELBIZ' ? (
                              payment.qrType == "LTC" ? "/assets/images/ltc_logo.jpeg" :
                                payment.qrType == "UNITEL" ? "/assets/images/unitel_logo.jpeg" :
                                  payment.qrType == "ETL" ? "/assets/images/etl_logo.png" :
                                    "/assets/images/box-loading.jpg"
                            ) : payment.bankLogoUrl
                              ? payment.bankLogoUrl
                              : "/assets/images/box-loading.jpg"
                          }
                        />
                        <div style={{ height: 2 }} />

                        <img
                          src={
                            payment.cardType == "visa"
                              ? "/assets/images/VisaCard.png" :
                              payment.cardType == "jcb" ? "/assets/images/jcb.png" :
                                payment.cardType == "discover" || payment.cardType == "diners" ? "/assets/images/discover_diner.png" :
                                  payment.cardType == "unionpay" ? "/assets/images/union_pay.png" :
                                    payment.cardType == "amex" ? "/assets/images/amex.png" : "/assets/images/MasterCard.png"
                          }
                          style={{
                            height: payment.cardType == 'discover' || payment.cardType == "diners" ?
                              8 :
                              payment.cardType == "amex" ? 30 :
                                payment.cardType == "unionpay" ? 23 :
                                  payment.cardType == "jcb" ? 25 :
                                    35
                          }}
                          alt=""
                        />
                      </div>

                      <div style={{ marginLeft: 10, flex: 1 }}>
                        <div>
                          <span style={{ fontSize: 12, fontWeight: "bold" }}>
                            {/* To account */}
                            {t("home-account")}:
                          </span>
                          <span style={{ fontSize: 12 }}>
                            {payment.bankName === "TELBIZ" ? payment.phone : payment.destMerchantName}
                          </span>
                        </div>

                        <div>
                          <span style={{ fontSize: 12, fontWeight: "bold" }}>
                            {/* From */}
                            {t("payment-from")}:
                          </span>
                          <span style={{ fontSize: 12 }}>
                            {" "}
                            {payment.firstName} {payment.lastName}
                          </span>
                        </div>

                        <div>
                          <span style={{ fontSize: 12, fontWeight: "bold" }}>
                            {/* Description */}
                            {t("payment-des")}:
                          </span>
                          <span style={{ fontSize: 12 }}> {payment.bankName === "TELBIZ" ? payment.qrType : payment.note}</span>
                        </div>

                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ fontSize: 12 }}>
                            {moment(payment.createdAt).format(
                              "DD-MM-YYYY H:mm"
                            )}
                          </div>

                          <div
                            style={{
                              color: "#EB3232",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            LAK -{" "}
                            {numberWithCommas(
                              payment.total ? payment.total : 0
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <center>
                    <hr style={{ width: "90%" }}></hr>
                  </center>
                </div>
              </div>
            );
          })}
      </div>
      <div style={{ height: 80 }} />
      <HomeBottomNav currentIndex={2} />
    </div>
  );
}

export default HomeHistory;

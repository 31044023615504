import {
  faArrowLeft,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BsQuestionCircleFill } from "react-icons/bs";
import React, { useState, useRef, useEffect } from "react";
import { Col, Form, Button, Modal } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import {  useMutation } from "@apollo/client/react";
import { DELETE_CARD } from "../graphql/mutation";
import { CARD_ID, COLOR_APP, POPUP_COLOR, USER_ID } from "../../constants";
import Loading from "../../components/Loading";
import "./Home.css";
import { useTranslation } from "react-i18next";
import { HEADER_HEIGHT } from "../../constants";

function HomeAccountCardDetails() {
  const history = useNavigate();
  const location = useLocation();

  const [canScroll, setCanScroll] = useState(false);
  const { innerWidth: width, innerHeight: height } = window;
  const ref = useRef(null);

  let _id = localStorage.getItem(CARD_ID);
  let cardId = new URLSearchParams(location.search).get("cardId");
  let cardName = new URLSearchParams(location.search).get("cardName");
  let expireMonth = new URLSearchParams(location.search).get("expireMonth");
  let expireYear = new URLSearchParams(location.search).get("expireYear");
  let brand = new URLSearchParams(location.search).get("brand");
  let _visible = new URLSearchParams(location.search).get("visible");
  const { t, i18n } = useTranslation();
  const userId = localStorage.getItem(USER_ID);

  const [show, setShow] = useState(false);
  const [visible, setVisible] = useState(_visible ? _visible : false);
  const [deleteCard] = useMutation(DELETE_CARD);
  const [loading, setLoading] = useState(false);
 

  useEffect(() => {
    // scrollable?
    if (ref.current.clientHeight < height) setCanScroll(true);
    else setCanScroll(false);
  }, []);


  const _removeCard = async () => {
    try {
      setLoading(true);
      const _res = await deleteCard({
        variables: {
          where: {
            id: _id,
          },
        },
      });

      if (_res.data.deleteCard) {
        setLoading(false);
        localStorage.removeItem(CARD_ID);
        history(`/home-account`);
      }
    } catch (error) { }
  };

  const _goBackHome = () => {
    history(`/home-account`);
  };


  if (loading) {
    return <Loading />;
  }

  return (
    <div className="wrapper">
      <div className="home-header" onClick={() => _goBackHome()}>
        <FontAwesomeIcon
          style={{
            marginLeft: 10,
            fontSize: "18",
            fontWeight: "bold",
            color: "#252A31",
          }}
          icon={faArrowLeft}
        />
      </div>

      <div style={{ height: HEADER_HEIGHT }} />
      {/* ====== */}
      {/* BOdy */}
      <div
        style={
          canScroll
            ? { position: "fixed", overflowY: "scroll", width: "100%" }
            : {}
        }
        ref={ref}
      >
        <div
          style={{
            fontSize: 17,
            fontWeight: "bold",
            marginTop: 26,
            marginLeft: 26,
          }}
        >
          {/* Card Detail */}
          {t("card-detail-one")}
        </div>

        {/* CardInfomations  */}

        <div
          className="box-shadow-sm"
          style={{
            height: 160,
            width: "90%",
            marginTop: 26,
            borderRadius: 6,
            borderLeft: "10px solid",
            borderColor: COLOR_APP,
            display: "flex",
            marginLeft: 20,
            padding: 10,
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: 20,
            }}
          >
            {/* **** **** 5145 5554 */}
            {/* {visible ? cardId : creditCard(cardId)} */}
            **** **** **** {cardId}
          </div>
          {/* ====  */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: 14,
                color: "#898989",
              }}
            >
              {/* Expire */}
              {t("card-expire")} {expireMonth + "/" + expireYear}
            </div>
            <div>
              {" "}
              <img
                style={{
                  height: 70,
                }}
                src={
                  brand == "visa"
                    ? "/assets/images/VisaCard.png"
                    : "/assets/images/MasterCard.png"
                }
              />
            </div>
          </div>
        </div>

        {/* Form Card  */}
        <Form style={{ marginLeft: 26, marginRight: 26, marginTop: 36 }}>
          <Form.Group className="mb-3">
            <Form.Label
              style={{ color: "#898989", fontSize: 14, fontWeight: "bold" }}
            >
              {/* Name */}
              {t("name")}
            </Form.Label>
            <Form.Control
              style={{
                backgroundColor: "#EFF2F5",
                color: "#101010",
                border: "none",
                height: 44,
              }}
              type="text"
              defaultValue={cardName}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label
              style={{
                color: "#898989",
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              {/* Card Number */}
              {t("card-number")}
            </Form.Label>
            <Form.Control
              style={{
                backgroundColor: "#EFF2F5",
                color: "#101010",
                border: "none",
                height: 44,
              }}
              type={"text"}
              // placeholder="**** **** 4445 5555"
              value={
                visible
                  ? `**** **** **** ${cardId} `
                  : `**** **** **** ${cardId}`
              }
              disabled
            />
          </Form.Group>
          <div style={{ display: "flex" }}>
            <Form.Group style={{ marginRight: 12 }}>
              <Form.Label
                style={{ color: "#898989", fontSize: 14, fontWeight: "bold" }}
              >
                {/* Expire */}
                {t("card-expire")}
              </Form.Label>
              <Form.Control
                style={{
                  backgroundColor: "#EFF2F5",
                  color: "#101010",
                  border: "none",
                  height: 44,
                  width: "38%",
                }}
                value={expireMonth + "/" + expireYear}
                disabled
              />
            </Form.Group>
          </div>
        </Form>
        {/* <BUtton  */}
        <Col style={{ marginTop: 80, marginLeft: 26, marginRight: 26 }}>
          <center>
            <Button
              variant="outline-danger"
              style={{
                backgroundColor: "#F9F9F9",
                width: "100%",

                height: 50,
                color: "#000000",
                fontWeight: "bold",
              }}
              onClick={() => setShow(true)}
            >
              <FontAwesomeIcon
                style={{ marginRight: 13, color: "#3F4952" }}
                icon={faTrash}
              />
              {/* Remove */}
              {t("card-remove")}
            </Button>

          </center>
        </Col>
        <div style={{ height: 30 }} />

        <Modal show={show} size="sm" onHide={() => setShow(false)} centered>
          <Modal.Body style={{ textAlign: "center" }}>
            <BsQuestionCircleFill
              style={{ fontSize: 60, color: POPUP_COLOR, marginBottom: 10 }}
            />
            <div style={{ fontSize: 20 }}>
              {/* Do you want to remove? */}
              {t("card-want-remove")}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              <Button
                style={{
                  backgroundColor: "red",
                  borderColor: "red",
                }}
                onClick={() => setShow(false)}
              >
                {/* Cancle */}
                {t("card-cancle")}
              </Button>

              <div style={{ width: 20 }} />

              <Button
                style={{
                  backgroundColor: COLOR_APP,
                  borderColor: COLOR_APP,
                }}
                onClick={() => _removeCard()}
              >
                {/* Remove */}
                {t("card-remove")}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default HomeAccountCardDetails;

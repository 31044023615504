import { faAngleRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

function HomeChooseAccount() {
  return (
    <div>
      {" "}
      <Link to="/homeaccount">
        <div
          style={{
            display: "flex",
            height: 49,
          }}
        >
          <FontAwesomeIcon
            style={{
              marginLeft: 18,
              marginTop: 17,
              height: 20,
              width: 16,
              fontWeight: "bold",
              color: "#252A31",
            }}
            icon={faArrowLeft}
          />
        </div>
      </Link>
      {/* ------- 0 */}
      <div
        style={{
          fontSize: 17,
          fontWeight: "bold",
          marginLeft: 26,
          marginTop: 26,
        }}
      >
        Select Your Cards
      </div>
      {/* Credit-Card  */}
      <div
        style={{
          height: 269,
          marginLeft: 26,
          marginRight: 26,
          marginTop: 26,
          borderRadius: 6,
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: 12,
            paddingTop: 18,
            paddingLeft: 19,
            marginBottom: 15,
          }}
        >
          Credit Card
        </div>
        {/* Cardd  */}
        <Link style={{ textDecoration: "none" }} to="/homeaccountcarddetails">
          <div
            style={{
              display: "flex",
              backgroundColor: "#F9F9F9",
              marginLeft: 19,
              marginRight: 20,
              marginTop: 10,
              borderRadius: 6,
            }}
          >
            <div>
              <img
                style={{
                  height: 70,
                }}
                src="/assets/images/VisaCard.png"
                className="VisaCard-image"
              />
            </div>
            <div style={{ marginLeft: 8 }}>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 12,
                  paddingTop: 19,
                  color: "#000000",
                }}
              >
                **** **** **** 5554
              </div>
              <div
                style={{
                  color: "#C4C4C4",
                  fontSize: 10,
                  fontWeight: "bold",
                  paddingTop: 5,
                }}
              >
                Expire 25/2026
              </div>
            </div>
            {/* ICon  */}
            <div style={{ color: "#000000", marginTop: 25, marginLeft: 30 }}>
              <FontAwesomeIcon
                style={{
                  weight: 12,
                  height: 24,
                  display: "flex",
                  justifyContent: "center",
                }}
                icon={faAngleRight}
              />
            </div>
          </div>
        </Link>
        {/* <mastercard? / */}
        <div
          style={{
            display: "flex",
            backgroundColor: "#F9F9F9",
            marginLeft: 19,
            marginRight: 20,
            marginTop: 10,
            borderRadius: 6,
          }}
        >
          <div>
            {" "}
            <img
              style={{
                height: 70,
              }}
              src="/assets/images/MasterCard.png"
              className="MasterCard-image"
            />
          </div>
          <div style={{ marginLeft: 8 }}>
            <div style={{ fontWeight: "bold", fontSize: 12, paddingTop: 19 }}>
              **** **** **** 5554
            </div>
            <div
              style={{
                color: "#C4C4C4",
                fontSize: 10,
                fontWeight: "bold",
                paddingTop: 5,
              }}
            >
              Expire 25/2026
            </div>
          </div>
          {/* ICon  */}
          <div style={{ color: "#000000", marginTop: 25, marginLeft: 30 }}>
            <FontAwesomeIcon
              style={{
                weight: 12,
                height: 24,
                display: "flex",
                justifyContent: "center",
              }}
              icon={faAngleRight}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeChooseAccount;

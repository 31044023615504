import { faArrowLeft, faImages } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { BrowserQRCodeReader } from "@zxing/browser";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { JDB_AUTH, MMONEY_AUTH } from "./graphql/mutation";
import swal from "sweetalert";
import { mmoneyQrFormatValidator, qrFormatValidator } from "../../helpers";
import "./Home.css";
import _ from "lodash";
import QrScanner from 'qr-scanner';
import { BACKGROUND_COLOR_APP, COLOR_APP, HEADER_HEIGHT } from "../../constants";
import { Modal } from "react-bootstrap";
import CustomAppBar from "../../components/CustomAppBar";
import { MdImage } from "react-icons/md";

function HomeQrPayMent() {
  const history = useNavigate();
  const [code, setCode] = useState("");
  const { t, i18n } = useTranslation();
  const [img, setImg] = useState();
  const [jdbAuthToken, setJdbAuthToken] = useState("");
  const [jdbTransactionId, setJdbTransactionId] = useState("");
  const [lat, setLat] = useState()
  const [lng, setLng] = useState()
  const [showQrError, setShowQrError] = useState(false)
  const [mmoneyToken, setMMoneyToken] = useState('');
  const [jdbCorrect, setJDBCorrect] = useState(false)


  const [getJDBAuth] = useMutation(JDB_AUTH);
  const [mmoneyAuth] = useMutation(MMONEY_AUTH);
  let called = false;
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    if (!called) {
      getJDBAuthToken();
      called = true;
    }
    scanCode();
  }, []);

  const videoRef = useRef(null);


  const getJDBAuthToken = async () => {
    try {
      const jdbAuthRes = await getJDBAuth();

      const jdbAuthResData = jdbAuthRes["data"];
      if (_.isEmpty(jdbAuthResData)) {
        swal(t("error-gateway-title"), t("error-gateway-detail"));
        history(`/home-add-creditcard`);
        return;
      }

      const success = jdbAuthResData["jdbAuth"]["success"];
      if (!success) {
        // alert(t("error-gateway"));
        swal(t("error-gateway-title"), t("error-gateway-detail"));
        history(`/home-add-creditcard`);
        return;
      }

      /**
       * get jdbAuthToken successfully
       */
      const transactionId = jdbAuthResData["jdbAuth"]["transactionId"];
      const jdbAuthToken = jdbAuthResData["jdbAuth"]["data"][0]["token"];

      setJdbTransactionId(transactionId);
      setJdbAuthToken(jdbAuthToken);
    } catch (err) {
      // alert(t("error-gateway"));
      swal(t("error-gateway-title"), t("error-gateway-detail"));
      history(`/home-add-creditcard`);
      return;
    }
  };

  useEffect(() => {

    if (code && jdbAuthToken && jdbCorrect) {
      // console.log("======>>> JDB Path")
      stopCamera()
      history(
        `/homeqr-payment-confirmtopay?code=${code}&jdbTransactionId=${jdbTransactionId}&jdbAuthToken=${jdbAuthToken}&lat=${lat}&lng=${lng}`
        , { state: { code: code } });
    }
  }, [code, jdbAuthToken]);

  useEffect(() => {
    if (code && mmoneyToken) {
      // console.log("=====>>>>>> Mmoney Path")
      stopCamera()
      history(`/homeqr-payment-confirmtopay?code=${code}&mmoneyToken=${mmoneyToken}&lat=${lat}&lng=${lng}`, { state: { code: code } });
    }
  }, [code, mmoneyToken]);


  const successCallback = (position) => {
    if (position.coords) {
      setLat(position.coords.latitude)
      setLng(position.coords.longitude)
    }
  };

  const errorCallback = (error) => {
    console.log(error);
  };

  const scanCode = async () => {
    try {

      var videoElement = document.getElementById('video');
      const scanner = new QrScanner(videoElement, async (detectedCode) => {
        // console.log("detectedCode: ", detectedCode)
        if (mmoneyQrFormatValidator(detectedCode)) {
          await getMmoneyAuth(detectedCode)
        } else {
          if (qrFormatValidator(detectedCode)) {
            setJDBCorrect(true)
            setCode(detectedCode);
          } else {
            setShowQrError(true)
          }
        }
        // setShowQrError(true)
        // else {
        //   getJDBAuthToken();
        // }

        // if (!qrFormatValidator(detectedCode)) {
        //   // swal(t("error-qr"), t("error-qr-detail")).then((result) => {
        //   //   window.location.reload();
        //   // });
        //   setShowQrError(true)
        // }
        // setJDBCorrect(true)
        // setCode(detectedCode);
      });
      scanner.start();
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   scanCode();
  // }, []);

  const getMmoneyAuth = async (detectedCode) => {
    const data = await mmoneyAuth();
    const authData = data.data.mmoneyAuth.accessToken
    setMMoneyToken(authData);
    setCode(detectedCode);
    // console.log('data ---------------->', authData);
    // console.log("===>>>>> getMmoneyAuth");
  }

  const _pickImage = async (event) => {
    try {
      const file = event.target.files[0];
      if (!file) return;
      QrScanner.scanImage(file, { returnDetailedScanResult: true })
        .then(async (result) => {
          var detectedCode = result.data
          if (_.isEmpty(detectedCode)) {
            // invalid qr code
            // swal(t("error-qr"), t("error-qr-detail")).then((result1) => {
            //   window.location.reload();
            // });
            setShowQrError(true)
            return;
          }

          /**
           * Mmoney QR Format Validator
           */
          // if (mmoneyQrFormatValidator(detectedCode)) {
          //   getMmoneyAuth()
          // }

          /**
           * JDB QR Format Validator
           */
          // if (!qrFormatValidator(detectedCode)) {
          //   // invalid qr code
          //   // swal(t("error-qr"), t("error-qr-detail")).then((result) => {
          //   //   window.location.reload();
          //   // });
          //   setShowQrError(true)

          //   return;
          // }

          // setCode(detectedCode);
          // console.log("detectedCode: ", detectedCode)
          if (mmoneyQrFormatValidator(detectedCode)) {
            await getMmoneyAuth(detectedCode)
          } else {
            if (qrFormatValidator(detectedCode)) {
              setJDBCorrect(true)
              setCode(detectedCode);
            } else {
              setShowQrError(true)
            }
          }
        })
        // .catch(el => swal(t("error-qr"), el).then((result) => {
        //   window.location.reload();
        // }));
        .catch(el => setShowQrError(true))
    } catch (err) {
      console.log("err: ", err)
      // swal(t("error-qr"), t("error-qr-detail")).then((result) => {
      //   window.location.reload();
      // });
      setShowQrError(true)
    }
  };

  const goBack = async() => {
     stopCamera()
    history(-1)
  }

  const stopCamera = () => {
    const stream = videoRef.current.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  return (
    <div style={{ backgroundColor: "rgba(30, 30, 30, 0.5)" }}>

      <CustomAppBar
        gotoOtherPage={goBack}
        title={t("scan-to-pay")}
      />

      <div>
        <div style={{ height: "100vh", width: "100%", backgroundColor: "#1c1c1c" }}>
          <div className="scanner-container">
            <video
              poster="data:image/gif,AAAA"
              id="video"
              ref={videoRef}
              
            ></video>
            <div className="overlay-element"></div>
            <div className="laser"></div>
          </div>



          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{
              position: "absolute",
              marginTop: 10,
              bottom: 82
            }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ fontSize: 18, fontWeight: "bold", color: "white" }}>{t("please-scan-to")}</div>
                <div style={{ width: 10 }} />
                <img src="assets/images/LAOQR.png" alt="" style={{
                  width: 40
                }} />
              </div>
            </div>

            <input
              id="file-upload"
              type="file"
              accept="image/*"
              name="file-upload"
              onChange={_pickImage}
              style={{
                position: "absolute",
                width: "100%",
              }}
              hidden
            />

            <label htmlFor="file-upload" style={{
              bottom: 20,
              borderRadius: 10,
              backgroundColor: "white",
              padding: "10px 13px",
              position: "absolute",
            }}>
              <div style={{
                display: "flex",
                alignItems: "center",

              }}>
                <MdImage fontSize={27} />
                <div style={{ width: 10 }} />
                <div style={{ fontSize: 16, fontWeight: "bold", color: "#4B4B4B" }}>{t("select-photo")}</div>
              </div>
            </label>
            <img
              width={0}
              height={0}
              id="imagetag"
              src={img}
              alt=""
              display="none"
            />
          </div>
        </div>
      </div>

      <Modal
        show={showQrError}
        size="sm"
        centered
      >
        <Modal.Body style={{ textAlign: "center" }}>
          <div style={{ fontSize: 24, fontWeight: "bold", color: "#000000A6" }}>
            {t("error-qr")}
          </div>
          <div style={{ textAlign: "center", marginTop: 10, color: "#000000A3" }}>{t("error-qr-detail")}, {t("please_make_sure_qr")}</div>
          <img src="assets/images/LAOQR.png" alt="" width={100} />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button className="btn btn-primary" style={{ backgroundColor: COLOR_APP, border: "none" }}
              onClick={() => { setShowQrError(false); window.location.reload() }}
            >{t("ok")}</button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default HomeQrPayMent;

import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import HomeAccount from '../pages/Home/HomeAccount';
import HomeAccountCardDetails from '../pages/Home/HomeAccountCardDetails';
import HomeAddCreditCard from '../pages/Home/HomeAddCreditCard';
import HomeAddNewAccount from '../pages/Home/HomeAddNewAccount';
import HomeChooseAccount from '../pages/Home/HomeChooseAccount';
import HomeHistory from '../pages/Home/HomeHistory';
import HomeHistoryDetails from '../pages/Home/HomeHistoryDetails';
import HomeProfile from '../pages/Home/HomeProfile';
import HomeQrPayMent from '../pages/Home/HomeQrPayMent';
import HomeQrPaymentConfirmToPay from '../pages/Home/HomeQrPaymentConfirmToPay';
import HomeQrPaymentConfirmToPayPin from '../pages/Home/HomeQrPaymentConfirmToPayPin';
import HomeQrPaymentConfirmToPaySuccess from '../pages/Home/HomeQrPaymentConfirmToPaySuccess';
import Kyc from '../pages/KYC/Kyc';
import KycSingUp from '../pages/KYC/KycSingUp';
import KycSingupDetail from '../pages/KYC/KycSingupDetail';
import KycRejected from '../pages/KYC/KycRejected';
import KycCondition from '../pages/KYC/KycCondition';
import KycEdit from '../pages/KYC/KycEdit';
import KycWait from '../pages/KYC/KycWait';
import HomeProfileInfo from '../pages/Home/HomeProfileInfo';
import HomeProfileLanguage from '../pages/Home/HomeProfileLanguage';
import HomeProfilePin from '../pages/Home/HomeProfilePin';
import HomeProfilePinConfirm from '../pages/Home/HomeProfilePinConfirm';
import HomeProfileHint from '../pages/Home/HomeProfileHint';
import Index from '../pages';
import HomeProfileSeePin from '../pages/Home/HomeProfileSeePin';
import HomePinSeeCard from '../pages/Home/HomePinSeeCard';
import HomeBanDetail from '../pages/Home/HomeBanDetail';
import HomeSelectPayments from '../pages/Home/HomeSelectPayments';
import HomeQrPaymentPin from '../pages/Home/HomeQrPaymentPin';
import HomePaymentError from '../pages/Home/HomePaymentError';
import HomeQrScan from '../pages/Home/HomeQrScan';
import ForgotPin from '../pages/Home/ForgotPin/ForgotPin';
import ForgotPinNewPin from '../pages/Home/ForgotPin/ForgotPinNewPin';
import ForgotPinHint from '../pages/Home/ForgotPin/ForgotPinHint';
import ForgotPinSuccess from '../pages/Home/ForgotPin/ForgotPinSuccess';
import ForgotPinExpire from '../pages/Home/ForgotPin/ForgotPinExpire';
import { ACCESS_TOKEN } from '../constants';
import KycCheckStatus from '../pages/KYC/KycCheckStatus';
import HomeQrPaymentDetail from '../pages/Home/HomeQrPaymentDetail';
import BuyDataPackageList from '../pages/Home/BuyDataPackage/BuyDataPackageList';
import BuyDataPackageDetail from '../pages/Home/BuyDataPackage/BuyDataPackageDetail';
import BuyDataPackagePin from '../pages/Home/BuyDataPackage/BuyDataPackagePin';
import BuyDataPackageSuccess from '../pages/Home/BuyDataPackage/BuyDataPackageSuccess';
import HomeComfirmPaymentSuccess from '../pages/Home/HomeComfirmPaymentSuccess';


function PrivateRoute({ children }) {
  let isAuthenticated = false;
  const token = localStorage.getItem(ACCESS_TOKEN);
  if (token) {
    isAuthenticated = true;
  }
  return isAuthenticated ? children : <Navigate to="/home-profile-pin/forgot-pin-expire" />;
}

function IndexRoutes() {



  return (
    <BrowserRouter>
      <Routes>
        {/* ----- HOME */}
        <Route path="/home-comfirm-payment-success" element={ <HomeComfirmPaymentSuccess/> } />

        <Route path="/home-add-creditcard" element={
          <PrivateRoute>
            <HomeAddCreditCard />
          </PrivateRoute>} />
        <Route path="/home-account" element={
          <PrivateRoute>
            <HomeAccount />
          </PrivateRoute>
        } />
        <Route path="/home-add-new-account" element={
          <PrivateRoute>
            <HomeAddNewAccount />
          </PrivateRoute>
        } />
        <Route path="/home-profile" element={
          <PrivateRoute>
            <HomeProfile />
          </PrivateRoute>
        } />
        <Route path="/home-account-carddetails" element={
          <PrivateRoute>
            <HomeAccountCardDetails />
          </PrivateRoute>
        } />
        <Route path="/home-profile-info" element={
          <PrivateRoute>
            <HomeProfileInfo />
          </PrivateRoute>} />
        <Route path="/home-profile-language" element={
          <PrivateRoute>
            <HomeProfileLanguage />
          </PrivateRoute>} />
        <Route path="/home-profile-pin" element={
          <PrivateRoute>
            <HomeProfilePin />
          </PrivateRoute>} />
        <Route path="/home-profile-pin-confirm" element={
          <PrivateRoute>
            <HomeProfilePinConfirm />
          </PrivateRoute>} />
        <Route path="/home-profile-hint" element={
          <PrivateRoute>
            <HomeProfileHint />
          </PrivateRoute>} />
        <Route path="/home-profile-see-pin" element={
          <PrivateRoute>
            <HomeProfileSeePin />
          </PrivateRoute>} />
        <Route path="/home-pin-see-card" element={
          <PrivateRoute>
            <HomePinSeeCard />
          </PrivateRoute>} />
        <Route path="/home-ban-detail" element={
          <PrivateRoute>
            <HomeBanDetail />
          </PrivateRoute>} />
        <Route path='/home-select-payments' element={
          <PrivateRoute>
            <HomeSelectPayments />
          </PrivateRoute>
        } />

        {/* KYC  */}
        <Route path="/kycsignup" element={
          <PrivateRoute>
            <KycSingUp />
          </PrivateRoute>
        } />

        {/* QR PAYMENT  */}
        <Route path="/home-qr-payment" element={
          <PrivateRoute>
            <HomeQrPayMent />
          </PrivateRoute>

        } />
        <Route path="/homechooseaccount" element={
          <PrivateRoute>
            <HomeChooseAccount />
          </PrivateRoute>
        } />
        <Route path="/homeqr-payment-confirmtopay" element={
          <PrivateRoute>
            <HomeQrPaymentConfirmToPay />
          </PrivateRoute>
        } />
        <Route path="/home-payment-error" element={
          <PrivateRoute>
            <HomePaymentError />
          </PrivateRoute>
        } />
        <Route path="/homeqr-payment-pin" element={
          <PrivateRoute>
            <HomeQrPaymentPin />
          </PrivateRoute>
        } />
        <Route path="/homeqrpaymentconfirmtopaypin" element={
          <PrivateRoute>
            <HomeQrPaymentConfirmToPayPin />
          </PrivateRoute>
        } />
        <Route path="/homeqr-payment-confirm-to-paysuccess" element={
          <PrivateRoute>
            <HomeQrPaymentConfirmToPaySuccess />
          </PrivateRoute>
        } />
        <Route path="/homeqr-payment-detail" element={
          <PrivateRoute>
            <HomeQrPaymentDetail />
          </PrivateRoute>
        } />

        {/* <History Payment  */}
        <Route path="/home-history" element={
          <PrivateRoute>
            <HomeHistory />
          </PrivateRoute>
        } />
        <Route path="/home-history-details" element={
          <PrivateRoute>
            <HomeHistoryDetails />
          </PrivateRoute>
        } />


        {/* BuyDataPackage */}
        <Route path="/buy-data-package-list" element={
          <PrivateRoute>
            <BuyDataPackageList />
          </PrivateRoute>
        } />
        <Route path="/buy-data-package-detail" element={
          <PrivateRoute>
            <BuyDataPackageDetail />
          </PrivateRoute>
        } />
        <Route path="/buy-data-package-pin" element={
          <PrivateRoute>
            <BuyDataPackagePin />
          </PrivateRoute>
        } />
        <Route path="/buy-data-package-success" element={
          <PrivateRoute>
            <BuyDataPackageSuccess />
          </PrivateRoute>
        } />


        {/* KYC  */}

        <Route path="/kyc" element={
          <PrivateRoute>
            <Kyc />
          </PrivateRoute>
        } />
        <Route path="/kycsingupdetail" element={
          <PrivateRoute>
            <KycSingupDetail />
          </PrivateRoute>
        } />
        <Route path="/kyc-condition" element={
          <PrivateRoute>
            <KycCondition />
          </PrivateRoute>
        } />
        <Route path="/kyc-edit" element={
          <PrivateRoute>
            <KycEdit />
          </PrivateRoute>
        } />
        <Route path="/kyc-wait" element={
          <PrivateRoute>
            <KycWait />
          </PrivateRoute>
        } />
        <Route path="/kyc-check-status" element={
          <PrivateRoute>
            <KycCheckStatus />
          </PrivateRoute>
        } />

        {/* <Route path="/kycsingup" element={<KycSingUp />} /> */}

        {/* PassengerReject  */}

        <Route path="/kyc-rejected" element={<KycRejected />} />
        <Route path='/' element={<Index />} />
        <Route path='/home-scan-qr' element={
          <PrivateRoute>
            <HomeQrScan />
          </PrivateRoute>
        } />
        <Route path='/home-profile-pin/forgot-pin' element={
          <PrivateRoute>
            <ForgotPin />
          </PrivateRoute>
        } />
        <Route path='/home-profile-pin/forgot-pin-hint' element={<ForgotPinHint />} />
        <Route path='/home-profile-pin/forgot-pin-newpin' element={<ForgotPinNewPin />} />
        <Route path='/home-profile-pin/forgot-pin-success' element={<ForgotPinSuccess />} />
        <Route path='/home-profile-pin/forgot-pin-expire' element={<ForgotPinExpire />} />




      </Routes>
    </BrowserRouter>
  );
}

export default IndexRoutes; 
import {
  faArrowLeft,
  faCircleXmark,
  faCircleCheck,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
//   import QRCode from "react-qr-code";
import { numberWithCommas } from "../../constants/ShowFormat";
import moment from "moment";
import { Watermark } from "@hirohe/react-watermark";
import "./Home.css";
import { GET_CARD, GET_DETAIL_PAYMENT } from "./graphql/query";
//   import {  } from "./graphql/query";
import Loading from "../../components/Loading";
import { BACKGROUND_COLOR_APP, COLOR_APP, HEADER_HEIGHT } from "../../constants";
import CustomAppBar from "../../components/CustomAppBar";
import { Button } from "react-bootstrap";

function HomeComfirmPaymentSuccess() {
  const history = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const [canScroll, setCanScroll] = useState(false);
  const ref = useRef(null);
  let id = new URLSearchParams(location.search).get("id");

  let price = new URLSearchParams(location.search).get("price");
  let createdAt = new URLSearchParams(location.search).get("createdAt");
  let cardId = new URLSearchParams(location.search).get("cardId");
  let refNo = new URLSearchParams(location.search).get("refNo");
  const [detailPayment, setDetailPayment] = useState({});

  // const [getCard, { data: cardData, loading: cardLoading }] = useLazyQuery(
  //   GET_CARD
  //   );
  const [getPayment, { data: dataPayment, loading: paymentLoading }] = useLazyQuery(
    GET_DETAIL_PAYMENT
  );

  useEffect(() => {
    getPayment({
      variables: {
        where: {
          id: id
        }
      }
    })

  }, []);

  const [text, setText] = useState(
    `$refNo:${numberWithCommas(parseFloat(price).toFixed(2))}-${detailPayment['billNo'] === undefined ? '' : detailPayment['billNo']}-at:${moment(createdAt).format(
      "DD-MM-YYYY"
    )}`
  );

  // const setData = async (detailPayment) => {
  //   const data = await detailPayment.billNo; 
  //   setPrice(data)
  //   // console.log('---------->',detailPayment.billNo);
  // } 

  // useEffect(() => {
  //   setData(detailPayment);
  // }, [detailPayment])

  useEffect(() => {
    if (dataPayment) {
      setDetailPayment(dataPayment['paymentComfirm'])
    }
    // setText(`billNo:${String(detailPayment['paymentComfirm'].billNo)}`)
  }, [dataPayment])


  // console.log('delte -------> ', detailPayment.billNo, detailPayment.total);
  if (paymentLoading) {
    return <Loading />;
  }


  return (
    <div className="wrapper" style={{ backgroundColor: BACKGROUND_COLOR_APP }}>


      <div style={{
        height: 60,
        display: "flex",
        justifyContent: "center",
        padding: 17,
        zIndex: 2000,
        boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.1)",
        backgroundColor: "white",
        position: "sticky",
        top: 0
      }}
      >
        <div style={{ fontSize: 18, fontWeight: 700 }}>Payment Detail</div>
      </div>
      <div
        style={
          canScroll
            ? { position: "fixed", overflowY: "scroll", width: "100%" }
            : {}
        }
        ref={ref}
      >
        <Watermark
          text={text}
          rotate={-20}
          textSize={12}
          fontFamily={"Times New Roman"}
          multiline={true}
          opacity={0.4}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: 24,
                marginRight: 24,
                marginTop: 10,
              }}
            >
              <div>
                <div style={{ fontSize: 17, fontWeight: "bold" }}>
                  {t("payment-success")}
                </div>
                <div style={{ marginTop: 13, color: "#4B4B4B" }}>
                  {moment(detailPayment.createdAt).format("DD/MM/YYYY HH:mm")}
                </div>

                <div style={{ display: "flex", flexDirection: "row", marginTop: 4 }}>
                  <div
                    style={{
                      fontSize: 16,
                      marginTop: 6,
                      color: "#999999",
                      marginBottom: 7
                    }}
                  >

                    {t("amount")}
                  </div>
                  <div style={{ width: 10 }} />
                  <div style={{ color: "#EB001B", fontSize: 24, fontWeight: "bold" }}>
                    LAK {numberWithCommas(parseFloat(detailPayment.amount))}
                  </div>
                </div>

                <div style={{ display: "flex", flexDirection: "row", marginTop: 4 }}>
                  <div
                    style={{
                      fontSize: 16,
                      color: "#999999",
                    }}
                  >
                    {t("payment-fee")}
                  </div>
                  <div style={{ width: 10 }} />
                  <div style={{ fontSize: 30, color: "#EB001B", fontSize: 16 }}>
                    LAK {numberWithCommas(parseFloat(detailPayment.totalFees))}
                  </div>
                </div>
              </div>
              <div style={{ paddingRight: 10 }}>
                {" "}
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="#139A18"
                  fontSize={70}
                />
              </div>
            </div>
          </div>

          <div style={{ padding: 20, paddingTop: 11 }}>

            <div
              style={{
                fontSize: 16,
                color: "#999999",
              }}
            >
              {t("payment-to")}
            </div>

            <div
              style={{
                display: "flex",
                height: 70,
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: 6,
                padding: 18,
                border: "1px solid #ECECEC"
              }}
            >
              <div style={{ fontSize: 16, fontWeight: "bold" }}>{detailPayment.destMerchantName ? detailPayment.destMerchantName : `DataPackage ${numberWithCommas(parseFloat(detailPayment.packageAmount))} MB`}</div>
              <div>
                <img
                  style={{
                    width: 50,
                    height: 50,
                  }}
                  src={detailPayment.bankName == 'TELBIZ' ? (
                    detailPayment.qrType == "LTC" ? "/assets/images/ltc_logo.jpeg" :
                      detailPayment.qrType == "UNITEL" ? "/assets/images/unitel_logo.jpeg" :
                        detailPayment.qrType == "ETL" ? "/assets/images/etl_logo.png" :
                          "/assets/images/box-loading.jpg"
                  ) : detailPayment.bankLogoUrl ? detailPayment.bankLogoUrl :
                    "/assets/images/box-loading.jpg"
                  }
                />
              </div>
            </div>

            <div
              style={{
                fontSize: 16,
                color: "#999999",
                marginTop: 11,
              }}
            >
              {t("payment-from")}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: 6,
                padding: 18,
                paddingTop: 10,
                paddingBottom: 10,
                border: "1px solid #ECECEC"
              }}
            >
              <div>
                <div style={{ fontSize: 16, fontWeight: "bold" }}>{detailPayment.cardHolderName}</div>
                <div style={{ fontSize: 14, fontWeight: "bold" }}>
                  ************{detailPayment.cardLast4digit}
                </div>
                {/* <div
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                      color: "#898989",
                    }}
                  >
                    {t("card-expire")} {!card.expireMonth || 'huevang'}/
                    {!card.expireYear || 'huevang'} 
                  </div> */}
              </div>
              <img
                style={{
                  height: detailPayment.cardType == "discover" || detailPayment.cardType == "diners" ? 20 :
                    detailPayment.cardType === "mastercard" ? 40 :
                      detailPayment.cardType === "visa" ? 20 :
                        50,
                }}
                src={
                  detailPayment.cardType == "visa"
                    ? "/assets/images/visa_icon.png"
                    : detailPayment.cardType == "jcb"
                      ? "/assets/images/jcb.png"
                      : detailPayment.cardType == "discover" || detailPayment.cardType == "diners"
                        ? "/assets/images/discover_diner.png"
                        : detailPayment.cardType == "unionpay"
                          ? "/assets/images/union_pay.png"
                          : detailPayment.cardType == "amex"
                            ? "/assets/images/amex.png"
                            : "/assets/images/mastercards.png"
                }
              />
            </div>



            {
              detailPayment.billNo === '' ?
                <>
                  <div style={{ marginTop: 11 }}></div>
                </> :
                <>
                  <div
                    style={{
                      fontSize: 16,
                      marginTop: 11,
                      color: "#999999",
                    }}
                  >
                    {t("payment-bill")}
                  </div>
                  <div>{detailPayment.bankName === "TELBIZ" ? detailPayment.bankTansactionId : detailPayment.billNo}</div>
                </>
            }

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {
                !detailPayment.description ? <>
                  <div></div>
                </> :
                  <>
                    <div>
                      <div
                        style={{
                          fontSize: 16,
                          marginTop: 11,
                          color: "#999999",
                          marginBottom: 11
                        }}
                      >
                        {t("payment-des")}
                      </div>
                      <div>{detailPayment.description}</div>
                    </div>
                  </>
              }
            </div>
          </div>

          <div style={{
            textAlign: "center",
            marginTop: 12,
            fontWeight: "bold",
            fontSize: 18,
            color: "#8C8C8C"
          }}>P O W E R E D <span style={{ marginRight: 6 }} />B Y
            <span style={{ color: COLOR_APP, marginLeft: 12 }}>L O C A
              <span style={{ marginLeft: 6 }} />P A Y</span></div>


        </Watermark>
      </div>
    </div>
  );
}

export default HomeComfirmPaymentSuccess;

  // <div style={{ height: 70 }} />
  // <div style={{
  //   position: "fixed",
  //   bottom: 0,
  //   left: 0,
  //   right: 0,
  //   width: "100%"
  // }}>
  //    <Button
  //     style={{
  //       height: 50,
  //       backgroundColor: COLOR_APP,
  //       textAlign: "center",
  //       border: "none",
  //       width: "100%",
  //       fontWeight: 700,
  //       fontSize: 16
  //     }}
  //     onClick={() => _gotoBack()}
  //   >
  //     {t("success")}
  //   </Button>
  // </div>
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import HomeBottomNav from './HomeBottomNav'

function HomeLoading() {
    return (
        <div style={{ display: "flex", alignItems: "center", height: "92vh" }}>
            <div>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "30%", marginTop: 10, marginLeft: 10 }}>
                        <Skeleton height={120} baseColor="#EFF2F5" />
                    </div>
                    <div style={{ width: "70%", marginTop: 10, marginLeft: 10 }}>
                        <Skeleton width={160} height={18} baseColor="#EFF2F5" />
                        <Skeleton width={90} height={18} baseColor="#EFF2F5" style={{ marginTop: 15 }} />
                        <Skeleton width={190} height={18} baseColor="#EFF2F5" style={{ marginTop: 15 }} />
                        <Skeleton width={90} height={18} baseColor="#EFF2F5" style={{ marginTop: 15 }} />
                    </div>
                </div>
                <div style={{ borderBottom: "1px solid #E8EDF1", marginTop: 20 }} />
                <div style={{ display: "flex" }}>
                    <div style={{ width: "30%", marginTop: 10, marginLeft: 10 }}>
                        <Skeleton height={120} baseColor="#EFF2F5" />
                    </div>
                    <div style={{ width: "70%", marginTop: 10, marginLeft: 10 }}>
                        <Skeleton width={160} height={18} baseColor="#EFF2F5" />
                        <Skeleton width={90} height={18} baseColor="#EFF2F5" style={{ marginTop: 15 }} />
                        <Skeleton width={190} height={18} baseColor="#EFF2F5" style={{ marginTop: 15 }} />
                        <Skeleton width={90} height={18} baseColor="#EFF2F5" style={{ marginTop: 15 }} />
                    </div>
                </div>
                <div style={{ borderBottom: "1px solid #E8EDF1", marginTop: 20 }} />
                <div style={{ display: "flex" }}>
                    <div style={{ width: "30%", marginTop: 10, marginLeft: 10 }}>
                        <Skeleton height={120} baseColor="#EFF2F5" />
                    </div>
                    <div style={{ width: "70%", marginTop: 10, marginLeft: 10 }}>
                        <Skeleton width={160} height={18} baseColor="#EFF2F5" />
                        <Skeleton width={90} height={18} baseColor="#EFF2F5" style={{ marginTop: 15 }} />
                        <Skeleton width={190} height={18} baseColor="#EFF2F5" style={{ marginTop: 15 }} />
                        <Skeleton width={90} height={18} baseColor="#EFF2F5" style={{ marginTop: 15 }} />
                    </div>
                </div>
            <HomeBottomNav />
            </div>
        </div>
    )
}

export default HomeLoading

import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useLazyQuery } from "@apollo/client";

import { GET_USER } from "../graphql/query";
import { UPDATE_USER_REJECTED } from "./graphql/mutation";
import { COLOR_APP, USER_ID } from "../../constants";
import { useMutation } from "@apollo/client";
import Loading from "../../components/Loading";
import _ from "lodash";
import { CREATE_UPPASS_FORM } from "../Home/graphql/mutation";

function KycRejected() {
  const history = useNavigate();
  const { t, i18n } = useTranslation();
  const [updateUser] = useMutation(UPDATE_USER_REJECTED);
  const userId = localStorage.getItem(USER_ID);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();

  const [getUser, { data: userData }] = useLazyQuery(GET_USER);
  const [createUpPassForm] = useMutation(CREATE_UPPASS_FORM);

  useEffect(() => {
    getUser({
      variables: {
        where: {
          id: userId,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (!_.isEmpty(userData)) {
      const user = userData["user"];
      setUser(user);
    }
  }, [userData]);

  const goToBack = () => {
    const profileImage = user["profileImage"];
    const firstName = user["firstName"];
    const lastName = user["lastName"];
    const phone = user["phone"];
    const sid = user["sid"];
    history(
      `/kyc?profileImage=${profileImage}&firstName=${firstName}&lastName=${lastName}&phone=${phone}&sid=${sid}`
    );
    // getUpPassUrl()
  };

  const getUpPassUrl = async () => {
    try {
      setLoading(true);
      const url = await createUpPassForm({
        variables: {
          userId
        }
      });
      if (url) {
        // setLoading(false)
        const UpPassURL = url.data.createUpPassForm.url;
        window.location.href = UpPassURL;
      }else {
        setLoading(false)
      }
      // setLoading(false)
    } catch (error) {
      setLoading(false)
      // console.log(error)
    }
  }

  const _changeUserStatus = async () => {
    setLoading(true);
    try {
      const _res = await updateUser({
        variables: {
          data: {
            userStatus: "NOT_REGISTER",
          },
          where: {
            id: userId,
          },
        },
      });
      if (_res) {
        // setLoading(false);
        goToBack();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="wrapper">
      <div style={{ height: 50 }}></div>
      <center>
        <img
          style={{
            width: "70%",
            height: "70%",
          }}
          src="/assets/images/passenger_reject.png"
          alt=""
        />
      </center>
      <div style={{ height: 33 }}></div>
      <center>
        <div style={{ fontSize: 24, fontWeight: "bold" }}>
          {/* ການລົງທະບຽນຂອງທ່ານຜິດພາດ */}
          {t("kyc-rejected-fail")}
        </div>
      </center>
      <div style={{ height: 12 }}></div>
      <center>
        <div style={{ color: "#898989", fontSize: 14, fontWeight: "bold" }}>
          {/* ກະລຸນາລົງທະບຽນໃໝ່ອີກຄັ້ງ */}
          {t("kyc-rejected-again")}
        </div>
      </center>
      <div style={{ height: 81 }}></div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          style={{
            backgroundColor: COLOR_APP,
            width: "90%",
            height: 44,
            marginTop: 20,
            border: "none",
          }}
          onClick={() => _changeUserStatus()}
        >
          {/* Back */}
          {t("ok")}
        </Button>
      </div>
    </div>
  );
}

export default KycRejected;

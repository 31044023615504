import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BACKGROUND_COLOR_APP, COLOR_APP } from "../../constants";
import CustomAppBar from "../../components/CustomAppBar";

function HomeProfileSeePin() {
  const history = useNavigate();
  const location = useLocation();

  const { t, i18n } = useTranslation();
  const [canScroll, setCanScroll] = useState(false);
  const { innerWidth: width, innerHeight: height } = window;
  const ref = useRef(null);

  const hintText = new URLSearchParams(location.search).get("hintText");

  useEffect(() => {
    if (ref.current.clientHeight < height) setCanScroll(true);
    else setCanScroll(false);
  }, []);

  const changeNewPin = () => {
    history(`/home-profile-pin?hintText=${hintText}`);
  };

  //routes
  const _goBackHomeProfilePin = () => {
    history(-1);
  };

  return (
    <div
    className="wrapper" 
      style={
        canScroll
          ? { position: "fixed", overflowY: "scroll", width: "100%",backgroundColor: BACKGROUND_COLOR_APP }
          : {backgroundColor: BACKGROUND_COLOR_APP}
      }
      ref={ref}
      
    >
      {/* <div
        style={{
          display: "flex",
          height: 49,
        }}
      >
        <FontAwesomeIcon
          style={{
            marginLeft: 18,
            marginTop: 17,
            height: 20,
            width: 16,
            fontWeight: "bold",
          }}
          icon={faArrowLeft}
          onClick={() => _goBackHomeProfilePin()}
        />
      </div> */}

      <CustomAppBar
        gotoOtherPage={_goBackHomeProfilePin}
        title={"LOCA PAY"}
      />

      <div style={{ height: 31 }} />
      <div style={{ textAlign: "center", fontWeight: "bold" }}>
        {/* PIN Setting */}
        {t("setting-pin")}
      </div>
      <div style={{ height: 20 }} />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Form.Control
          as="textarea"
          rows={3}
          style={{
            width: "90%",
            fontSize: 20,
            fontWeight: "bold",
            textAlign: "center",
          }}
          defaultValue={hintText}
          disabled
        />
      </div>

      <div
        style={{
          position: "fixed",
          bottom: 15,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          style={{
            backgroundColor: COLOR_APP,
            borderColor: COLOR_APP,
            height: 50,
            width: "90%",
          }}
          onClick={() => changeNewPin()}
        >
          {/* Change Pin */}
          {t("setting-change")}
        </Button>
      </div>
    </div>
  );
}

export default HomeProfileSeePin;

import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { USER_ID } from '../../constants';
import { GET_USER } from '../graphql/query';

function KycCheckStatus() {
    const [show, setShow] = useState(false)
    const history = useNavigate()
    const { t, i18n } = useTranslation();
    const userId = localStorage.getItem(USER_ID);

    const [
        getUser,
        { data: userQueryData, loading: loading },
    ] = useLazyQuery(GET_USER);

    useEffect(() => {
        getUser({
            variables: {
                where: {
                    id: userId,
                },
            },
        });
    }, [])

    useEffect(() => {
        if (userQueryData) {
            // console.log("userQueryData.user: ", userQueryData.user)
            if (userQueryData.user.userStatus === "VERIFIED") {
                setShow(true)
                setTimeout(() => {
                    history(`/home-add-creditcard`)
                }, 2000)
            } else {
                history(`/kyc-wait`)
            }
        }
    }, [userQueryData])

    return (
        <div>
            <Modal show={show} size="sm" onHide={() => setShow(false)} centered>
                <Modal.Body style={{ textAlign: "center" }}>
                    <BsFillCheckCircleFill
                        style={{ fontSize: 60, color: "#0DCD5A", marginBottom: 10 }}
                    />
                    <div style={{ fontSize: 20 }}>
                        {t("kyc-approved")}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default KycCheckStatus

import { faArrowLeft, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { USER_ID } from "../../constants";
import { useLazyQuery } from "@apollo/client";
import { GET_USER } from "./graphql/query";
// import moment from 'moment'
import "./Home.css";
import { useTranslation } from "react-i18next";

function HomePaymentError() {
  const history = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  let price = new URLSearchParams(location.search).get("price");
  let cardId = new URLSearchParams(location.search).get("cardId");
  let expireMonth = new URLSearchParams(location.search).get("expireMonth");
  let expireYear = new URLSearchParams(location.search).get("expireYear");
  let description = new URLSearchParams(location.search).get("description");
  let qrType = new URLSearchParams(location.search).get("description")
  let bankName = new URLSearchParams(location.search).get("bankName")
  let destMerchantName = new URLSearchParams(location.search).get(
    "destMerchantName"
  );
  let bankLogoUrl = new URLSearchParams(location.search).get("bankLogoUrl");

  var nf = new Intl.NumberFormat();

  const userId = localStorage.getItem(USER_ID);

  const [getUser, { data: userData, loading: loading }] = useLazyQuery(
    GET_USER
  );

  useEffect(() => {
    getUser({
      variables: {
        where: {
          id: userId,
        },
      },
    });

    creditCard(cardId);
  }, []);

  const creditCard = (card) => {
    let starsString = card.slice(0, -4);
    let showNumbers = card.slice(-4);
    let regMe = /[0-9]/g;
    let allStars = starsString.replace(regMe, "*");

    return allStars + showNumbers;
  };

  const gotoHome = () => {
    localStorage.removeItem("DESCRIPTION");
    history(`/home-add-creditcard`);
  };

  const _goBack = () => {
    history(-1);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          height: 49,
        }}
      >
        <FontAwesomeIcon
          style={{
            marginLeft: 18,
            marginTop: 17,
            height: 20,
            width: 16,
            fontWeight: "bold",
            color: "#252A31",
          }}
          icon={faArrowLeft}
          onClick={() => _goBack()}
        />
      </div>
      {/* --- */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: 24,
          marginRight: 24,
          marginTop: 30,
        }}
      >
        <div>
          <div style={{ fontSize: 17 }}>
            {/* Payment Failed */}
            {t("payment-failed")}
          </div>
          <div style={{ fontSize: 30, color: "red" }}>
            LAK {nf.format(price)}
          </div>
        </div>
        <FontAwesomeIcon icon={faTimesCircle} color="red" fontSize={50} />
      </div>

      {/* <div style={{ marginTop: 22, marginBottom: 24, marginLeft: 24, fontSize: 12, color: "#898989", fontWeight: "bold" }}>12/5/2021  15:00</div> */}

      <div
        className="box-shadow-sm"
        style={{
          marginLeft: 24,
          marginRight: 24,
          height: "100%",
          borderRadius: 6,
          paddingBottom: 40,
        }}
      >
        <div
          style={{
            marginLeft: 30,
            paddingTop: 35,
            color: "#898989",
            fontSize: 12,
            fontWeight: "bold",
          }}
        >
          {/* To  */}
          {t("payment-to")}:
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: 40,
            marginRight: 30,
          }}
        >
          <div>
            <div style={{ fontSize: 14, fontWeight: "bold" }}>
              {destMerchantName}
            </div>
            {/* <div style={{ color: "#898989", fontSize: 12 }}>
              **** **** **** 4444
            </div> */}
          </div>
          <div>
            {" "}
            <img
              style={{
                width: 60,
                height: 60,
              }}
              src={bankName == 'TELBIZ' ? (
                qrType == "LTC" ? "/assets/images/ltc_logo.jpeg" :
                  qrType == "UNITEL" ? "/assets/images/unitel_logo.jpeg" :
                    qrType == "ETL" ? "/assets/images/etl_logo.png" :
                      "/assets/images/box-loading.jpg"
              ) : bankLogoUrl ? bankLogoUrl :
                "/assets/images/box-loading.jpg"
              }
            />
          </div>
        </div>
        {/* ---MasterCard  */}
        <div
          style={{
            marginLeft: 30,
            marginTop: 35,
            color: "#898989",
            fontSize: 12,
            fontWeight: "bold",
          }}
        >
          {/* Form */}
          {t("payment-from")}:
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: 40,
            marginRight: 30,
          }}
        >
          <div>
            {userData ? (
              <div style={{ fontSize: 14, fontWeight: "bold" }}>
                {userData.user.firstName} {userData.user.lastName}
              </div>
            ) : (
              <div />
            )}
            <div style={{ color: "#898989", fontSize: 12 }}>
              {creditCard(cardId)}
            </div>
            <div style={{ color: "#898989", fontSize: 10, fontWeight: "bold" }}>
              Expire {expireMonth}/{expireYear}
            </div>
          </div>
          <div>
            {" "}
            <img
              style={{
                height: 60,
              }}
              src="/assets/images/MasterCard.png"
              alt=""
            />
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <div
              style={{
                marginLeft: 30,
                paddingTop: 35,
                color: "#898989",
                fontSize: 12,
                fontWeight: "bold",
              }}
            >
              {/* Bill No */}
              {t("payment-bill")}
            </div>

            <div style={{ marginLeft: 40, marginTop: 24 }}>
              Loca0001fffsfsfsfsf
            </div>
            <div
              style={{
                marginLeft: 30,
                paddingTop: 35,
                color: "#898989",
                fontSize: 12,
                fontWeight: "bold",
              }}
            >
              {/* Descriptions */}
              {t("payment-des")}
            </div>

            <div style={{ marginLeft: 40, marginTop: 24 }}>{description}</div>
          </div>
          <div
            style={{
              justifyContent: "end",
              alignItems: "end",
              display: "flex",
              paddingRight: 35,
            }}
          >
            {" "}
            <img
              style={{
                width: 80,
                height: 80,
              }}
              src="/assets/images/Qr-code-black.png"
              alt=""
            />
          </div>
        </div>
      </div>

      <div style={{ height: 80 }} />
      <Button
        style={{
          height: 50,
          display: "flex",
          width: "100%",
          borderRadius: "none",
          backgroundColor: "#00ADFF",
          alignItems: "center",
          justifyContent: "center",
          border: "none",
          position: "fixed",
          bottom: 0,
        }}
        onClick={() => gotoHome()}
      >
        {/* Back */}
        {t("payment-back")}
      </Button>
    </div>
  );
}

export default HomePaymentError;

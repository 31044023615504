import { faArrowLeft, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { AiOutlineCloseCircle } from "react-icons/ai";
import * as _ from "lodash";
import { USER_KYC } from "./graphql/mutation";
import { BACKGROUND_COLOR_APP, HEADER_HEIGHT } from "../../constants";

import {
  CARD_ID,
  COLOR_APP,
  COLOR_LINE,
  COLOR_SECONDARY,
  FACE_IMAGE,
  IDCARD_IMAGE,
  NEW_USER_DATA,
  POPUP_COLOR,
  PROFILE_IMAGE,
  SID,
} from "../../constants";
import Loading from "../../components/Loading";
import { useTranslation } from "react-i18next";
import { RiErrorWarningFill } from "react-icons/ri";
import CustomAppBar from "../../components/CustomAppBar";

function KycSingupDetail() {
  //variables
  const history = useNavigate();
  const location = useLocation();

  const [userKYC] = useMutation(USER_KYC);

  const firstName = new URLSearchParams(location.search).get("firstName");
  const lastName = new URLSearchParams(location.search).get("lastName");
  const phone = new URLSearchParams(location.search).get("phone");
  const dobDate = new URLSearchParams(location.search).get("dobDate");
  const passportNumber = new URLSearchParams(location.search).get(
    "passportNumber"
  );
  const issueDate = new URLSearchParams(location.search).get("issueDate");
  const expireDate = new URLSearchParams(location.search).get("expireDate");
  const gender = new URLSearchParams(location.search).get("gender");
  const userLang = new URLSearchParams(location.search).get("userLang");
  const faceImage = localStorage.getItem(FACE_IMAGE);
  const idcardImage = localStorage.getItem(IDCARD_IMAGE);
  const profileImage = localStorage.getItem(PROFILE_IMAGE);
  const sid = localStorage.getItem(SID);

  //states
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [showFaceError, setShowFaceError] = useState(false);

  const _gobackToKycSignup = () => {
    history(
      `/kycsignup?firstName=${firstName}&lastName=${lastName}&gender=${gender}&phone=${phone}&passportNumber=${passportNumber}&dobDate=${dobDate}&issueDate=${issueDate}&expireDate=${expireDate}&userLang=${userLang}&profileImage=${profileImage}`
    );
  };

  const _gotoKycEdit = () => {
    history(
      `/kyc-edit?firstName=${firstName}&lastName=${lastName}&gender=${gender}&phone=${phone}&passportNumber=${passportNumber}&dobDate=${dobDate}&issueDate=${issueDate}&expireDate=${expireDate}&userLang=${userLang}`
    );
  };

  const registerUserKYC = async () => {
    // TODO: show register loading
    setLoading(true);

    try {
      var newData = {
        sid,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        birthday: dobDate,
        profileImage,
        passportNumber,
        gender: gender,
        passportIssueData: issueDate,
        passportExpireDate: expireDate,
        passportImage: idcardImage,
        faceAndPassportImage: faceImage,
        userLang,
      };

      // TODO: register KYC
      const userRes = await userKYC({
        variables: {
          data: newData,
        },
      });

      // If failed
      if (_.isEmpty(userRes.data.userKYC)) {
        setLoading(false);
        setShowError(true);

        return;
      }

      // If success
      setLoading(false);
      // localStorage.removeItem(SID);
      localStorage.removeItem(FACE_IMAGE);
      localStorage.removeItem(IDCARD_IMAGE);
      localStorage.removeItem(PROFILE_IMAGE);
      localStorage.removeItem(CARD_ID);
      localStorage.removeItem(NEW_USER_DATA);

      setShow(true);

      // if isPassAutoKYC => goHome
      const isPassAutoKYC = userRes.data.userKYC.data["isPassAutoKYC"];
      if (isPassAutoKYC) {
        setTimeout(() => {
          setShow(false);
          _gotoHome();
        }, 3000);
        return;
      }

      // Otherwise goto KYCWait
      setTimeout(() => {
        setShow(false);
        _gotoKycWait();
      }, 3000);
    } catch (error) {
      setLoading(false);
      // setShowFaceError(true)
      setShowError(true);
      setTimeout(() => {
        // setShowFaceError(false);
        setShowError(true);
        _gobackToKycSignup();
      }, 3000);
    }
  };

  //routes
  const _gotoKycWait = () => {
    history("/kyc-wait");
  };

  const _gotoHome = () => {
    history("/home-add-creditcard");
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="wrapper" style={{backgroundColor: BACKGROUND_COLOR_APP}}>
      {/* <div className="kyc-signup-header">
        <div style={{ fontWeight: "bold", margin: 0, color: "black" }}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            onClick={() => _gobackToKycSignup()}
            style={{ fontSize: "18", marginRight: 10 }}
          />{" "}
          {t("kyc-regis")}
        </div>
      </div> */}

      <CustomAppBar
        gotoOtherPage={_gobackToKycSignup}
        title={t("kyc-regis")}
      />

      <div style={{ height: HEADER_HEIGHT }}></div>

      {/* Body */}
      <div>
        <div>
          <div style={{ textAlign: "center" }}>
            <img
              src={
                profileImage
                  ? profileImage
                  : "/assets/images/default_profile.png"
              }
              alt="not found"
              style={{
                width: 132,
                height: 132,
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          </div>

          <div style={{ height: 24 }}></div>
          <div
            className="detail-info"
            style={{ borderBottom: `1px solid ${COLOR_LINE}` }}
          >
            <div style={{ color: COLOR_SECONDARY }}>
              {/* First name */}
              {t("login-firstname")}
            </div>
            <div>{firstName}</div>
          </div>
          <div
            className="detail-info"
            style={{ borderBottom: `1px solid ${COLOR_LINE}` }}
          >
            <div style={{ color: COLOR_SECONDARY }}>
              {/* Last name */}
              {t("login-lastname")}
            </div>
            <div>{lastName}</div>
          </div>
          <div
            className="detail-info"
            style={{ borderBottom: `1px solid ${COLOR_LINE}` }}
          >
            <div style={{ color: COLOR_SECONDARY }}>
              {/* Tel */}
              {t("login-tel")}
            </div>
            <div>{phone}</div>
          </div>

          <div
            className="detail-info"
            style={{ borderBottom: `1px solid ${COLOR_LINE}` }}
          >
            <div style={{ color: COLOR_SECONDARY }}>
              {/* Passport Number */}
              {t("login-passport")}
            </div>
            <div>{passportNumber}</div>
          </div>
          <div
            className="detail-info"
            style={{ borderBottom: `1px solid ${COLOR_LINE}` }}
          >
            <div style={{ color: COLOR_SECONDARY }}>
              {/* Issue Date */}
              {t("login-issue")}
            </div>
            <div>{moment(issueDate).format("DD/MM/YYYY")}</div>
          </div>

          <div
            className="detail-info"
            style={{ borderBottom: `1px solid ${COLOR_LINE}` }}
          >
            <div style={{ color: COLOR_SECONDARY }}>
              {/* Expire Date */}
              {t("login-expire")}
            </div>
            <div>{moment(expireDate).format("DD/MM/YYYY")}</div>
          </div>

          <div style={{ height: 26 }} />
          <div style={{ textAlign: "center" }}>
            <div style={{ fontWeight: "bold" }}>
              {/* Photo with face */}
              {t("login-photo")}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {faceImage != "" ? (
                <img src={faceImage} width={235} />
              ) : (
                <img
                  src="/assets/images/detail_info.png"
                  style={{ width: 235, height: 232, marginBottom: 26 }}
                  alt="not found"
                />
              )}
            </div>

            <div style={{ height: 30 }} />
            <div style={{ fontWeight: "bold" }}>
              {/* Passport Picture */}
              {t("login-passport-pic")}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {idcardImage != "" ? (
                <img src={idcardImage} style={{ width: 235 }} />
              ) : (
                <img
                  src="/assets/images/kyc_idCard1.png"
                  style={{ width: 235, height: 232, marginBottom: 24 }}
                  alt="not found"
                />
              )}
            </div>
            <div style={{ height: 40 }} />

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 26,
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  onClick={() => _gotoKycEdit()}
                  style={{
                    marginLeft: 26,
                    color: COLOR_APP,
                    fontWeight: "bold",
                  }}
                >
                  {/* Edit */}
                  {t("login-edit")}
                </div>
                <Button
                  className="btn"
                  onClick={() => registerUserKYC()}
                  style={{
                    width: "65%",
                    backgroundColor: COLOR_APP,
                    border: "1px solid #00ADFF",
                  }}
                >
                  {/* Finish */}
                  {t("login-finish")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        backdrop="static"
        show={show}
        size="sm"
        onHide={() => setShow(false)}
        centered
      >
        <Modal.Body style={{ textAlign: "center" }}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            style={{
              width: 80,
              height: 80,
              color: "#0DCD5A",
            }}
          />
          <div>
            {/* Your request successful! */}
            {t("kyc-request-success")}!
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showError}
        size="sm"
        onHide={() => setShowError(false)}
        centered
      >
        <Modal.Body style={{ textAlign: "center" }}>
          <AiOutlineCloseCircle
            style={{ fontSize: 60, color: "red", marginBottom: 10 }}
          />
          <div style={{ color: "red" }}>
            {/* Your request is failed! */}
            {t("kyc-request-fail")}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showFaceError}
        size="sm"
        // onHide={() => (false)}
        centered
      >
        <Modal.Body style={{ textAlign: "center" }}>
          <RiErrorWarningFill
            style={{ fontSize: 60, color: POPUP_COLOR, marginBottom: 10 }}
          />
          <div>
            {/* Please select a photo with a face */}
            {t("kyc-face")}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default KycSingupDetail;

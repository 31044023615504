import {
  faAngleRight,
  faArrowLeft,
  faCheckCircle,
  faCreditCard,
  faFile,
  faGear,
  faMars,
  faUserAlt,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useLazyQuery } from "@apollo/client";
import { BiWorld } from "react-icons/bi";
import {IoIosLock} from 'react-icons/io'
import { useNavigate, useLocation } from "react-router-dom";
import { GET_USER } from "../graphql/query";
import moment from "moment";
import { COLOR_APP, COUNTRY_CODE, EMAIL, NEW_USER_DATA, PHONE, FIRST_NAME, LAST_NAME, BACKGROUND_COLOR_APP } from "../../constants";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { CREATE_UPPASS_FORM } from "./graphql/mutation";
import Loading from "../../components/Loading";
import HomeBottomNav from "../../components/HomeBottomNav";
import CustomAppBar from "../../components/CustomAppBar";

function HomeProfile() {
  //variables
  const history = useNavigate();
  const location = useLocation();
  const userId = new URLSearchParams(location.search).get("userId");
  const firstName = new URLSearchParams(location.search).get("firstName");
  const profileImage = new URLSearchParams(location.search).get("profileImage");
  const [show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  const [canScroll, setCanScroll] = useState(false);
  const { innerWidth: width, innerHeight: height } = window;
  const ref = useRef(null);
  const [createUpPassForm] = useMutation(CREATE_UPPASS_FORM);

  //states
  const [userData, setUserData] = useState();
  const [verifiedByCarSharing, setVerifiedByCarSharing] = useState(false);
  const [phone, setPhone] = useState()
  const [countryCode, setCountryCode] = useState()
  const [email, setEmail] = useState()
  const [cusName, setCusName] = useState();
  const [cusLastName, setCusLastName] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const [
    getUser,
    { data: userQueryData, loading: loading, error: error },
  ] = useLazyQuery(GET_USER);

  useEffect(() => {
    getUser({
      variables: {
        where: {
          id: userId,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (userQueryData) {
      setUserData(userQueryData.user);
      setPhone(userQueryData.user.phone)
      setCountryCode(userQueryData.user.countryCode)
      setEmail(userQueryData.user.email)
      setCusName(userQueryData.user.firstName)
      setCusLastName(userQueryData.user.lastName)
      const verifiedByCarSharing = userQueryData.user.verifiedByCarSharing;
      setVerifiedByCarSharing(verifiedByCarSharing);
    }
  }, [userQueryData]);

  useEffect(() => {
    // console.log("ref.current.clientHeight: ", ref.current.clientHeight)
    // console.log("height: ",height)
    if (ref.current.clientHeight > height) setCanScroll(true);
    else setCanScroll(false);
  }, []);

  const getUpPassUrl = async () => {
    try {
      setIsLoading(true);
      const url = await createUpPassForm({
        variables: {
          userId
        }
      });
      if (url) {
        // setIsLoading(false)
        const UpPassURL = url.data.createUpPassForm.url;
        window.location.href = UpPassURL;
      }else {
        setIsLoading(false)
      }
      // setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      // console.log(error)
    }
  }

  //Routes
  const _gotoProfileInfo = () => {
    const birthday = moment(userData.birthday).format("yyyy-MM-DD");
    const issueDate = moment(userData.passportIssueData).format("yyyy-MM-DD");
    const expireDate = moment(userData.passportExpireDate).format("yyyy-MM-DD");
    const registrationDate = moment(userData.createdAt).format("yyyy-MM-DD");

    history(
      `/home-profile-info?firstName=${userData.firstName}&lastName=${userData.lastName}&gender=${userData.gender}&dobDate=${birthday}&tel=${userData.phone}&passportNumber=${userData.passportNumber}&issueDate=${issueDate}&expireDate=${expireDate}&registrationDate=${registrationDate}`
    );
  };

  const _gotoHomeProfileLanguage = () => {
    history("/home-profile-language");
  };

  const _gotoHomeProfilePin = () => {
    localStorage.setItem(PHONE, phone)
    localStorage.setItem(COUNTRY_CODE, countryCode)
    localStorage.setItem(EMAIL, email)
    localStorage.setItem(FIRST_NAME, cusName)
    localStorage.setItem(LAST_NAME, cusLastName)
    if (userData.pinCode) {
      history(`/home-profile-pin?pinCode=true&hintText=${userData.pinHint}`);
    } else {
      history(`/home-profile-pin`);
    }
  };

  const _gotoKYC = () => {
    if (userData.userStatus == "WAITTING") {
      history(`/kyc-wait`);
    } else if (userData.userStatus == "VERIFIED") {
      setShow(true);
    } else if (userData.userStatus == "REJECTED") {
      history("/kyc-rejected");
    } else {
      history(
        `/kyc?profileImage=${userData.profileImage}&firstName=${userData.firstName}&lastName=${userData.lastName}&phone=${userData.phone}&sid=${userData.sid}`
      );
      // getUpPassUrl()
    }
  };

  const _goBacktoHome = () => {
    history("/home-add-creditcard");
  };

  if (isLoading) {
    return (
      <Loading />
    )
  }

  return (
    <div
      // style={canScroll ? { position: "absolute", overflowY: "scroll" } : {}}
      ref={ref}
      className={canScroll ? "wrapper":""}
      style={{backgroundColor: BACKGROUND_COLOR_APP}}
    >
      {/* <div
        style={{
          display: "flex",
          height: 49,
        }}
      >
        <FontAwesomeIcon
          style={{
            marginLeft: 18,
            marginTop: 17,
            height: 20,
            width: 16,
            fontWeight: "bold",
          }}
          icon={faArrowLeft}
          onClick={() => _goBacktoHome()}
        />
      </div> */}

      <CustomAppBar 
        gotoOtherPage={_goBacktoHome}
        title={"LOCA PAY"}
      />

      {userData ? (
        <div>
          <div>
            <center>
              <img
                style={{
                  marginTop: 29,
                  width: 132,
                  height: 130,
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
                src={
                  userData.profileImage
                    ? userData.profileImage
                    : userData.gender == "FEMALE"
                      ? "/assets/images/female.png"
                      : "/assets/images/male.png"
                }
                className="Profile-image"
              />
              <div
                style={{
                  marginTop: 11,
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                {userData.firstName} {userData.lastName}
              </div>
            </center>
          </div>

          {/* Infomation  */}

          <Row
            style={{
              marginTop: 31,
              marginLeft: 26,
              marginRight: 26,
            }}
          >
            <Button
              style={{
                marginBottom: 10,
                fontWeight: "bold",
                color: "#252A31",
                backgroundColor: "white",
                height: 56,
                border: "none",
                borderRadius: 6,
              }}
              onClick={() => _gotoProfileInfo()}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <FontAwesomeIcon
                    style={{ marginRight: 14 }}
                    icon={faUserAlt}
                  />
                  {t("profile")}
                  {/* Profile */}
                </div>
                <div>
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </div>
            </Button>

            {/* button2  */}
            <Button
              style={{
                fontWeight: "bold",
                color: "#252A31",
                backgroundColor: "white",
                height: 56,
                border: "none",
                borderRadius: 6,
              }}
              onClick={() => _gotoHomeProfilePin()}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <IoIosLock style={{ marginRight: 14 }} fontSize={20} />
                  {/* PIN Setting */}
                  {t("setting-pin")}
                </div>
                <div>
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </div>
            </Button>

            <Button
              style={{
                marginTop: 10,
                marginBottom: 10,
                fontWeight: "bold",
                color: "#252A31",
                backgroundColor: "white",
                height: 56,
                border: "none",
                borderRadius: 6,
              }}
              onClick={() => _gotoHomeProfileLanguage()}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <BiWorld style={{ marginRight: 14 }} size={20} />
                  {/* Languages */}
                  {t("setting-languages")}
                </div>
                <div>
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </div>
            </Button>

            {/* button2  */}
            <Button
              style={{
                fontWeight: "bold",
                color: "#252A31",
                backgroundColor: "white",
                height: 56,
                border: "none",
                borderRadius: 6,
                marginBottom: 10,
              }}
              onClick={() => _gotoKYC()}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <FontAwesomeIcon
                    style={{ marginRight: 14 }}
                    icon={faUserCheck}
                  />
                  {/* KYC */}
                  {t("setting-kyc")}
                </div>
                <div>
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </div>
            </Button>

            <Button
              style={{
                fontWeight: "bold",
                color: "#252A31",
                backgroundColor: "white",
                height: 56,
                border: "none",
                borderRadius: 6,
              }}
              onClick={() => { history(`/kyc-condition`); localStorage.setItem("KYC_POLICY", "PROFILE")}}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <FontAwesomeIcon
                    style={{ marginRight: 14 }}
                    icon={faFile}
                  />
                  LOCA PAY Terms & Conditions
                </div>
                <div>
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </div>
            </Button>
          </Row>
        </div>
      ) : (
        <div>
          <div>
            <center>
              <img
                style={{
                  marginTop: 29,
                  width: 132,
                  height: 130,
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
                src={profileImage}
              />
              <div
                style={{
                  marginTop: 11,
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                {firstName}
              </div>
            </center>
          </div>

          <Row
            style={{
              marginTop: 31,
              marginLeft: 26,
              marginRight: 26,
            }}
          >
            <Button
              style={{
                marginBottom: 10,
                fontWeight: "bold",
                color: "#252A31",
                backgroundColor: "white",
                height: 56,
                border: "none",
                borderRadius: 6,
              }}
              onClick={() => _gotoProfileInfo()}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <FontAwesomeIcon
                    style={{ marginRight: 14 }}
                    icon={faCreditCard}
                  />{" "}
                  Profile
                </div>
                <div>
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </div>
            </Button>

            {/* button2  */}
            <Button
              style={{
                fontWeight: "bold",
                color: "#252A31",
                backgroundColor: "white",
                height: 56,
                border: "none",
                borderRadius: 6,
              }}
            onClick={() => _gotoHomeProfilePin()}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <FontAwesomeIcon style={{ marginRight: 14 }} icon={faGear} />
                  PIN Setting
                </div>
                <div>
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </div>
            </Button>

            <Button
              style={{
                marginTop: 10,
                marginBottom: 10,
                fontWeight: "bold",
                color: "#252A31",
                backgroundColor: "white",
                height: 56,
                border: "none",
                borderRadius: 6,
              }}
              onClick={() => _gotoHomeProfileLanguage()}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <BiWorld style={{ marginRight: 14 }} size={20} />
                  Languages
                </div>
                <div>
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </div>
            </Button>

            {/* button2  */}
            <Button
              style={{
                fontWeight: "bold",
                color: "#252A31",
                backgroundColor: "white",
                height: 56,
                border: "none",
                borderRadius: 6,
                marginBottom: 10,
              }}
              onClick={() => _gotoKYC()}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <FontAwesomeIcon
                    style={{ marginRight: 14 }}
                    icon={faUserCheck}
                  />
                  KYC
                </div>
                <div>
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </div>
            </Button>

            <Button
              style={{
                fontWeight: "bold",
                color: "#252A31",
                backgroundColor: "white",
                height: 56,
                border: "none",
                borderRadius: 6,
              }}
              onClick={() =>{ history(`/kyc-condition`); localStorage.setItem("KYC_POLICY", "PROFILE")}}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <FontAwesomeIcon
                    style={{ marginRight: 14 }}
                    icon={faFile}
                  />
                  LOCA PAY Terms & Conditions
                </div>
                <div>
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </div>
            </Button>
          </Row>
        </div>
      )}

      <div style={{height: 120}}/>
      <HomeBottomNav currentIndex={4}/>

      <Modal show={show} size="sm" onHide={() => setShow(false)} centered>
        <Modal.Body style={{ textAlign: "center" }}>
          <FontAwesomeIcon icon={faCheckCircle} color="#0DCD5A" fontSize={60} />
          <div style={{ height: 20 }} />
          <div style={{ fontSize: 20 }}>
            {/* You've completed the KYC */}
            {t("kyc-already") +
              (verifiedByCarSharing ? t("by-carsharing") : "")}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default HomeProfile;

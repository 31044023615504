import { faArrowLeft, faDeleteLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CLICK_COLOR, COLOR_APP, USER_ID } from "../../../constants";
import { BsCircle, BsCircleFill } from "react-icons/bs";
import { IoIosLock } from "react-icons/io";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_USER } from "../../graphql/query";
import Loading from "../../../components/Loading";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import swal from "sweetalert";

import * as _ from "lodash";
import "../../Home/Home.css";
// import { numberWithCommas } from "../../constants/ShowFormat";

import { CREATE_TELBIZ_PAYMENT, PIN_COMPARE, UPDATE_USER } from "../graphql/mutation";
import CustomAppBar from "../../../components/CustomAppBar";
import { REQUEST_TELBIZ_AUTH } from "./graphql/mutation";
// import CustomAppBar from "../../../components/CustomAppBar";

function HomeQrPaymentPin() {
  //variables
  const history = useNavigate();
  const location = useLocation();
  const [pinCompare] = useMutation(PIN_COMPARE);

  const userId = localStorage.getItem(USER_ID);
  const [count, setCount] = useState(0);
  let sum = new URLSearchParams(location.search).get("sum");
  let price = new URLSearchParams(location.search).get("price");
  let packageData = new URLSearchParams(location.search).get("package");
  let packageName = new URLSearchParams(location.search).get("packageName");
  let phone = new URLSearchParams(location.search).get("phone");
  let lat = new URLSearchParams(location.search).get("lat");
  let lng = new URLSearchParams(location.search).get("lng");
  let feeAmount = new URLSearchParams(location.search).get("feeAmount");
  let charge = new URLSearchParams(location.search).get("charge");
  let stripeCardId = new URLSearchParams(location.search).get("stripeCardId");
  let cardHolderName = new URLSearchParams(location.search).get("cardHolderName");
  let cardLast4digit = new URLSearchParams(location.search).get("cardLast4digit");
  let expireMonth = new URLSearchParams(location.search).get("expireMonth");
  let expireYear = new URLSearchParams(location.search).get("expireYear");
  let qrType = new URLSearchParams(location.search).get("qrType");
  let brand = new URLSearchParams(location.search).get("brand");

  const [pinCode, setPinCode] = useState("");
  const [wrong, setWrong] = useState(false);
  const { t, i18n } = useTranslation();

  const [updateUser] = useMutation(UPDATE_USER);
  const [telbizAuth, setTelbizAuth] = useState()
  const [createLoading, setCreateLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [arrayButton, setArrayButton] = useState();
  const [buttonClick, setButtonClick] = useState({
    button0: false,
    button1: false,
    button2: false,
    button3: false,
    button4: false,
    button5: false,
    button6: false,
    button7: false,
    button8: false,
    button9: false,
    button10: false,
  });
  const [createTelbizPayment] = useMutation(CREATE_TELBIZ_PAYMENT);

  const [getUser, { data: userQueryData }] = useLazyQuery(
    GET_USER
  );
  const [requestTelbizAuth] = useMutation(REQUEST_TELBIZ_AUTH);


  useEffect(() => {
    getUser({
      variables: {
        where: {
          id: userId,
        },
      },
    });
    let myStr = shuffleButton();
    setArrayButton(myStr);
    RequesttelbizAuth()
  }, []);

  // console.log('-------------------->',userQueryData);

  const RequesttelbizAuth = async () => {
    try {
      const telbizAuth = await requestTelbizAuth();
      if (telbizAuth) {
        setTelbizAuth(telbizAuth.data.telbizRequestAuth.accessToken)
      }
    } catch (error) {
      console.log("error: ", error)
    }
  }

  const calAllFees = ({ price, feeAmount, charge }) => {
    return (parseInt(price) || 0) * parseFloat(feeAmount) + parseInt(charge);
  };

  //   useEffect(() => {
  //     var priceValue = parseFloat(price.replace(/,/g, ""));
  //     setRealPrice(priceValue);
  //   }, [price]);

  useEffect(() => {
    setTimeout(() => {
      setButtonClick({ button0: false });
    }, 100);
    setTimeout(() => {
      setButtonClick({ button0: false });
    }, 100);
    if (count == 4) {
      pinCompareReq(pinCode);
    }
  }, [count]);

  const pinCompareReq = async (pinCode) => {
    try {
      const pinCompareRes = await pinCompare({
        variables: {
          pinCode: pinCode,
        },
      });
      const resData = pinCompareRes["data"];
      if (_.isEmpty(resData)) return;

      const pinCompareResult = resData["pinCompare"]["success"];
      if (!pinCompareResult) {
        setTimeout(() => {
          setWrong(true);
          setCount(0);
          setPinCode("");
        }, 500);
        return;
      }

      // Do a payment
      handlePayment();
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const handlePayment = async () => {
    setCreateLoading(true);
    let totalPrice =
      (parseInt(price) || 0) +
      (parseInt(price) || 0) * parseFloat(feeAmount) +
      parseInt(charge);
    // let vat = totalPrice - price;
    try {

      //   price
      const amount = parseInt(price);
      const total = parseInt(totalPrice);
      const fees = parseFloat(feeAmount);
      const _charge = parseFloat(charge);

      const paymentReqData = {
        pinCode: pinCode,
        lat: parseFloat(lat),
        long: parseFloat(lng),

        amount: amount,
        fees: fees,
        charge: _charge,
        total: total,
        phone,
        stripeCardId,
        cardLast4digit: cardLast4digit,
        cardHolderName: cardHolderName,
        packageId: packageData,
        qrType,
        cardType: brand,
        packageAmount: packageName
      };
      // console.log("paymentReqData: ", paymentReqData)

      const _res = await createTelbizPayment({
        variables: {
          data: paymentReqData,
          telbizToken: telbizAuth
        },
      });

      if (_res) {
        setCreateLoading(false)
        // console.log("_res: ",_res.data.confirmBuyPackage)
        if (_res.data.confirmBuyPackage.id) {
          let responseData = _res.data.confirmBuyPackage
          _updateUserLimitAmount();

          history(`/buy-data-package-success?bankTansactionId=${responseData.bankTansactionId}&createdAt=${responseData.createdAt}&phone=${phone}&packageName=${packageName}&amount=${responseData.amount}&totalFees=${responseData.totalFees}&cardHolderName=${responseData.cardHolderName}&cardLast4digit=${responseData.cardLast4digit}&expireMonth=${expireMonth}&expireYear=${expireYear}&cardType=${responseData.cardType}&qrString=${responseData.qrString}&qrType=${responseData.qrType}&id=${responseData.id}`)

          // const billNo = _res.data.createPayment.bankT;
          // const refNo = _res.data.createPayment.refNo;
          // history(
          //   `/homeqr-payment-confirm-to-paysuccess?price=${realPrice}&createdAt=${_res.data.createPayment.createdAt}&cardId=${cardId}&expireMonth=${expireMonth}&expireYear=${expireYear}&description=${description}&id=${_res.data.createPayment.id}&brand=${brand}&vat=${vat}&billNo=${billNo}&destMerchantName=${destMerchantName}&refNo=${refNo}&bankLogoUrl=${bankLogoUrl}`
          // );
        } else {
          setCreateLoading(false);
          setPaymentError(true);
          // history(
          //   `/home-payment-error?price=${realPrice}&createdAt=${_res.data.createPayment.createdAt}&cardId=${cardId}&expireMonth=${expireMonth}&expireYear=${expireYear}&id=${_res.data.createPayment.id}&brand=${brand}&vat=${vat}&destMerchantName=${destMerchantName}&bankLogoUrl=${bankLogoUrl}`
          // );
        }
      }

    } catch (error) {
      setCreateLoading(false);
      let errMsg = error.message;
      let { title, detail } = errorPaymentHandlingString(errMsg);
      swal(t(title), t(detail));
      history(`/home-add-creditcard`);
      return;
    }
  };

  const STRIPE_ERROR = "STRIPE_ERROR";
  const BANK_ERROR = "BANK_ERROR";
  const errorPaymentHandlingString = (errMsg) => {
    if (errMsg === STRIPE_ERROR) {
      return { title: "stripe-error-title", detail: "stripe-error-detail" };
    } else if (errMsg === BANK_ERROR) {
      return { title: "bank-error-title", detail: "bank-error-detail" };
    } else if (errMsg == "CARD_IS_REQUIRED_3D_SECURE") {
      return { title: 'card_not_support', detail: "require_secure_error" }
    }

    return { title: "error-gateway-title", detail: "error-gateway-detail" };
  };

    const _updateUserLimitAmount = async () => {
      if (sum === null) return;
      try {
        const _res = await updateUser({
          variables: {
            data: {
              limitAmount: parseInt(sum),
            },
            where: {
              id: userId,
            },
          },
        });
        if (_res) {
        }
      } catch (error) { }
    };

  const shuffleButton = () => {
    let array = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  };

  //routes
  const _goBackConfirmtoPay = () => {
    history(-1);
  };

  const _goBackToHome = () => {
    localStorage.removeItem("DESCRIPTION");
    history(`/home-add-creditcard`);
  };

  if (createLoading) {
    return <Loading />;
  }

  return (
    <div className="wrapper">

      <CustomAppBar
        gotoOtherPage={_goBackConfirmtoPay}
        title={"LOCA PAY"}
      />

      <div style={{ height: 10 }} />

      {/* Body */}
      <div>
        <div style={{ textAlign: "center" }}>
          <IoIosLock fontSize={50} />
        </div>

        <div style={{ fontSize: 17, fontWeight: "bold", textAlign: "center", marginTop: 10 }}>
          {/* Please input PIN */}
          {t("setting-please")}
        </div>

        <div style={{ height: 10 }} />
        <div style={{ display: "flex", justifyContent: "center" }}>
          {count >= 1 ? (
            <BsCircleFill
              fontSize={14}
              style={{ marginRight: 20 }}
              color={COLOR_APP}
            />
          ) : (
            <BsCircle
              fontSize={14}
              style={{ marginRight: 20 }}
              color={COLOR_APP}
            />
          )}
          {count >= 2 ? (
            <BsCircleFill
              fontSize={14}
              style={{ marginRight: 20 }}
              color={COLOR_APP}
            />
          ) : (
            <BsCircle
              fontSize={14}
              style={{ marginRight: 20 }}
              color={COLOR_APP}
            />
          )}
          {count >= 3 ? (
            <BsCircleFill
              fontSize={14}
              style={{ marginRight: 20 }}
              color={COLOR_APP}
            />
          ) : (
            <BsCircle
              fontSize={14}
              style={{ marginRight: 20 }}
              color={COLOR_APP}
            />
          )}
          {count >= 4 ? (
            <BsCircleFill fontSize={14} color={COLOR_APP} />
          ) : (
            <BsCircle fontSize={14} color={COLOR_APP} />
          )}
        </div>
        <div style={{ textAlign: "center", marginTop: 8, color: "#999999" }}>
          {t("setting-hint")}: {userQueryData ? userQueryData.user.pinHint : ""}
        </div>
        {wrong ? (
          <div style={{ textAlign: "center", color: "red" }}>
            {/* You pin is not correct */}
            {t("setting-not-correct")}
          </div>
        ) : (
          <div />
        )}

        <div style={{ height: 14 }} />
        {arrayButton ? (
          <div
            style={{ position: "absolute", width: "100%", marginBottom: 20 }}
          >
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <div
                className="pin-button"
                style={{
                  backgroundColor: buttonClick.button0 ? CLICK_COLOR : "",
                }}
                onClick={() => {
                  if (count <= 4) {
                    setCount(count + 1);
                    setButtonClick({ button0: true });
                  }
                  setPinCode(pinCode + `${arrayButton[0]}`);
                }}
              >
                {arrayButton[0]}
              </div>
              <div
                className="pin-button"
                style={{
                  backgroundColor: buttonClick.button1 ? CLICK_COLOR : "",
                }}
                onClick={() => {
                  if (count <= 4) {
                    setCount(count + 1);
                    setButtonClick({ button1: true });
                  }
                  setPinCode(pinCode + `${arrayButton[1]}`);
                }}
              >
                {arrayButton[1]}
              </div>
              <div
                className="pin-button"
                style={{
                  backgroundColor: buttonClick.button2 ? CLICK_COLOR : "",
                }}
                onClick={() => {
                  if (count <= 4) {
                    setCount(count + 1);
                    setButtonClick({ button2: true });
                  }
                  setPinCode(pinCode + `${arrayButton[2]}`);
                }}
              >
                {arrayButton[2]}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: 11,
              }}
            >
              <div
                className="pin-button"
                style={{
                  backgroundColor: buttonClick.button3 ? CLICK_COLOR : "",
                }}
                onClick={() => {
                  if (count <= 4) {
                    setCount(count + 1);
                    setButtonClick({ button3: true });
                  }
                  setPinCode(pinCode + `${arrayButton[3]}`);
                }}
              >
                {arrayButton[3]}
              </div>
              <div
                className="pin-button"
                style={{
                  backgroundColor: buttonClick.button4 ? CLICK_COLOR : "",
                }}
                onClick={() => {
                  if (count <= 4) {
                    setCount(count + 1);
                    setButtonClick({ button4: true });
                  }
                  setPinCode(pinCode + `${arrayButton[4]}`);
                }}
              >
                {arrayButton[4]}
              </div>
              <div
                className="pin-button"
                style={{
                  backgroundColor: buttonClick.button5 ? CLICK_COLOR : "",
                }}
                onClick={() => {
                  if (count <= 4) {
                    setCount(count + 1);
                    setButtonClick({ button5: true });
                  }
                  setPinCode(pinCode + `${arrayButton[5]}`);
                }}
              >
                {arrayButton[5]}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: 11,
              }}
            >
              <div
                className="pin-button"
                style={{
                  backgroundColor: buttonClick.button6 ? CLICK_COLOR : "",
                }}
                onClick={() => {
                  if (count <= 4) {
                    setCount(count + 1);
                    setButtonClick({ button6: true });
                  }
                  setPinCode(pinCode + `${arrayButton[6]}`);
                }}
              >
                {arrayButton[6]}
              </div>
              <div
                className="pin-button"
                style={{
                  backgroundColor: buttonClick.button7 ? CLICK_COLOR : "",
                }}
                onClick={() => {
                  if (count <= 4) {
                    setCount(count + 1);
                    setButtonClick({ button7: true });
                  }
                  setPinCode(pinCode + `${arrayButton[7]}`);
                }}
              >
                {arrayButton[7]}
              </div>
              <div
                className="pin-button"
                style={{
                  backgroundColor: buttonClick.button8 ? CLICK_COLOR : "",
                }}
                onClick={() => {
                  if (count <= 4) {
                    setCount(count + 1);
                    setButtonClick({ button8: true });
                  }
                  setPinCode(pinCode + `${arrayButton[8]}`);
                }}
              >
                {arrayButton[8]}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: 11,
              }}
            >
              <div style={{ height: 88, width: 88 }}></div>
              <div
                className="pin-button"
                style={{
                  backgroundColor: buttonClick.button9 ? CLICK_COLOR : "",
                }}
                onClick={() => {
                  if (count <= 4) {
                    setCount(count + 1);
                    setButtonClick({ button9: true });
                  }
                  setPinCode(pinCode + `${arrayButton[9]}`);
                }}
              >
                {arrayButton[9]}
              </div>
              <div
                className="pin-button"
                style={{
                  backgroundColor: buttonClick.button10 ? CLICK_COLOR : "",
                }}
                onClick={() => {
                  setButtonClick({ button10: true });
                  if (count > 0) {
                    setCount(count - 1);
                  }
                  const newCode = pinCode.slice(0, -1);
                  setPinCode(newCode);
                }}
              >
                <FontAwesomeIcon icon={faDeleteLeft} />
              </div>
            </div>
          </div>
        ) : (
          <div />
        )}
        <Modal
          show={paymentError}
          size="sm"
          centered
        >
          <Modal.Body style={{ textAlign: "center" }}>
            <img src="/assets/images/payment_failed.png" alt="" width={135} />
            <div
              style={{
                marginTop: 16,
                fontWeight: "bold",
                fontSize: 18,
                marginBottom: 16,
              }}
            >
              {t("payment-failed")}!
            </div>
            <Button
              style={{
                backgroundColor: COLOR_APP,
                border: "none",
                width: "90%",
              }}
              onClick={() => _goBackToHome()}
            >
              {t("payment-back")}
            </Button>
          </Modal.Body>
        </Modal>

        <Modal show={show} size="sm" onHide={() => setShow(false)} centered>
          <Modal.Body style={{ textAlign: "center" }}>
            <AiOutlineCloseCircle
              style={{ fontSize: 60, color: "red", marginBottom: 20 }}
            />
            <div style={{ color: "red" }}>{t("payment-in-correct")}!</div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default HomeQrPaymentPin;


// import React, { useState, useEffect } from 'react'
// import { BACKGROUND_COLOR_APP, CLICK_COLOR, COLOR_APP } from '../../../constants';
// import { useNavigate } from 'react-router-dom';
// import CustomAppBar from '../../../components/CustomAppBar';
// import { IoIosLock } from 'react-icons/io';
// import { useTranslation } from 'react-i18next';
// import { BsCircle, BsCircleFill } from 'react-icons/bs';
// import { Modal } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faDeleteLeft } from '@fortawesome/free-solid-svg-icons';

// function BuyDataPackagePin() {
//     const navigate = useNavigate()
//     const { t, i18n } = useTranslation();
//     const [count, setCount] = useState(0);
//     const [wrong, setWrong] = useState(false);
//     const [arrayButton, setArrayButton] = useState();
//     const [buttonClick, setButtonClick] = useState({
//         button0: false,
//         button1: false,
//         button2: false,
//         button3: false,
//         button4: false,
//         button5: false,
//         button6: false,
//         button7: false,
//         button8: false,
//         button9: false,
//         button10: false,
//     });
//     const [pinCode, setPinCode] = useState("");


//     useEffect(() => {
//         let myStr = shuffleButton();
//         setArrayButton(myStr);
//     }, []);

//     useEffect(() => {
//         setTimeout(() => {
//             setButtonClick({ button0: false });
//         }, 100);
//         setTimeout(() => {
//             setButtonClick({ button0: false });
//         }, 100);
//         if (count == 4) {
//             pinCompareReq(pinCode);
//         }
//     }, [count]);


//     const shuffleButton = () => {
//         let array = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
//         let currentIndex = array.length,
//             randomIndex;

//         while (currentIndex != 0) {
//             // Pick a remaining element.
//             randomIndex = Math.floor(Math.random() * currentIndex);
//             currentIndex--;

//             // And swap it with the current element.
//             [array[currentIndex], array[randomIndex]] = [
//                 array[randomIndex],
//                 array[currentIndex],
//             ];
//         }
//         return array;
//     };

//     const pinCompareReq = async (pinCode) => {
//         try {
//             // const pinCompareRes = await pinCompare({
//             //     variables: {
//             //         pinCode: pinCode,
//             //     },
//             // });
//             // const resData = pinCompareRes["data"];
//             // if (_.isEmpty(resData)) return;

//             // const pinCompareResult = resData["pinCompare"]["success"];
//             // if (!pinCompareResult) {
//             //     setTimeout(() => {
//             //         setWrong(true);
//             //         setCount(0);
//             //         setPinCode("");
//             //     }, 500);
//             //     return;
//             // }
//             setTimeout(() => {
//                 setWrong(true);
//                 setCount(0);
//                 setPinCode("");
//             }, 500);

//             gotoBuyDataPackageSuccess()
//         } catch (err) {
//             console.log("err: ", err);
//         }
//     };


//     const goBack = () => {
//         navigate(-1)
//     }

//     const gotoBuyDataPackageSuccess = () => [
//         navigate(`/buy-data-package-success`)
//     ]


//     return (
//         <div className="wrapper" style={{ backgroundColor: BACKGROUND_COLOR_APP }}>

//             <CustomAppBar
//                 gotoOtherPage={goBack}
//                 title={"LOCA PAY"}
//             />

//             <div style={{ height: 20 }} />

//             {/* Body */}
//             <div>
//                 <div style={{ textAlign: "center" }}>
//                     <IoIosLock fontSize={74} />
//                 </div>

//                 <div style={{ fontSize: 17, fontWeight: "bold", textAlign: "center", marginTop: 33 }}>
//                     {/* Please input PIN */}
//                     {t("setting-please")}
//                 </div>

//                 <div style={{ height: 20 }} />
//                 <div style={{ display: "flex", justifyContent: "center" }}>
//                     {count >= 1 ? (
//                         <BsCircleFill
//                             fontSize={14}
//                             style={{ marginRight: 20 }}
//                             color={COLOR_APP}
//                         />
//                     ) : (
//                         <BsCircle
//                             fontSize={14}
//                             style={{ marginRight: 20 }}
//                             color={COLOR_APP}
//                         />
//                     )}
//                     {count >= 2 ? (
//                         <BsCircleFill
//                             fontSize={14}
//                             style={{ marginRight: 20 }}
//                             color={COLOR_APP}
//                         />
//                     ) : (
//                         <BsCircle
//                             fontSize={14}
//                             style={{ marginRight: 20 }}
//                             color={COLOR_APP}
//                         />
//                     )}
//                     {count >= 3 ? (
//                         <BsCircleFill
//                             fontSize={14}
//                             style={{ marginRight: 20 }}
//                             color={COLOR_APP}
//                         />
//                     ) : (
//                         <BsCircle
//                             fontSize={14}
//                             style={{ marginRight: 20 }}
//                             color={COLOR_APP}
//                         />
//                     )}
//                     {count >= 4 ? (
//                         <BsCircleFill fontSize={14} color={COLOR_APP} />
//                     ) : (
//                         <BsCircle fontSize={14} color={COLOR_APP} />
//                     )}
//                 </div>
//                 <div style={{ textAlign: "center", marginTop: 8, color: "#999999" }}>
//                     {t("setting-hint")}: 1122
//                 </div>
//                 {wrong ? (
//                     <div style={{ textAlign: "center", color: "red" }}>
//                         {/* You pin is not correct */}
//                         {t("setting-not-correct")}
//                     </div>
//                 ) : (
//                     <div />
//                 )}

//                 <div style={{ height: 30 }} />
//                 {arrayButton ? (
//                     <div
//                         style={{ position: "absolute", width: "100%", marginBottom: 20 }}
//                     >
//                         <div style={{ display: "flex", justifyContent: "space-evenly" }}>
//                             <div
//                                 className="pin-button"
//                                 style={{
//                                     backgroundColor: buttonClick.button0 ? CLICK_COLOR : "",
//                                 }}
//                                 onClick={() => {
//                                     if (count <= 4) {
//                                         setCount(count + 1);
//                                         setButtonClick({ button0: true });
//                                     }
//                                     setPinCode(pinCode + `${arrayButton[0]}`);
//                                 }}
//                             >
//                                 {arrayButton[0]}
//                             </div>
//                             <div
//                                 className="pin-button"
//                                 style={{
//                                     backgroundColor: buttonClick.button1 ? CLICK_COLOR : "",
//                                 }}
//                                 onClick={() => {
//                                     if (count <= 4) {
//                                         setCount(count + 1);
//                                         setButtonClick({ button1: true });
//                                     }
//                                     setPinCode(pinCode + `${arrayButton[1]}`);
//                                 }}
//                             >
//                                 {arrayButton[1]}
//                             </div>
//                             <div
//                                 className="pin-button"
//                                 style={{
//                                     backgroundColor: buttonClick.button2 ? CLICK_COLOR : "",
//                                 }}
//                                 onClick={() => {
//                                     if (count <= 4) {
//                                         setCount(count + 1);
//                                         setButtonClick({ button2: true });
//                                     }
//                                     setPinCode(pinCode + `${arrayButton[2]}`);
//                                 }}
//                             >
//                                 {arrayButton[2]}
//                             </div>
//                         </div>
//                         <div
//                             style={{
//                                 display: "flex",
//                                 justifyContent: "space-evenly",
//                                 marginTop: 11,
//                             }}
//                         >
//                             <div
//                                 className="pin-button"
//                                 style={{
//                                     backgroundColor: buttonClick.button3 ? CLICK_COLOR : "",
//                                 }}
//                                 onClick={() => {
//                                     if (count <= 4) {
//                                         setCount(count + 1);
//                                         setButtonClick({ button3: true });
//                                     }
//                                     setPinCode(pinCode + `${arrayButton[3]}`);
//                                 }}
//                             >
//                                 {arrayButton[3]}
//                             </div>
//                             <div
//                                 className="pin-button"
//                                 style={{
//                                     backgroundColor: buttonClick.button4 ? CLICK_COLOR : "",
//                                 }}
//                                 onClick={() => {
//                                     if (count <= 4) {
//                                         setCount(count + 1);
//                                         setButtonClick({ button4: true });
//                                     }
//                                     setPinCode(pinCode + `${arrayButton[4]}`);
//                                 }}
//                             >
//                                 {arrayButton[4]}
//                             </div>
//                             <div
//                                 className="pin-button"
//                                 style={{
//                                     backgroundColor: buttonClick.button5 ? CLICK_COLOR : "",
//                                 }}
//                                 onClick={() => {
//                                     if (count <= 4) {
//                                         setCount(count + 1);
//                                         setButtonClick({ button5: true });
//                                     }
//                                     setPinCode(pinCode + `${arrayButton[5]}`);
//                                 }}
//                             >
//                                 {arrayButton[5]}
//                             </div>
//                         </div>
//                         <div
//                             style={{
//                                 display: "flex",
//                                 justifyContent: "space-evenly",
//                                 marginTop: 11,
//                             }}
//                         >
//                             <div
//                                 className="pin-button"
//                                 style={{
//                                     backgroundColor: buttonClick.button6 ? CLICK_COLOR : "",
//                                 }}
//                                 onClick={() => {
//                                     if (count <= 4) {
//                                         setCount(count + 1);
//                                         setButtonClick({ button6: true });
//                                     }
//                                     setPinCode(pinCode + `${arrayButton[6]}`);
//                                 }}
//                             >
//                                 {arrayButton[6]}
//                             </div>
//                             <div
//                                 className="pin-button"
//                                 style={{
//                                     backgroundColor: buttonClick.button7 ? CLICK_COLOR : "",
//                                 }}
//                                 onClick={() => {
//                                     if (count <= 4) {
//                                         setCount(count + 1);
//                                         setButtonClick({ button7: true });
//                                     }
//                                     setPinCode(pinCode + `${arrayButton[7]}`);
//                                 }}
//                             >
//                                 {arrayButton[7]}
//                             </div>
//                             <div
//                                 className="pin-button"
//                                 style={{
//                                     backgroundColor: buttonClick.button8 ? CLICK_COLOR : "",
//                                 }}
//                                 onClick={() => {
//                                     if (count <= 4) {
//                                         setCount(count + 1);
//                                         setButtonClick({ button8: true });
//                                     }
//                                     setPinCode(pinCode + `${arrayButton[8]}`);
//                                 }}
//                             >
//                                 {arrayButton[8]}
//                             </div>
//                         </div>
//                         <div
//                             style={{
//                                 display: "flex",
//                                 justifyContent: "space-evenly",
//                                 marginTop: 11,
//                             }}
//                         >
//                             <div style={{ height: 88, width: 88 }}></div>
//                             <div
//                                 className="pin-button"
//                                 style={{
//                                     backgroundColor: buttonClick.button9 ? CLICK_COLOR : "",
//                                 }}
//                                 onClick={() => {
//                                     if (count <= 4) {
//                                         setCount(count + 1);
//                                         setButtonClick({ button9: true });
//                                     }
//                                     setPinCode(pinCode + `${arrayButton[9]}`);
//                                 }}
//                             >
//                                 {arrayButton[9]}
//                             </div>
//                             <div
//                                 className="pin-button"
//                                 style={{
//                                     backgroundColor: buttonClick.button10 ? CLICK_COLOR : "",
//                                 }}
//                                 onClick={() => {
//                                     setButtonClick({ button10: true });
//                                     if (count > 0) {
//                                         setCount(count - 1);
//                                     }
//                                     const newCode = pinCode.slice(0, -1);
//                                     setPinCode(newCode);
//                                 }}
//                             >
//                                 <FontAwesomeIcon icon={faDeleteLeft} />
//                             </div>
//                         </div>
//                     </div>
//                 ) : (
//                     <div />
//                 )}
//                 {/* <Modal
//                     show={paymentError}
//                     size="sm"
//                     centered
//                 >
//                     <Modal.Body style={{ textAlign: "center" }}>
//                         <img src="/assets/images/payment_failed.png" alt="" width={135} />
//                         <div
//                             style={{
//                                 marginTop: 16,
//                                 fontWeight: "bold",
//                                 fontSize: 18,
//                                 marginBottom: 16,
//                             }}
//                         >
//                             {t("payment-failed")}!
//                         </div>
//                         <Button
//                             style={{
//                                 backgroundColor: COLOR_APP,
//                                 border: "none",
//                                 width: "90%",
//                             }}
//                         >
//                             {t("payment-back")}
//                         </Button>
//                     </Modal.Body>
//                 </Modal> */}

//                 {/* <Modal show={show} size="sm" onHide={() => setShow(false)} centered>
//                     <Modal.Body style={{ textAlign: "center" }}>
//                         <AiOutlineCloseCircle
//                             style={{ fontSize: 60, color: "red", marginBottom: 20 }}
//                         />
//                         <div style={{ color: "red" }}>{t("payment-in-correct")}!</div>
//                     </Modal.Body>
//                 </Modal> */}
//             </div>
//         </div>
//     )
// }

// export default BuyDataPackagePin

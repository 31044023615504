import React, { useState, useEffect } from 'react'
import CustomAppBar from '../../../components/CustomAppBar'
import { useLocation, useNavigate } from 'react-router-dom'
import { BACKGROUND_COLOR_APP, COLOR_APP } from '../../../constants'
import { Modal, Row, Col, InputGroup, Button } from 'react-bootstrap'
import { GET_PACKAGE_DATA } from './graphql/query';
import DataPackageCard from '../../../components/DataPackageCard'
import NumberFormat from 'react-number-format'
import { MdRadioButtonChecked } from 'react-icons/md'
import { IoIosRadioButtonOff } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import { RiErrorWarningFill } from 'react-icons/ri'
import { useLazyQuery } from '@apollo/client'
import Loading from '../../../components/Loading'
import _ from 'lodash'
import { convertPhoneToService } from '../../../helpers'

function BuyDataPackageList() {
    const navigate = useNavigate()
    const location = useLocation();

    let _packageData = new URLSearchParams(location.search).get("package");
    let _phone = new URLSearchParams(location.search).get("phone");
    let _qrType = new URLSearchParams(location.search).get("qrType");

    const { t, i18n } = useTranslation();
    const [showRequired, setShowRequired] = useState(false);
    const [checked, setChecked] = useState(_packageData ? _packageData : '')
    const [phone, setPhone] = useState(_phone ? _phone : '');

    const [showLess, setShowLess] = useState(false);
    const [showLess8, setShowLess8] = useState(false);
    const [show, setShow] = useState(false);
    const [showRequiredService, setShowRequiredService] = useState(false);
    const [showNotPhoneOfService, setShowNotPhoneOfService] = useState(false);
    const [packageList, setPackageList] = useState([]);
    const [packageListData, setPackageListData] = useState({});
    const [selectPackage, setSelectPackage] = useState(_qrType ? _qrType : "");
    const [lat, setLat] = useState()
    const [lng, setLng] = useState()
    const [showPhone, setShowPhone] = useState(_qrType ? true : false)

    const [getPackage, { data: packageData, loading: packageLoading }] = useLazyQuery(
        GET_PACKAGE_DATA
    );

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);

    }, [])

    useEffect(() => {
        if (packageData) {
            const data = packageData.listDataPackages.data;

            // sorted data by locaPrice cheapest to the most expensive
            const sortedData = [...data].sort((a, b) => a.locaPrice - b.locaPrice);

            setPackageList(sortedData);
        }
    }, [packageData])

    useEffect(() => {
        if (selectPackage) {
            getPackage({
                variables: {
                    where: {
                        serviceProvider: selectPackage
                    }
                }
            })
        }
    }, [selectPackage])

    // useEffect(()=> {
    //     if(packageListData){
    //         console.log("packageListData: ", packageListData)
    //     }
    // },[packageListData])

    const successCallback = (position) => {
        if (position.coords) {
            setLat(position.coords.latitude)
            setLng(position.coords.longitude)
        }
    };

    const errorCallback = (error) => {
        console.log(error);
    };


    const _goBack = () => {
        navigate(`/home-add-creditcard`)
    }

    const goByDataPackage = () => {
        try {
            if (!selectPackage) {
                return setShowRequiredService(true);
            }
            if (_.isEmpty(packageListData)) {
                return setShow(true);
            }
            if (!phone) {
                return setShowLess(true);
            }
            if (phone.trim().length < 8) {
                return setShowLess8(true);
            }
            if (phone.trim().length >= 9) {
                return setShowLess8(true);
            }
            if (convertPhoneToService(phone) !== selectPackage) {
                return setShowNotPhoneOfService(true);
            }
            // const data = {
            //     phone: phone,
            //     package: packageListData.title,
            //     price: packageListData.price
            // }
            // console.log('-----<',data);
            navigate(`/buy-data-package-detail?phone=${phone.trim()}&packageName=${packageListData.title}&package=${packageListData.id}&price=${packageListData.price}&lat=${lat}&lng=${lng}&qrType=${selectPackage}`)
        } catch (error) {
            console.log(error);
        }
    }

    if (packageLoading) {
        return <Loading />
    }

    return (
        <div className='wrapper' style={{ backgroundColor: BACKGROUND_COLOR_APP }}>
            <CustomAppBar
                gotoOtherPage={_goBack}
                title={"LOCA PAY"}
            />

            <div style={{ padding: 20 }}>
                {/* <div style={{ fontSize: 18, fontWeight: "bold" }}>{t("buy-data-package")}</div> */}
                {/* <div style={{ color: "#999999", fontSize: 16 }}>{t("please-select-package")}</div> */}
                {/* <div style={{ height: 19 }} /> */}


                <div style={{ marginBottom: 12, fontSize: 14 }}>Please select a service provider</div>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.1)",
                    padding: 8,
                    borderRadius: 8,
                    marginBottom: 12,
                    border: selectPackage === 'LTC' ? "2px solid #B3D4DC" : "none",
                    backgroundColor: selectPackage === 'LTC' ? "#EEF8FE" : "white"
                }}
                    onClick={() => { setSelectPackage('LTC'); setShowPhone(true) }}
                >
                    <div style={{ display: "flex" }}>
                        <img src="assets/images/ltc_logo.jpeg" alt="" width={40} style={{ borderRadius: 50 }} />
                        <div style={{ marginLeft: 12 }}>
                            <div style={{ fontWeight: "bold" }}>Lao telecom</div>
                            <div style={{ color: "grey", fontSize: 14 }}>+856-20-5xxx-xxxx</div>
                        </div>
                    </div>
                    {selectPackage === 'LTC' ? <MdRadioButtonChecked fontSize={22} color='#10AAF9' /> : <div />}
                </div>

                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.1)",
                    padding: 8,
                    borderRadius: 8,
                    marginBottom: 12,
                    border: selectPackage === 'UNITEL' ? "2px solid #B3D4DC" : "none",
                    backgroundColor: selectPackage === 'UNITEL' ? "#EEF8FE" : "white"
                }}
                    onClick={() => { setSelectPackage('UNITEL'); setShowPhone(true) }}
                >
                    <div style={{ display: "flex" }}>
                        <img src="assets/images/unitel_logo.jpeg" alt="" width={40} style={{ borderRadius: 50 }} />
                        <div style={{ marginLeft: 12 }}>
                            <div style={{ fontWeight: "bold" }}>Unitel</div>
                            <div style={{ color: "grey", fontSize: 14 }}>+856-20-9xxx-xxxx</div>
                        </div>
                    </div>
                    {selectPackage === 'UNITEL' ? <MdRadioButtonChecked fontSize={22} color='#10AAF9' /> : <div />}
                </div>

                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.1)",
                    padding: 8,
                    borderRadius: 8,
                    marginBottom: 12,
                    border: selectPackage === 'ETL' ? "2px solid #B3D4DC" : "none",
                    backgroundColor: selectPackage === 'ETL' ? "#EEF8FE" : "white"
                }}
                    onClick={() => { setSelectPackage('ETL'); setShowPhone(true) }}
                >
                    <div style={{ display: "flex" }}>
                        <img src="assets/images/etl_logo.png" alt="" width={40} style={{ borderRadius: 50 }} />
                        <div style={{ marginLeft: 12 }}>
                            <div style={{ fontWeight: "bold" }}>ETL Company Limited</div>
                            <div style={{ color: "grey", fontSize: 14 }}>+856-20-2xxx-xxxx</div>
                        </div>
                    </div>
                    {selectPackage === 'ETL' ? <MdRadioButtonChecked fontSize={22} color='#10AAF9' /> : <div />}
                </div>

                <div style={{ height: 12 }} />

                <div style={{ display: "flex", justifyContent: "center", color: "#746E6E", marginBottom: 16, alignItems: "center" }}>
                    {/* <div>---------------------- Data Package -------------------------</div> */}
                    <div style={{ width: "35%", border: "0.5px solid #EBEBEB", margin: 2, display: "inline-block" }}></div>
                    <div style={{ fontSize: 14 }}>Data Package</div>
                    <div style={{ width: "35%", border: "0.5px solid #EBEBEB", margin: 2, display: "inline-block" }}></div>
                </div>
                {selectPackage ? <Row>
                    {
                        packageList.map((item, index) => {
                            return <Col
                                key={index}
                                xs={{ span: 6 }}
                                sm={{ span: 4 }}
                                md={{ span: 3 }}
                                lg={{ span: 2 }}
                                xl={{ span: 1 }}
                                style={{ marginBottom: 18 }}>
                                <DataPackageCard
                                    // onClick={()=> {setData(item)}}
                                    // onChange={()=> {setPackageListData(item)}}
                                    title={selectPackage === "LTC" ?
                                        item.ltcNMAmount : selectPackage === "UNITEL" ?
                                            item.unitelNMAmount : selectPackage === "ETL" ?
                                                item.etlNSAmount : ""
                                    }
                                    duration={`${t("duration")} ${selectPackage === "LTC" ?
                                        item.ltcNSDays : selectPackage === "UNITEL" ?
                                            item.unitelNMDays : selectPackage === "ETL" ?
                                                item.etlNMDays : ""
                                        } ${t("day")}`}
                                    price={item.locaPrice}
                                    backgroundColor={checked === item.id ? COLOR_APP : "white"}
                                    color={checked === item.id ? "white" : ""}
                                    setChecked={setChecked}
                                    setPackageListData={setPackageListData}
                                    currentChecked={item.id}
                                    icon={checked === item.id ? <MdRadioButtonChecked fontSize={22} /> : <IoIosRadioButtonOff fontSize={22} />}
                                    id={item.id}
                                />
                            </Col>
                        })
                    }

                </Row> : <div />}
                <div style={{ height: 160 }} />

            </div>
            {showPhone ? <div
                style={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: "100%"
                }}
            >
                <div style={{
                    background: "0 4px 8px 6px rgba(0, 0, 0, 0.2)",
                    backgroundColor: "white",
                    textAlign: "center",
                    padding: "19px 23px"
                }}>
                    <div style={{ fontSize: 16, color: "#999999" }}>{t("please-input-phone")}</div>
                    <div style={{ height: 14 }} />
                    <InputGroup>
                        <InputGroup.Text style={{ border: "none", backgroundColor: "#F8F8F8" }}>
                            +856 20
                        </InputGroup.Text>
                        <NumberFormat
                            className="number-format-custom form-control"
                            format="########"
                            style={{
                                backgroundColor: "#F8F8F8",
                                border: "none",
                                height: 45
                            }}
                            value={phone}
                            onChange={(e) => { setPhone(e.target.value) }}
                        />
                    </InputGroup>

                    {showRequired ? (
                        <div style={{ color: "red" }}>
                            {t("phone-number-required")}
                        </div>
                    ) : (
                        <div />
                    )}

                </div>
                <Button
                    style={{
                        height: 50,
                        backgroundColor: COLOR_APP,
                        textAlign: "center",
                        border: "none",
                        width: "100%",
                        fontWeight: 700,
                        fontSize: 16
                    }}
                    onClick={() => goByDataPackage()}
                >
                    {t("kyc-next")}
                </Button>
            </div> : <div />}

            <Modal
                show={showLess}
                size="sm"
                onHide={() => setShowLess(false)}
                centered
            >
                <Modal.Body style={{ textAlign: "center" }}>
                    <RiErrorWarningFill style={{ fontSize: 60, color: "yellow" }} />
                    <div>
                        {/* Price must more than 10,000 Kip  */}
                        <p className="py-2">{t("please-input-phone-number")}</p>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={showLess8}
                size="sm"
                onHide={() => setShowLess8(false)}
                centered
            >
                <Modal.Body style={{ textAlign: "center" }}>
                    <RiErrorWarningFill style={{ fontSize: 60, color: "yellow" }} />
                    <div>
                        {/* Price must more than 10,000 Kip  */}
                        <p>{t("please-input-eight-number")}</p>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={showNotPhoneOfService}
                size="sm"
                onHide={() => setShowNotPhoneOfService(false)}
                centered
            >
                <Modal.Body style={{ textAlign: "center" }}>
                    <RiErrorWarningFill style={{ fontSize: 60, color: "yellow" }} />
                    <div>
                        {/* Price must more than 10,000 Kip  */}
                        <p>{t("please-input-phone-of-service")}</p>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={show}
                size="sm"
                onHide={() => setShow(false)}
                centered
            >
                <Modal.Body style={{ textAlign: "center" }}>
                    <RiErrorWarningFill style={{ fontSize: 60, color: "yellow" }} />
                    <div>
                        {/* Price must more than 10,000 Kip  */}
                        <p>{t("please-choose-package")}</p>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={showRequiredService}
                size="sm"
                onHide={() => setShowRequiredService(false)}
                centered
            >
                <Modal.Body style={{ textAlign: "center" }}>
                    <RiErrorWarningFill style={{ fontSize: 60, color: "yellow" }} />
                    <div>
                        {/* Price must more than 10,000 Kip  */}
                        <p>{t("please-choose-service-provider")}</p>
                    </div>
                </Modal.Body>
            </Modal>


        </div>
    )
}

export default BuyDataPackageList
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect, useRef } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import CustomAppBar from '../../components/CustomAppBar'
import { useMutation } from '@apollo/client'
import { UPDATE_USER } from '../graphql/mutation'
import { BACKGROUND_COLOR_APP, USER_ID } from '../../constants'

function HomeProfileLanguage() {

    const history = useNavigate()
    const { t, i18n } = useTranslation();
    const myLang = localStorage.getItem("userLang")
    const [canScroll, setCanScroll] = useState(false)
    const { innerWidth: width, innerHeight: height } = window;
    const ref = useRef(null);
    const [updateUser] = useMutation(UPDATE_USER);
    const userId = localStorage.getItem(USER_ID);


    useEffect(() => {
        if (ref.current.clientHeight < height) setCanScroll(true);
        else setCanScroll(false);
    }, [])

    const changeLanguageHandler = (lang) => {
        i18n.changeLanguage(lang);

        localStorage.setItem("userLang", lang);
    };

    const _goBackToHomeProfile = () => {
        history(-1)
    }

    const updateUserData = async (lang) => {
        try {
            const update = await updateUser({
                variables: {
                    data: {
                        userLang: lang
                    },
                    where: {
                        id: userId
                    }
                }
            });
            // console.log("update: ", update)
        } catch (error) {
            console.log("updateError: ", error)
        }
    }

    return (
        <div style={canScroll ? { position: "fixed", overflowY: "scroll", width: "100%",backgroundColor: BACKGROUND_COLOR_APP,height: "100%" } : {backgroundColor: BACKGROUND_COLOR_APP, height: "100%" }}
            ref={ref}
        >
            {/* <div style={{
                display: "flex",
                height: 49,
            }}>
                <FontAwesomeIcon style={{
                    marginLeft: 18,
                    marginTop: 17,
                    height: 20,
                    width: 16,
                    fontWeight: "bold"
                }} icon={faArrowLeft}
                    onClick={() => _goBackToHomeProfile()}
                />
            </div> */}

            <CustomAppBar
                gotoOtherPage={_goBackToHomeProfile}
                title={"LOCA PAY"}
            />

            <div style={{ height: 25 }} />
            <div style={{ fontSize: 24, fontWeight: "bold", marginLeft: 18, }}>
                {/* Languages */}
                {t("setting-languages")}
            </div>
            <div style={{ height: 36 }} />
            <div style={{ display: "flex", justifyContent: "space-between", marginRight: 36, marginLeft: 40 }}
                onClick={() => { changeLanguageHandler("en"); updateUserData("en"); }}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Form.Check
                        type="radio"
                        value="en"
                        name='lang'
                        onChange={(e) => { changeLanguageHandler(e.target.value); updateUserData(e.target.value) }}
                        checked={myLang == "en" ? true : false}
                    />
                    <span style={{ marginLeft: 16 }}>English</span>
                </div>
                <img src="assets/images/English.png" alt="" style={{ width: 34, height: 33 }} />
            </div>
            <div style={{ height: 36 }} />
            <div style={{ display: "flex", justifyContent: "space-between", marginRight: 36, marginLeft: 40 }}
                onClick={() => { changeLanguageHandler("la"); updateUserData("la"); }}
            >

                <div style={{ display: "flex", alignItems: "center" }}>
                    <Form.Check
                        type="radio"
                        value="la"
                        name='lang'
                        checked={myLang == "la" ? true : false}
                        onChange={(e) => { changeLanguageHandler(e.target.value); updateUserData(e.target.value); }}
                    />
                    <span style={{ marginLeft: 16 }}>Lao</span>
                </div>
                <img src="assets/images/Laos.png" alt="" style={{ width: 34, height: 33 }} />
            </div>
        </div>
    )
}

export default HomeProfileLanguage

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    en: {
        translation: require("./en.json"),
    },
    la: {
        translation: require("./la.json"),
    },
};

const userLang = localStorage.getItem("userLang");

i18n.use(initReactI18next).init({
    resources,
    lng: userLang ? userLang : "en",
  
    interpolation: {
      escapeValue: false,
    },
  });
  
  export default i18n;
import { faArrowLeft, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CREATE_CARD } from "../graphql/mutation";
import NumberFormat from "react-number-format";
import { BACKGROUND_COLOR_APP, COLOR_APP, POPUP_COLOR, USER_ID } from "../../constants";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import { RiErrorWarningFill } from "react-icons/ri";
import { UPDATE_USER } from "./graphql/mutation";

import "./HomeAddNewAccount.css";
import Loading from "../../components/Loading";
import { GET_CARDS, GET_USER } from "../graphql/query";
import CustomAppBar from "../../components/CustomAppBar";

function HomeAddNewAccount() {
  const history = useNavigate();

  const [canScroll, setCanScroll] = useState(false);
  const [accept, setAccept] = useState(false);
  const { innerWidth: width, innerHeight: height } = window;
  const ref = useRef(null);

  const [cardData, setCardData] = useState();
  const [cardName, setCardName] = useState();
  const [cardId, setCardId] = useState();
  const [expireMonth, setExpireMonth] = useState();
  const [expireYear, setExpireYear] = useState();
  const [cvc, setCVC] = useState();
  const [createCard] = useMutation(CREATE_CARD);
  const [count, setCount] = useState(0);
  const [fillData, setFillData] = useState(false);
  const [showError, setShowError] = useState(false);
  const [expiry, setExpiry] = useState("MM/YY");
  const [showLess, setShowLess] = useState();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [yearError, setYearError] = useState(false);
  const userId = localStorage.getItem(USER_ID);
  const [userData, setUserData] = useState();
  const [monthError, setMonthError] = useState(false);
  const [showDuplicatedCard, setShowDuplicatedCard] = useState(false);

  const [updateUser] = useMutation(UPDATE_USER);
  const [getUser, { data: userQueryData }] = useLazyQuery(GET_USER);
  const [getCard, { data: cardQueryData }] = useLazyQuery(GET_CARDS);

  useEffect(() => {
    getUser({
      variables: {
        where: {
          id: userId,
        },
      },
    });

    getCard({
      variables: {
        where: {
          createdBy: userId,
        },
      },
    });

    // scrollable?
    if (ref.current.clientHeight > height) setCanScroll(true);
    else setCanScroll(false);
  }, []);

  useEffect(() => {
    if (userQueryData) {
      setUserData(userQueryData.user);
    }
  }, [userQueryData]);

  useEffect(() => {
    if (cardQueryData && cardQueryData.cards) {
      setCardData(cardQueryData.cards.data);
    }
  }, [cardQueryData]);

  const _saveCard = async () => {
    if (cardName && cardId && expireMonth && expireYear && cvc) {
      let cardNumber = await cardId.split(" ");

      let element = "";
      let lstElement = "";
      for (let index = 0; index < cardNumber.length; index++) {
        element += cardNumber[index];
        lstElement = cardNumber[index] != "" ? cardNumber[index] : lstElement
      }

      const format = new Date().getFullYear().toString();
      const yearSplit = format.split("0");
      if (expireYear < yearSplit[1] || monthError == true) {
        setShowLess(true);
      } else {
        setLoading(true);
        try {
          if (cardData && cardData.length > 0) {
            let checkDuplicate = false
            for (var i = 0; i < cardData.length; i++) {
              if (cardData[i].cardId == lstElement && cardData[i].cardName.toLowerCase() == cardName.toLowerCase()) checkDuplicate = true
            }
            if (checkDuplicate) {
              // console.log("Duplicate")
              setShowDuplicatedCard(true)
              setLoading(false);
            } else {
              const _res = await createCard({
                variables: {
                  data: {
                    cardId: element,
                    cardName,
                    expireMonth,
                    expireYear,
                    email: userData.email,
                    cvc: cvc,
                  },
                },
              });

              // console.log("res ==>> ",_res);
              if (_res.data.createCard) {
                setLoading(false);
                if (_res.data) {
                  if (!userData.defaultCard) {
                    _updateDefaultCard(_res.data.createCard.id);
                  }
                  history(`/home-account`);
                }
                setCount(count + 1);
              }
            }
          } else {
            const _res = await createCard({
              variables: {
                data: {
                  cardId: element,
                  cardName,
                  expireMonth,
                  expireYear,
                  email: userData.email,
                  cvc: cvc,
                },
              },
            });

            // console.log("res ==>> ",_res);
            if (_res.data.createCard) {
              setLoading(false);
              if (_res.data) {
                if (!userData.defaultCard) {
                  _updateDefaultCard(_res.data.createCard.id);
                }
                history(`/home-account`);
              }
              setCount(count + 1);
            }
          }
        } catch (error) {
          setLoading(false);
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
            history(`/home-account`);
          }, 3000);
        }
      }
    } else {
      setFillData(true);
    }
  };

  const _updateDefaultCard = async (cardId) => {
    try {
      const _res = await updateUser({
        variables: {
          data: {
            defaultCard: cardId,
          },
          where: {
            id: userId,
          },
        },
      });
      if (_res) {
        history(`/home-account`);
      }
    } catch (error) { }
  };

  const formatMonthYear = (e) => {
    if (e.target.value) {
      let format = e.target.value.split("/");
      const fullYear = new Date().getFullYear().toString();
      const yearSplit = fullYear.split("0");
      let _month = format[0];
      var numberPattern = /\d+/g;
      _month.match(numberPattern);

      if (parseInt(_month) <= 12) {
        let myMonth = _month.split("/");
        setExpiry(e.target.value);
        setExpireMonth(myMonth[0]);
        setExpireYear(format[1]);

        setMonthError(false);
      } else {
        setMonthError(true);
        setExpiry("MM/YY");
      }

      if (format[1] < yearSplit[1]) {
        setYearError(true);
      } else {
        setYearError(false);
      }
    }
  };

  const gotoBack = () => {
    history(-1);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      style={
        canScroll
          ? { position: "fixed", overflowY: "scroll", width: "100%", backgroundColor: BACKGROUND_COLOR_APP }
          : { backgroundColor: BACKGROUND_COLOR_APP }
      }
      ref={ref}
      className="wrapper"
    >

      <CustomAppBar
        gotoOtherPage={gotoBack}
        title={"LOCA PAY"}
      />

      <div
        style={{
          marginRight: 26,
          marginLeft: 26,
          marginTop: 26,
        }}
      >
        {/* Card Details */}
        <div style={{
          fontSize: 18,
          fontWeight: "bold",
        }}>{t("card-detail")}</div>
        <div style={{ color: "#999999" }}>{t("please-input-credit")}</div>
      </div>

      <div style={{ display: "flex", marginLeft: 26, marginTop: 17 }}>
        <img src="assets/images/card_detail_pic.png" alt="" style={{ width: 170 }} />
      </div>

      {/* Form  */}

      <Form style={{ marginLeft: 26, marginRight: 26, marginTop: 17 }}>
        <Form.Group className="mb-3">
          <Form.Label style={{ color: "#999999" }}>{t("card-name")}</Form.Label>
          <Form.Control
            style={{
              color: "#101010",
              border: "none",
              height: 44,
              fontWeight: "bold",
              borderRadius: 8
            }}
            type="text"
            name="cardname"
            value={cardName}
            onChange={(e) => setCardName(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label style={{ color: "#999999" }}>{t("add-card-number")}</Form.Label>
          <NumberFormat
            className="number-format-custom"
            format="#### #### #### #### ###"
            style={{
              color: "#101010",
              border: "none",
              paddingLeft: 10,
              borderRadius: 8,
              height: 44,
              fontWeight: "bold",
              width: "100%",
            }}
            value={cardId}
            onChange={(e) => setCardId(e.target.value)}
          />
        </Form.Group>

        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <Form.Group className="mb-3" style={{ width: "45%" }}>
            <Form.Label style={{ color: "#999999" }}>{t("add-card-expiry")}</Form.Label>
            <div>
              <NumberFormat
                className="number-format-cus"
                format="##/##"
                placeholder="MM/YY"
                style={{
                  color: yearError || monthError ? "red" : "",
                  border: "none",
                  paddingLeft: 10,
                  borderRadius: 4,
                  height: 44,
                  fontWeight: "bold",
                  width: "100%",
                }}
                mask={["M", "M", "Y", "Y"]}
                value={expiry}
                // defaultValue={expiry}
                onChange={(e) => formatMonthYear(e)}
              />
            </div>
          </Form.Group>

          <Form.Group className="mb-3" style={{ width: "45%" }}>
            <Form.Label style={{ color: "#999999" }}>CVC</Form.Label>
            <Form.Control
              id="expiry-control"
              type="number"
              value={cvc}
              onChange={(e) => {
                if (e.target.value.length === 5) return false;
                setCVC(e.target.value);
              }}
              style={{ backgroundColor: "white", width: "100%" }}
            />
          </Form.Group>
        </div>

        <input
          style={{ marginRight: 8 }}
          type="checkbox"
          onClick={() => setAccept(!accept)}
        />
        <span style={{ color: '#E3B200' }}>{t("add-card-accept")}</span>
      </Form>
      {/* Submit----- */}
      {/* <div style={{display:"flex", justifyContent:"center"}}>
        <div style={{
          textAlign: "start",
          fontSize: 14,
          marginTop: 16,
          color: "grey",
          marginLeft: 26,
          marginRight: 26,
          color: "#E3B200"
        }}>{t("add-card-warning")}</div>
      </div> */}
      <div style={{ height: 20 }} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          disabled={accept ? false : true}
          style={{
            backgroundColor: COLOR_APP,
            width: "90%",
            height: 50,
            marginTop: 29,
            border: "none",
            borderRadius: 8
          }}
          onClick={() => _saveCard()}
        >
          {/* Save Card */}
          {t("add-card-save")}
        </Button>
      </div>

      <Modal
        show={showError}
        size="sm"
        onHide={() => setShowError(false)}
        centered
      >
        <Modal.Body style={{ textAlign: "center" }}>
          <RiErrorWarningFill style={{ fontSize: 60, color: POPUP_COLOR }} />
          <div>
            {/* Your Card is already exist */}
            {t("card-incorrect")}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={fillData}
        size="sm"
        onHide={() => setFillData(false)}
        centered
      >
        <Modal.Body style={{ textAlign: "center" }}>
          <RiErrorWarningFill
            style={{ fontSize: 60, color: POPUP_COLOR, marginBottom: 10 }}
          />
          <div>
            {/* Your Card is already exist */}
            {t("card-fill")}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showLess}
        size="sm"
        onHide={() => setShowLess(false)}
        centered
      >
        <Modal.Body style={{ textAlign: "center" }}>
          <RiErrorWarningFill
            style={{ fontSize: 60, color: POPUP_COLOR, marginBottom: 10 }}
          />
          <div>
            {/* Your Card is already exist */}
            {t("card-expire-year")}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDuplicatedCard}
        size="sm"
        onHide={() => setShowDuplicatedCard(false)}
        centered
      >
        <Modal.Body style={{ textAlign: "center" }}>
          <FontAwesomeIcon icon={faTriangleExclamation} fontSize={80} color={"#eb9b34"} />
          <div style={{ marginTop: 20, marginBottom: 10 }}>
            {t("card-already")}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default HomeAddNewAccount;

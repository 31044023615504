import { gql } from '@apollo/client';

export const REQUEST_TELBIZ_AUTH = gql`
mutation Mutation {
  telbizRequestAuth {
    accessToken
    code
    detail
    expires
    message
    password
    refreshToken
    success
    username
  }
}
`;
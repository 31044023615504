import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { BACKGROUND_COLOR_APP, USER_ID } from "../../constants";
import { HEADER_HEIGHT } from "../../constants";
import CustomAppBar from "../../components/CustomAppBar";

function Kyc() {
  const history = useNavigate();
  const location = useLocation();

  let firstName = new URLSearchParams(location.search).get("firstName");
  let lastName = new URLSearchParams(location.search).get("lastName");
  let profileImage = new URLSearchParams(location.search).get("profileImage");
  let _userLang = new URLSearchParams(location.search).get("userLang");
  let countryCode = new URLSearchParams(location.search).get("countryCode");
  let phone = new URLSearchParams(location.search).get("phone");
  let fcmTopic = new URLSearchParams(location.search).get("fcmTopic");
  let userId = localStorage.getItem(USER_ID);
  const { t, i18n } = useTranslation();

  const [userLang, setUserLang] = useState(_userLang ? _userLang : "en");

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("userLang", lang);
  };

  const _gotoKycSignup = () => {
    history(
      `/kycsignup?firstName=${firstName}&lastName=${lastName}&profileImage=${profileImage}&userLang=${userLang}&countryCode=${countryCode}&phone=${phone}&fcmTopic=${fcmTopic}`
    );
  };

  const _goBack = () => {
    history(`/home-profile?userId=${userId}`);
  };

  return (
    <div className="wrapper" style={{backgroundColor: BACKGROUND_COLOR_APP}}>
      {/* <div className="kyc-header">
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={() => _goBack()}
          style={{ fontSize: "18", marginRight: 10 }}
        />
      </div> */}
      <CustomAppBar 
          gotoOtherPage={_goBack}
          title={"LOCA PAY"}
      />
      {/* Body */}
      <div
      >
        <div style={{ textAlign: "center" }}>
          {/* 720 × 712 */}
          <img
            style={{ objectFit: "contain" }}
            height={250}
            src="/assets/images/Kyc_Logo_Welcome.png"
            alt=""
          />
        </div>
        <div style={{ height: 25 }} />
        <h2
          style={{ fontWeight: "bold", textAlign: "center", marginBottom: 19 }}
        >
          {/* Welcome to Loca Pay */}
          {t("kyc-welcome")}
        </h2>
        <p style={{ fontSize: 14, textAlign: "center" }}>
          {/* Please vertify with KYC to login Loca Pay */}
          {t("kyc-verify")}
        </p>
        <div style={{ height: 60 }} />
        <div style={{ textAlign: "center" }}>
          <button
            className="btn"
            style={{
              backgroundColor: "#00ADFF",
              color: "white",
              width: "85%",
            }}
            onClick={() => _gotoKycSignup()}
          >
            {/* Register */}
            {t("kyc-register")}
          </button>
        </div>
        <div style={{ height: 110 }} />
        <div style={{ justifyContent: "center", display: "flex" }}>
          <div
            style={{
              width: "65%",
              justifyContent: "space-around",
              display: "flex",
            }}
          >
            <img
              src="/assets/images/kyc_lao.png"
              alt=""
              style={{ width: 36, height: 36 }}
              onClick={() => changeLanguageHandler("la")}
            />
            <img
              src="/assets/images/kyc_english.png"
              alt=""
              style={{ width: 36, height: 36 }}
              onClick={() => changeLanguageHandler("en")}
            />
          </div>
        </div>
        <div style={{ height: 20 }} />
      </div>
    </div>
  );
}

export default Kyc;

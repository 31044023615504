import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import CustomAppBar from '../../../components/CustomAppBar';
import { useNavigate, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { BACKGROUND_COLOR_APP, COLOR_APP, USER_ID } from '../../../constants';
import { numberWithCommas } from '../../../constants/ShowFormat';
import { GET_CARDS } from '../../graphql/query';
import { useLazyQuery } from '@apollo/client';
import Loading from '../../../components/Loading';
import { GET_FEE, GET_POPUPS, GET_USER } from '../graphql/query';
import { GrClose } from 'react-icons/gr';
import CreditCardInfo from '../../../components/CreditCardInfo';
import { DirectiveLocation } from 'graphql';

function BuyDataPackageDetail() {

    // const history = useNavigate();
    const location = useLocation();
    const navigate = useNavigate()
    const { t, i18n } = useTranslation();

    let packageData = new URLSearchParams(location.search).get("package");
    let packageName = new URLSearchParams(location.search).get("packageName");
    let price = new URLSearchParams(location.search).get("price");
    let phone = new URLSearchParams(location.search).get("phone");
    let lat = new URLSearchParams(location.search).get("lat");
    let lng = new URLSearchParams(location.search).get("lng");
    let qrType = new URLSearchParams(location.search).get("qrType");


    const userId = localStorage.getItem(USER_ID);
    const [defaultCardData, setDefaultCardData] = useState();
    const [showKyc, setShowKyc] = useState(false);
    const [changeCard, setChangeCard] = useState(false);
    const [cardData, setCardData] = useState({});
    const [totalFeesString, setTotalFeesString] = useState();
    const [stripeCardId, setStripeCardId] = useState()
    // const [realPrice, setRealPrice] = useState();

    const [getCard, { data: cardQueryData, loading: cardLoading }] = useLazyQuery(GET_CARDS);
    const [getUser, { data: userQueryData }] = useLazyQuery(
        GET_USER
    );
    const [getPopup, { data: popupQueryData }] = useLazyQuery(GET_POPUPS);
    const [getFee, { data: feeQueryData }] = useLazyQuery(GET_FEE);


    useEffect(() => {
        getFee();
        getUser({
            variables: {
                where: {
                    id: userId,
                },
            },
        });
        getCard();
        getPopup();
    }, [])

    useEffect(() => {
        if (cardQueryData && userQueryData) {
            setCardData(cardQueryData.cards.data);
            for (let index = 0; index < cardQueryData.cards.data.length; index++) {
                if (
                    cardQueryData.cards.data[index].id == userQueryData.user.defaultCard
                ) {
                    setDefaultCardData(cardQueryData.cards.data[index]);
                    setStripeCardId(cardQueryData.cards.data[index].id)
                }
            }
        }
    }, [cardQueryData, userQueryData])

    useEffect(() => {
        if (userQueryData) {
            if (userQueryData.user.userStatus == "NOT_REGISTER") {
                setShowKyc(true);
            }
        }
    }, [userQueryData]);

    useEffect(() => {
        if (feeQueryData) {
            const feesData = feeQueryData.fee.data[0];

            const { feeAmount, charge } = feesData;
            const totalFees = calAllFees({ price, feeAmount, charge });

            setTotalFeesString(numberWithCommas(parseFloat(totalFees).toFixed(2)));
        }
    }, [feeQueryData]);

    const calAllFees = ({ price, feeAmount, charge }) => {
        return (parseInt(price) || 0) * parseFloat(feeAmount) + parseInt(charge);
    };



    const _goBack = () => {
        navigate(`/buy-data-package-list?phone=${phone}&package=${packageData}&qrType=${qrType}`)
    }

    const gotoBuyDataPackagePin = () => {
        navigate(`/buy-data-package-pin?phone=${phone}&package=${packageData}&packageName=${packageName}&price=${price}&lat=${lat}&lng=${lng}&feeAmount=${feeQueryData.fee.data[0].feeAmount}&charge=${feeQueryData.fee.data[0].charge}&stripeCardId=${stripeCardId}&cardHolderName=${defaultCardData.cardName}&cardLast4digit=${defaultCardData.cardId}&expireMonth=${defaultCardData.expireMonth}&expireYear=${defaultCardData.expireYear}&brand=${defaultCardData.brand}&qrType=${qrType}`)
    }

    if (cardLoading) {
        return <Loading />
    }

    return (
        <div className="wrapper" style={{ backgroundColor: BACKGROUND_COLOR_APP }}>
            <CustomAppBar
                gotoOtherPage={_goBack}
                title={"LOCA PAY"}
            />

            <div style={{ padding: 20 }}>
                {/* Payment  */}
                <div
                    style={{
                        fontSize: 17,
                        fontWeight: "bold",
                    }}
                >
                    {/* Payment */}
                    {t("buy-data-package")}
                </div>

                <div style={{ display: "flex", flexDirection: "row", marginTop: 6 }}>
                    <div
                        style={{
                            fontSize: 16,
                            marginTop: 6,
                            color: "#999999"
                        }}
                    >
                        {/* Amount */}
                        {t("amount")}
                    </div>
                    <div style={{ width: 10 }} />
                    <div style={{ color: "#EB001B", fontSize: 24, fontWeight: "bold" }}>LAK -{numberWithCommas(parseFloat(price).toFixed(2))}</div>
                </div>

                {/* <div style={{ height: 15 }} /> */}
                <div style={{ display: "flex", flexDirection: "row", marginTop: 4 }}>
                    <div
                        style={{
                            fontSize: 16,
                            color: "#999999"
                        }}
                    >{t("payment-fee")}</div>
                    <div style={{ width: 10 }} />
                    <div style={{ color: "#EB001B", fontSize: 15 }}>LAK -{totalFeesString}</div>
                </div>

                <div
                    style={{
                        fontSize: 16,
                        marginTop: 8,
                        color: "#999999",
                    }}
                >
                    {/* To */}
                    {t("payment-to")}
                </div>

                <div
                    style={{
                        // height: 70,
                        borderRadius: 6,
                        padding: 8,
                        border: "1px solid #b6b6b6",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <div>
                        <div style={{ fontSize: 16, fontWeight: "bold" }}>DataPackage {numberWithCommas(parseFloat(packageName))} MB</div>
                        <div style={{display:"flex"}}>
                            <div
                                style={{
                                    fontSize: 14,
                                    fontWeight:"bold"
                                }}
                            >{t("phone-number")}</div>
                            <div style={{ width: 10 }} />
                            <div style={{ fontSize: 14, fontWeight: "bold", }}>+856 20 {phone}</div>
                        </div>

                    </div>
                    <div>
                        <img
                            style={{
                                width: 50,
                                height: 50,
                            }}
                            src={qrType == "LTC" ? "/assets/images/ltc_logo.jpeg" :
                                qrType == "UNITEL" ? "/assets/images/unitel_logo.jpeg" :
                                    qrType == "ETL" ? "/assets/images/etl_logo.png" :
                                        "/assets/images/box-loading.jpg"}
                        />
                    </div>
                </div>

                <div
                    style={{
                        fontSize: 16,
                        color: "#999999",
                        marginTop: 11,
                    }}
                >
                    {/* From */}
                    {t("payment-from")}
                </div>

                {defaultCardData ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderRadius: 6,
                            padding: 10,
                            border: "1px solid #b6b6b6"
                        }}
                        onClick={() => setChangeCard(true)}
                    >
                        <div>
                            <div style={{ fontSize: 16, fontWeight: "bold" }}>{defaultCardData ? defaultCardData.cardName : ""}</div>
                            <div style={{ fontSize: 14, fontWeight: "bold" }}>
                                ************{defaultCardData ? defaultCardData.cardId : ""}
                            </div>
                            <div
                                style={{
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    color: "#898989",
                                }}
                            >
                                {t("card-expire")} {defaultCardData.expireMonth} /{" "}
                                {defaultCardData.expireYear}
                            </div>
                        </div>
                        <img
                            style={{
                                height: defaultCardData.brand == "discover" || defaultCardData.brand == "diners" ? 20 :
                                    defaultCardData.brand === "mastercard" ? 40 :
                                        defaultCardData.brand === "visa" ? 20 :
                                            50,
                            }}
                            src={
                                defaultCardData.brand == "visa"
                                    ? "/assets/images/visa_icon.png"
                                    : defaultCardData.brand == "jcb"
                                        ? "/assets/images/jcb.png"
                                        : defaultCardData.brand == "discover" || defaultCardData.brand == "diners"
                                            ? "/assets/images/discover_diner.png"
                                            : defaultCardData.brand == "unionpay"
                                                ? "/assets/images/union_pay.png"
                                                : defaultCardData.brand == "amex"
                                                    ? "/assets/images/amex.png"
                                                    : "/assets/images/mastercards.png"
                            }
                        />
                    </div>
                ) : (
                    <div />
                )}

                <div
                    style={{
                        fontSize: 16,
                        marginTop: 11,
                        color: "#999999",
                        marginBottom: 6
                    }}
                >
                    {t("payment-des")}
                </div>
                <div>{qrType}</div>

                <div style={{ height: 20 }} />

                <div style={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: "100%"
                }}>
                    <Button
                        style={{
                            height: 50,
                            backgroundColor: COLOR_APP,
                            textAlign: "center",
                            border: "none",
                            width: "100%",
                            fontWeight: 700,
                            fontSize: 16
                        }}
                        onClick={() => gotoBuyDataPackagePin()}
                    >
                        {t("payment-accept")}
                    </Button>
                </div>
            </div>

            <Modal
                show={showKyc}
                size="sm"
                onHide={() => setShowKyc(false)}
                centered
            >
                <Modal.Body style={{ textAlign: "center" }}>
                    <img
                        src={
                            popupQueryData
                                ? popupQueryData.popups.data[0].popupImage
                                : "/assets/images/Kyc_Logo_Welcome.png"
                        }
                        style={{ height: 150, marginBottom: 16 }}
                        alt=""
                    />
                    <div style={{ marginBottom: 16 }}>
                        {/* {t("kyc-please-kyc")} */}
                        {popupQueryData ? popupQueryData.popups.data[0].note : ""}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{ fontWeight: "bold", color: COLOR_APP }}
                            onClick={() => setShowKyc(false)}
                        >
                            {/* Later */}
                            {t("kyc-later")}
                        </div>
                        <Button
                            style={{
                                backgroundColor: COLOR_APP,
                                border: "none",
                            }}
                            onClick={() =>
                                location(
                                    `/kyc?profileImage=${userQueryData.user.profileImage}&firstName=${userQueryData.user.firstName}&lastName=${userQueryData.user.lastName}&phone=${userQueryData.user.phone}&sid=${userQueryData.user.sid}`
                                )
                            }
                        >
                            {t("kyc-registers")}
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={changeCard}
                size="sm"
                onHide={() => setChangeCard(false)}
                centered
            >
                <Modal.Body style={{ textAlign: "center" }}>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                        <GrClose onClick={() => setChangeCard(false)} />
                    </div>
                    {cardData ? <CreditCardInfo data={cardData} /> : <div />}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default BuyDataPackageDetail

import React, { useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from "react-router";
import { useLazyQuery } from "@apollo/client/react";
import { COLOR_APP } from "../../constants";
import "./kyc.css";
import { GET_LIMIT_AMOUNT } from "../Home/graphql/query";
import { numberWithCommas } from "../../constants/ShowFormat";

function KycWait() {
  const { t, i18n } = useTranslation();
  const history = useNavigate();
  const [maxAmount, setMaxAmount] = useState(0);

  const [canScroll, setCanScroll] = useState(false);
  const { innerWidth: width, innerHeight: height } = window;
  const ref = useRef(null);

  const [getLimitAmount, { data: limitData }] = useLazyQuery(GET_LIMIT_AMOUNT);

  useEffect(() => {
    getLimitAmount();

    // scrollable?
    if (ref.current.clientHeight < height) setCanScroll(true);
    else setCanScroll(false);
  }, []);

  useEffect(() => {
    if (limitData) {
      let maxAmount = limitData.limitAmounts.data[0].maxAmount;
      if (maxAmount != null && maxAmount != undefined) {
        maxAmount = numberWithCommas(limitData.limitAmounts.data[0].maxAmount);
      }

      setMaxAmount(maxAmount);
    }
  }, [limitData]);

  const backToHome = () => {
    history(`/home-add-creditcard`);
    window.location.reload();
  };

  return (
    <div
      style={
        canScroll
          ? { width: "100%", position: "fixed", overflowY: "scroll" }
          : {}
      }
      ref={ref}
    >
      <div style={{ height: 60 }} />

      <Carousel
        className="main-slider"
        showThumbs={false}
        showArrows={false}
        autoPlay={true}
        showStatus={false}
        infiniteLoop={true}
      >
        <div>
          <img
            style={{ objectFit: "contain" }}
            height={250}
            src="/assets/images/kyc_wait1.png"
            alt=""
          />
          <div style={{ fontSize: 20, fontWeight: "bold" }}>
            {/* You’re get Vertify soon! */}
            {t("kyc-got-verify")}!
          </div>
          <div>
            {/* Please wait admin accept, You’re get checking and Confirm on 24 hours! */}
            {t("kyc-got-please")}!
          </div>
        </div>
        <div>
          <img
            style={{ objectFit: "contain" }}
            height={250}
            src="/assets/images/kyc_wait2.png"
            alt=""
          />
          <div style={{ fontSize: 20, fontWeight: "bold" }}>
            {/* Payment isn’t hard ! */}
            {t("pay-now-while-waitting")}
          </div>
          <div>
            {/* Please wait admin accept, You’re get checking and Confirm on 24 hours! */}
            {`${t("pay-now-while-waitting-des")} ${maxAmount} ${t(
              "pay-now-while-waitting-des-end"
            )}`}
          </div>
        </div>
        <div>
          <img
            style={{ objectFit: "contain" }}
            height={250}
            src="/assets/images/kyc_wait3.jpeg"
            alt=""
          />
          <div style={{ fontSize: 20, fontWeight: "bold" }}>
            {/* Convenient, Safety Quickly with Loca Pay */}
            {t("kyc-convenient")}
          </div>
          <div>
            {/* Please wait admin accept, You’re get checking and Confirm on 24 hours! */}
            {t("kyc-convenient-des")}
          </div>
        </div>
      </Carousel>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          style={{
            backgroundColor: COLOR_APP,
            width: "90%",
            height: 44,
            marginTop: 20,
            border: "none",
          }}
          onClick={() => backToHome()}
        >
          {/* Back */}
          {t("payment-back")}
        </Button>
      </div>
    </div>
  );
}

export default KycWait;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { UPDATE_USER, USER_AUTH } from "./graphql/mutation";
import { Modal } from "react-bootstrap";
import { RiErrorWarningLine } from "react-icons/ri";
import { USER_REGISTER } from "./graphql/mutation";
import { ACCESS_TOKEN, GENDER, SID, TOKEN, USER_ID } from "../constants";

function Index() {
  //variables
  const history = useNavigate();
  const location = useLocation();

  //states
  const [show, setShow] = useState(false);
  const [userAuth] = useMutation(USER_AUTH);
  const [userRegister] = useMutation(USER_REGISTER);
  const [updateUser] = useMutation(UPDATE_USER);

  useEffect(() => {
    authUser();
  }, []);

  const updateUserData = (userId, fcmTopic, userStatus) => {
    try {
      updateUser({
        variables: {
          data: {
            fcmTopic: fcmTopic
          },
          where: {
            id: userId
          }
        }
      });
      if (userStatus) {
        if (userStatus == "REJECTED") {
          history("/kyc-rejected");
        } else {
          history(`/home-add-creditcard`);
        }
      }
    } catch (error) {

    }
  }

  const authUser = async () => {
    //TODO: Get sid & token from url paramater
    let token = new URLSearchParams(location.search).get("token");

    if (!token) {
      setShow(true);
      return;
    }

    // TODO: send Post request to Lailao /auth API (body: {sid, token}) => userData
    const userData = await userAuth({ variables: { token: token } });

    if (userData.data.auth.data.id) {
      let userStatus = userData.data.auth.data.userStatus;
      let userId = userData.data.auth.data.id;
      let fcmTopic = userData.data.auth.data.fcmTopic
      localStorage.setItem(USER_ID, userId);
      localStorage.setItem(SID, userData.data.auth.data.sid);
      localStorage.setItem(GENDER, userData.data.auth.data.gender);
      localStorage.setItem(ACCESS_TOKEN, userData.data.auth.accessToken);
      localStorage.setItem(TOKEN, token);
      localStorage.setItem("userLang", "en");
      updateUserData(userId, fcmTopic, userStatus)
    } else {
      let firstName = userData.data.auth.data.firstName;
      let lastName = userData.data.auth.data.lastName;
      let countryCode = userData.data.auth.data.countryCode;
      let phone = userData.data.auth.data.phone;
      let userLang = userData.data.auth.data.userLang;
      let profileImage = userData.data.auth.data.profileImage;
      let fcmTopic = userData.data.auth.data.fcmTopic;
      let email = userData.data.auth.data.email;

      let newUserData = {
        sid: userData.data.auth.data.sid,
        firstName,
        lastName,
        email,
        countryCode,
        phone,
        userLang,
        profileImage,
        fcmTopic,
        limitAmount: 0,
      };
      const resData = await userRegister({
        variables: {
          data: newUserData,
        },
      });

      if (resData.data && resData.data.userRegister.data) {
        localStorage.setItem(USER_ID, resData.data.userRegister.data.id);
        localStorage.setItem(SID, resData.data.userRegister.data.sid);
        localStorage.setItem(
          ACCESS_TOKEN,
          resData.data.userRegister.accessToken
        );
        localStorage.setItem(TOKEN, token);

        history(`/home-add-creditcard`);
      }
    }
  };

  return (
    <div>
      <Modal show={show} size="sm" onHide={() => setShow(false)} centered>
        <Modal.Body style={{ textAlign: "center" }}>
          <RiErrorWarningLine
            style={{ fontSize: 60, color: "red", marginBottom: 10 }}
          />
          <div style={{ color: "red", fontSize: 20 }}>
            you don't have token!
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Index;

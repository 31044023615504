import React from "react";
import Routes from "./routes";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { ACCESS_TOKEN, DEV_SERVER_API, PROD_SERVER_API } from "./constants";
import multiLangSetup from "./multiLang";

function App() {
  const httpLink = createHttpLink({
    // uri: "http://localhost:7070",
    // uri: DEV_SERVER_API,
    uri: PROD_SERVER_API,
  });

  const authLink = setContext((_, { headers }) => {
    //get the authentication taken from local storage if it exits
    const token = localStorage.getItem(ACCESS_TOKEN);

    return {
      headers: {
        ...headers,
        authorization: token ? token : "",
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    // if (graphQLErrors)
    //   graphQLErrors.forEach(({ message, locations, path }) =>
    //     console.log(
    //       `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
    //     )
    //   );
    // if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const client = new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <Routes />
    </ApolloProvider>
  );
}

export default App;

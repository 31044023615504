import { BottomNavigation, BottomNavigationAction } from '@material-ui/core'
import React, { useState } from 'react'
import { COLOR_APP, USER_ID } from '../constants'
import { faCreditCard, faGear, faHome, faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {useNavigate} from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function HomeBottomNav({currentIndex}) {
    const navigate = useNavigate()
    const userId = localStorage.getItem(USER_ID);
    const { t, i18n } = useTranslation();

    const gotoHome = () => {
        navigate(`/home-add-creditcard`)
    }

    const gotoHistory = () => {
        navigate(`/home-history`)
    }

    const gotoHomeAccount = () =>{
        navigate(`/home-account`)
    }
    
    const gotoHomeProfile = () => {
        navigate(`/home-profile?userId=${userId}`)
    }

    return (
        <div>
            <BottomNavigation
                style={{
                    width: "100%",
                    position: "fixed",
                    bottom: 0,
                    borderTop: "1px solid rgb(0 0 0 / 0.2)",
                }}
                showLabels
            >
                <BottomNavigationAction
                    style={{ color: currentIndex === 1 ? COLOR_APP : "" }}
                    onClick={()=> gotoHome()}
                    label={<span className="bottomNavLabel">
                        {t("home")}
                    </span>}
                    icon={<FontAwesomeIcon icon={faHome} style={{ fontSize: 20 }} />}
                />

                <BottomNavigationAction
                    style={{
                        color: currentIndex === 2 ? COLOR_APP :"",
                    }}
                    onClick={()=> gotoHistory()}
                    label={<span className="bottomNavLabel">
                        {t("activity")}
                    </span>}
                    icon={<FontAwesomeIcon icon={faMoneyBill} style={{ fontSize: 20 }} />}
                />

                <BottomNavigationAction
                    style={{
                        color: currentIndex === 3 ? COLOR_APP : "",
                    }}
                    onClick={()=> gotoHomeAccount()}
                    label={<span className="bottomNavLabel">
                        {t("my-card")}
                    </span>}
                    icon={<FontAwesomeIcon icon={faCreditCard} style={{ fontSize: 20 }} />}
                />

                <BottomNavigationAction
                    style={{
                        color: currentIndex === 4 ? COLOR_APP :"",
                    }}
                    onClick={()=> gotoHomeProfile()}
                    label={<span className="bottomNavLabel">
                        {t("home-setting")}
                    </span>}
                    icon={<FontAwesomeIcon icon={faGear} style={{ fontSize: 20 }} />}
                />

            </BottomNavigation>
        </div>
    )
}

export default HomeBottomNav

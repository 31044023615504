import { faArrowLeft, faDeleteLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BsCircle, BsCircleFill } from "react-icons/bs";
import { useNavigate, useLocation } from "react-router-dom";
import { COLOR_APP, CLICK_COLOR,BACKGROUND_COLOR_APP } from "../../constants";
// import { BACKGROUND_COLOR_APP, CLICK_COLOR, COLOR_APP, COLOR_PIN, COLOR_PINE } from "../../constants";

import "./Home.css";
import CustomAppBar from "../../components/CustomAppBar";

function HomeProfilePinConfirm() {
  const location = useLocation();
  const [buttonClick, setButtonClick] = useState({
    button0: false,
    button1: false,
    button2: false,
    button3: false,
    button4: false,
    button5: false,
    button6: false,
    button7: false,
    button8: false,
    button9: false,
    button10: false,
  });

  //variables
  const history = useNavigate();
  const [count, setCount] = useState(0);
  const [pinCode, setPinCode] = useState("");
  const [show, setShow] = useState(false);

  const oldCode = new URLSearchParams(location.search).get("oldCode");

  const { t, i18n } = useTranslation();

  const _goBacktoHome = () => {
    history(-1);
  };

  useEffect(() => {
    setTimeout(() => {
      setButtonClick({ button0: false });
    }, 100);
    if (count == 4) {
      setTimeout(() => {
        if (oldCode == pinCode) {
          history(`/home-profile-hint?pinCode=${pinCode}`);
        } else {
          setCount(0);
          setPinCode("");
          setShow(true);
        }
      }, 200);
    }
  }, [count]);

  const _goBackProfilePin = () => {
    history(`/home-profile-pin`);
  };

  return (
    <div className="wrapper" style={{ backgroundColor: BACKGROUND_COLOR_APP }}>
      {/* <div className="home-header"> */}
        <CustomAppBar
          gotoOtherPage={_goBacktoHome}
          title={"LOCA PAY"}
        />
  
        {/* <FontAwesomeIcon
          style={{
            marginLeft: 10,
            fontSize: "18",
            fontWeight: "bold",
          }}
          icon={faArrowLeft}
          onClick={() => _goBackProfilePin()}
        /> */}
      {/* </div> */}

      <div style={{ height: 72 }} />
      <div style={{ fontSize: 17, fontWeight: "bold", textAlign: "center" }}>
        {/* Please Confirm your PIN */}
        {t("setting-please-confirm")}
      </div>

      <div style={{ height: 40 }} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        {count >= 1 ? (
          <BsCircleFill
            fontSize={14}
            style={{ marginRight: 20 }}
            color={COLOR_APP}
          />
        ) : (
          <BsCircle
            fontSize={14}
            style={{ marginRight: 20 }}
            color={COLOR_APP}
          />
        )}
        {count >= 2 ? (
          <BsCircleFill
            fontSize={14}
            style={{ marginRight: 20 }}
            color={COLOR_APP}
          />
        ) : (
          <BsCircle
            fontSize={14}
            style={{ marginRight: 20 }}
            color={COLOR_APP}
          />
        )}
        {count >= 3 ? (
          <BsCircleFill
            fontSize={14}
            style={{ marginRight: 20 }}
            color={COLOR_APP}
          />
        ) : (
          <BsCircle
            fontSize={14}
            style={{ marginRight: 20 }}
            color={COLOR_APP}
          />
        )}
        {count >= 4 ? (
          <BsCircleFill fontSize={14} color={COLOR_APP} />
        ) : (
          <BsCircle fontSize={14} color={COLOR_APP} />
        )}
      </div>
      {show ? (
        <div style={{ textAlign: "center", marginTop: 10, color: "red" }}>
          {/* Your PIN is not match */}
          {t("setting-not-match")}
        </div>
      ) : (
        <div />
      )}

      <div style={{ height: 60 }} />
      <div
        style={{
          marginBottom: 20,
          position: "absolute",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button0 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button0: true });
              }
              setPinCode(pinCode + "1");
            }}
          >
            1
          </div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button1 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button1: true });
              }
              setPinCode(pinCode + "2");
            }}
          >
            2
          </div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button2 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button2: true });
              }
              setPinCode(pinCode + "3");
            }}
          >
            3
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: 11,
          }}
        >
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button3 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button3: true });
              }
              setPinCode(pinCode + "4");
            }}
          >
            4
          </div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button4 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button4: true });
              }
              setPinCode(pinCode + "5");
            }}
          >
            5
          </div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button5 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button5: true });
              }
              setPinCode(pinCode + "6");
            }}
          >
            6
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: 11,
          }}
        >
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button6 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button6: true });
              }
              setPinCode(pinCode + "7");
            }}
          >
            7
          </div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button7 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button7: true });
              }
              setPinCode(pinCode + "8");
            }}
          >
            8
          </div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button8 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button8: true });
              }
              setPinCode(pinCode + "9");
            }}
          >
            9
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: 11,
          }}
        >
          <div style={{ height: 88, width: 88 }}></div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button9 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button9: true });
              }
              setPinCode(pinCode + "0");
            }}
          >
            0
          </div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button10 ? CLICK_COLOR : "" }}
            onClick={() => {
              setButtonClick({ button10: true });
              if (count > 0) {
                setCount(count - 1);
              }
              const newCode = pinCode.slice(0, -1);
              setPinCode(newCode);
            }}
          >
            <FontAwesomeIcon icon={faDeleteLeft} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeProfilePinConfirm;

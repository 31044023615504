import {
  faArrowLeft,
  faPlus,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { RiErrorWarningFill } from "react-icons/ri";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { GET_CARDS, GET_USER } from "../graphql/query";
import {
  BACKGROUND_COLOR_APP,
  COLOR_APP,
  POPUP_COLOR,
  USER_ID,
} from "../../constants";
import { useTranslation } from "react-i18next";
import { HEADER_HEIGHT } from "../../constants";
import CreditCardDefault from "../../components/CreditCardDefault";
import HomeBottomNav from "../../components/HomeBottomNav";
import HomeLoading from "../../components/HomeLoading";
import CustomAppBar from "../../components/CustomAppBar";

function HomeAccount() {
  const history = useNavigate();
  const userId = localStorage.getItem(USER_ID);
  const [cardData, setCardData] = useState();
  const [userData, setUserData] = useState();
  const [totalCard, setTotalCard] = useState();
  const [showPin, setShowPin] = useState();
  const [showLimitCard, setShowLimitCard] = useState(false);
  const [showNotDeleteCard, setShowNotDeleteCard] = useState(false);
  const { t, i18n } = useTranslation();

  const [canScroll, setCanScroll] = useState(false);
  const { innerWidth: width, innerHeight: height } = window;
  const ref = useRef(null);

  const _goBackAddCreditCard = () => {
    history(`/home-add-creditcard`);
  };

  const [getUser, { data: userQueryData, loading: userDataLoading }] = useLazyQuery(GET_USER, {
    fetchPolicy: "network-only",
  });

  const [
    getCard,
    { data: cardQueryData, loading: loading, error: error },
  ] = useLazyQuery(GET_CARDS);

  useEffect(() => {
    getCard({
      variables: {
        where: {
          createdBy: userId,
        },
      },
    });

    getUser({
      variables: {
        where: {
          id: userId,
        },
      },
    });

    // scrollable?
    if (ref.current.clientHeight > height) setCanScroll(true);
    else setCanScroll(false);
  }, []);

  useEffect(() => {
    if (cardQueryData) {
      setCardData(cardQueryData.cards.data);
      setTotalCard(cardQueryData.cards.total);
    }
  }, [cardQueryData]);

  useEffect(() => {
    if (userQueryData) {
      setUserData(userQueryData.user);
    }
  }, [userQueryData]);

  if (userDataLoading || loading) {
    return <HomeLoading />;
  }

  return (
    <div
      style={
        canScroll
          ? {
            position: "fixed",
            overflowY: "scroll",
            width: "100%",
            backgroundColor: BACKGROUND_COLOR_APP,
          }
          : { backgroundColor: BACKGROUND_COLOR_APP }
      }
      ref={ref}
      className="wrapper"
    >
      {/* Nav bar*/}
      <CustomAppBar gotoOtherPage={_goBackAddCreditCard} title={"LOCA PAY"} />
      {/* Body */}
      <div
        style={
          canScroll
            ? { position: "fixed", overflowY: "scroll", width: "100%" }
            : {}
        }
        ref={ref}
      >
        {/* Card  */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: 26,
            marginLeft: 26,
            marginTop: 20,
            border: "none",
          }}
        >
          <div>
            <div
              style={{
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              {/* All Cards */}
              {t("my-card")}
            </div>
            <div style={{ color: "#999999", fontSize: 16 }}>
              {t("set-default-card")}
            </div>
          </div>
          <div>
            <FontAwesomeIcon
              icon={faPlusCircle}
              fontSize={30}
              color={COLOR_APP}
              onClick={() => {
                totalCard >= 3 ? setShowLimitCard(true) : (userData.pinCode ? history("/home-add-new-account") : setShowPin(true))
              }}
            />
          </div>
        </div>

        {/* {totalCard >= 1 ? <CreditCardInfo data={cardData} isClickAble /> : <div/>} */}
        {totalCard >= 1 ? <CreditCardDefault data={cardData} setShowNotDeleteCard={setShowNotDeleteCard} /> : <div />}

        {/* Add card        */}
        {/* <div style={{ marginTop: 150 }}>
          <center>
            {t("card-please-select")}
          </center>
        </div>
         <center style={{ marginTop: 50 }}>
          <div
            style={{
              width: 337,
              height: 120,
              backgroundColor: "#F9F9F9",
              borderRadius: 6,
            }}
            onClick={() => history(`/home-add-new-account`)}
          >
            <FontAwesomeIcon
              style={{
                color: "white",
                marginTop: 6.75,
                backgroundColor: "#00B0FF",
                width: 30,
                height: 30,
                borderRadius: 15,
                marginTop: 31,
              }}
              icon={faPlus}
            />
            <div
              style={{
                fontSize: 12,
                color: "#00ADFF",
                fontWeight: "bold",
              }}
            >
              {t("card-add-new")}
            </div>
          </div>
        </center> */}
      </div>

      <Modal show={showPin} size="sm" onHide={() => setShowPin(false)} centered>
        <Modal.Body style={{ textAlign: "center" }}>
          <RiErrorWarningFill style={{ fontSize: 60, color: POPUP_COLOR }} />
          <div style={{ marginBottom: 16 }}>{t("show-pin")}</div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                color: POPUP_COLOR,
                fontWeight: "bold",
                width: "30%",
                paddingTop: 6,
                paddingBottom: 6,
              }}
              onClick={() => history(`/home-profile-pin`)}
            >
              {t("popup-set-pin")}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showLimitCard} size="sm" onHide={() => setShowLimitCard(false)} centered>
        <Modal.Body style={{ textAlign: "center" }}>
          <RiErrorWarningFill style={{ fontSize: 60, color: POPUP_COLOR }} />
          <div style={{ marginBottom: 16 }}>{t("popup-limit-card")}</div>
        </Modal.Body>
      </Modal>

      <Modal show={showNotDeleteCard} size="sm" onHide={() => setShowNotDeleteCard(false)} centered>
        <Modal.Body style={{ textAlign: "center" }}>
          <RiErrorWarningFill style={{ fontSize: 60, color: POPUP_COLOR }} />
          <div style={{ marginBottom: 16 }}>{t("popup-not-delete-card")}</div>
        </Modal.Body>
      </Modal>

      <div style={{ height: 80 }} />
      <HomeBottomNav currentIndex={3} />
    </div>
  );
}

export default HomeAccount;

export const COLOR_APP = "#00ADFF";
export const COLOR_DANGER = "#EB3232";
export const COLOR_PRIMARY = "#EFF2F5";
export const COLOR_SECONDARY = "#697D95";
export const COLOR_LINE = "#DFE2E9";
export const COLOR_PIN = "#E6E6E6";
export const POPUP_COLOR = "#ECD200";
export const CLICK_COLOR = "#d1b7b7";
export const MASTERCARD_COLOR = "#000D26";
export const VISA_CARD = "#1A1F5F";
export const AMERICAN_EXPRESS_COLOR = "#112A4A";
export const JCB_COLOR = "#2C3B47";
export const UNIONPAY_COLOR = "#1A6E90";
export const DISCOVER_DINER_COLOR = "#F78222";
export const BACKGROUND_COLOR_APP = "#F8F8F8"


export const USER_ID = "USER_ID";
export const SID = "SID";
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const TOKEN = "TOKEN";
export const DRIVER_ID = "driverId";
export const DRIVERID_CREATEDATE = "driverIdCreateDate";
export const DRIVERID_EXPIREDATE = "driverIdExpireDate";
export const BACKDRIVER_IDIMAGE = "backDriverIdImage";
export const BANK_NAME = "bankName";
export const ACCOUNT_NAME = "accountName";
export const ACCOUNT_ID = "accountId";
export const CARREGISTRATION_ID = "carRegistrationId";
export const IDCARD_ISSUEDATE = "idCardIssueDate";
export const IDCARD_EXPIREDATE = "idcardExpireDate";
export const LICENSE_PLATE = "licensePlate";
export const LICENSE_PROVINCE = "licenseProvince";
export const LICENSE_FIRST = "licenseFirst";
export const TANKNUM = "tankNum";
export const CARTYPE = "carType";
export const CARBRAND = "carBrand";
export const CARGENERATION = "carGeneration";
export const CAR_COLOR = "carColor";
export const FRONTCARD_REGISTRATIONIMAGE = "frontCardRegistrationImage";
export const FRONTCAR_IMAGE = "fronCarImage";
export const BACKCAR_IMAGE = "backCarImage";
export const LEFTCAR_IMAGE = "leftCarImage";
export const RIGHTCAR_IMAGE = "rightCarImage";
export const INSIDECAR_IMAGE = "insideCarImage";
// export const LOCA_STRIPE_PK = pk_test_chstlusmBdwmnNlRPTxcrrlG
export const LOCA_STRIPE_PK = "pk_live_zDkoi97wO64j92VVBvGZCJNd";
export const LAILAO_STRIPE_PK = "pk_test_yH6Ea9iIUgLVYLlcVNKNRExV001UHTpJBX";
export const DEV_SERVER_API = "https://3rtz0ogul6.execute-api.ap-southeast-1.amazonaws.com";
// export const DEV_SERVER_API = "http://192.168.43.228:7070";
export const PROD_SERVER_API = "https://locapay-lailao-api.lailaolab.com";
export const IMAGE_SERVER_API =
  "https://loca-lailao-digi-api.lailaolab.com:9000"; // prod digital ocean
//export const IMAGE_SERVER_API = "http://localhost:7071"; // local
// export const DEV_FONT_API = "http://192.168.43.228:3000";
// export const DEV_FONT_API = "https://d31r6cu9o4fazj.cloudfront.net"; //DEV
export const DEV_FONT_API = " https://app.locapay.la";  //PROD

export const MAX_FILE_SIZE = "20MB";
export const DOB_DATE = "dobDate";
export const GENDER = "gender";
export const PERSONALID = "personalId";
export const CREATEDATE = "createDate";
export const ENDDATE = "endDate";
export const FACE_IMAGE = "FACE_IMAGE";
export const VIDEO_BLOBURL = "VIDEO_BLOBURL";
export const IDCARD_IMAGE = "IDCARD_IMAGE";
export const USER_LANG = "userLang";
export const CARD_ID = "CARD_ID";
export const NEW_USER_DATA = "NEW_USER_DATA";
export const PROFILE_IMAGE = "PROFILE_IMAGE";
export const HEADER_HEIGHT = 50;
export const PHONE = "PHONE";
export const COUNTRY_CODE = "COUNTRY_CODE";
export const EMAIL = "EMAIL";
export const FIRST_NAME = "FIRST_NAME";
export const LAST_NAME = "LAST_NAME";

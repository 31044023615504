import { faArrowLeft, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Button, Modal } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { BACKGROUND_COLOR_APP, COLOR_APP, USER_ID } from "../../constants";
import { UPDATE_USER } from "../graphql/mutation";
import { useTranslation } from "react-i18next";
import "./Home.css";
import CustomAppBar from "../../components/CustomAppBar";

function HomeProfileHint() {
  //variables
  const history = useNavigate();
  const location = useLocation();
  const pinCode = new URLSearchParams(location.search).get("pinCode");

  const userId = localStorage.getItem(USER_ID);
  const [show, setShow] = useState(false);
  const [hintText, setHintText] = useState();
  const [showFill, setShowFill] = useState(false);
  const { t, i18n } = useTranslation();
  const [canScroll, setCanScroll] = useState(false);
  const { innerWidth: width, innerHeight: height } = window;
  const ref = useRef(null);

  const [updateUSER] = useMutation(UPDATE_USER);

  useEffect(() => {
    if (ref.current.clientHeight < height) setCanScroll(true);
    else setCanScroll(false);
  }, []);

  const _savePIN = async () => {
    if (hintText) {
      try {
        const _res = await updateUSER({
          variables: {
            data: {
              pinCode,
              pinHint: hintText,
            },
            where: {
              id: userId,
            },
          },
        });

        if (_res.data.updateUser) {
          setShow(true);
          setTimeout(() => {
            setShow(false);
            _gotoHomeProfile();
          }, 3000);
        }
      } catch (error) {}
    } else {
      setShowFill(true);
    }
  };

  //Routes
  const _goBackPinConfirm = () => {
    history(`/home-profile-pin-confirm`);
  };

  const _gotoHomeProfile = () => {
    history(`/home-profile?userId=${userId}`);
  };
  const _goBacktoHome = () => {
    history(-1);
  };

  return (
    <div
    className="wrapper" 
      style={
        canScroll
          ? { position: "fixed", overflowY: "scroll", width: "100%", backgroundColor: BACKGROUND_COLOR_APP }
          : {backgroundColor: BACKGROUND_COLOR_APP}
      }
      ref={ref}
    >
          <CustomAppBar
          gotoOtherPage={_goBacktoHome}
          title={"LOCA PAY"}
        />
      {/* <div className="home-header">
        <FontAwesomeIcon
          style={{
            marginLeft: 10,
            fontSize: "18",
            fontWeight: "bold",
          }}
          icon={faArrowLeft}
          onClick={() => _goBackPinConfirm()}
        />
      </div> */}
      <div style={{ height: 80 }} />
      <div style={{ fontSize: 17, fontWeight: "bold", textAlign: "center" }}>
        {/* Please input hint for your PIN */}
        {t("setting-input")}
      </div>
      <div style={{ height: 26 }} />

      <div style={{ paddingLeft: 26, paddingRight: 26 }}>
        <Form.Control
          as="textarea"
          rows={3}
          style={{ backgroundColor: "#EFF2F5" }}
          value={hintText}
          onChange={(e) => setHintText(e.target.value)}
        />
        {showFill ? (
          <div style={{ color: "red" }}>
            {/* Please input your hint */}
            {t("setting-input-pin")}
          </div>
        ) : (
          <div />
        )}
      </div>

      <div
        style={{
          position: "fixed",
          bottom: 15,
          width: "100%",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Button
          style={{
            backgroundColor: COLOR_APP,
            borderColor: COLOR_APP,
            height: 50,
            width: "90%",
          }}
          onClick={() => _savePIN()}
        >
          {/* Save */}
          {t("setting-save")}
        </Button>
      </div>

      <Modal
        backdrop="static"
        show={show}
        size="sm"
        onHide={() => setShow(false)}
        centered
      >
        <Modal.Body style={{ textAlign: "center" }}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            style={{
              width: 80,
              height: 80,
              color: "#0DCD5A",
            }}
          />
          <div>
            {/* PIN setting successful! */}
            {t("setting-success")}!
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default HomeProfileHint;

import React, { useEffect, useState } from 'react'
import CustomAppBar from '../../components/CustomAppBar'
import { useLocation, useNavigate } from 'react-router-dom';
import { BACKGROUND_COLOR_APP, COLOR_APP } from '../../constants';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import * as _ from "lodash";
import { useLazyQuery } from '@apollo/client';
import { GET_FEE } from './graphql/query';
import { numberWithCommas } from '../../constants/ShowFormat';

function HomeQrPaymentDetail() {
    const history = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const description = localStorage.getItem("DESCRIPTION")

    let cardId = new URLSearchParams(location.search).get("cardId");
    let cardName = new URLSearchParams(location.search).get("cardName");
    let sum = new URLSearchParams(location.search).get("sum");
    let expireMonth = new URLSearchParams(location.search).get("expireMonth");
    let expireYear = new URLSearchParams(location.search).get("expireYear");
    let price = new URLSearchParams(location.search).get("price");
    let code = new URLSearchParams(location.search).get("code");
    let id = new URLSearchParams(location.search).get("id");
    let brand = new URLSearchParams(location.search).get("brand");
    let bankLogoUrl = new URLSearchParams(location.search).get("bankLogoUrl");
    let bankName = new URLSearchParams(location.search).get("bankName");
    let billNumber = new URLSearchParams(location.search).get("billNumber");
    let _feeAmount = new URLSearchParams(location.search).get("feeAmount");
    let lat = new URLSearchParams(location.search).get("lat");
    let lng = new URLSearchParams(location.search).get("lng");

    // mmoney

    let mmoneyToken = new URLSearchParams(location.search).get("mmoneyToken");
    let mmoneyKey = new URLSearchParams(location.search).get("mmoneyKey");
    let shopCategory = new URLSearchParams(location.search).get("shopCategory");
    let responseMessage = new URLSearchParams(location.search).get("responseMessage");
    let responseCode = new URLSearchParams(location.search).get("responseCode");
    let merchantMobile = new URLSearchParams(location.search).get("merchantMobile");
    let bankTansactionId = new URLSearchParams(location.search).get("bankTansactionId");

    if (_.isEmpty(billNumber)) billNumber = ""

    // bank info qr
    let jdbTransactionId = new URLSearchParams(location.search).get(
        "jdbTransactionId"
    );
    let jdbAuthToken = new URLSearchParams(location.search).get("jdbAuthToken");
    let destBankCode = new URLSearchParams(location.search).get("destBankCode");
    let destBankId = new URLSearchParams(location.search).get("destBankId");
    let destCurrency = new URLSearchParams(location.search).get("destCurrency");

    // get destMerchantId from location state to prevent urlencoding
    let destMerchantId = location.state.destMerchantId

    let destMerchantName = new URLSearchParams(location.search).get(
        "destMerchantName"
    );
    let exReferenceNo = new URLSearchParams(location.search).get("exReferenceNo");
    let paymentTypeId = new URLSearchParams(location.search).get("paymentTypeId");
    let qrType = new URLSearchParams(location.search).get("qrType");
    let remark = new URLSearchParams(location.search).get("remark");

    const [feeData, setFeeData] = useState();
    const [realPrice, setRealPrice] = useState();
    const [totalFeesString, setTotalFeesString] = useState();

    const [getFee, { data: feeQueryData }] = useLazyQuery(GET_FEE);


    useEffect(() => {
        getFee();
        // console.log('dataa ---------->', destBankCode);
    }, [])

    useEffect(() => {
        if (feeQueryData) {
            const feesData = feeQueryData.fee.data[0];
            setFeeData(feesData);

            const { feeAmount, charge } = feesData;
            const totalFees = calAllFees({ price: realPrice, feeAmount, charge });

            setTotalFeesString(numberWithCommas(parseFloat(totalFees).toFixed(2)));
        }
    }, [feeQueryData]);

    useEffect(() => {
        // let priceStr = price.split(" ");
        // var priceValue = parseFloat(priceStr[1].replace(/,/g, ""));
        var priceValue = parseFloat(price.replace(/,/g, ""));
        setRealPrice(priceValue);
    }, [price]);

    const calAllFees = ({ price, feeAmount, charge }) => {
        return (parseInt(price) || 0) * parseFloat(feeAmount) + parseInt(charge);
    };



    const _goBackConfirmtoPay = () => {
        history(-1);
    };

    const _gotoHomQrPaymentPin = () => {
        // console.log("feeAmount: ", _feeAmount)
        history(
            `/homeqr-payment-pin?id=${id}&cardId=${cardId}&cardName=${cardName}&expireMonth=${expireMonth}&expireYear=${expireYear}&price=${price}&code=${code}&brand=${brand}&sum=${sum}` +
            `&jdbTransactionId=${jdbTransactionId}&jdbAuthToken=${jdbAuthToken}&destBankCode=${destBankCode}&destBankId=${destBankId}&destCurrency=${destCurrency}&destMerchantName=${destMerchantName}` +
            `&exReferenceNo=${exReferenceNo}&paymentTypeId=${paymentTypeId}&qrType=${qrType}&remark=${remark}&bankName=${bankName}&bankLogoUrl=${bankLogoUrl}&billNumber=${billNumber}&feeAmount=${_feeAmount}&lat=${lat}&lng=${lng}`
            , { state: { destMerchantId: destMerchantId } }
        );
    }

    const _gotoHomeQrPaymentPin = () => {
        history(
            `/homeqr-payment-pin?id=${id}&cardId=${cardId}&cardName=${cardName}&expireMonth=${expireMonth}&expireYear=${expireYear}&price=${price}&code=${code}&brand=${brand}` +
            `&mmoneyKey=${mmoneyKey}&mmoneyToken=${mmoneyToken}&merchantMobile=${merchantMobile}&destMerchantName=${destMerchantName}&bankTansactionId=${bankTansactionId}` +
            `&responseCode=${responseCode}&responseMessage=${responseMessage}&qrType=${qrType}&shopCategory=${shopCategory}&bankName=${bankName}&bankLogoUrl=${bankLogoUrl}&lat=${lat}&lng=${lng}&feeAmount=${_feeAmount}`,
            { state: { destMerchantId: destMerchantId } }
        );
    }

    return (
        <div className="wrapper" style={{ backgroundColor: BACKGROUND_COLOR_APP }}>
            <CustomAppBar
                gotoOtherPage={_goBackConfirmtoPay}
                title={"LOCA PAY"}
            />

            <div style={{ padding: 20 }}>
                {/* Payment  */}
                <div
                    style={{
                        fontSize: 17,
                        fontWeight: "bold",
                        marginBottom: 10,
                    }}
                >
                    {/* Payment */}
                    {t("payment")}
                </div>

                <div
                    style={{
                        fontSize: 16,
                        marginTop: 20,
                        color: "#999999"
                    }}
                >
                    {/* Amount */}
                    {t("amount")}
                </div>
                <div style={{ color: "#EB001B", fontSize: 25, fontWeight: "bold" }}>LAK-{price}</div>
                <div style={{ height: 15 }} />
                <div
                    style={{
                        fontSize: 16,
                        color: "#999999"
                    }}
                >Fee</div>
                <div style={{ color: "#EB001B", fontSize: 15 }}>LAK-{totalFeesString}</div>

                <div
                    style={{
                        fontSize: 16,
                        color: "#999999",
                        marginBottom: 11,
                        marginTop: 6
                    }}
                >
                    {/* To */}
                    {t("payment-to")}
                </div>

                <div
                    style={{
                        display: "flex",
                        // height: 70,
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: 6,
                        padding: 8,
                        border: "1px solid #b6b6b6"
                    }}
                >
                    <div style={{ fontSize: 16, fontWeight: "bold" }}>{destMerchantName}</div>
                    <div>
                        <img
                            style={{
                                width: 50,
                                height: 50,
                                borderRadius: 40,
                              }}
                            src={bankName == 'TELBIZ' ? (
                                qrType == "LTC" ? "/assets/images/ltc_logo.jpeg" :
                                    qrType == "UNITEL" ? "/assets/images/unitel_logo.jpeg" :
                                        qrType == "ETL" ? "/assets/images/etl_logo.png" :
                                            "/assets/images/box-loading.jpg"
                            ) : bankLogoUrl ? bankLogoUrl :
                                "/assets/images/box-loading.jpg"
                            }
                        />
                    </div>
                </div>

                <div
                    style={{
                        fontSize: 16,
                        marginTop: 11,
                        color: "#999999"
                    }}
                >
                    {/* From */}
                    {t("payment-from")}
                </div>

                <div
                    style={{
                        display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: 6,
                    // marginTop: 5,
                    padding: 10,
                    border: "1px solid #b6b6b6"
                    }}
                >
                    <div>
                        <div style={{ fontSize: 16, fontWeight: "bold" }}>{cardName ? cardName : ""}</div>
                        <div style={{ fontSize: 14, fontWeight: "bold" }}>
                            ************{cardId ? cardId : ""}
                        </div>
                        <div
                            style={{
                                fontSize: 12,
                                fontWeight: "bold",
                                color: "#898989",
                            }}
                        >
                            {t("card-expire")} {expireMonth}/
                            {expireYear}
                        </div>
                    </div>
                    <img
                        style={{
                            height: brand == "discover" || brand == "diners" ? 20 :
                                brand === "mastercard" ? 30 :
                                    brand === "visa" ? 20 :
                                        50,
                        }}
                        src={
                            brand == "visa"
                                ? "/assets/images/visa_icon.png"
                                : brand == "jcb"
                                    ? "/assets/images/jcb.png"
                                    : brand == "discover" || brand == "diners"
                                        ? "/assets/images/discover_diner.png"
                                        : brand == "unionpay"
                                            ? "/assets/images/union_pay.png"
                                            : brand == "amex"
                                                ? "/assets/images/amex.png"
                                                : "/assets/images/mastercards.png"
                        }
                    />
                </div>



                <div
                    style={{
                        fontSize: 16,
                        marginTop: 11,
                        color: "#999999",
                        marginBottom: 13
                    }}
                >
                    Description
                </div>
                <div>{description}</div>

                <div style={{ height: 20 }} />

                <div style={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: "100%"
                }}>
                    <Button
                        style={{
                            height: 50,
                            backgroundColor: COLOR_APP,
                            textAlign: "center",
                            border: "none",
                            width: "100%",
                            fontWeight: 700,
                            fontSize: 16
                        }}
                        // onClick={() => {_gotoHomQrPaymentPin()  }}
                        onClick={() => { if (mmoneyToken) { _gotoHomeQrPaymentPin() } else { _gotoHomQrPaymentPin() } }}
                    >
                        {t("payment-accept")}
                    </Button>
                </div>
            </div>


        </div>
    )
}

export default HomeQrPaymentDetail

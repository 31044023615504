import { faPlusCircle, faRotate } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'

import "./CustomCard.css"
import { useTranslation } from 'react-i18next';

function CustomCard(
    {
        cardData,
        scanPopupData,
        _gotoScanPage,
        setShowAddCard,
        setShowWarningScan,
        defaultCardData,
        userData,
        _gotoHomeAccount,
        setShowPin
    }) {

    const { t, i18n } = useTranslation();
    const [platForm, setPlatForm] = useState()

    useEffect(() => {
        detectPlatform()
    }, [])

    const detectPlatform = () => {
        const userAgent = window.navigator.userAgent;
        if (userAgent.match(/Android/i)) {
            // console.log("Android")
            setPlatForm("Android")
        } else {
            // console.log("IOS")
            setPlatForm("IOS")

        }
    }

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: platForm === "Android" ? 10:20,
            paddingRight: platForm === "Android" ? 10:20,
            position: "relative",
            marginBottom: 60
        }}>
            {defaultCardData ?
                <div className={defaultCardData.brand === "mastercard" ? 'card-box-mastercard'
                    : defaultCardData.brand === 'visa' ? 'card-box-visa'
                        : defaultCardData.brand === 'jcb' ? 'card-box-jcb'
                            : defaultCardData.brand === "amex" ? 'card-box-amex'
                                : defaultCardData.brand === "unionpay" ? 'card-box-unionpay'
                                    : defaultCardData.brand === "discover" || defaultCardData.brand === "diners" ? 'card-box-discover-diners'
                                        : 'card-box'
                }>
                    <div style={{ color: "white", fontSize: 17, fontWeight: "bold" }}>
                        <div>{defaultCardData.cardName}</div>
                        <div>{`************${defaultCardData.cardId}`}</div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        color: "white",
                    }}>
                        <div style={{ display: "flex", alignItems: "end" }}>
                            <div style={{
                                fontSize: 10,
                                fontWeight: "bold",
                                backgroundColor: "rgba(255, 255, 255, 0.2)",
                                padding: "6px 12px",
                                borderRadius: 6,
                            }}
                                onClick={() => {
                                    userData.pinCode ? _gotoHomeAccount() : setShowPin(true);
                                }}
                            >
                                <FontAwesomeIcon icon={faRotate} fontSize={13} /> {t("switch")}
                            </div>
                        </div>
                        <div style={{ textAlign: "end" }}>
                            <img
                                src={defaultCardData.brand === "mastercard" ?
                                    "/assets/images/mastercards.png" :
                                    defaultCardData.brand === "visa" ?
                                        "/assets/images/visa.png" :
                                        defaultCardData.brand === "jcb" ? "/assets/images/jcb.png"
                                            : defaultCardData.brand === "amex" ? "/assets/images/amex.png"
                                                : defaultCardData.brand === "unionpay" ? "/assets/images/union_pay.png"
                                                    : defaultCardData.brand === "discover" || defaultCardData.brand === "diners" ? "/assets/images/discover_diner.png"
                                                        : ""
                                }
                                alt="" style={{ width: defaultCardData.brand === "discover" || defaultCardData.brand === "diners" ? 80 : 40 }} />
                            <div style={{ fontSize: platForm === "Android" ? 10:12, marginTop: 9 }}>{`${t("expires")}: ${defaultCardData.expireMonth}/${defaultCardData.expireYear}`}</div>
                        </div>
                    </div>
                </div>
                : <div className='card-box'>
                    <div style={{ color: "white", fontSize: 17, fontWeight: "bold" }}>
                        {t("no-card")}
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        color: "white",
                    }}>
                        <div style={{
                            fontSize: 10,
                            fontWeight: "bold",
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                            padding: 6,
                            borderRadius: 6,
                        }}
                            onClick={() => {
                                userData.pinCode ? _gotoHomeAccount() : setShowPin(true);
                            }}
                        ><FontAwesomeIcon icon={faPlusCircle} /> {t("home-add-card")}</div>
                        <div style={{ fontSize: 12 }}>{t("expires")}XX/XX</div>
                    </div>
                </div>}
            <div className='scan-box'>
                <div
                    // className="box-scan"
                    onClick={() => {
                        if (scanPopupData && scanPopupData.isOpen) setShowWarningScan(true)
                        else if (cardData && cardData.cards && cardData.cards.total > 0)
                            _gotoScanPage();
                        else setShowAddCard(true);
                    }}
                    // onClick={()=> setShowWarningPopup(true)}
                    style={{
                        width: 110,
                        height: 110,
                        background: 'linear-gradient(135deg, #C8F2FF 0%, #8ACFFB 100%)',
                        marginTop: 9,
                        borderRadius: 12,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <div style={{ textAlign: "center", fontSize: 13, fontWeight: "bold", color: "#006EA7" }}>
                        <img src='assets/images/icon_scan.png' width={67} />
                        <div style={{ marginTop: 8 }}>{t("home-scan-to-pay")}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomCard

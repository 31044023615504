import { faArrowLeft, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BACKGROUND_COLOR_APP, COLOR_APP, DEV_FONT_API, USER_ID } from "../../constants";
import { useLazyQuery } from "@apollo/client";
import { GET_USER } from "./graphql/query";
import { Watermark } from "@hirohe/react-watermark";
import moment from "moment";
import QRCode from "react-qr-code";
import "./Home.css";
import { useTranslation } from "react-i18next";
import { numberWithCommas } from "../../constants/ShowFormat";
import { HEADER_HEIGHT } from "../../constants";
import CustomAppBar from "../../components/CustomAppBar";

function HomeQrPaymentConfirmToPaySuccess() {
  const history = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  let price = new URLSearchParams(location.search).get("price");
  let createdAt = new URLSearchParams(location.search).get("createdAt");
  let cardId = new URLSearchParams(location.search).get("cardId");
  let expireMonth = new URLSearchParams(location.search).get("expireMonth");
  let expireYear = new URLSearchParams(location.search).get("expireYear");
  let brand = new URLSearchParams(location.search).get("brand");
  let vat = new URLSearchParams(location.search).get("vat");
  let bankLogoUrl = new URLSearchParams(location.search).get("bankLogoUrl");
  let cardName = new URLSearchParams(location.search).get("cardName");
  let id = new URLSearchParams(location.search).get("id");
  // let price = 3500000
  // let createdAt = Date.now
  // let cardId = '4242424242424242'
  // let expireMonth = '06'
  // let expireYear = '24'
  // let brand = 'mastercard'
  // let vat = 14.19
  // let bankLogoUrl = 'assets/images/BcelOne.png'



  let destMerchantName = new URLSearchParams(location.search).get(
    "destMerchantName"
  );
  // let destMerchantName = 'SHOP-112233'
  let refNo = new URLSearchParams(location.search).get("refNo");
  // let refNo = 'LOCA123fer33'
  const description = localStorage.getItem("DESCRIPTION");
  // let description = 'Pay for shirt'

  const userId = localStorage.getItem(USER_ID);

  const [text, setText] = useState(
    `$refNo:${refNo}-${price}-${description}-at:${moment(createdAt).format(
      "DD-MM-YYYY"
    )}`
  );

  const [getUser, { data: userData }] = useLazyQuery(
    GET_USER
  );

  useEffect(() => {
    getUser({
      variables: {
        where: {
          id: userId,
        },
      },
    });

    creditCard(cardId);
  }, []);

  const creditCard = (card) => {
    let starsString = card.slice(0, -4);
    let showNumbers = card.slice(-4);
    let regMe = /[0-9]/g;
    let allStars = starsString.replace(regMe, "*");

    return allStars + showNumbers;
  };

  const options = {
    chunkWidth: 180,
    chunkHeight: 10,
    textAlign: "left",
    textBaseline: "bottom",
    globalAlpha: 0.17,
    font: "8px Times New Roman",
    rotateAngle: -0.2,
  };

  const gotoHome = () => {
    localStorage.removeItem("DESCRIPTION");
    history(`/home-add-creditcard`);
  };

  const _goBack = () => {
    localStorage.removeItem("DESCRIPTION");
    history(`/home-add-creditcard`);
  };

  return (
    <div className="wrapper" style={{ backgroundColor: BACKGROUND_COLOR_APP }}>
      {/* <div className="home-header">
        <FontAwesomeIcon
          style={{
            marginLeft: 10,
            fontSize: "18",
            fontWeight: "bold",
            color: "#252A31",
          }}
          icon={faArrowLeft}
          onClick={() => _goBack()}
        />
      </div> */}

      <CustomAppBar
        gotoOtherPage={_goBack}
        title={"LOCA PAY"}
      />

      {/* <div style={{ height: HEADER_HEIGHT }} /> */}
      {/* Body */}
      <div>
        <Watermark
          text={text}
          rotate={-20}
          textSize={12}
          fontFamily={"Times New Roman"}
          multiline={true}
          opacity={0.4}
        >
          <div style={{ display: "flex", flexDirection: "column", minHeight: "80vh", justifyContent: "space-between" }}>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 10,
                }}
              >
                <div>
                  <div style={{ fontSize: 17 }}>
                    {/* Payment Successfully */}
                    {t("payment-success")}
                  </div>
                  <div style={{ marginTop: 8, color: "#4B4B4B" }}>
                    {moment(createdAt).format("DD/MM/YYYY HH:mm")}
                  </div>

                  <div style={{ display: "flex", flexDirection: "row", marginTop: 4 }}>
                    <div
                      style={{
                        fontSize: 16,
                        marginTop: 2,
                        color: "#999999",
                        marginBottom: 7
                      }}
                    >
                      {/* Amount */}
                      {t("amount")}
                    </div>
                    <div style={{ width: 10 }} />
                    <div style={{ color: "#EB001B", fontSize: 20, fontWeight: "bold" }}>
                      LAK {numberWithCommas(price)}
                    </div>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row", marginTop: 4 }}>
                    <div
                      style={{
                        fontSize: 16,
                        color: "#999999",
                        marginBottom: 5
                      }}
                    >
                      {/* Amount */}
                      {t("payment-fee")}
                    </div>
                    <div style={{ width: 10 }} />
                    <div style={{ color: "#EB001B", fontSize: 16 }}>
                      LAK {numberWithCommas(parseFloat(vat).toFixed(2))}
                    </div>
                  </div>
                </div>
                <div style={{ paddingRight: 10 }}>
                  {" "}
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="#139A18"
                    fontSize={70}
                  />
                </div>
              </div>

              {/* <div
                    style={{
                      marginTop: 22,
                      marginBottom: 10,
                      marginLeft: 24,
                      fontSize: 12,
                      color: "#898989",
                      fontWeight: "bold",
                    }}
                  >
                    {moment(createdAt).format("DD-MM-YYYY H:mm")}
                  </div> */}

              <div style={{ padding: 20, paddingTop: 4 }}>

                <div
                  style={{
                    fontSize: 16,
                    color: "#999999",
                    // marginBottom: 11
                  }}
                >
                  {/* To */}
                  {t("payment-to")}
                </div>

                <div
                  style={{
                    display: "flex",
                    // height: 70,
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: 6,
                    padding: 8,
                    border: "1px solid #b6b6b6"
                  }}
                >
                  <div style={{ fontSize: 16, fontWeight: "bold" }}>{destMerchantName}</div>
                  <div>
                    <img
                      style={{
                        width: 50,
                        height: 50,
                        borderRadius: 40,
                      }}
                      src={description == "LTC" ? "/assets/images/ltc_logo.jpeg" :
                        description == "UNITEL" ? "/assets/images/unitel_logo.jpeg" :
                          description == "ETL" ? "/assets/images/etl_logo.png" :
                            bankLogoUrl ? bankLogoUrl :
                              "/assets/images/box-loading.jpg"}
                    />
                  </div>
                </div>

                <div
                  style={{
                    fontSize: 16,
                    marginTop: 11,
                    color: "#999999"
                  }}
                >
                  {/* From */}
                  {t("payment-from")}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: 6,
                    // marginTop: 5,
                    padding: 14,
                    border: "1px solid #b6b6b6"
                  }}
                >
                  <div>
                    <div style={{ fontSize: 16, fontWeight: "bold" }}>{cardName ? cardName : ""}</div>
                    <div style={{ fontSize: 14, fontWeight: "bold" }}>
                      ************{cardId ? cardId : ""}
                    </div>
                    <div
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        color: "#898989",
                      }}
                    >
                      {t("card-expire")} {expireMonth}/
                      {expireYear}
                    </div>
                  </div>
                  <img
                    style={{
                      height: brand == "discover" || brand == "diners" ? 20 :
                        brand === "mastercard" ? 30 :
                          brand === "visa" ? 20 :
                            50,
                    }}
                    src={
                      brand == "visa"
                        ? "/assets/images/visa_icon.png"
                        : brand == "jcb"
                          ? "/assets/images/jcb.png"
                          : brand == "discover" || brand == "diners"
                            ? "/assets/images/discover_diner.png"
                            : brand == "unionpay"
                              ? "/assets/images/union_pay.png"
                              : brand == "amex"
                                ? "/assets/images/amex.png"
                                : "/assets/images/mastercards.png"
                    }
                  />
                </div>




                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "start" }}>
                  <div>
                    <div
                      style={{
                        fontSize: 16,
                        marginTop: 11,
                        color: "#999999",
                      }}
                    >
                      {t("payment-bill")}
                    </div>
                    <div>{refNo}</div>

                    <div
                      style={{
                        fontSize: 16,
                        marginTop: 11,
                        color: "#999999",
                      }}
                    >
                      {t("payment-des")}
                    </div>
                    <div>{description}</div>
                  </div>

                  <div style={{ marginTop: 14 }}>
                    <div
                      className="qr-background"
                    >
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src="assets/images/logo-locapay.png" alt="" width={50} />
                      </div>
                      <div style={{ padding: 6, backgroundColor: "white", borderRadius: 3 }}>
                        <QRCode value={`${DEV_FONT_API}/home-comfirm-payment-success?id=${id}&price=${price}&createdAt=${createdAt}`} size={80} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
                      className="box-shadow-sm"
                      style={{
                        padding: 10,
                        marginLeft: 20,
                        marginRight: 20,
                        height: "50vh",
                        borderRadius: 6,
                        paddingBottom: 40,
                      }}
                    >
                      <div
                        style={{
                          color: "#898989",
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                      >
                        {t("payment-to")} :
                      </div>
                      <div
                        style={{
                          paddingLeft: 10,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <div style={{ fontSize: 14, fontWeight: "bold" }}>
                            {destMerchantName}
                          </div>
                        </div>
                        <img
                          style={{
                            width: 60,
                            height: 60,
                            borderRadius: 50,
                          }}
                          src={
                            bankLogoUrl != "null"
                              ? bankLogoUrl
                              : "/assets/images/box-loading.jpg"
                          }
                        />
                      </div> */}
              {/* ---MasterCard  */}
              {/* <div
                        style={{
                          color: "#898989",
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                      >
                        {t("payment-from")} :
                      </div>
                      <div
                        style={{
                          paddingLeft: 10,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          {userData ? (
                            <div style={{ fontSize: 14, fontWeight: "bold" }}>
                              {userData.user.firstName} {userData.user.lastName}
                            </div>
                          ) : (
                            <div />
                          )}
                          <div style={{ color: "#898989", fontSize: 12 }}>
                            **** **** **** {cardId}
                          </div>
                          <div
                            style={{
                              color: "#898989",
                              fontSize: 10,
                              fontWeight: "bold",
                            }}
                          >
                            {t("card-expire")} {expireMonth}/{expireYear}
                          </div>
                        </div>
                        <div>
                          {" "}
                          <img
                            style={{
                              height: brand == "discover" || brand == "diners" ? 25 :
                                brand == "jcb" || brand == "unionpay" ? 45 :
                                  60,
                            }}
                            src={
                              brand == "mastercard"
                                ? "/assets/images/MasterCard.png" :
                                brand == "jcb" ? "/assets/images/jcb.png" :
                                  brand == "discover" || brand == "diners" ? "/assets/images/discover_diner.png" :
                                    brand == "unionpay" ? "/assets/images/union_pay.png" :
                                      brand == "amex" ? "/assets/images/amex.png"
                                        : "/assets/images/VisaCard.png"
                            }
                            alt=""
                          />
                        </div>
                      </div> */}

              {/* <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", }}>
                        <div>
                          <div
                            style={{
                              marginTop: 10,
                              color: "#898989",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            {t("payment-bill")}
                          </div>
                          <div style={{ paddingLeft: 10 }}>{refNo}</div>

                          <div
                            style={{
                              marginTop: 10,
                              color: "#898989",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            {t("payment-des")}
                          </div>
                          <div style={{ paddingLeft: 10 }}>{description}</div>
                        </div>

                        <div >
                          <div
                            className="qr-background"
                          >
                            <div style={{ display: "flex", justifyContent: "center", marginBottom: 6 }}>
                              <img src="assets/images/logo-locapay.png" alt="" width={50} />
                            </div>
                            <div style={{ padding: 6, backgroundColor: "white", borderRadius: 3 }}>
                              <QRCode value={refNo} size={80} />
                            </div>
                          </div>
                        </div>
                      </div> 

                    </div>*/}
            </div>
            <div style={{
              textAlign: "center",
              // marginTop: 12,
              fontWeight: "bold",
              fontSize: 18,
              color: "#8C8C8C"
            }}>P O W E R E D <span style={{ marginRight: 6 }} />B Y
              <span style={{ color: COLOR_APP, marginLeft: 12 }}>L O C A
                <span style={{ marginLeft: 6 }} />P A Y</span>
            </div>


            {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "fixed",
                bottom: 20,
                width: "100%",
              }}
            >
              <Button
                style={{
                  height: 50,
                  display: "flex",
                  width: "90%",
                  borderRadius: "none",
                  backgroundColor: "#00ADFF",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                }}
                onClick={() => gotoHome()}
              >
                {t("success")}
              </Button>
            </div> */}

            <div style={{ height: 30 }} />
          </div>
          <div style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%"
          }}>
            <Button
              style={{
                height: 50,
                backgroundColor: COLOR_APP,
                textAlign: "center",
                border: "none",
                width: "100%",
                fontWeight: 700,
                fontSize: 16
              }}
              onClick={() => gotoHome()}
            >
              {t("success")}
            </Button>
          </div>
        </Watermark>
      </div>
    </div>
  );
}

export default HomeQrPaymentConfirmToPaySuccess;

import {
  faCheck,
  faCheckCircle,
  faCreditCard,
  faGear,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Carousel, Form, InputGroup, Modal, Button } from "react-bootstrap";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import * as _ from "lodash";

import { BACKGROUND_COLOR_APP, COLOR_APP, COLOR_PRIMARY, POPUP_COLOR, USER_ID } from "../../constants";
import { GET_USER } from "../graphql/query";
import {
  GET_BANNERS,
  GET_PAYMENTS,
  GET_CARDS,
  GET_POPUPS,
  GET_LIMIT_AMOUNT,
  GET_HOME_POPUP,
  GET_SCAN_POPUP,
} from "./graphql/query";
import { useTranslation } from "react-i18next";
import { MdOutlineQrCodeScanner } from "react-icons/md";
import HomeLoading from "../../components/HomeLoading";
import EmptyHistoryPayment from "../../components/EmptyHistoryPayment";
import { RiErrorWarningFill } from "react-icons/ri";
import moment from "moment";
import { numberWithCommas } from "../../constants/ShowFormat";
import { CREATE_UPPASS_FORM } from "./graphql/mutation";
import Loading from "../../components/Loading";
import { BsCheckCircleFill } from "react-icons/bs"
import CustomCard from "../../components/CustomCard";
import ServicesButton from "../../components/ServicesButton";
import HomeBottomNav from "../../components/HomeBottomNav";

function HomeAddCreditCard() {
  // variables
  const history = useNavigate();
  const userId = localStorage.getItem(USER_ID);
  const Language = localStorage.getItem("userLang")

  const { t, i18n } = useTranslation();

  //states
  const [userData, setUserData] = useState();
  const [bannerData, setBannerData] = useState();

  const [
    getUser,
    { data: userQueryData, loading: loading },
  ] = useLazyQuery(GET_USER, { fetchPolicy: 'network-only' });

  const [getPopUp, { data: popupQueryData }] = useLazyQuery(GET_POPUPS);

  const [getBanners, { data: bannerQueryData }] = useLazyQuery(GET_BANNERS);
  const [
    getPayments,
    { data: paymentData, loading: paymentLoading },
  ] = useLazyQuery(GET_PAYMENTS);
  const [getCards, { data: cardData, loading: cardLoading }] = useLazyQuery(GET_CARDS);

  const [getHomePopup, { data: homePopupQueryData }] = useLazyQuery(GET_HOME_POPUP);
  const [getScanPopup, { data: scanPopupQueryData }] = useLazyQuery(GET_SCAN_POPUP)

  const [getLimitAmount, { data: limitData }] = useLazyQuery(GET_LIMIT_AMOUNT);
  const [showAddCard, setShowAddCard] = useState(false);
  const [showPin, setShowPin] = useState();
  const [defaultCardData, setDefaultCardData] = useState();
  const [cardDataList, setCardDataList] = useState();
  const [showKyc, setShowKyc] = useState(false);
  const [checkKyc, setCheckKyc] = useState(false)
  const [paymentSelect, setPaymentSelect] = useState();
  const [isBanned, setIsBanned] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [createUpPassForm] = useMutation(CREATE_UPPASS_FORM);
  const [showWarningPopup, setShowWarningPopup] = useState(false)
  const [showWarningScan, setShowWarningScan] = useState(false)
  const [homePopupData, setHomePopupData] = useState()
  const [scanPopupData, setScanPopupData] = useState()

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    getUser({
      variables: {
        where: {
          id: userId,
        },
      },
    });

    getBanners();

    getPayments({
      variables: {
        where: {
          userId: userId,
          status: "SUCCESS"
        },
        orderBy: "createdAt_DESC",
        limit: 6,
      },
    });

    getCards({
      variables: {
        where: {
          createdBy: userId,
        },
      },
    });

    getPopUp();
    getLimitAmount();
    getHomePopup();
    getScanPopup();
  }, []);

  useEffect(() => {
    if (userQueryData) {
      setUserData(userQueryData.user);
      if (userQueryData.user.userStatus == "NOT_REGISTER") {
        setShowKyc(true);
      }

      if (userQueryData.user.userStatus == "VERIFIED") {
        setCheckKyc(true)
      } else {
        setCheckKyc(false)
      }
    }
  }, [userQueryData]);

  useEffect(() => {
    if (bannerQueryData) {
      const banner = bannerQueryData.banners.data;
      const filterData = banner.filter((el) => el.bannerStatus === true);
      setBannerData(filterData);
      // console.log('data ------------>',filterData);
    }
  }, [bannerQueryData]);

  useEffect(() => {
    if (cardData && userData) {
      setCardDataList(cardData.cards.data);
      for (let index = 0; index < cardData.cards.data.length; index++) {
        if (cardData.cards.data[index].id == userData.defaultCard) {
          setDefaultCardData(cardData.cards.data[index]);
        }
      }
    }
  }, [cardData, userData]);

  // useEffect(() => {
  //   if (limitData) {
  //   }
  // }, [limitData]);

  useEffect(() => {
    if (homePopupQueryData) {
      setHomePopupData(homePopupQueryData.homepopups.data[0])
      setShowWarningPopup(homePopupQueryData.homepopups.data[0] ? homePopupQueryData.homepopups.data[0].isOpen : false)
    }
  }, [homePopupQueryData])

  useEffect(() => {
    if (scanPopupQueryData) {
      setScanPopupData(scanPopupQueryData.scanpopups.data[0])
    }
  }, [scanPopupQueryData])

  // useEffect(() => {
  //   if (paymentData) {
      // console.log("paymentData: ", paymentData)
  //   }
  // }, [paymentData])


  const successCallback = (position) => {
    // console.log("position: ",position.coords)
  };

  const errorCallback = (error) => {
    // console.log(error);
  };

  const _searchByCreditCard = async (credit) => {
    if (!credit) {
      getPayments({
        variables: {
          where: {
            userId: userId,
            status: "SUCCESS"
          },
          orderBy: "createdAt_DESC",
          limit: 6,
        },
      });
    } else if (credit) {
      getPayments({
        variables: {
          where: {
            userId: userId,
            stripeCardId: credit,
            status: "SUCCESS"
          },
          orderBy: "createdAt_DESC",
          limit: 6,
        },
      });
    }
  };

  const getUpPassUrl = async () => {
    try {
      setIsLoading(true);
      const url = await createUpPassForm({
        variables: {
          userId
        }
      });
      if (url) {
        // setIsLoading(false)
        const UpPassURL = url.data.createUpPassForm.url;
        window.location.href = UpPassURL;
      } else {
        setIsLoading(false)
      }
      // setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      // console.log(error)
    }
  }

  //routes
  const _gotoHomeProfile = () => {
    history(`/home-profile?userId=${userId}`);
  };

  const _gotoHomeAccount = () => {
    history(`/home-account`);
  };

  const _gotoBanDetail = (bannerId) => {
    history(`/home-ban-detail?bannerId=${bannerId}`);
  };

  const _gotoScanPage = () => {
    if (userData.isBanned) {
      setIsBanned(true);
    } else {
      history(`/home-qr-payment`);
    }

    // history(`/home-qr-scan`)
  };

  const _gotoHomeDetail = (data) => {
    let desc = data.bankName === 'TELBIZ' ? data.qrType : data.note
    history(
      `/home-history-details?id=${data.id}&createdAt=${data.createdAt}&price=${data.amount}&phone=${data.phone}&creditCard=${data.cardLast4digit}&note=${desc}&cardId=${data.stripeCardId}&startingPrice=${data.amount}&totalFees=${data.totalFees}&billNo=${data.billNo}&bankName=${data.bankName}&bankTansactionId=${data.bankTansactionId}&destMerchantName=${data.destMerchantName}&refNo=${data.refNo}&status=${data.status}&bankLogoUrl=${data.bankLogoUrl}&cardType=${data.cardType}&cardName=${data.cardHolderName}&brand=${data.cardType}&expireMonth=${data.expireMonth}&expireYear=${data.expireYear}&description=${desc}&cardNumber=${data.cardLast4digit}&qrString=${data.qrString}&packageAmount=${data.packageAmount}`
    );
  };

  const _gotoHistory = () => {
    history(`/home-history`);
  };

  const _gotoBuyDataPackage = () => {
    if (cardData && cardData.cards && cardData.cards.total > 0) {
      history(`/buy-data-package-list`);
    } else {
      setShowAddCard(true)
    }
  }

  if (loading || paymentLoading || cardLoading) {
    return <HomeLoading />;
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="wrapper" style={{ backgroundColor: BACKGROUND_COLOR_APP }}>
      <div>
        <img
          style={{
            marginLeft: 21,
            // marginBottom: 14,
            width: 90,
            marginTop: 20,
          }}
          src="/assets/images/loca-logo.png"
        />
      </div>

      {userData ? (
        <div>
          <div
            // className="box-shadow-md"
            style={{
              marginLeft: 20,
              marginRight: 20,
              borderRadius: 6,
              minHeight: 80,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              border: "none",
            }}
            onClick={() => _gotoHomeProfile()}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  fontSize: 17,
                  fontWeight: "bold",
                }}
              >
                <div>
                  {userData.firstName} {userData.lastName}
                  {checkKyc ?
                    <span style={{ marginLeft: 5 }}>
                      <FontAwesomeIcon icon={faCheckCircle} color="#10A5F2" /> <span style={{ color: "#999999", fontSize: 8, fontWeight: "normal" }}>KYC</span>
                    </span>
                    : <></>
                  }
                </div>
                <div style={{ fontSize: 14, fontWeight: "normal", color: "#999999" }}>{t("welcome-back")}</div>
              </div>
            </div>

            <img
              style={{
                // marginLeft: 22,
                width: 50,
                height: 50,
                borderRadius: "50%",
                objectFit: "cover",
              }}
              src={
                userData.profileImage
                  ? userData.profileImage
                  : userData.gender === "FEMALE"
                    ? "/assets/images/female.png"
                    : "/assets/images/male.png"
              }
              className="Profile-image"
            />
          </div>

          <CustomCard
            cardData={cardData}
            scanPopupData={scanPopupData}
            _gotoScanPage={_gotoScanPage}
            setShowAddCard={setShowAddCard}
            setShowWarningScan={setShowWarningScan}
            defaultCardData={defaultCardData}
            userData={userData}
            _gotoHomeAccount={_gotoHomeAccount}
            setShowPin={setShowPin}
          />

          <div style={{ marginLeft: 20, marginRight: 20, marginBottom: 20 }}>
            <div style={{ fontSize: 16, fontWeight: "bold", marginBottom: 11, userSelect: "none" }}>{t("service")}</div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* <ServicesButton
                image={"assets/images/icon_esim.png"}
                text={t("buy-e-sim")}
              /> */}
              <ServicesButton
                image={"assets/images/icon_package.png"}
                text={t("buy-data")}
                secondText={t("package")}
                gotoOtherPage={_gotoBuyDataPackage}
              />
              {/* <ServicesButton
                image={"assets/images/icon_promotion.png"}
                text={t("promotion")}
              /> */}
            </div>
          </div>


          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <div style={{ fontSize: 16, fontWeight: "bold", marginBottom: 11, userSelect: "none" }}>{t("news")}</div>
            <Carousel indicators={false} autoPlay={true}>
              {bannerData ? (
                bannerData.map((data, index) => {
                  return (
                    <Carousel.Item
                      key={index}
                      onClick={() => _gotoBanDetail(data.id)}
                    >
                      <img
                        height={100}
                        objectfit="fill"
                        src={
                          data.bannerImage
                            ? data.bannerImage
                            : "/assets/images/Ads_loca.png"
                        }
                        alt=""
                      />
                    </Carousel.Item>
                  );
                })
              ) : (
                <img
                  objectfit="fill"
                  style={{
                    height: 100,
                  }}
                  src={"/assets/images/Ads_loca.png"}
                  alt=""
                />
              )}
            </Carousel>
          </div>
          {/* } */}
          {/* <div
            style={{
              fontSize: 14,
              color: "#828282",
              marginTop: 27,
              marginLeft: 26,
              fontWeight: "bold",
            }}
          >
            {t("home-recents")}
          </div> */}
          <div
            style={{
              fontSize: 16,
              color: "#4B4B4B",
              marginTop: 27,
              marginLeft: 26,
              fontWeight: "bold",
            }}
          >
            {t("activity")}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              marginBottom: 20,
            }}
          >
            <InputGroup className="mb-3" style={{ width: "90%" }}>
              <InputGroup.Text
                style={{ borderRight: "none", backgroundColor: "#EFF2F5" }}
              >
                <FontAwesomeIcon icon={faCreditCard} />
              </InputGroup.Text>
              <Form.Select
                style={{ borderLeft: "none", backgroundColor: "#EFF2F5" }}
                value={paymentSelect}
                onChange={(e) => {
                  _searchByCreditCard(e.target.value);
                  setPaymentSelect(e.target.value);
                }}
              >
                <option value="">{t("all-card")}</option>
                {cardDataList &&
                  cardDataList.map((card, index) => {
                    return (
                      <option value={card.id} key={index}>
                        {card.brand} &nbsp; **** **** **** {card.cardId}
                      </option>
                    );
                  })}
              </Form.Select>
            </InputGroup>
          </div>
          {_.isEmpty(paymentData) ? (
            <EmptyHistoryPayment />
          ) : (
            <div>
              {!_.isEmpty(paymentData) &&
                paymentData.payments.data.map((payment, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                      key={index}
                      onClick={() => _gotoHomeDetail(payment)}
                    >
                      <div
                        style={{
                          width: "100%",
                          paddingLeft: 20,
                          paddingRight: 20,
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div>
                            <img
                              src={
                                payment.bankName == 'TELBIZ' ? (
                                  payment.qrType == "LTC" ? "/assets/images/ltc_logo.jpeg" :
                                    payment.qrType == "UNITEL" ? "/assets/images/unitel_logo.jpeg" :
                                      payment.qrType == "ETL" ? "/assets/images/etl_logo.png" :
                                        "/assets/images/box-loading.jpg"
                                ) : payment.bankLogoUrl
                                  ? payment.bankLogoUrl
                                  : "/assets/images/box-loading.jpg"
                              }
                              style={{
                                width: 35,
                                height: 35,
                                // borderRadius: 35,
                              }}
                              alt=""
                            />

                            <div style={{ height: 2 }} />

                            <img
                              src={
                                payment.cardType == "visa"
                                  ? "/assets/images/visa_icon.png" :
                                  payment.cardType == "jcb" ? "/assets/images/jcb.png" :
                                    payment.cardType == "discover" || payment.cardType == "diners" ? "/assets/images/discover_diner.png" :
                                      payment.cardType == "unionpay" ? "/assets/images/union_pay.png" :
                                        payment.cardType == "amex" ? "/assets/images/amex.png" : "/assets/images/MasterCard.png"
                              }
                              style={{
                                height: payment.cardType == 'discover' || payment.cardType == "diners" ?
                                  8 :
                                  payment.cardType == "amex" ? 30 :
                                    payment.cardType == "unionpay" ? 23 :
                                      payment.cardType == "jcb" ? 25 :
                                        payment.cardType === "visa" ? 9 :
                                          payment.cardType === "mastercard" ? 20 :
                                            35
                              }}
                              alt=""
                            />
                          </div>

                          <div style={{ width: 20 }} />

                          <div style={{ fontSize: 12, flex: 1 }}>
                            <span style={{ fontWeight: "bold" }}>
                              {/* To account */}
                              {t("home-account")}:
                            </span>{" "}
                            {payment.bankName === "TELBIZ" ? payment.phone : payment.destMerchantName}
                            <div>
                              <span style={{ fontWeight: "bold" }}>
                                {/* From */}
                                {t("payment-from")}:
                              </span>
                              <span style={{ marginLeft: 6 }}>
                                {payment.firstName} {payment.lastName}
                              </span>
                            </div>
                            <div>
                              <span style={{ fontWeight: "bold" }}>
                                {/* Description */}
                                {t("payment-des")}:{" "}
                              </span>
                              <span>
                                {/* Thanks */}
                                {payment.bankName === "TELBIZ" ? payment.qrType : payment.note}
                              </span>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                {moment(payment.createdAt).format(
                                  "DD-MM-YYYY H:mm"
                                )}
                              </div>
                              <div
                                style={{
                                  color: "red",
                                  marginLeft: 6,
                                  fontWeight: "bold",
                                  fontSize: 14,
                                }}
                              >
                                LAK - {numberWithCommas(payment.total)}
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                  );
                })}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  // className="box-shadow-see-all"
                  onClick={() => _gotoHistory()}
                  className="btn btn-primary"
                  style={{ width: "90%", backgroundColor: COLOR_APP, borderColor: COLOR_APP }}
                >
                  {t("home-see-all")}
                </button>
              </div>
            </div>
          )}
          <div style={{ height: 80 }} />
          <HomeBottomNav
            currentIndex={1}
          />
        </div>
      ) : (
        <div />
      )}
      <div style={{ height: 30 }} />

      <Modal show={showKyc} size="sm" onHide={() => setShowKyc(false)} centered>
        <Modal.Body style={{ textAlign: "center" }}>
          <img
            src={
              popupQueryData
                ? popupQueryData.popups.data[0].popupImage
                : "/assets/images/Kyc_Logo_Welcome.png"
            }
            style={{ height: 150, marginBottom: 16 }}
            alt=""
          />
          <div style={{ marginBottom: 16 }}>
            {/* {t("kyc-please-kyc")} */}
            {popupQueryData ? popupQueryData.popups.data[0].note : ""}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <div
              style={{ fontWeight: "bold", color: COLOR_APP }}
              onClick={() => setShowKyc(false)}
            >
              {/* Later */}
              {t("kyc-later")}
            </div>
            <Button
              style={{
                backgroundColor: COLOR_APP,
                border: "none",
              }}
              onClick={() =>
                history(
                  `/kyc?profileImage=${userData.profileImage}&firstName=${userData.firstName}&lastName=${userData.lastName}&phone=${userData.phone}&sid=${userData.sid}`
                )
                // {
                //   setShowKyc(false)
                //   getUpPassUrl()
                // }
              }
            >
              {t("kyc-registers")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={isBanned}
        size="sm"
        onHide={() => setIsBanned(false)}
        centered
      >
        <Modal.Body style={{ textAlign: "center" }}>
          <RiErrorWarningFill style={{ fontSize: 60, color: POPUP_COLOR }} />
          <div>
            {/* Please add card first */}
            {t("home-banned")}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showAddCard}
        size="sm"
        onHide={() => setShowAddCard(false)}
        centered
      >
        <Modal.Body style={{ textAlign: "center" }}>
          <RiErrorWarningFill style={{ fontSize: 60, color: POPUP_COLOR }} />
          <div>
            {/* Please add card first */}
            {t("add-card")}
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showPin} size="sm" onHide={() => setShowPin(false)} centered>
        <Modal.Body style={{ textAlign: "center" }}>
          <RiErrorWarningFill style={{ fontSize: 60, color: POPUP_COLOR }} />
          <div style={{ marginBottom: 16 }}>{t("show-pin")}</div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                color: POPUP_COLOR,
                fontWeight: "bold",
                width: "30%",
                paddingTop: 6,
                paddingBottom: 6,
              }}
              onClick={() => history(`/home-profile-pin`)}
            >
              {t("popup-set-pin")}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showWarningPopup} size="sm" onHide={() => setShowWarningPopup(false)} centered>
        <Modal.Body style={{ textAlign: "center" }}>
          <img
            src={homePopupData ? homePopupData.icon : ""}
            alt=""
            style={{ width: 100, height: 100, objectFit: "cover" }}
          />
          <div dangerouslySetInnerHTML={{
            __html: homePopupData ? Language === "la" ? homePopupData.laoTitle : homePopupData.engTitle : "",
          }}></div>
          <div style={{ height: 8 }} />
          <div
            dangerouslySetInnerHTML={{
              __html: homePopupData ? Language === "la" ? homePopupData.laoFirstSubTitle : homePopupData.engFirstSubTitle : ""
            }}
          ></div>
          <div
            dangerouslySetInnerHTML={{
              __html: homePopupData ? Language === "la" ? homePopupData.laoSecondSubTitle : homePopupData.engSecondSubTitle : ""
            }}
          ></div>
          <div style={{ height: 6 }} />

          <div style={{ height: 16 }} />
          <Button style={{
            backgroundColor: "#00ADFF",
            borderColor: "#00ADFF",
            width: 200
          }}
            onClick={() => setShowWarningPopup(false)}
          >{t("ok")}</Button>
        </Modal.Body>
      </Modal>

      <Modal show={showWarningScan} size="sm" onHide={() => setShowWarningScan(false)} centered>
        <Modal.Body style={{ textAlign: "center" }}>
          <img
            src={scanPopupData ? scanPopupData.icon : ""}
            alt=""
            style={{ width: 100, height: 100, objectFit: "cover" }}
          />
          <div dangerouslySetInnerHTML={{
            __html: scanPopupData ? Language === "la" ? scanPopupData.laoTitle : scanPopupData.engTitle : "",
          }}></div>
          <div style={{ height: 8 }} />
          <div
            dangerouslySetInnerHTML={{
              __html: scanPopupData ? Language === "la" ? scanPopupData.laoFirstSubTitle : scanPopupData.engFirstSubTitle : ""
            }}
          ></div>
          <div
            dangerouslySetInnerHTML={{
              __html: scanPopupData ? Language === "la" ? scanPopupData.laoSecondSubTitle : scanPopupData.engSecondSubTitle : ""
            }}
          ></div>
          <div style={{ height: 6 }} />

          <div style={{ height: 16 }} />
          <Button style={{
            backgroundColor: "#00ADFF",
            borderColor: "#00ADFF",
            width: 200
          }}
            onClick={() => setShowWarningScan(false)}
          >{t("ok")}</Button>
        </Modal.Body>
      </Modal>


    </div>
  );
}

export default HomeAddCreditCard;

import React from "react";
import { GrClose } from "react-icons/gr";
import { useNavigate, useLocation } from "react-router-dom";
import { BACKGROUND_COLOR_APP, HEADER_HEIGHT } from "../../constants";
import CustomAppBar from "../../components/CustomAppBar";

function KycCondition() {
  const history = useNavigate();
  const location = useLocation();

  const firstName = new URLSearchParams(location.search).get("firstName");
  const lastName = new URLSearchParams(location.search).get("lastName");
  const phone = new URLSearchParams(location.search).get("phone");
  const email = new URLSearchParams(location.search).get("email");
  const countryCode = new URLSearchParams(location.search).get("countryCode");
  const dobDate = new URLSearchParams(location.search).get("dobDate");
  const gender = new URLSearchParams(location.search).get("gender");
  const issueDate = new URLSearchParams(location.search).get("issueDate");
  const expireDate = new URLSearchParams(location.search).get("expireDate");
  const passportNumber = new URLSearchParams(location.search).get(
    "passportNumber"
  );
  // const profileImage = new URLSearchParams(location.search).get("profileImage");
  const profileImage = localStorage.getItem("PROFILE_IMAGE");
  const fcmTopic = new URLSearchParams(location.search).get("fcmTopic");
  const userLang = new URLSearchParams(location.search).get("userLang");
  const langCondition = localStorage.getItem("userLang");
  const kycPolicy = localStorage.getItem("KYC_POLICY")


  const _goBack = () => {
    if (kycPolicy === "PROFILE") {
      localStorage.removeItem("KYC_POLICY")
      history(-1)
    } else {
      if (
        issueDate != "Invalid date" ||
        expireDate != "Invalid date" ||
        dobDate != "Invalid date"
      ) {
        history(
          `/kycsignup?firstName=${firstName}&lastName=${lastName}&phone=${phone}&email=${email}&countryCode=${countryCode}&dobDate=${dobDate}&gender=${gender}&passportNumber=${passportNumber}&issueDate=${issueDate}&expireDate=${expireDate}&userLang=${userLang}&profileImage=${profileImage}&fcmTopic=${fcmTopic}`
        );
      } else {
        history(-1);
      }
    }

  };


  return (
    <div className="wrapper" style={{backgroundColor: BACKGROUND_COLOR_APP}}>
      {/* <div className="kyc-header">
        <GrClose onClick={() => _goBack()} />
      </div> */}
      <CustomAppBar
        gotoOtherPage={_goBack}
        title={"LOCA PAY"}
      />
      {/* <div style={{ height: HEADER_HEIGHT }}></div> */}

      {/* Body */}
      <div>
        <div style={{ padding: 10 }}>
          <div style={{ marginRight: 10, marginLeft: 10 }}>
            <div style={{ textAlign: "center", fontSize: 24 }}>
              LOCA PAY Terms & Conditions
            </div>
            <div style={{ marginTop: 4 }}> <span style={{ marginLeft: 12 }} />In applying for the use of LOCA PAY services, the user agrees to comply and be bound by the following terms and conditions:</div>
            <br />
            <div style={{ fontSize: 18 }}>Initial Registration:</div>
            <div style={{ height: 12 }} />
            <div>1. To register for an LOCA PAY account, the user must provide certain information, including passport data and credit card details, as part of the KYC process.</div>
            <div>2. After completing registration, the user must create a secure personal password according to the company's instructions.</div>

            <div style={{ height: 14 }} />
            <div style={{ fontSize: 18 }}>Charges & Payment Proceeding: </div>
            <div style={{ height: 12 }} />
            <div>1. Users can use LOCA PAY to pay for goods or services from stores that currently accept LAO QR Code Standard.</div>
            <div>2. Transactions will be displayed in Lao Kip, but users will be charged in USD equivalent based on the prevailing exchange rate at the time of the transaction. </div>
            <div>3. The company will display and calculate its fees before the transaction is confirmed. </div>
            <div>4. Users are responsible for ensuring their credit card has sufficient funds to cover the transaction amount in USD. </div>
            <div>5. Any fees, charges or taxes associated with the transaction will be borne by the user. </div>
            <div>6. By proceeding with the payment, the user agrees to these terms and conditions.</div>
            <div style={{ height: 12 }} />
            <div style={{ fontSize: 18 }}>Fees & Charges:</div>
            <div>LOCA PAY Charges 1.5% + 5,000 LAK for every transaction made with LOCA PAY</div>
            <div style={{ height: 12 }} />
            <div style={{ fontSize: 18 }}>Responsibilities:</div>
            <div>1. The Company is not liable for any transaction arising out of the use of LOCA PAY by the user's telephone number, password, or transaction tool.</div>
            <div>2. Users must keep their user account and phone number used for registration, as well as their password, confidential and not disclose them to others. The Company is not liable for any damages incurred as a result of unauthorized access to the user's account. </div>
            <div>3. Users acknowledge that any transaction made using their phone number and password will be considered a service order by the user, and the Company is not responsible for any damages resulting from such access. </div>
            <div>4. Users agree to take responsibility for any errors caused by their own transactions and to seek resolution with the other party involved. The Company is not liable for any damages resulting from such transactions. </div>
            <div>5. In case of a lost or stolen mobile phone, users can contact the Company to suspend their wallet account by calling the Contact Center at +8562098111129 or emailing locapaysupport@loca.la, 24 hours a day.</div>
            <div style={{ height: 12 }} />
            <div style={{ fontSize: 18 }}>Service Users (Customer): </div>
            <div>1. Users must register and accept the terms and conditions of the company without prior notice. </div>
            <div>2. Users agree to pay all fees and charges related to the use of LOCA PAY at rates set by the company. The Company reserves the right to change the rates, fees and charges as it deems appropriate at any time without prior notice to the user.</div>
            <div style={{ height: 12 }} />
            <div>The Company reserves the right to update these terms and conditions without prior notice to the user. By proceeding with the use of LOCA PAY, the user agrees to abide by these terms and conditions.</div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: 8 }}>Updated: March 15 2023 3:00 PM</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KycCondition;

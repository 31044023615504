import { useLazyQuery } from '@apollo/client'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import { BACKGROUND_COLOR_APP, COLOR_APP } from '../../../constants'
import { GET_USER_FROM_TOKEN } from '../../graphql/query'


function ForgotPinNewPin() {
  const history = useNavigate()
  const location = useLocation()
  const [showPin, setShowPin] = useState(false);
  const [showConfirmPin, setShowConfirmPin] = useState(false);
  const token = new URLSearchParams(location.search).get('token')
  const [customerName, setCustomerName] = useState();
  const [customerLastName, setCustomerLastName] = useState();
  const [newPin, setNewPin] = useState();
  const [confirmPin, setConfirmPin] = useState();
  const [userId, setUserId] = useState();
  const [cusNameError, setCusNameError] = useState(false);
  const [newPinError, setNewPinError] = useState(false);
  const [confirmPinError, setConfirmPinError] = useState(false);
  const [lengthError, setLengthError] = useState(false);
  const [error, setError] = useState(false);

  const [
    getUser,
    { data: userQueryData, error: userQueryError },
  ] = useLazyQuery(GET_USER_FROM_TOKEN);



  useEffect(() => {
    if (token) {
      getUser({
        variables: {
          token: token
        }
      })
    }
  }, [])

  useEffect(() => {
    if (userQueryData) {
      setCustomerName(userQueryData.reverifyResetPinData.firstName)
      setCustomerLastName(userQueryData.reverifyResetPinData.lastName)
      setUserId(userQueryData.reverifyResetPinData.userId)
    }
  }, [userQueryData])


  useEffect(()=> {
    if(userQueryError) {
      let splitError = userQueryError.toString().split(": ")
      if(splitError[1] === "USER_ALREADY_RESET_PIN") {
        history('/home-profile-pin/forgot-pin-success', { replace: true })
      }
    }
  }, [userQueryError])


  const gotoForgotPinHint = () => {
    setCusNameError(false)
    setNewPinError(false)
    setConfirmPinError(false)
    setError(false)
    setLengthError(false)
    if (!customerName) {
      setCusNameError(true)
    }
    if (!newPin) {
      setNewPinError(true)
    }
    if (!confirmPin) {
      setConfirmPinError(true)
    }
    if (customerName && newPin && confirmPin) {
      if (newPin !== confirmPin) {
        setError(true)
      } else {
        if (newPin.length == 4) {
          history(`/home-profile-pin/forgot-pin-hint?token=${token}&newPin=${newPin}&userId=${userId}`)
        } else {
          setLengthError(true)
        }
      }

    }
  }

  const handleNewPinChange = (e) => {
    const limit = 4;
    setNewPin(e.target.value.slice(0, limit))
  }

  const handleConfirmPinChange = (e) => {
    const limit = 4;
    setConfirmPin(e.target.value.slice(0, limit))
  }


  return (
    <div>
      <center>
        <div style={{ height: 53 }} />
        <img
          style={{
            width: 153
          }}
          src="/assets/images/loca-logo.png"
        />
        <div style={{ height: 72 }} />
        <div style={{ width: "85%", textAlign: "left" }}>
          <label htmlFor="" style={{ marginBottom: 4 }}>Customer name</label>
          <Form.Control style={{
            height: 44,
            backgroundColor: "#EAEAEA"
          }}
            value={customerName + customerLastName}
          />
          {cusNameError ? <div style={{ color: "red" }}>Please input customer name</div> : <div />}
        </div>
        <div style={{ height: 24 }} />
        <div style={{ width: "85%", textAlign: "left" }}>
          <label htmlFor="" style={{ marginBottom: 4 }}>New PIN</label>
          <InputGroup>
            <Form.Control
              type={showPin ? "number" : 'password'}
              style={{
                height: 44,
                backgroundColor: "#F1F4F5",
                borderRight: "none"
              }}
              placeholder="Enter new PIN..."
              value={newPin}
              onChange={(e) => handleNewPinChange(e)}
              inputMode='numeric'
              pattern='[0-9]*'
            />
            <InputGroup.Text style={{ backgroundColor: "#F1F4F5", borderLeft: "none" }}
              onClick={() => setShowPin(!showPin)}
            >
              <FontAwesomeIcon icon={showPin ? faEyeSlash : faEye} />
            </InputGroup.Text>
          </InputGroup>
          {newPinError ? <div style={{ color: "red" }}>Please input the new PIN</div> : <div />}
        </div>
        <div style={{ height: 24 }} />
        <div style={{ width: "85%", textAlign: "left" }}>
          <label htmlFor="" style={{ marginBottom: 4 }}>Confirm PIN</label>
          <InputGroup>
            <Form.Control
              type={showConfirmPin ? "number" : 'password'}
              style={{
                height: 44,
                backgroundColor: "#F1F4F5",
                borderRight: "none"
              }}
              placeholder="Confirm new PIN..."
              value={confirmPin}
              onChange={(e) => handleConfirmPinChange(e)}
              inputMode='numeric'
              pattern='[0-9]*'
            />
            <InputGroup.Text style={{ backgroundColor: "#F1F4F5", borderLeft: "none" }}
              onClick={() => setShowConfirmPin(!showConfirmPin)}
            >
              <FontAwesomeIcon icon={showConfirmPin ? faEyeSlash : faEye} />
            </InputGroup.Text>
          </InputGroup>
          {confirmPinError ? <div style={{ color: "red" }}>Please input confirm PIN</div> : <div />}
          {error ? <div style={{ color: "red" }}>The PIN is not match</div> : <div />}
          {lengthError ? <div style={{ color: "red" }}>Please input a 4-digit PIN code</div> : <div />}
        </div>

        <div style={{ height: 80 }} />

        <button className='btn' style={{
          backgroundColor: COLOR_APP,
          borderColor: COLOR_APP,
          color: "white",
          fontWeight: "bold",
          width: "85%",
          height: 48,
          marginBottom: 20
        }}
          onClick={() => gotoForgotPinHint()}
        >Next</button>
      </center>
    </div>
  )
}

export default ForgotPinNewPin

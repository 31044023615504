import {
  faArrowLeft,
  faCircleXmark,
  faCircleCheck,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import QRCode from "react-qr-code";
import { numberWithCommas } from "../../constants/ShowFormat";
import moment from "moment";
import { Watermark } from "@hirohe/react-watermark";
import "./Home.css";
import { GET_CARD } from "./graphql/query";
import Loading from "../../components/Loading";
import { BACKGROUND_COLOR_APP, COLOR_APP, DEV_FONT_API, DEV_SERVER_API, HEADER_HEIGHT } from "../../constants";
import CustomAppBar from "../../components/CustomAppBar";
import { Button } from "react-bootstrap";

function HomeHistoryDetails() {
  const history = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const [canScroll, setCanScroll] = useState(false);
  const ref = useRef(null);

  let status = new URLSearchParams(location.search).get("status");
  let id = new URLSearchParams(location.search).get("id");
  let price = new URLSearchParams(location.search).get("price");
  let startingPrice = new URLSearchParams(location.search).get("startingPrice");
  let createdAt = new URLSearchParams(location.search).get("createdAt");
  let creditCard = new URLSearchParams(location.search).get("creditCard");
  let detail = new URLSearchParams(location.search).get("note");
  let cardId = new URLSearchParams(location.search).get("cardId");
  let totalFees = new URLSearchParams(location.search).get("totalFees");
  let refNo = new URLSearchParams(location.search).get("refNo");
  let billNo = new URLSearchParams(location.search).get("billNo");
  let destMerchantName = new URLSearchParams(location.search).get(
    "destMerchantName"
  );
  let bankLogoUrl = new URLSearchParams(location.search).get("bankLogoUrl");
  let cardName = new URLSearchParams(location.search).get("cardName")
  let brand = new URLSearchParams(location.search).get("cardType")
  let expireMonth = new URLSearchParams(location.search).get("expireMonth")
  let expireYear = new URLSearchParams(location.search).get("expireYear")
  let description = new URLSearchParams(location.search).get("description")
  let cardNumber = new URLSearchParams(location.search).get("cardNumber")
  let qrString = new URLSearchParams(location.search).get("qrString")
  let qrType = new URLSearchParams(location.search).get("description")
  let packageAmount = new URLSearchParams(location.search).get("packageAmount")
  let bankName = new URLSearchParams(location.search).get("bankName")
  let bankTansactionId = new URLSearchParams(location.search).get("bankTansactionId")
  let phone = new URLSearchParams(location.search).get("phone")

  const [text, setText] = useState(
    `$refNo:${bankName === 'TELBIZ' ? bankTansactionId : billNo}-${price}-${detail}-at:${moment(createdAt).format(
      "DD-MM-YYYY"
    )}`
  );
  const [card, setCard] = useState()

  const [getCard, { data: cardData, loading: cardLoading }] = useLazyQuery(
    GET_CARD
  );

  useEffect(() => {
    getCard({
      variables: {
        where: {
          id: cardId,
        },
      },
    });

  }, []);

  useEffect(() => {
    if (cardData) {
      setCard(cardData.card)
    }
  }, [cardData])


  const _gotoBack = () => {
    history(-1);
  };

  if (cardLoading) {
    return <Loading />;
  }

  return (
    <div className="wrapper" style={{ backgroundColor: BACKGROUND_COLOR_APP }}>
      {/* <div className="home-history-header" style={{ textAlign: "center" }}>
        <FontAwesomeIcon
          style={{
            marginLeft: 10,
            fontSize: "18",
            fontWeight: "bold",
            color: "#252A31",
          }}
          icon={faArrowLeft}
          onClick={() => _gotoBack()}
        />
        {t("detail")}
        <FontAwesomeIcon
          style={{
            marginLeft: 10,
            fontSize: "18",
            fontWeight: "bold",
            color: "white",
          }}
          icon={faArrowLeft}
        />
      </div> */}

      <CustomAppBar
        gotoOtherPage={_gotoBack}
        title={t("detail")}
      />

      {/* <div style={{ height: HEADER_HEIGHT }} /> */}
      <div
        style={
          canScroll
            ? { position: "fixed", overflowY: "scroll", width: "100%" }
            : {}
        }
        ref={ref}
      >
        <Watermark
          text={text}
          rotate={-20}
          textSize={12}
          fontFamily={"Times New Roman"}
          multiline={true}
          opacity={0.4}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: 20,
                marginRight: 20,
                marginTop: 10,
              }}
            >
              <div>
                <div style={{ fontSize: 17, fontWeight: "bold" }}>
                  {/* Payment Successfully */}
                  {t("payment-success")}
                </div>
                <div style={{ marginTop: 8, color: "#4B4B4B" }}>
                  {moment(createdAt).format("DD/MM/YYYY HH:mm")}
                </div>

                <div style={{ display: "flex", flexDirection: "row", marginTop: 4 }}>
                  <div
                    style={{
                      fontSize: 16,
                      marginTop: 2,
                      color: "#999999",
                      marginBottom: 7
                    }}
                  >
                    {/* Amount */}
                    {t("amount")}
                  </div>
                  <div style={{ width: 10 }} />
                  <div style={{ fontSize: 20, color: "#EB001B", fontWeight: "bold" }}>
                    LAK {numberWithCommas(price)}
                  </div>
                </div>

                <div style={{ display: "flex", flexDirection: "row", marginTop: 4 }}>
                  <div
                    style={{
                      fontSize: 16,
                      color: "#999999",
                      marginBottom: 5
                    }}
                  >
                    {/* Amount */}
                    {t("payment-fee")}
                  </div>
                  <div style={{ width: 10 }} />
                  <div style={{ color: "#EB001B", fontSize: 16 }}>
                    LAK {numberWithCommas(parseFloat(totalFees).toFixed(2))}
                  </div>
                </div>
              </div>
              <div style={{ paddingRight: 10 }}>
                {" "}
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="#139A18"
                  fontSize={70}
                />
              </div>
            </div>
          </div>

          <div style={{ padding: 20, paddingTop: 6 }}>

            <div
              style={{
                fontSize: 16,
                color: "#999999",
              }}
            >
              {/* To */}
              {t("payment-to")}
            </div>

            <div>
              {bankName === 'TELBIZ' ?
                <div
                  style={{
                    // height: 60,
                    borderRadius: 6,
                    padding: 8,
                    border: "1px solid #b6b6b6",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <div>
                    <div style={{ fontSize: 16, fontWeight: "bold" }}>{destMerchantName !== "null" ? destMerchantName : `DataPackage ${numberWithCommas(parseFloat(packageAmount))} MB`}</div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          fontSize: 14,
                          fontWeight: "bold"
                        }}
                      >
                        {t("phone-number")}
                      </div>
                      <div style={{ width: 10 }} />
                      <div style={{ fontSize: 14, fontWeight: 'bold' }}>
                        +856 20 {phone}
                      </div>
                    </div>

                  </div>
                  <div>
                    <img
                      style={{
                        width: 50,
                        height: 50,
                      }}
                      src={bankName == 'TELBIZ' ? (
                        qrType == "LTC" ? "/assets/images/ltc_logo.jpeg" :
                          qrType == "UNITEL" ? "/assets/images/unitel_logo.jpeg" :
                            qrType == "ETL" ? "/assets/images/etl_logo.png" :
                              "/assets/images/box-loading.jpg"
                      ) : bankLogoUrl ? bankLogoUrl :
                        "/assets/images/box-loading.jpg"
                      }
                    />
                  </div>
                </div>
                : <div
                  style={{
                    // height: 70,
                    borderRadius: 6,
                    padding: 8,
                    border: "1px solid #b6b6b6",
                  }}
                >
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                    <div style={{ fontSize: 16, fontWeight: "bold" }}>{destMerchantName !== "null" ? destMerchantName : `DataPackage ${numberWithCommas(parseFloat(packageAmount))} MB`}</div>
                    <div>
                      <img
                        style={{
                          width: 50,
                          height: 50,
                        }}
                        src={bankName == 'TELBIZ' ? (
                          qrType == "LTC" ? "/assets/images/ltc_logo.jpeg" :
                            qrType == "UNITEL" ? "/assets/images/unitel_logo.jpeg" :
                              qrType == "ETL" ? "/assets/images/etl_logo.png" :
                                "/assets/images/box-loading.jpg"
                        ) : bankLogoUrl ? bankLogoUrl :
                          "/assets/images/box-loading.jpg"
                        }
                      />
                    </div>
                  </div>
                </div>
              }
            </div>

            <div
              style={{
                fontSize: 16,
                color: "#999999",
                marginTop: 11
              }}
            >
              {/* From */}
              {t("payment-from")}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: 6,
                padding: 10,
                paddingTop: 6,
                paddingBottom: 6,
                border: "1px solid #b6b6b6"
              }}
            >
              <div>
                <div style={{ fontSize: 16, fontWeight: "bold" }}>{cardName ? cardName : ""}</div>
                <div style={{ fontSize: 14, fontWeight: "bold" }}>
                  ************{creditCard ? creditCard : ""}
                </div>
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#898989",
                  }}
                >
                  {t("card-expire")} {card ? card.expireMonth : ""}/
                  {card ? card.expireYear : ""}
                </div>
              </div>
              <img
                style={{
                  height: brand == "discover" || brand == "diners" ? 20 :
                    brand === "mastercard" ? 40 :
                      brand === "visa" ? 20 :
                        50,
                }}
                src={
                  brand == "visa"
                    ? "/assets/images/visa_icon.png"
                    : brand == "jcb"
                      ? "/assets/images/jcb.png"
                      : brand == "discover" || brand == "diners"
                        ? "/assets/images/discover_diner.png"
                        : brand == "unionpay"
                          ? "/assets/images/union_pay.png"
                          : brand == "amex"
                            ? "/assets/images/amex.png"
                            : "/assets/images/mastercards.png"
                }
              />
            </div>



            {refNo === "null" ? <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {description === 'null' ? <>
                  <div></div>
                </> :
                  <>
                    <div>
                      <div
                        style={{
                          fontSize: 16,
                          marginTop: 11,
                          color: "#999999",
                        }}
                      >
                        {t("payment-des")}
                      </div>
                      <div>{description}</div>
                    </div>
                  </>
                }


                <div style={{ marginTop: 14 }}>
                  <div
                    className="qr-background"
                  >
                    <div style={{ display: "flex", justifyContent: "center", marginBottom: 6 }}>
                      <img src="assets/images/logo-locapay.png" alt="" width={50} />
                    </div>
                    <div style={{ padding: 6, backgroundColor: "white", borderRadius: 3 }}>
                      <QRCode value={
                        `${DEV_FONT_API}/home-comfirm-payment-success?id=${id}&price=${price}&createdAt=${createdAt}`
                      } size={80} />
                    </div>
                  </div>
                </div>
              </div>
            </div> : <div>


              <div style={{ display: "flex", justifyContent: "space-between" }}>

                <div>
                  <div>
                    <div
                      style={{
                        fontSize: 16,
                        marginTop: 11,
                        color: "#999999",
                        marginBottom: 11
                      }}
                    >
                      {t("payment-bill")}
                    </div>
                    <div>{refNo}</div>
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: 16,
                        marginTop: 11,
                        color: "#999999",
                      }}
                    >
                      {t("payment-des")}
                    </div>
                    <div>{description}</div>
                  </div>
                </div>

                <div style={{ marginTop: 14 }}>
                  <div
                    className="qr-background"
                  >
                    <div style={{ display: "flex", justifyContent: "center", marginBottom: 6 }}>
                      <img src="assets/images/logo-locapay.png" alt="" width={50} />
                    </div>
                    <div style={{ padding: 6, backgroundColor: "white", borderRadius: 3 }}>
                      <QRCode value={
                        `${DEV_FONT_API}/home-comfirm-payment-success?id=${id}&price=${price}&createdAt=${createdAt}`
                      } size={80} />
                    </div>
                  </div>
                </div>

              </div>
            </div>}



          </div>
          <div style={{
            textAlign: "center",
            marginTop: 12,
            fontWeight: "bold",
            fontSize: 18,
            color: "#8C8C8C"
          }}>P O W E R E D <span style={{ marginRight: 6 }} />B Y
            <span style={{ color: COLOR_APP, marginLeft: 12 }}>L O C A
              <span style={{ marginLeft: 6 }} />P A Y</span></div>
          <div style={{ height: 70 }} />
          <div style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%"
          }}>
            <Button
              style={{
                height: 50,
                backgroundColor: COLOR_APP,
                textAlign: "center",
                border: "none",
                width: "100%",
                fontWeight: 700,
                fontSize: 16
              }}
              onClick={() => _gotoBack()}
            >
              {t("success")}
            </Button>
          </div>
        </Watermark>
      </div>
    </div>
  );
}

export default HomeHistoryDetails;

import React, { useState } from 'react'
import { BACKGROUND_COLOR_APP, COLOR_APP, DEV_FONT_API, DEV_SERVER_API } from '../../../constants'
import { useLocation, useNavigate } from 'react-router-dom'
import { Watermark } from "@hirohe/react-watermark";
import CustomAppBar from '../../../components/CustomAppBar'
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import QRCode from 'react-qr-code';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { numberWithCommas } from '../../../constants/ShowFormat';

function BuyDataPackageSuccess() {
  const navigate = useNavigate()
  const location = useLocation();
  const { t, i18n } = useTranslation();
  let brand = new URLSearchParams(location.search).get("cardType");
  let bankTansactionId = new URLSearchParams(location.search).get("bankTansactionId");
  let createdAt = new URLSearchParams(location.search).get("createdAt");
  let amount = new URLSearchParams(location.search).get("amount");
  let totalFees = new URLSearchParams(location.search).get("totalFees");
  let cardHolderName = new URLSearchParams(location.search).get("cardHolderName");
  let cardLast4digit = new URLSearchParams(location.search).get("cardLast4digit");
  let expireMonth = new URLSearchParams(location.search).get("expireMonth");
  let expireYear = new URLSearchParams(location.search).get("expireYear");
  let qrString = new URLSearchParams(location.search).get("qrString");
  let qrType = new URLSearchParams(location.search).get("qrType");
  let id = new URLSearchParams(location.search).get("id");
  let phone = new URLSearchParams(location.search).get("phone");
  let packageName = new URLSearchParams(location.search).get("packageName");

  const CONFIRM_PATH = `${DEV_FONT_API}/home-comfirm-payment-success?id=${id}`

  const [text, setText] = useState(
    `$refNo:${bankTansactionId}-${amount}-${qrType}-at:${moment(createdAt).format('DD-MM-YYYY')}`
  );

  const gotoHome = () => {
    navigate(`/home-add-creditcard`);
  };

  // const goBack = () => {
  //     navigate(-1)
  // }

  return (
    <div className="wrapper" style={{ backgroundColor: BACKGROUND_COLOR_APP }}>
      <CustomAppBar
        gotoOtherPage={gotoHome}
        title={"LOCA PAY"}
      />

      <div>
        <Watermark
          text={text}
          rotate={-20}
          textSize={12}
          fontFamily={"Times New Roman"}
          multiline={true}
          opacity={0.4}
        >
          <div>
            {/* --- */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: 20,
                marginRight: 20,
                marginTop: 10,
              }}
            >
              <div>
                <div style={{ fontSize: 17 }}>
                  {/* Payment Successfully */}
                  {t("payment-success")}
                </div>
                <div style={{ marginTop: 4, color: "#4B4B4B" }}>
                  {moment(createdAt).format('DD-MM-YYYY HH:mm')}
                </div>

                <div style={{ display: "flex", flexDirection: "row", marginTop: 6 }}>
                  <div
                    style={{
                      fontSize: 16,
                      marginTop: 6,
                      color: "#999999",
                      // marginBottom: 7
                    }}
                  >
                    {/* Amount */}
                    {t("amount")}
                  </div>
                  <div style={{ width: 10 }} />
                  <div style={{ fontSize: 24, color: "#EB001B", fontWeight: "bold" }}>
                    LAK {numberWithCommas(amount)}
                  </div>
                </div>

                <div style={{ display: "flex", flexDirection: "row", marginTop: 6 }}>
                  <div
                    style={{
                      fontSize: 16,
                      color: "#999999",
                    }}
                  >
                    {/* Amount */}
                    {t("payment-fee")}
                  </div>
                  <div style={{ width: 10 }} />
                  <div style={{ color: "#EB001B", fontSize: 16 }}>
                    LAK {numberWithCommas(totalFees)}
                  </div>
                </div>
              </div>
              <div style={{ paddingRight: 10 }}>
                {" "}
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="#139A18"
                  fontSize={70}
                />
              </div>
            </div>

            {/* <div
              style={{
                marginTop: 22,
                marginBottom: 10,
                marginLeft: 24,
                fontSize: 12,
                color: "#898989",
                fontWeight: "bold",
              }}
            >
              {moment(createdAt).format("DD-MM-YYYY H:mm")}
            </div> */}

            <div style={{ padding: 20, paddingTop: 8 }}>

              <div
                style={{
                  fontSize: 16,

                  color: "#999999",
                }}
              >
                {/* To */}
                {t("payment-to")}
              </div>

              <div
                style={{
                  display: "flex",
                  // height: 60,
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: 6,
                  padding: 8,
                  border: "1px solid #b6b6b6"
                }}
              >
                <div>
                  <div style={{ fontSize: 16, fontWeight: "bold" }}>DataPackage {numberWithCommas(parseFloat(packageName))} MB {qrType}</div>
                  <div style={{ display: "flex"}}>
                    <div
                      style={{
                        fontSize: 14,
                        fontWeight:"bold"
                      }}
                    >
                      {t("phone-number")}
                    </div>
                    <div style={{ width: 10 }} />
                    <div style={{ fontSize: 14, fontWeight: 'bold' }}>
                      +856 20 {phone}
                    </div>
                  </div>
                </div>

                <div>
                  <img
                    style={{
                      width: 50,
                      height: 50
                    }}
                    src={qrType == "LTC" ? "/assets/images/ltc_logo.jpeg" :
                      qrType == "UNITEL" ? "/assets/images/unitel_logo.jpeg" :
                        qrType == "ETL" ? "/assets/images/etl_logo.png" :
                          "/assets/images/box-loading.jpg"}
                  />
                </div>
              </div>

              <div
                style={{
                  fontSize: 16,
                  color: "#999999",
                  marginTop: 11,
                }}
              >
                {/* From */}
                {t("payment-from")}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 6,
                  padding: 10,
                  border: "1px solid #b6b6b6"
                }}
              >
                <div>
                  <div style={{ fontSize: 16, fontWeight: "bold" }}>{cardHolderName}</div>
                  <div style={{ fontSize: 14, fontWeight: "bold" }}>
                    ************{cardLast4digit}
                  </div>
                  <div
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                      color: "#898989",
                    }}
                  >
                    {t("card-expire")} {expireMonth}/
                    {expireYear}
                  </div>
                </div>
                <img
                  style={{
                    height: brand == "discover" || brand == "diners" ? 20 :
                      brand === "mastercard" ? 40 :
                        brand === "visa" ? 20 :
                          50,
                  }}
                  src={
                    brand == "visa"
                      ? "/assets/images/visa_icon.png"
                      : brand == "jcb"
                        ? "/assets/images/jcb.png"
                        : brand == "discover" || brand == "diners"
                          ? "/assets/images/discover_diner.png"
                          : brand == "unionpay"
                            ? "/assets/images/union_pay.png"
                            : brand == "amex"
                              ? "/assets/images/amex.png"
                              : "/assets/images/mastercards.png"
                  }
                />
              </div>


              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "start" }}>
                <div>
                  <div>
                    <div
                      style={{
                        fontSize: 16,
                        marginTop: 11,
                        color: "#999999"
                      }}
                    >
                      {t("payment-des")}
                    </div>
                    <div>{qrType}</div>
                  </div>
                  <div
                    style={{
                      fontSize: 16,
                      marginTop: 11,
                      color: "#999999"
                    }}
                  >
                    {t("payment-bill")}
                  </div>
                  <div>{bankTansactionId}</div>
                </div>

                <div style={{ marginTop: 18 }}>
                  <div
                    className="qr-background"
                  >
                    <div style={{ display: "flex", justifyContent: "center", marginBottom: 6 }}>
                      <img src="assets/images/logo-locapay.png" alt="" width={50} />
                    </div>
                    <div style={{ padding: 6, backgroundColor: "white", borderRadius: 3 }}>
                      <QRCode value={CONFIRM_PATH} size={80} />
                    </div>
                  </div>
                </div>
              </div>
            </div>


            {/* <div
              className="box-shadow-sm"
              style={{
                padding: 10,
                marginLeft: 20,
                marginRight: 20,
                height: "50vh",
                borderRadius: 6,
                paddingBottom: 40,
              }}
            >
               <div
                style={{
                  color: "#898989",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                {t("payment-to")} :
              </div>
              <div
                style={{
                  paddingLeft: 10,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <div style={{ fontSize: 14, fontWeight: "bold" }}>
                    {destMerchantName}
                  </div>
                </div>
                <img
                  style={{
                    width: 60,
                    height: 60,
                    borderRadius: 50,
                  }}
                  src={
                    bankLogoUrl != "null"
                      ? bankLogoUrl
                      : "/assets/images/box-loading.jpg"
                  }
                />
              </div> */}
            {/* ---MasterCard  */}
            {/* <div
                style={{
                  color: "#898989",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                {t("payment-from")} :
              </div>
              <div
                style={{
                  paddingLeft: 10,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  {userData ? (
                    <div style={{ fontSize: 14, fontWeight: "bold" }}>
                      {userData.user.firstName} {userData.user.lastName}
                    </div>
                  ) : (
                    <div />
                  )}
                  <div style={{ color: "#898989", fontSize: 12 }}>
                    **** **** **** {cardId}
                  </div>
                  <div
                    style={{
                      color: "#898989",
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                  >
                    {t("card-expire")} {expireMonth}/{expireYear}
                  </div>
                </div>
                <div>
                  {" "}
                  <img
                    style={{
                      height: brand == "discover" || brand == "diners" ? 25 :
                        brand == "jcb" || brand == "unionpay" ? 45 :
                          60,
                    }}
                    src={
                      brand == "mastercard"
                        ? "/assets/images/MasterCard.png" :
                        brand == "jcb" ? "/assets/images/jcb.png" :
                          brand == "discover" || brand == "diners" ? "/assets/images/discover_diner.png" :
                            brand == "unionpay" ? "/assets/images/union_pay.png" :
                              brand == "amex" ? "/assets/images/amex.png"
                                : "/assets/images/VisaCard.png"
                    }
                    alt=""
                  />
                </div>
              </div> */}

            {/* <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", }}>
                <div>
                  <div
                    style={{
                      marginTop: 10,
                      color: "#898989",
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    {t("payment-bill")}
                  </div>
                  <div style={{ paddingLeft: 10 }}>{refNo}</div>

                  <div
                    style={{
                      marginTop: 10,
                      color: "#898989",
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    {t("payment-des")}
                  </div>
                  <div style={{ paddingLeft: 10 }}>{description}</div>
                </div>

                <div >
                  <div
                    className="qr-background"
                  >
                    <div style={{ display: "flex", justifyContent: "center", marginBottom: 6 }}>
                      <img src="assets/images/logo-locapay.png" alt="" width={50} />
                    </div>
                    <div style={{ padding: 6, backgroundColor: "white", borderRadius: 3 }}>
                      <QRCode value={refNo} size={80} />
                    </div>
                  </div>
                </div>
              </div> 

            </div>*/}
            <div style={{
              textAlign: "center",
              marginTop: 12,
              fontWeight: "bold",
              fontSize: 18,
              color: "#8C8C8C"
            }}>P O W E R E D <span style={{ marginRight: 6 }} />B Y
              <span style={{ color: COLOR_APP, marginLeft: 12 }}>L O C A
                <span style={{ marginLeft: 6 }} />P A Y</span></div>

            <div style={{ height: 60 }} />
            <div style={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              width: "100%"
            }}>
              <Button
                style={{
                  height: 50,
                  backgroundColor: COLOR_APP,
                  textAlign: "center",
                  border: "none",
                  width: "100%",
                  fontWeight: 700,
                  fontSize: 16
                }}
                onClick={() => gotoHome()}
              >
                {t("success")}
              </Button>
            </div>
            {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "fixed",
                bottom: 20,
                width: "100%",
              }}
            >
              <Button
                style={{
                  height: 50,
                  display: "flex",
                  width: "90%",
                  borderRadius: "none",
                  backgroundColor: "#00ADFF",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                }}
                onClick={() => gotoHome()}
              >
                {t("success")}
              </Button>
            </div> */}

            <div style={{ height: 20 }} />
          </div>
        </Watermark>
      </div>
    </div>
  )
}

export default BuyDataPackageSuccess

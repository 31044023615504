import React, { useEffect, useState, useRef } from "react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { showGender } from "../../components/ShowFormat";
import CustomAppBar from "../../components/CustomAppBar";
import { BACKGROUND_COLOR_APP } from "../../constants";

function HomeProfileInfo() {
  //variables
  const history = useNavigate();
  const location = useLocation();
  const firstName = new URLSearchParams(location.search).get("firstName");
  const lastName = new URLSearchParams(location.search).get("lastName");
  const gender = new URLSearchParams(location.search).get("gender");
  const dobDate = new URLSearchParams(location.search).get("dobDate");
  const tel = new URLSearchParams(location.search).get("tel");
  const passportNumber = new URLSearchParams(location.search).get(
    "passportNumber"
  );
  const issueDate = new URLSearchParams(location.search).get("issueDate");
  const expireDate = new URLSearchParams(location.search).get("expireDate");
  const registrationDate = new URLSearchParams(location.search).get(
    "registrationDate"
  );

  const { t, i18n } = useTranslation();
  const [canScroll, setCanScroll] = useState(false);
  const { innerWidth: width, innerHeight: height } = window;
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current.clientHeight < height) setCanScroll(true);
    else setCanScroll(false);
  }, []);

  useEffect(() => {}, [expireDate]);

  const _goBackToHome = () => {
    history(-1);
  };

  return (
    <div className="wrapper" style={{backgroundColor: BACKGROUND_COLOR_APP}}>
      {/* <div className="kyc-signup-header">
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={() => _goBackToHome()}
          style={{ fontSize: "18", marginRight: 10 }}
        />
        {t("profile")}
      </div> */}

      <CustomAppBar 
        gotoOtherPage={_goBackToHome}
        title={t("profile")}
      />
      <div
        style={
          canScroll
            ? { position: "fixed", overflowY: "scroll", width: "100%" }
            : {}
        }
        ref={ref}
      >
        <div
          style={{
            borderBottom: "1px solid Lightgrey",
            marginTop: 12,
          }}
        >
          <div
            style={{
              height: 30,
              marginLeft: 26,
              fontSize: "14",
              color: "#697D95",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <div style={{ marginTop: 8, fontSize: "14", color: "#697D95" }}>
              {/* First name */}
              {t("login-firstname")}
            </div>
            <div
              style={{
                marginRight: 25,
                marginTop: 8,
                fontSize: "14",
                color: "#101010",
              }}
            >
              {firstName ? firstName : ""}
            </div>
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px solid Lightgrey",
            marginTop: 12,
          }}
        >
          <div
            style={{
              height: 30,
              marginLeft: 26,
              fontSize: "14",
              color: "#697D95",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <div style={{ marginTop: 8, fontSize: "14", color: "#697D95" }}>
              {/* Last name */}
              {t("login-lastname")}
            </div>
            <div
              style={{
                marginRight: 25,
                marginTop: 8,
                fontSize: "14",
                color: "#101010",
              }}
            >
              {lastName ? lastName : ""}
            </div>
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px solid Lightgrey",
            marginTop: 12,
          }}
        >
          <div
            style={{
              // height: 30,
              marginLeft: 26,
              fontSize: "14",
              color: "#697D95",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <div style={{ marginTop: 8, fontSize: "14", color: "#697D95" }}>
              {/* Gender */}
              {t("login-gender")}
            </div>
            <div
              style={{
                marginRight: 25,
                marginTop: 8,
                fontSize: "14",
                color: "#101010",
              }}
            >
              {showGender(gender)}
            </div>
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px solid Lightgrey",
            marginTop: 12,
          }}
        >
          <div
            style={{
              // height: 30,
              marginLeft: 26,
              fontSize: "14",
              color: "#697D95",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <div style={{ marginTop: 8, fontSize: "14", color: "#697D95" }}>
              {/* Date of Birth */}
              {t("login-dob")}
            </div>
            <div
              style={{
                marginRight: 25,
                marginTop: 8,
                fontSize: "14",
                color: "#101010",
              }}
            >
              {dobDate == "Invalid date"
                ? "-"
                : moment(dobDate).format("DD/MM/YYYY")}
            </div>
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px solid Lightgrey",
            marginTop: 12,
          }}
        >
          <div
            style={{
              // height: 30,
              marginLeft: 26,
              fontSize: "14",
              color: "#697D95",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <div style={{ marginTop: 8, fontSize: "14", color: "#697D95" }}>
              {/* Tel */}
              {t("login-tel")}
            </div>
            <div
              style={{
                marginRight: 25,
                marginTop: 8,
                fontSize: "14",
                color: "#101010",
              }}
            >
              {tel}
            </div>
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px solid Lightgrey",
            marginTop: 12,
          }}
        >
          <div
            style={{
              // height: 30,
              marginLeft: 26,
              fontSize: "14",
              color: "#697D95",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <div style={{ marginTop: 8, fontSize: "14", color: "#697D95" }}>
              {/* Passport Number */}
              {t("login-passport")}
            </div>
            <div
              style={{
                marginRight: 25,
                marginTop: 8,
                fontSize: "14",
                color: "#101010",
              }}
            >
              {passportNumber != "null" ? passportNumber : "-"}
            </div>
          </div>
        </div>

        <div
          style={{
            borderBottom: "1px solid Lightgrey",
            marginTop: 12,
          }}
        >
          <div
            style={{
              // height: 30,
              marginLeft: 26,
              fontSize: "14",
              color: "#697D95",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <div style={{ marginTop: 8, fontSize: "14", color: "#697D95" }}>
              {/* Issue Date */}
              {t("login-issue")}
            </div>
            <div
              style={{
                marginRight: 25,
                marginTop: 8,
                fontSize: "14",
                color: "#101010",
              }}
            >
              {issueDate != "Invalid date"
                ? moment(issueDate).format("DD/MM/YYYY")
                : "-"}
            </div>
          </div>
        </div>

        <div
          style={{
            borderBottom: "1px solid Lightgrey",
            marginTop: 12,
          }}
        >
          <div
            style={{
              // height: 30,
              marginLeft: 26,
              fontSize: "14",
              color: "#697D95",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <div style={{ marginTop: 8, fontSize: "14", color: "#697D95" }}>
              {/* Expire Date */}
              {t("login-expire")}
            </div>
            <div
              style={{
                marginRight: 25,
                marginTop: 8,
                fontSize: "14",
                color: "#101010",
              }}
            >
              {expireDate != "Invalid date"
                ? moment(expireDate).format("DD/MM/YYYY")
                : "-"}
            </div>
          </div>
        </div>

        <div
          style={{
            borderBottom: "1px solid Lightgrey",
            marginTop: 12,
          }}
        >
          <div
            style={{
              // height: 30,
              marginLeft: 26,
              fontSize: "14",
              color: "#697D95",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <div style={{ marginTop: 8, fontSize: "14", color: "#697D95" }}>
              {/* Registration Date */}
              {t("login-regis")}
            </div>
            <div
              style={{
                marginRight: 25,
                marginTop: 8,
                fontSize: "14",
                color: "#101010",
              }}
            >
              {registrationDate != "Invalid date"
                ? moment(registrationDate).format("DD/MM/YYYY")
                : "-"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeProfileInfo;

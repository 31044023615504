import { faArrowLeft, faEnvelopeCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { MdEmail } from 'react-icons/md'
import { FaSms } from 'react-icons/fa'
import {
    BACKGROUND_COLOR_APP,
    COLOR_APP,
    COUNTRY_CODE,
    EMAIL,
    PHONE,
    USER_ID
} from '../../../constants'
import { useMutation } from '@apollo/client'
import { SEND_EMAIL_RESET_PIN, SEND_SMS_RESET_PIN } from '../../graphql/mutation'
import Loading from '../../../components/Loading'
import CustomAppBar from '../../../components/CustomAppBar'

function ForgotPin() {

    const history = useNavigate()
    const { t, i18n } = useTranslation();
    const [showReceive, setShowReceive] = useState(false)
    const userId = localStorage.getItem(USER_ID)
    const phone = localStorage.getItem(PHONE)
    const maskedPhoneNumber = maskPhoneNumber(phone);
    const countryCode = localStorage.getItem(COUNTRY_CODE)
    const email = localStorage.getItem(EMAIL)
    const maskedEmail = maskEmail(email);
    const [loading, setLoading] = useState(false)

    const [sendEmailResetPin] = useMutation(SEND_EMAIL_RESET_PIN)
    const [sendSMSResetPin] = useMutation(SEND_SMS_RESET_PIN)

    const sendEmail = async () => {
        try {
            setLoading(true)
            const _res = await sendEmailResetPin({
                variables: {
                    email: email,
                }
            });
            if (_res) {
                setLoading(false)
                setShowReceive(true)
            }
        } catch (error) {
            // console.log("error: ", error)
            setLoading(false)
        }
    }

    const sendSMSReset = async () => {
        try {
            setLoading(true)
            const _res = await sendSMSResetPin({
                variables: {
                    phone: phone,
                }
            });
            if (_res) {
                setLoading(false)
                setShowReceive(true)
            }
        } catch (error) {
            // console.log("error: ", error)
            setLoading(false)
        }
    }

    function maskPhoneNumber(phoneNumber) {
        const firstDigits = phoneNumber.substring(0, 4);
        const lastDigits = phoneNumber.substring(8);
        const middleDigits = phoneNumber.substring(3, 7).replace(/\d/g, '*');
        return `${firstDigits}${middleDigits}${lastDigits}`;
    }

    function maskEmail(email) {
        const splitEmail = email.split('@')
        const hidden_string = splitEmail[0].substring(0, splitEmail[0].length - 4) + "****".slice(-4);
        return hidden_string + '@gmail.com';
    }



    //Routes
    const _goBack = () => {
        history(-1)
    }

    const gotoProfile = () => {
        history(`/home-profile?userId=${userId}`)
    }

    if (loading) {
        return (
            <Loading />
        )
    }

    return (
        <div className='wrapper' style={{backgroundColor: BACKGROUND_COLOR_APP}}>
            {/* <div className="home-header">
                <FontAwesomeIcon
                    style={{
                        marginLeft: 10,
                        fontSize: "18",
                        fontWeight: "bold",
                    }}
                    icon={faArrowLeft}
                    onClick={() => _goBack()}
                />
            </div> */}

            <CustomAppBar
                gotoOtherPage={_goBack}
                title={"LOCA PAY"}
            />

            <div style={{ height: 18 }} />
            <div style={{ color: "#4A607B", fontSize: 17, fontWeight: "bold", marginLeft: 24 }}>{t("select-reset-pin")}</div>
            <div style={{ height: 32 }} />
            <center>
                {/* {countryCode === "+856" ? <Button */}
                {phone.length === 10 && phone[0] === "2" && phone[1] === "0" ? <Button
                    style={{
                        color: "#252A31",
                        backgroundColor: "white",
                        border: "none",
                        boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.15)",
                        height: 70,
                        borderRadius: 8,
                        width: "85%",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 16
                    }}
                    onClick={() => sendSMSReset()}
                >
                    <FaSms fontSize={40} color={COLOR_APP} />
                    <div style={{ textAlign: "left", marginLeft: 28 }}>
                        <div style={{ fontSize: 20, color: "#4A607B", fontWeight: "bold" }}>SMS</div>
                        {/* <div style={{ color: "#697D95" }}>+8562098****20</div> */}
                        <div style={{ color: "#697D95" }}>
                            {countryCode != "null" ? countryCode : "+856"}
                            {maskedPhoneNumber}
                        </div>
                    </div>
                </Button> : <div />}
                <Button
                    style={{
                        color: "#252A31",
                        backgroundColor: "white",
                        border: "none",
                        boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.15)",
                        height: 70,
                        borderRadius: 8,
                        width: "85%",
                        display: "flex",
                        alignItems: "center",
                    }}
                    onClick={() => sendEmail()}
                >
                    <MdEmail fontSize={40} color={COLOR_APP} />
                    <div style={{ textAlign: "left", marginLeft: 28 }}>
                        <div style={{ fontSize: 20, color: "#4A607B", fontWeight: "bold" }}>Email</div>
                        {/* <div style={{ color: "#697D95" }}>phetji****@gmail.com</div> */}
                        <div style={{ color: "#697D95" }}>{maskedEmail}</div>
                    </div>
                </Button>
            </center>

            <Modal
                show={showReceive}
                size="sm"
                centered
            >
                <Modal.Body style={{ textAlign: "center" }}>
                    <FontAwesomeIcon icon={faEnvelopeCircleCheck} fontSize={80} color={COLOR_APP} />
                    <div style={{ height: 39 }} />
                    <div style={{ color: "#4A607B", fontWeight: "bold" }}>
                        {/* You will receive the reset link soon */}
                        {t("receive-link-soon")}
                    </div>
                    <div style={{ marginTop: 8, color: "#697D95" }}>
                        {t("link-reset-pin")} <br />
                        {t("will-be-expired")}
                    </div>
                    <div style={{ height: 32 }} />
                    <div style={{ float: "right" }}>
                        <Button style={{
                            width: 114,
                            height: 44,
                            color: COLOR_APP,
                            backgroundColor: "#F2F2F2",
                            border: "none",
                            borderRadius: 6
                        }}
                            onClick={() => { setShowReceive(false); gotoProfile() }}
                        >{t("got-it")}</Button>
                    </div>
                    <div style={{ height: 10 }} />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ForgotPin

import {
  faArrowLeft,
  faDeleteLeft,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { BsCircle, BsCircleFill } from "react-icons/bs";
import { useNavigate, useLocation } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { CLICK_COLOR, COLOR_APP, USER_ID } from "../../constants";
import { GET_USER } from "./graphql/query";
import { useTranslation } from "react-i18next";

function HomePinSeeCard() {
  const history = useNavigate();
  const [count, setCount] = useState(0);
  const [wrong, setWrong] = useState(false);
  const [pinCode, setPinCode] = useState("");
  const { t, i18n } = useTranslation();
  const [getUser, { data: userQueryData }] = useLazyQuery(GET_USER);

  const location = useLocation();
  let id = new URLSearchParams(location.search).get("id");
  let cardId = new URLSearchParams(location.search).get("cardId");
  let cardName = new URLSearchParams(location.search).get("cardName");
  let expireMonth = new URLSearchParams(location.search).get("expireMonth");
  let expireYear = new URLSearchParams(location.search).get("expireYear");
  let cvc = new URLSearchParams(location.search).get("cvc");
  let brand = new URLSearchParams(location.search).get("brand");
  let firstName = new URLSearchParams(location.search).get("firstName");
  let lastName = new URLSearchParams(location.search).get("lastName");
  const userId = localStorage.getItem(USER_ID);
  const [buttonClick, setButtonClick] = useState({
    button0: false,
    button1: false,
    button2: false,
    button3: false,
    button4: false,
    button5: false,
    button6: false,
    button7: false,
    button8: false,
    button9: false,
    button10: false,
  });

  useEffect(() => {
    getUser({
      variables: {
        where: {
          id: userId,
        },
      },
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setButtonClick({ button0: false });
    }, 100);
    if (count == 4) {
      setTimeout(() => {
        if (pinCode == userQueryData.user.pinCode) {
          history(
            `/home-account-carddetails?id=${id}&cardId=${cardId}&cardName=${cardName}&expireMonth=${expireMonth}&expireYear=${expireYear}&cvc=${cvc}&firstName=${firstName}&lastName=${lastName}&visible=true&brand=${brand}`
          );
        } else {
          setWrong(true);
          setCount(0);
          setPinCode("");
        }
      }, 200);
    }
  }, [count]);

  //routes
  const _goBackCreditDetail = () => {
    history(-1);
  };

  return (
    <div className="wrapper">
      <div className="home-header">
        <FontAwesomeIcon
          style={{
            marginLeft: 10,
            fontSize: "18",
            fontWeight: "bold",
          }}
          icon={faArrowLeft}
          onClick={() => _goBackCreditDetail()}
        />
      </div>

      <div style={{ height: 72 }} />
      <div style={{ fontSize: 17, fontWeight: "bold", textAlign: "center" }}>
        {/* Please input PIN */}
        {t("setting-please")}
      </div>

      <div style={{ height: 20 }} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <FontAwesomeIcon icon={faLock} color={COLOR_APP} fontSize={40} />
      </div>
      <div style={{ height: 40 }} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        {count >= 1 ? (
          <BsCircleFill
            fontSize={14}
            style={{ marginRight: 20 }}
            color={COLOR_APP}
          />
        ) : (
          <BsCircle
            fontSize={14}
            style={{ marginRight: 20 }}
            color={COLOR_APP}
          />
        )}
        {count >= 2 ? (
          <BsCircleFill
            fontSize={14}
            style={{ marginRight: 20 }}
            color={COLOR_APP}
          />
        ) : (
          <BsCircle
            fontSize={14}
            style={{ marginRight: 20 }}
            color={COLOR_APP}
          />
        )}
        {count >= 3 ? (
          <BsCircleFill
            fontSize={14}
            style={{ marginRight: 20 }}
            color={COLOR_APP}
          />
        ) : (
          <BsCircle
            fontSize={14}
            style={{ marginRight: 20 }}
            color={COLOR_APP}
          />
        )}
        {count >= 4 ? (
          <BsCircleFill fontSize={14} color={COLOR_APP} />
        ) : (
          <BsCircle fontSize={14} color={COLOR_APP} />
        )}
      </div>
      {wrong ? (
        <div style={{ textAlign: "center", color: "red" }}>
          {t("setting-not-correct")}
        </div>
      ) : (
        <div />
      )}

      <div style={{ height: 50 }} />
      <div style={{ marginBottom: 20 }}>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button0 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button0: true });
              }
              setPinCode(pinCode + "1");
            }}
          >
            1
          </div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button1 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button1: true });
              }
              setPinCode(pinCode + "2");
            }}
          >
            2
          </div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button2 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button2: true });
              }
              setPinCode(pinCode + "3");
            }}
          >
            3
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: 11,
          }}
        >
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button3 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button3: true });
              }
              setPinCode(pinCode + "4");
            }}
          >
            4
          </div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button4 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button4: true });
              }
              setPinCode(pinCode + "5");
            }}
          >
            5
          </div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button5 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button5: true });
              }
              setPinCode(pinCode + "6");
            }}
          >
            6
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: 11,
          }}
        >
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button6 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button6: true });
              }
              setPinCode(pinCode + "7");
            }}
          >
            7
          </div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button7 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button7: true });
              }
              setPinCode(pinCode + "8");
            }}
          >
            8
          </div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button8 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button8: true });
              }
              setPinCode(pinCode + "9");
            }}
          >
            9
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: 11,
          }}
        >
          <div style={{ height: 88, width: 88 }}></div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button9 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count <= 4) {
                setCount(count + 1);
                setButtonClick({ button9: true });
              }
              setPinCode(pinCode + "0");
            }}
          >
            0
          </div>
          <div
            className="pin-button"
            style={{ backgroundColor: buttonClick.button10 ? CLICK_COLOR : "" }}
            onClick={() => {
              if (count > 0) {
                setCount(count - 1);
                setButtonClick({ button10: true });
              }
              const newCode = pinCode.slice(0, -1);
              setPinCode(newCode);
            }}
          >
            <FontAwesomeIcon icon={faDeleteLeft} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePinSeeCard;

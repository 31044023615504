import React, { useState, useEffect } from "react";
import { AiOutlineRight } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { GET_CARDS } from "../graphql/query";
import { USER_ID } from "../../constants/index";
import { useTranslation } from "react-i18next";

const SelectPayments = () => {
  const history = useNavigate();
  const location = useLocation();
  let code = new URLSearchParams(location.search).get("code");
  const userId = localStorage.getItem(USER_ID);
  const [cardData, setCardData] = useState();
  const { t, i18n } = useTranslation();

  const [
    getCard,
    { data: cardQueryData },
  ] = useLazyQuery(GET_CARDS);

  useEffect(() => {
    getCard({
      variables: {
        where: {
          createdBy: userId,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (cardQueryData) {
      setCardData(cardQueryData.cards.data);
    }
  }, [cardQueryData]);

  //routes
  const _gotoConfirmToPay = (card) => {
    history(
      `/homeqr-payment-confirmtopay?id=${card.id}&cardId=${card.cardId}&cardName=${card.cardName}&expireMonth=${card.expireMonth}&expireYear=${card.expireYear}&code=${code}&brand=${card.brand}`
    );
  };

  return (
    <div className="wrapper">
      <div
        style={{
          display: "flex",
          height: 49,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <FontAwesomeIcon
          style={{
            marginLeft: 18,
            height: 20,
            width: 16,
          }}
          icon={faArrowLeft}
          onClick={() => history(-1)}
        />
        <div />
      </div>

      <div
        style={{
          padding: "1.3rem",
        }}
      >
        <p
          style={{
            fontSize: "17px",
            fontWeight: 700,
          }}
        >
          {/* Select your card */}
          {t("card-select")}
        </p>

        <div
          style={{
            width: "330px",
            height: "260px",
          }}
        >
          <p
            style={{
              padding: "20px",
              fontSize: "12px",
              fontWeight: 700,
            }}
          >
            {/* Credit Cards */}
            {t("credit-card")}
          </p>

          {cardData ? (
            cardData.map((card, index) => {
              return (
                <div key={index}>
                  <div
                    style={{
                      display: "flex",
                      background: "#F9F9F9",
                      width: "100%",
                      height: "66px",
                    }}
                    onClick={() => _gotoConfirmToPay(card)}
                  >
                    <div
                      style={{
                        flex: 0.5,
                      }}
                    >
                      <img
                        src={
                          card.brand == "visa"
                            ? "/assets/images/VisaCard.png"
                            : "/assets/images/MasterCard.png"
                        }
                        alt="paypal"
                        style={{
                          height: 70,
                          paddingLeft: "6px",
                        }}
                      />
                    </div>

                    <div
                      style={{
                        flex: 2,
                        paddingLeft: "23px",
                      }}
                    >
                      <p
                        style={{
                          paddingTop: "10px",
                          fontSize: "12px",
                          fontWeight: 700,
                        }}
                      >
                        {/* **** **** **** 5554 */}
                        **** **** **** {card.cardId}
                      </p>
                      <p
                        style={{
                          paddingBottom: "3rem",
                          fontSize: "10px",
                          opacity: 0.7,
                        }}
                      >
                        {t("card-expire")} {card.expireMonth} /{" "}
                        {card.expireYear}
                      </p>
                    </div>

                    <div
                      style={{
                        flex: 0.2,
                        paddingTop: "20px",
                      }}
                    >
                      <AiOutlineRight size={25} />
                    </div>
                  </div>

                  <div style={{ height: 10 }} />
                </div>
              );
            })
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectPayments;

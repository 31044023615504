import { gql } from '@apollo/client';

export const GET_PACKAGE_DATA = gql`
query Query($where: WhereListDataPackageInput) {
  listDataPackages(where: $where) {
    total
    data {
      bestNMCode
      bestNSCode
      createdAt
      description
      etlNMAmount
      etlNMCode
      etlNMDays
      etlNSAmount
      etlNSCode
      etlNSDays
      id
      locaPrice
      ltcNMAmount
      ltcNMCode
      ltcNMDays
      ltcNSAmount
      ltcNSCode
      ltcNSDays
      name
      price
      tPlusNMCode
      tPlusNSCode
      tplusNMAmount
      tplusNMDays
      tplusNSAmount
      tplusNSDays
      type
      unitelNMAmount
      unitelNMCode
      unitelNMDays
      unitelNSAmount
      unitelNSCode
      unitelNSDays
      updatedAt
    }
  }
}
`;


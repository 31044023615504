import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, Form, InputGroup } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Home.css";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useLazyQuery, useMutation } from "@apollo/client";
import swal from "sweetalert";
import { BACKGROUND_COLOR_APP, HEADER_HEIGHT } from "../../constants";

import {
  GET_ALL_LIMIT_PAYMENT,
  GET_CARDS,
  GET_LIMIT_AMOUNT,
  GET_POPUPS,
  GET_USER,
} from "./graphql/query";
import { RiErrorWarningFill } from "react-icons/ri";
import { GrClose } from "react-icons/gr";
import NumberFormat from "react-number-format";
import { COLOR_APP, POPUP_COLOR, USER_ID } from "../../constants";
import "./HomeAddNewAccount.css";
import CreditCardInfo from "../../components/CreditCardInfo";
import { numberWithCommas } from "../../constants/ShowFormat";
import { CREATE_SCAN_LOGGING, CREATE_UPPASS_FORM, JDB_GET_INFO_QR, SHOW_MMONEY_QR_INFO } from "./graphql/mutation";
import Loading from "../../components/Loading";
import CustomAppBar from "../../components/CustomAppBar";

const MAX_DESCRIPTION = 128;

function HomeQrPaymentConfirmToPay() {
  const history = useNavigate();
  const location = useLocation();
  const { innerWidth: width, innerHeight: height } = window;

  // pass code though state to prevent url encode
  let code = location.state.code

  let jdbTransactionId = new URLSearchParams(location.search).get(
    "jdbTransactionId"
  );
  let jdbAuthToken = new URLSearchParams(location.search).get("jdbAuthToken");
  let mmoneyToken = new URLSearchParams(location.search).get("mmoneyToken");
  let lat = new URLSearchParams(location.search).get("lat");
  let lng = new URLSearchParams(location.search).get("lng");

  const { t, i18n } = useTranslation();
  const userId = localStorage.getItem(USER_ID);
  const [showKyc, setShowKyc] = useState(false);

  const [price, setPrice] = useState();
  const [description, setDescription] = useState("");
  const [disableDescriptionEdit, setDisableDescriptionEdit] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  const [showDes, setShowDes] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showLess, setShowLess] = useState(false);
  const [showLimit, setShowLimit] = useState(false);
  const [limitColor, setLimitColor] = useState(false);
  const [changeCard, setChangeCard] = useState(false);
  const [cardData, setCardData] = useState();
  const [defaultCardData, setDefaultCardData] = useState();

  // JDB Info Qr Detail
  const [destBankCode, setDestBankCode] = useState("");
  const [destBankId, setDestBankId] = useState("");
  const [destCurrency, setDestCurrency] = useState("");
  const [destMerchantId, setDestMerchantId] = useState("");
  const [destMerchantName, setDestMerchantName] = useState("");
  const [exReferenceNo, setExReferenceNo] = useState("");
  const [paymentTypeId, setPaymentTypeId] = useState("");
  const [qrType, setQrType] = useState("");
  const [remark, setRemark] = useState("");
  const [txnAmount, setTxnAmount] = useState(0);
  const [bankName, setBankName] = useState("");
  const [bankLogoUrl, setBankLogoUrl] = useState("");
  const [billNumber, setBillNumber] = useState("")
  const [feeList, setFeeList] = useState([])
  const [limitPayment, setLimitPayment] = useState();
  const [createUpPassForm] = useMutation(CREATE_UPPASS_FORM);
  const [isLoading, setIsLoading] = useState(false);
  const [showQrError, setShowQrError] = useState(false)
  const [showQrBlockError, setShowQrBlockError] = useState(false)

  //mmoney 

  const [mmoneyKey, setMMoneyKey] = useState('');
  const [merchantMobile, setMerchantMobile] = useState('');
  const [responseCode, setResponseCode] = useState('')
  const [responseMessage, setResponseMessage] = useState('');
  const [shopCategory, setShopCategory] = useState('');
  const [bankTansactionId, setBankTansactionId] = useState('');



  const [getUser, { data: userQueryData }] = useLazyQuery(
    GET_USER
  );

  const [getLimitAmount, { data: limitAmountData }] = useLazyQuery(
    GET_LIMIT_AMOUNT
  );
  const [getLimintPayment, { data: limitPaymentData }] = useLazyQuery(GET_ALL_LIMIT_PAYMENT);

  const [createScanLogging] = useMutation(CREATE_SCAN_LOGGING)
  const [getPopup, { data: popupQueryData }] = useLazyQuery(GET_POPUPS);
  const [getCard, { data: cardQueryData }] = useLazyQuery(GET_CARDS);
  const [jdbGetInfoQr] = useMutation(JDB_GET_INFO_QR);
  const [showMMoneyQrInfo] = useMutation(SHOW_MMONEY_QR_INFO);
  let called = false;

  useEffect(() => {
    // console.log('lat', lat, 'lon', lng);
    if (!called) {
      if (mmoneyToken) {
        mmoneyGetInfoQr();
      } else {
        jdbGetInfoQrReq();
        called = true;
      }
    }
  }, []);

  const mmoneyGetInfoQr = async () => {
    try {
      // const data = {
      //   code: code,
      //   accessToken: mmoneyToken
      // }

      const showMMoneyQrData = await showMMoneyQrInfo({
        variables: {
          mmoneyToken: mmoneyToken,
          qrCode: code
        }
      })

      const info = showMMoneyQrData.data.verifyMmoneyQr;
      const { key, merchantMobile, merchantName, bankLogoURL, qrType, responseCode, responseMessage, shopCategory, shopName, transAmount, bankTansactionId } = info;
      setMMoneyKey(key);
      setQrType(qrType);
      setMerchantMobile(merchantMobile);
      setResponseCode(responseCode);
      setResponseMessage(responseMessage);
      setShopCategory(shopCategory);
      setDestMerchantName(merchantName);
      setTxnAmount(transAmount);
      setBankName(shopName);
      setBankLogoUrl(bankLogoURL);
      setBankTansactionId(bankTansactionId);
      // setBillNumber(billNumber)

      if (transAmount != 0) {
        setPrice(transAmount);
      }

      // console.log('show my data :', info);
    } catch (error) {
      console.log(error);
    }
  }

  const QR_BLOCKED = "QR_BLOCKED";
  const jdbGetInfoQrReq = async () => {
    try {
      const jdbGetInfoQrRes = await jdbGetInfoQr({
        variables: {
          jdbToken: jdbAuthToken,
          requestId: jdbTransactionId,
          emvCode: code,
          lat: parseFloat(lat),
          long: parseFloat(lng)
        },
      });

      saveScanData(jdbGetInfoQrRes["data"]["jdbGetInfoQr"], jdbTransactionId, code)

      const jdbGetInfoQrResData = jdbGetInfoQrRes["data"];
      if (_.isEmpty(jdbGetInfoQrResData)) {
        // alert(t("error-gateway"));
        // swal(t("error-qr"), t("error-qr-detail"));
        // history(`/home-add-creditcard`);
        setShowQrError(true)
        return;
      }

      const success = jdbGetInfoQrResData["jdbGetInfoQr"]["success"];
      if (!success) {
        // alert(t("error-gateway"));
        // swal(t("error-qr"), t("error-qr-detail"));
        // history(`/home-add-creditcard`);
        setShowQrError(true)
        return;
      }

      /**
       * get jdb get info qr successfully
       */
      const jdbGetInfoQrResDataInside =
        jdbGetInfoQrResData["jdbGetInfoQr"]["data"][0];

      const {
        destBankId,
        destBankCode,
        destCurrency,
        destMerchantId,
        destMerchantName,
        exReferenceNo,
        paymentTypeId,
        qrType,
        remark,
        txnAmount,
        bankName,
        bankLogoUrl,
        billNumber,
        feeList
      } = jdbGetInfoQrResDataInside;
      // console.log('jdbBank ---------------------->',jdbGetInfoQrResDataInside);

      setDestBankId(destBankId)
      setDestBankCode(destBankCode);
      setDestCurrency(destCurrency);
      setDestMerchantId(destMerchantId);
      setDestMerchantName(destMerchantName);
      setExReferenceNo(exReferenceNo);
      setPaymentTypeId(paymentTypeId);
      setQrType(qrType);
      setRemark(remark);
      if (remark !== "0%") {
        setDescription(remark)
      }

      setFeeList(feeList)

      if (!_.isEmpty(remark) && remark !== "0%") {
        setDisableDescriptionEdit(true)
      }

      setTxnAmount(txnAmount);
      setBankName(bankName);
      setBankLogoUrl(bankLogoUrl);
      setBillNumber(billNumber)

      if (txnAmount != 0) {
        // setPrice(`LAK ${numberWithCommas(txnAmount)}`);
        setPrice(`${numberWithCommas(txnAmount)}`);
      }
    } catch (err) {
      console.log("===>>>err: ", err)
      let errMsg = err.message;
      if (errMsg === QR_BLOCKED) {
        setShowQrBlockError(true)
        return;
      }
      // alert(t("error-gateway"));
      // swal(t("error-qr"), t("error-qr-detail"));
      // history(`/home-add-creditcard`);
      setShowQrError(true)
      return;
    }
  };

  useEffect(() => {
    getUser({
      variables: {
        where: {
          id: userId,
        },
      },
    });

    // console.log(price);
    // console.log(txnAmount);

    getPopup();
    getCard();
    getLimitAmount();
    getLimintPayment();
  }, []);

  useEffect(() => {
    if (userQueryData) {
      if (userQueryData.user.userStatus == "NOT_REGISTER") {
        setShowKyc(true);
      }
    }
  }, [userQueryData]);

  useEffect(() => {
    if (cardQueryData && userQueryData) {
      setCardData(cardQueryData.cards.data);
      for (let index = 0; index < cardQueryData.cards.data.length; index++) {
        if (
          cardQueryData.cards.data[index].id == userQueryData.user.defaultCard
        ) {
          setDefaultCardData(cardQueryData.cards.data[index]);
        }
      }
    }
  }, [cardQueryData, userQueryData]);

  useEffect(() => {
    if (limitPaymentData) {
      setLimitPayment(limitPaymentData.limitPayments.data[0].maxAmount)
    }
  }, [limitPaymentData])

  const getUpPassUrl = async () => {
    try {
      setIsLoading(true);
      const url = await createUpPassForm({
        variables: {
          userId
        }
      });
      if (url) {
        // setIsLoading(false)
        const UpPassURL = url.data.createUpPassForm.url;
        window.location.href = UpPassURL;
      } else {
        setIsLoading(false)
      }
      // setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }


  const saveScanData = async (data, jdbTransactionId, code) => {
    try {
      let _status;
      if (data.success == true) {
        _status = "SUCCESS"
      } else {
        _status = "FAIL"
      }
      // TODO: get Merchant ID from deskMerchantID
      const _jsonData = JSON.stringify(data.data[0]);
      let newData = {
        data: _jsonData,
        lat: lat ? parseFloat(lat) : null,
        long: lng ? parseFloat(lng) : null,
        requestId: jdbTransactionId,
        emvCode: code,
        status: _status,
        merchantId: data.data[0].destMerchantId ? data.data[0].destMerchantId : null,
        merchantName: data.data[0].destMerchantName
      }
      await createScanLogging({
        variables: {
          data: newData
        }
      })
    } catch (error) {

    }
  }

  const _acceptPayment = async () => {
    // console.log(price)
    // console.log(!price)
    if (!price && !description) {
      setShowPrice(true);
      setShowDes(true);
    } else if (!price) {
      setShowPrice(true);
    } else if (!description) {
      setShowDes(true);
    } else {
      let priceStr = price;
      // var priceValue = parseFloat(priceStr[1].replace(/,/g, ""));
      var priceValue = parseFloat(priceStr.replace(/,/g, ""));
      if (priceValue < 15000) {
        setShowLess(true);
      } else {
        if (userQueryData.user.userStatus) {
          // if (parseInt(priceValue) > 5000000) {
          if (parseInt(priceValue) > parseInt(limitPayment)) {
            setShowLimit(true)
          } else {
            if (parseInt(userQueryData.user.limitAmount) + parseInt(priceValue) > limitAmountData.limitAmounts.data[0].maxAmount && userQueryData.user.userStatus != "VERIFIED") {
              setShowError(true);
            } else {
              // Get feeAmount
              feeList.sort((a, b) => a - b);
              let feeData
              for (var i = 0; i < feeList.length; i++) {
                if ((i + 1) < feeList.length) {
                  if (parseInt(priceValue) >= parseInt(feeList[i]["from"]) && parseInt(priceValue) < parseInt(feeList[i + 1]["from"])) {
                    feeData = feeList[i]
                  }
                } else {
                  if (parseInt(priceValue) >= parseInt(feeList[i]["from"])) {
                    feeData = feeList[i]
                  }
                }
              }

              let sum = parseInt(userQueryData.user.limitAmount) + parseInt(priceValue);
              localStorage.setItem("DESCRIPTION", description);
              // console.log("price ====>>>>>", price)
              history(
                `/homeqr-payment-detail?id=${defaultCardData.id}&cardId=${defaultCardData.cardId}&cardName=${defaultCardData.cardName}&expireMonth=${defaultCardData.expireMonth}&expireYear=${defaultCardData.expireYear}&price=${price}&code=${code}&brand=${defaultCardData.brand}&sum=${sum}&userId=${userId}` +
                `&jdbTransactionId=${jdbTransactionId}&jdbAuthToken=${jdbAuthToken}&destBankCode=${destBankCode}&destBankId=${destBankId}&destCurrency=${destCurrency}&destMerchantName=${destMerchantName}` +
                `&exReferenceNo=${exReferenceNo}&paymentTypeId=${paymentTypeId}&qrType=${qrType}&remark=${remark}&bankName=${bankName}&bankLogoUrl=${bankLogoUrl}&billNumber=${billNumber}&feeAmount=${feeData ? feeData["feeAmount"] : 0}&lat=${lat}&lng=${lng}`
                , { state: { destMerchantId: destMerchantId } }
              );
            }
          }

        } else {
          // Get feeAmount
          feeList.sort((a, b) => a - b);
          let feeData
          for (var i = 0; i < feeList.length; i++) {
            if ((i + 1) < feeList.length) {
              if (parseInt(priceValue) >= parseInt(feeList[i]["from"]) && parseInt(priceValue) < parseInt(feeList[i + 1]["from"])) {
                feeData = feeList[i]
              }
            } else {
              if (parseInt(priceValue) >= parseInt(feeList[i]["from"])) {
                feeData = feeList[i]
              }
            }
          }
          localStorage.setItem("DESCRIPTION", description);
          history(
            `/homeqr-payment-detail?id=${defaultCardData.id}&cardId=${defaultCardData.cardId}&cardName=${defaultCardData.cardName}&expireMonth=${defaultCardData.expireMonth}&expireYear=${defaultCardData.expireYear}&price=${price}&code=${code}&brand=${defaultCardData.brand}&userId=${userId}` +
            `&jdbTransactionId=${jdbTransactionId}&jdbAuthToken=${jdbAuthToken}&destBankCode=${destBankCode}&destBankId=${destBankId}&destCurrency=${destCurrency}&destMerchantName=${destMerchantName}` +
            `&exReferenceNo=${exReferenceNo}&paymentTypeId=${paymentTypeId}&qrType=${qrType}&remark=${remark}&bankName=${bankName}&bankLogoUrl=${bankLogoUrl}&billNumber=${billNumber}&feeAmount=${feeData ? feeData["feeAmount"] : 0}&lat=${lat}&lng=${lng}`,
            { state: { destMerchantId: destMerchantId } }
          );
        }
      }
    }
  };

  const _mmoneyAcceptPayment = () => {
    try {
      // console.log(mmoneyKey);
      // console.log("price", price)
      if (!price && !description) {
        setShowPrice(true);
        setShowDes(true);
      } else if (!price) {
        setShowPrice(true);
      } else if (!description) {
        setShowDes(true);
      } else {
        let priceStr = price;
        // var priceValue = parseFloat(priceStr[1].replace(/,/g, ""));
        var priceValue = parseFloat(priceStr.replace(/,/g, ""));
        if (priceValue < 15000) {
          setShowLess(true);
        } else {
          if (userQueryData.user.userStatus) {
            // if (parseInt(priceValue) > 5000000) {
            if (parseInt(priceValue) > parseInt(limitPayment)) {
              setShowLimit(true)
            } else {
              localStorage.setItem("DESCRIPTION", description);
              history(
                `/homeqr-payment-detail?id=${defaultCardData.id}&cardId=${defaultCardData.cardId}&cardName=${defaultCardData.cardName}&expireMonth=${defaultCardData.expireMonth}&expireYear=${defaultCardData.expireYear}&price=${price}&code=${code}&brand=${defaultCardData.brand}&userId=${userId}` +
                `&mmoneyKey=${mmoneyKey}&mmoneyToken=${mmoneyToken}&merchantMobile=${merchantMobile}&destMerchantName=${destMerchantName}&bankTansactionId=${bankTansactionId}` +
                `&responseCode=${responseCode}&responseMessage=${responseMessage}&qrType=${qrType}&shopCategory=${shopCategory}&bankName=${bankName}&bankLogoUrl=${bankLogoUrl}&lat=${lat}&lng=${lng}`,
                { state: { destMerchantId: destMerchantId } }
              );
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Prevent special char on input field
  const isValidChar = (str) => {
    return !/[~`!@#$%\^&*()+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str);
  };

  const onGoBack = () => {
    history(`/home-add-creditcard`)
    window.location.reload(true)
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="wrapper" style={{ backgroundColor: BACKGROUND_COLOR_APP }}>
      {/* Navbar  */}

      <CustomAppBar
        gotoOtherPage={onGoBack}
        title={"LOCA PAY"}
      />

      {/* Body */}
      <div style={{ padding: 20 }}>
        {/* Payment  */}

        <div
          style={{
            fontSize: 17,
            fontWeight: "bold",
            marginTop: 11,
            
          }}
        >
          {/* Payment */}
          {t("payment")}
        </div>

        <div
          style={{
            fontSize: 16,
            color: "#999999",
            marginBottom: 10,
          }}
        >
          {/* To */}
          {t("payment-to")}
        </div>

        <div
          // className="box-shadow-sm"
          style={{
            display: "flex",
            // height: 70,
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: 6,
            padding: 8,
            border: "1px solid #ECECEC"
          }}
        >
          <div style={{ fontSize: 16, fontWeight: "bold" }}>{destMerchantName}</div>
          <div>
            <img
              style={{
                width: 50,
                height: 50,
                borderRadius: 40,
              }}
              src={bankName == 'TELBIZ' ? (
                qrType == "LTC" ? "/assets/images/ltc_logo.jpeg" :
                  qrType == "UNITEL" ? "/assets/images/unitel_logo.jpeg" :
                    qrType == "ETL" ? "/assets/images/etl_logo.png" :
                      "/assets/images/box-loading.jpg"
              ) : bankLogoUrl ? bankLogoUrl :
                "/assets/images/box-loading.jpg"
              }
            />
          </div>
        </div>

        <div
          style={{
            fontSize: 16,
            marginTop: 20,
            color: "#999999"
          }}
        >
          {/* From */}
          {t("payment-from")}
        </div>

        {defaultCardData ? (
          <div
            // className="box-shadow-sm"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: 6,
              marginTop: 8,
              padding: 10,
              border: "1px solid #ECECEC"
            }}
            onClick={() => setChangeCard(true)}
          >
            <div>
              <div style={{ fontSize: 16, fontWeight: "bold" }}>{defaultCardData ? defaultCardData.cardName : ""}</div>
              <div style={{ fontSize: 14, fontWeight: "bold" }}>
                ************{defaultCardData ? defaultCardData.cardId : ""}
              </div>
              <div
                style={{
                  fontSize: 12,
                  fontWeight: "bold",
                  color: "#898989",
                }}
              >
                {t("card-expire")} {defaultCardData.expireMonth} /{" "}
                {defaultCardData.expireYear}
              </div>
            </div>
            <img
              style={{
                height: defaultCardData.brand == "discover" || defaultCardData.brand == "diners" ? 20 :
                  defaultCardData.brand === "mastercard" ? 40 :
                    defaultCardData.brand === "visa" ? 20 :
                      50,
              }}
              src={
                defaultCardData.brand == "visa"
                  ? "/assets/images/visa_icon.png"
                  : defaultCardData.brand == "jcb"
                    ? "/assets/images/jcb.png"
                    : defaultCardData.brand == "discover" || defaultCardData.brand == "diners"
                      ? "/assets/images/discover_diner.png"
                      : defaultCardData.brand == "unionpay"
                        ? "/assets/images/union_pay.png"
                        : defaultCardData.brand == "amex"
                          ? "/assets/images/amex.png"
                          : "/assets/images/mastercards.png"
              }
            />
          </div>
        ) : (
          <div />
        )}



        {/* --Amount-- */}
        <br></br>
        {/* <center>
          <NumberFormat
            autoFocus={true}
            className="number-format-cus"
            decimalScale={0}
            allowNegative={false}
            thousandSeparator={true}
            inputMode="decimal"
            prefix={"LAK "}
            style={{
              color: "#101010",
              border: `1px solid ${limitColor ? "red" : "#E6E6E6"}`,
              paddingLeft: 10,
              borderRadius: 4,
              fontSize: 22,
              height: 60,
              width: "100%",
              textAlign: "center",
            }}
            disabled={txnAmount != 0 ? true : false}
            placeholder={t("amount")}
            value={txnAmount != 0 ? txnAmount : price}
            onChange={(e) => {
              let priceStr = e.target.value.split(" ");
              if (priceStr[1]) {
                var priceValue = parseFloat(priceStr[1].replace(/,/g, ""))
                // if (priceValue > 5000000) {
                if (priceValue > parseInt(limitPayment)) {
                  setPrice(e.target.value);
                  setLimitColor(true)
                } else {
                  setPrice(e.target.value);
                  setShowPrice(false);
                  setLimitColor(false)
                }
              }
            }}
          />
          {showPrice ? (
            <div style={{ color: "red" }}>
              {t("payment-fill")}
            </div>
          ) : (
            <div />
          )}
        </center> */}

        {/* Description  */}
        <div
          style={{
            fontSize: 16,
            color: "#999999"
          }}
        >
          {/* Description */}
          {t("payment-des")} ({MAX_DESCRIPTION})
        </div>
        <center style={{ marginTop: 4 }}>
          <Form.Control
            style={{ width: "100%" }}
            as="textarea"
            rows={3}
            disabled={disableDescriptionEdit}
            value={description}
            // Prevent copy paste
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            onChange={(e) => {
              // Max Char
              if (e.target.value.length > MAX_DESCRIPTION) {
                return;
              }
              // TODO: Prevent special charactor
              let character = e.target.value;
              if (!isValidChar(character)) {
                setDescription(description);
                return;
              }

              setDescription(character);
              setShowDes(false);
            }}
          />

          {showDes ? (
            <div style={{ color: "red" }}>
              {/* Please fill your description */}
              {t("payment-fill-des")}
            </div>
          ) : (
            <div />
          )}
        </center>

        <div style={{ height: 200 }} />

        <div
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%"
          }}
        >
          <div style={{
            background: "0 4px 8px 6px rgba(0, 0, 0, 0.2)",
            backgroundColor: "white",
            textAlign: "center",
            padding: "19px 23px"
          }}>
            <div style={{ fontSize: 16, color: "#999999" }}>{t("please-input-amount")}</div>
            <div style={{ height: 14 }} />
            <InputGroup className="mb-3" style={{ width: "100%" }}>
              <div style={{ width: "85%" }}>
                <NumberFormat
                  autoFocus={true}
                  className="number-format-cus"
                  decimalScale={0}
                  allowNegative={false}
                  thousandSeparator={true}
                  inputMode="decimal"
                  // prefix={"LAK "}
                  style={{
                    color: "#101010",
                    paddingLeft: 10,
                    borderRadius: 4,
                    fontSize: 22,
                    height: 50,
                    width: "100%",
                    textAlign: "center",
                    backgroundColor: "#F8F8F8",
                    border: "none",
                  }}
                  disabled={txnAmount != 0 ? true : false}
                  placeholder={t("amount")}
                  value={txnAmount != 0 ? txnAmount : price}
                  onChange={(e) => {
                    // let priceStr = e.target.value.split(" ");
                    // if (priceStr[1]) {
                    let priceStr = e.target.value;
                    if (priceStr) {
                      var priceValue = parseFloat(priceStr.replace(/,/g, ""))
                      // if (priceValue > 5000000) {
                      if (priceValue > parseInt(limitPayment)) {
                        setPrice(e.target.value);
                        setLimitColor(true)
                      } else {
                        setPrice(e.target.value);
                        setShowPrice(false);
                        setLimitColor(false);
                      }
                    }
                  }}
                />

              </div>
              <InputGroup.Text
                className="input-icon-lak">LAK</InputGroup.Text>
            </InputGroup>

          </div>
          <Button
            style={{
              height: 50,
              backgroundColor: COLOR_APP,
              textAlign: "center",
              border: "none",
              width: "100%",
              fontWeight: 700,
              fontSize: 16
            }}
            onClick={() => { if (mmoneyToken) { _mmoneyAcceptPayment() } else { _acceptPayment() } }}
          >
            {/* {t("payment-accept")} */}
            Next
          </Button>
        </div>

        <Modal
          show={showKyc}
          size="sm"
          onHide={() => setShowKyc(false)}
          centered
        >
          <Modal.Body style={{ textAlign: "center" }}>
            <img
              src={
                popupQueryData
                  ? popupQueryData.popups.data[0].popupImage
                  : "/assets/images/Kyc_Logo_Welcome.png"
              }
              style={{ height: 150, marginBottom: 16 }}
              alt=""
            />
            <div style={{ marginBottom: 16 }}>
              {/* {t("kyc-please-kyc")} */}
              {popupQueryData ? popupQueryData.popups.data[0].note : ""}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <div
                style={{ fontWeight: "bold", color: COLOR_APP }}
                onClick={() => setShowKyc(false)}
              >
                {/* Later */}
                {t("kyc-later")}
              </div>
              <Button
                style={{
                  backgroundColor: COLOR_APP,
                  border: "none",
                }}
                onClick={() =>
                  history(
                    `/kyc?profileImage=${userQueryData.user.profileImage}&firstName=${userQueryData.user.firstName}&lastName=${userQueryData.user.lastName}&phone=${userQueryData.user.phone}&sid=${userQueryData.user.sid}`
                  )
                }
              >
                {t("kyc-registers")}
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showError}
          size="sm"
          onHide={() => setShowError(false)}
          centered
        >
          <Modal.Body style={{ textAlign: "center" }}>
            <RiErrorWarningFill style={{ fontSize: 60, color: POPUP_COLOR }} />
            <div>
              {/* Your price is more than limit  */}
              {t("payment-limit")} ({" "}
              {userQueryData
                ? `${numberWithCommas(
                  limitAmountData
                    ? limitAmountData.limitAmounts.data[0].maxAmount
                    : 0
                )} LAK`
                : ""}
              )
            </div>
            <div>
              {/* you must to KYC first */}
              {t("payment-kyc")}
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showLimit}
          size="sm"
          onHide={() => setShowLimit(false)}
          centered
        >
          <Modal.Body style={{ textAlign: "center" }}>
            <RiErrorWarningFill style={{ fontSize: 60, color: POPUP_COLOR }} />
            <div>
              {/* Your price payment is more than 5,000,0000 kip  */}
              {/* {t("over_limit_amount")} */}
              {t("over_limit_amount")} {parseInt(limitPayment).toLocaleString()} LAK
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showLess}
          size="sm"
          onHide={() => setShowLess(false)}
          centered
        >
          <Modal.Body style={{ textAlign: "center" }}>
            <RiErrorWarningFill style={{ fontSize: 60, color: "yellow" }} />
            <div>
              {/* Price must more than 10,000 Kip  */}
              {t("popup-more")} 15,000 {t("popup-kip")}
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={changeCard}
          size="sm"
          onHide={() => setChangeCard(false)}
          centered
        >
          <Modal.Body style={{ textAlign: "center" }}>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <GrClose onClick={() => setChangeCard(false)} />
            </div>
            {cardData ? <CreditCardInfo data={cardData} /> : <div />}
          </Modal.Body>
        </Modal>

        <Modal
          show={showQrError}
          size="sm"
          centered
        >
          <Modal.Body style={{ textAlign: "center" }}>
            <div style={{ fontSize: 24, fontWeight: "bold", color: "#000000A6" }}>
              {t("error-qr")}
            </div>
            <div style={{ textAlign: "center", marginTop: 10, color: "#000000A3" }}>{t("error-qr-detail")}, {t("please_make_sure_qr")}</div>
            <img src="assets/images/LAOQR.png" alt="" width={100} />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button className="btn btn-primary" style={{ backgroundColor: COLOR_APP, border: "none" }}
                onClick={() => { setShowQrError(false); history(`/home-add-creditcard`) }}
              >{t("ok")}</button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showQrBlockError}
          size="sm"
          centered
        >
          <Modal.Body style={{ textAlign: "center" }}>
            <RiErrorWarningFill fontSize={90} color={"#56CCF2"} />
            <div style={{ fontSize: 18, fontWeight: "bold", color: "#EB3232", marginTop: 8 }}>
              {t("qr-blocked-title")}
            </div>
            <div style={{ textAlign: "center", marginTop: 10, color: "#000000A3" }}>{t("qr-blocked-text")}</div>
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
              <button className="btn btn-primary" style={{ backgroundColor: COLOR_APP, border: "none" }}
                onClick={() => { setShowQrBlockError(false); history(`/home-add-creditcard`); }}
              >{t("ok")}</button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showPrice}
          size="sm"
          onHide={() => setShowPrice(false)}
          centered
        >
          <Modal.Body style={{ textAlign: "center" }}>
            <RiErrorWarningFill style={{ fontSize: 60, color: "yellow" }} />
            <div>
              {t("popup-more")} 15,000 {t("popup-kip")}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default HomeQrPaymentConfirmToPay;

import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function ForgotPinSuccess() {
    return (
        <div>
            <center>
                <div style={{ height: 53 }} />
                <img
                    style={{
                        width: 153
                    }}
                    src="/assets/images/loca-logo.png"
                />
                <div style={{ height: 120 }} />
                <FontAwesomeIcon icon={faCircleCheck} fontSize={100} color="#0DCD5A"/>
                <div style={{color: "#4A607B", marginTop: 29, marginBottom: 20}}>Your PIN has been successfully changed!</div>
            </center>
        </div>
    )
}

export default ForgotPinSuccess

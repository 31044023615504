
import { COLOR_SECONDARY } from "../constants";

import React from 'react'
import { useTranslation } from "react-i18next";

function Loading() {

    const { t, i18n } = useTranslation();

    return (
        <div>
            <div style={{ height: "100vh", }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <img src="/assets/images/loading.gif" alt="" style={{ width: "60%", marginTop: 150 }} />
                </div>
                <div style={{ textAlign: "center", fontWeight: "bold", fontSize: 20, color: COLOR_SECONDARY }}>
                    {t("loading")}...
                </div>
            </div>
        </div>
    )
}

export default Loading

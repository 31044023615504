import React from 'react'

function ForgotPinExpire() {
    return (
        <div style={{
            display: "flex",
            justifyContent:"center",
            alignItems:"center",
            height: "90vh"

        }}>
            <div>
                <img src="/assets/images/expire_logo.png" alt="" style={{ width: 288 }} />
                <div style={{marginTop: 40, fontSize: 24, fontWeight: 400, textAlign: "center"}}>This link is expired</div>
            </div>
        </div>
    )
}

export default ForgotPinExpire

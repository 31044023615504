import { gql } from "@apollo/client";

export const MMONEY_COMFIRM_PAYMENT = gql`
mutation Mutation($data: ConfirmQRInput!) {
  confirmMmoneyQr(data: $data) {
    id
    createdAt
    bankTansactionId
  }
}
`

export const MMONEY_AUTH = gql`
mutation Mutation {
  mmoneyAuth {
    accessToken
    expires
    tokenType
  }
}
`
export const SHOW_MMONEY_QR_INFO = gql`
mutation Mutation($mmoneyToken: String!, $qrCode: String!) {
  verifyMmoneyQr(mmoneyToken: $mmoneyToken, qrCode: $qrCode) {
    key
    merchantMobile
    merchantName
    qrType
    responseCode
    responseMessage
    bankLogoURL
    shopCategory
    shopName
    transAmount
    bankTansactionId
  }
}
`

export const CREATE_PAYMENT = gql`
  mutation Mutation($data: PaymentInput!) {
    createPayment(data: $data) {
      id
      billNo
      createdAt
      refNo
      bankName
      bankLogoUrl
    }
  }
`;

export const UPDATE_USER = gql`
  mutation Mutation($data: UserInput!, $where: UserWhereInputOne!) {
    updateUser(data: $data, where: $where) {
      id
    }
  }
`;
export const JDB_AUTH = gql`
  mutation JdbAuth {
    jdbAuth {
      timestamp
      success
      message
      transactionId
      data {
        token
      }
    }
  }
`;

export const JDB_GET_INFO_QR = gql`
  mutation JdbGetInfoQr(
    $jdbToken: String!
    $requestId: String!
    $emvCode: String!
    $lat:Float 
    $long: Float
  ) {
    jdbGetInfoQr(
      jdbToken: $jdbToken
      requestId: $requestId
      emvCode: $emvCode
      lat: $lat 
      long: $long
    ) {
      timestamp
      success
      message
      transactionId
      data {
        qrType
        destBankCode
        destBankId
        destMerchantId
        destMerchantName
        destCurrency
        txnAmount
        remark
        exReferenceNo
        paymentTypeId
        bankName
        bankLogoUrl
        billNumber
        feeList {
          from
          feeAmount
        }
      }
    }
  }
`;

export const PIN_COMPARE = gql`
  mutation PinCompare($pinCode: String) {
    pinCompare(pinCode: $pinCode) {
      success
    }
  }
`;

export const CREATE_SCAN_LOGGING = gql`
mutation Mutation($data: ScanLoggingInput!) {
  createScanLogging(data: $data) {
    id
  }
}
`;

export const CREATE_UPPASS_FORM = gql`
mutation Mutation($userId: String!) {
  createUpPassForm(userId: $userId) {
    url
  }
}
`;

export const CREATE_TELBIZ_PAYMENT = gql`
mutation Mutation($data: ConfirmTelbizPackage!, $telbizToken: String) {
  confirmBuyPackage(data: $data, telbizToken: $telbizToken) {
    amount
    bankTansactionId
    birthday
    cardHolderName
    cardLast4digit
    cardType
    charge
    countryCode
    email
    fees
    gender
    isPassAutoKYC
    lastName
    lat
    locaSID
    long
    note
    passportNumber
    phone
    pinCode
    stripeCardId
    total
    totalFees
    userApprovedAt
    userCreatedAt
    userId
    userStatus
    userStripeId
    createdAt
    firstName
    id
    qrString
    qrType
  }
}
`

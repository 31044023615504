import React from 'react'

function ServicesButton({ image, text, secondText,gotoOtherPage }) {
    return (
        <div style={{ textAlign: "center" }}>
            <div
                style={{
                    width: 100,
                    height: 74,
                    boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.2)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 6,
                }}
                onClick={gotoOtherPage}
            >
                <img src={image} alt="" style={{ width: 53 }} />
            </div>
            <div style={{ marginTop: 12, fontSize: 14 }}>{text}</div>
            <div style={{ fontSize: 14 }}>{secondText}</div>
        </div>
    )
}

export default ServicesButton

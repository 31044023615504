import { gql } from "@apollo/client";

export const USER_KYC = gql`
  mutation Mutation($data: UserInput!) {
    userKYC(data: $data) {
      accessToken
      refreshToken
      data {
        id
        sid
        firstName
        isPassAutoKYC
      }
    }
  }
`;

export const UPDATE_USER_REJECTED = gql`
  mutation Mutation($data: UserInput!, $where: UserWhereInputOne!) {
    updateUser(data: $data, where: $where) {
      id
    }
  }
`;

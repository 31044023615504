import { gql } from '@apollo/client'

export const USER_AUTH = gql`
  mutation Mutation($token: String!) {
  auth(token: $token) {
    accessToken
    refreshToken
    data {
      id
      firstName
      lastName
      sid
      userLang
      userStatus
      countryCode
      gender
      email
      birthday
      profileImage
      fcmTopic
      phone
    }
  }
}
`;

export const UPDATE_USER = gql`
mutation Mutation($data: UserInput!, $where: UserWhereInputOne!) {
  updateUser(data: $data, where: $where) {
    id
  }
}
`;

export const RESET_PIN = gql`
mutation Mutation($data: UserInput!, $where: UserWhereInputOne!, $token: String!) {
  resetPin(data: $data, where: $where, token: $token) {
    id
  }
}
`;

export const CREATE_CARD = gql`
mutation Mutation($data: CardInput!) {
  createCard(data: $data) {
    id
    cardId
  }
}
`;

export const DELETE_CARD = gql`
mutation Mutation($where: CardWhereInputOne!) {
  deleteCard(where: $where) {
    id
  }
}
`;

export const USER_REGISTER = gql`
mutation Mutation($data: UserInput!) {
  userRegister(data: $data) {
    accessToken
    refreshToken
    data {
      id
      sid
    }
  }
}
`;

export const SEND_EMAIL_RESET_PIN = gql`
mutation Mutation($email: String) {
  sendEmailResetPin(email: $email) {
    email
    status
  }
}
`;

export const SEND_SMS_RESET_PIN = gql`
mutation Mutation($phone: String) {
  sendSMSResetPin(phone: $phone) {
    phone
    status
  }
}
`;
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { GET_BANNER } from "./graphql/query";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import "./Home.css";
import CustomAppBar from "../../components/CustomAppBar";
import { BACKGROUND_COLOR_APP } from "../../constants";

function HomeBanDetail() {
  const history = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [canScroll, setCanScroll] = useState(false);
  const { innerWidth: width, innerHeight: height } = window;
  const ref = useRef(null);

  const [getBanner, { data: bannerQueryData, loading: loading }] = useLazyQuery(
    GET_BANNER
  );

  useEffect(() => {
    let bannerId = new URLSearchParams(location.search).get("bannerId");
    if (bannerId) {
      getBanner({
        variables: {
          where: {
            id: bannerId,
          },
        },
      });
    }

    if (ref.current.clientHeight < height) setCanScroll(true);
    else setCanScroll(false);
  }, []);

  //routes
  const _goBacktoHome = () => {
    history(-1);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      style={
        canScroll
          ? { position: "fixed", overflowY: "scroll", width: "100%", backgroundColor: BACKGROUND_COLOR_APP }
          : {backgroundColor: BACKGROUND_COLOR_APP}
      }
      ref={ref}
      className="wrapper"
    >
      {/* <div className="home-header">
        <FontAwesomeIcon
          style={{
            marginLeft: 18,
            // marginTop: 17,
            height: 20,
            width: 16,
            fontWeight: "bold",
          }}
          icon={faArrowLeft}
          onClick={() => _goBacktoHome()}
        />
      </div> */}

      <CustomAppBar
        gotoOtherPage={_goBacktoHome}
        title={"LOCA PAY"}
      />

      {/* <div style={{ height: 60 }} /> */}

      {/* Body */}
      <div
        style={{
          padding: 20,
        }}
      >
        <div
          style={{
            fontSize: 14,
            fontWeight: "bold",
            overflowWrap: "break-word",
            width: 300,
          }}
        >
          {bannerQueryData ? bannerQueryData.banner.title : ""}
        </div>

        <div style={{ height: 24 }} />

        <div style={{ textAlign: "center" }}>
          <img
            objectfit="fill"
            style={{
              height: 100,
            }}
            src={bannerQueryData ? bannerQueryData.banner.bannerImage : ""}
            alt=""
          />
        </div>

        <div style={{ height: 20 }} />

        <div>
          <div
            style={{
              fontSize: 14,
              fontWeight: "bold",
              color: "#828282",
            }}
          >
            {/* Detail */}
            {t("detail")}
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: bannerQueryData ? bannerQueryData.banner.note : "",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default HomeBanDetail;

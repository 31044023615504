import { faArrowLeft, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { MdArrowForwardIos, MdKeyboardArrowDown } from "react-icons/md";
import { RiErrorWarningFill } from "react-icons/ri";
import { useNavigate, useLocation } from "react-router";
import MyCustomCamera from "../../components/MyCustomCamera";
import {
  BACKGROUND_COLOR_APP,
  FACE_IMAGE,
  IDCARD_IMAGE,
  POPUP_COLOR,
  PROFILE_IMAGE,
} from "../../constants";
import { HEADER_HEIGHT } from "../../constants";
import CustomAppBar from "../../components/CustomAppBar";

function KycEdit() {
  const history = useNavigate();
  const location = useLocation();

  const _faceImage = localStorage.getItem(FACE_IMAGE);
  const _idcardImage = localStorage.getItem(IDCARD_IMAGE);
  const _profileImage = localStorage.getItem(PROFILE_IMAGE);
  let _firstName = new URLSearchParams(location.search).get("firstName");
  let _lastName = new URLSearchParams(location.search).get("lastName");
  let userLang = new URLSearchParams(location.search).get("userLang");
  let countryCode = new URLSearchParams(location.search).get("countryCode");
  let _phone = new URLSearchParams(location.search).get("phone");
  let fcmTopic = new URLSearchParams(location.search).get("fcmTopic");
  let _gender = new URLSearchParams(location.search).get("gender");
  let _passportNumber = new URLSearchParams(location.search).get(
    "passportNumber"
  );
  let _dobDate = new URLSearchParams(location.search).get("dobDate");
  let _issueDate = new URLSearchParams(location.search).get("issueDate");
  let _expireDate = new URLSearchParams(location.search).get("expireDate");

  const [accept, setAccept] = useState(false);
  const [faceImage, setFaceImage] = useState(
    _faceImage != null ? _faceImage : ""
  );
  const [idcardImage, setIdCardImage] = useState(
    _idcardImage != null ? _idcardImage : ""
  );

  const [isIdCardImage, setIsIdCardImage] = useState(false);
  const [isFaceImage, setIsFaceImage] = useState(false);
  const [firstName, setFirstName] = useState(_firstName ? _firstName : "");
  const [lastName, setLastName] = useState(_lastName ? _lastName : "");
  const [gender, setGender] = useState(_gender ? _gender : "");
  const [dobDate, setDobDate] = useState(_dobDate ? new Date(_dobDate) : null);
  const [phone, setPhone] = useState(_phone ? _phone : "");
  const [passportNumber, setPassportNumber] = useState(
    _passportNumber ? _passportNumber : ""
  );
  const [issueDate, setIssueDate] = useState(
    _issueDate ? new Date(_issueDate) : null
  );
  const [expireDate, setExpireDate] = useState(
    _expireDate ? new Date(_expireDate) : null
  );
  const [minExpireDate, setMinExpireDate] = useState("");
  const [current, setCurrent] = useState();
  const [myDate, setMyDate] = useState();
  const [showError, setShowError] = useState();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    var result = current - myDate;
    if (result < 18) {
      setShowError(true);
      setDobDate(null);
    }
  }, [dobDate]);

  const _gotoKycCondition = () => {
    const createDate = moment(issueDate).format("yyyy-MM-DD");
    const endDate = moment(expireDate).format("yyyy-MM-DD");
    const birthday = moment(dobDate).format("yyyy-MM-DD");

    localStorage.setItem(FACE_IMAGE, faceImage);
    localStorage.setItem(IDCARD_IMAGE, idcardImage);
    localStorage.setItem(PROFILE_IMAGE, _profileImage);

    history(
      `/kyc-condition?firstName=${firstName}&lastName=${lastName}&gender=${gender}&phone=${phone}&passportNumber=${passportNumber}&dobDate=${birthday}&issueDate=${createDate}&expireDate=${endDate}&userLang=${userLang}&countryCode=${countryCode}&fcmTopic=${fcmTopic}`
    );
  };

  const _gotoKycSignupDetail = () => {
    if (accept) {
      const createDate = moment(issueDate).format("yyyy-MM-DD");
      const endDate = moment(expireDate).format("yyyy-MM-DD");
      const birthday = moment(dobDate).format("yyyy-MM-DD");

      localStorage.setItem(FACE_IMAGE, faceImage);
      localStorage.setItem(IDCARD_IMAGE, idcardImage);
      localStorage.setItem(PROFILE_IMAGE, _profileImage);

      history(
        `/kycsingupdetail?firstName=${firstName}&lastName=${lastName}&gender=${gender}&phone=${phone}&passportNumber=${passportNumber}&dobDate=${birthday}&issueDate=${createDate}&expireDate=${endDate}&userLang=${userLang}&fcmTopic=${fcmTopic}`
      );
    }
  };

  const _goBackSignupDetail = () => {
    history(-1);
  };

  return (
    <div className="wrapper" style={{backgroundColor: BACKGROUND_COLOR_APP}}>
      {/* <div className="kyc-signup-header">
        <div style={{ fontWeight: "bold", margin: 0, color: "black" }}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            onClick={() => _goBackSignupDetail()}
            style={{ fontSize: "18", marginRight: 10 }}
          />{" "}
          {t("kyc-title-edit")}
        </div>
      </div> */}

      <CustomAppBar
        gotoOtherPage={_goBackSignupDetail}
        title={t("kyc-title-edit")}
      />

      <div style={{ height: HEADER_HEIGHT }}></div>

      {/* Body */}
      <div>
        <center>
          <img
            style={{
              width: 132,
              height: 132,
              borderRadius: "50%",
              objectFit: "cover",
            }}
            src={_profileImage ? _profileImage : "/assets/images/Add_Photo.png"}
          />
        </center>
        {/* Form Card  */}
        <Form style={{ marginLeft: 26, marginRight: 26, marginTop: 36 }}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label
              style={{ color: "#252A31", fontSize: 14, fontWeight: "normal" }}
            >
              {/* First name */}
              {t("login-firstname")}
            </Form.Label>
            <Form.Control
              style={{
                backgroundColor: "#EFF2F5",
                color: "#101010",
                border: "none",
                height: 44,
              }}
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder={t("kyc-firstname")}
            />
            {firstName == "" ? (
              <span style={{ color: "red", fontSize: 12 }}>
                {/* Please input your firstname */}
                {t("kyc-firstname")}
              </span>
            ) : (
              ""
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label
              style={{ color: "#252A31", fontSize: 14, fontWeight: "normal" }}
            >
              {/* Last name */}
              {t("login-lastname")}
            </Form.Label>
            <Form.Control
              style={{
                backgroundColor: "#EFF2F5",
                color: "#101010",
                border: "none",
                height: 44,
              }}
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder={t("kyc-firstname")}
            />
            {lastName == "" ? (
              <span style={{ color: "red", fontSize: 12 }}>
                {/* Please input your lastname */}
                {t("kyc-lastname")}
              </span>
            ) : (
              ""
            )}
            {/* Gender-- */}
          </Form.Group>
          <div style={{ marginBottom: 5 }}>
            {/* Gender */}
            {t("login-gender")}
          </div>
          <div>
            <input
              type="radio"
              value="FEMALE"
              name="gender"
              checked={gender === "FEMALE"}
              onChange={(e) => setGender(e.target.value)}
            />
            <span style={{ marginRight: 100 }}>
              {/* Female */}
              {t("kyc-female")}
            </span>
            <input
              type="radio"
              value="MALE"
              checked={gender === "MALE"}
              onChange={(e) => setGender(e.target.value)}
              name="gender"
            />
            {t("kyc-male")}
            {/* Male */}
          </div>
          {gender === "" ? (
            <span style={{ color: "red", fontSize: 12 }}>
              {/* Please select your gender */}
              {t("kyc-gender-select")}
            </span>
          ) : (
            ""
          )}

          <div className="form-group" style={{ marginTop: 8 }}>
            <label>
              {/* Date of Birth */}
              {t("kyc-birthday")}
            </label>
            <div className="input-group mb-3" style={{ width: "100%" }}>
              <span
                style={{
                  width: "15%",
                  backgroundColor: "#EFF2F5",
                  borderRight: "none",
                }}
                className="input-group-text"
                id="basic-addon1"
              >
                {" "}
                <FontAwesomeIcon icon={faCalendarAlt} />{" "}
              </span>
              <div style={{ width: "70%", backgroundColor: "#EFF2F5" }}>
                <DatePicker
                  className="form-control"
                  id="dtp-dob"
                  autoComplete="off"
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  yearDropdownItemNumber={93}
                  scrollableYearDropdown
                  placeholderText={t("kyc-birthday-select")}
                  selected={dobDate}
                  onChange={(date) => {
                    setDobDate(date);
                    setMinExpireDate(date.getFullYear() + 1);
                  }}
                />
              </div>
              <span
                style={{
                  width: "15%",
                  backgroundColor: "#EFF2F5",
                  borderLeft: "none",
                }}
                className="input-group-text"
                id="basic-addon1"
              >
                <MdKeyboardArrowDown
                  style={{ fontSize: 18, color: "#697D95" }}
                />{" "}
              </span>

              {dobDate == null ? (
                <span style={{ color: "red", fontSize: 12 }}>
                  {/* Please select your birth day */}
                  {t("kyc-birthday-select")}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label
              style={{ color: "#252A31", fontSize: 14, fontWeight: "normal" }}
            >
              Tel
            </Form.Label>
            <Form.Control
              style={{
                backgroundColor: "#EFF2F5",
                color: "#101010",
                border: "none",
                height: 44,
              }}
              type="text"
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label
              style={{ color: "#252A31", fontSize: 14, fontWeight: "normal" }}
            >
              {/* Passport Number */}
              {t("kyc-passport")}
            </Form.Label>
            <div>
              <Form.Control
                style={{
                  backgroundColor: "#EFF2F5",
                  color: "#101010",
                  border: "none",
                  height: 44,
                }}
                type="text"
                value={passportNumber}
                onChange={(e) => setPassportNumber(e.target.value)}
                placeholder={t("kyc-passport-fill")}
              />
              {passportNumber === "" ? (
                <span style={{ color: "red", fontSize: 12 }}>
                  {/* Please select your passport number */}
                  {t("kyc-passport-input")}
                </span>
              ) : (
                ""
              )}
            </div>
          </Form.Group>
        </Form>

        <div className="form-group" style={{ marginLeft: 26, marginRight: 26 }}>
          <label>
            {/* Issue Date */}
            {t("login-issue")}
          </label>
          <div className="input-group mb-3" style={{ width: "100%" }}>
            <span
              style={{
                width: "15%",
                backgroundColor: "#EFF2F5",
                borderRight: "none",
              }}
              className="input-group-text"
              id="basic-addon1"
            >
              {" "}
              <FontAwesomeIcon icon={faCalendarAlt} />{" "}
            </span>
            <div style={{ width: "70%", backgroundColor: "#EFF2F5" }}>
              <DatePicker
                className="form-control"
                placeholderText={t("kyc-select-issue")}
                id="dtp-dob"
                autoComplete="off"
                dateFormat="dd/MM/yyyy"
                showYearDropdown
                yearDropdownItemNumber={93}
                scrollableYearDropdown
                selected={issueDate}
                onChange={(date) => {
                  setIssueDate(date);
                  setMinExpireDate(date.getFullYear() + 1);
                }}
              />
            </div>
            <span
              style={{
                width: "15%",
                backgroundColor: "#EFF2F5",
                borderLeft: "none",
              }}
              className="input-group-text"
              id="basic-addon1"
            >
              <MdKeyboardArrowDown style={{ fontSize: 18, color: "#697D95" }} />{" "}
            </span>
            {issueDate == null ? (
              <span style={{ color: "red", fontSize: 12 }}>
                {/* Please select passport issue date */}
                {t("kyc-select-issue")}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="form-group" style={{ marginLeft: 26, marginRight: 26 }}>
          <label>
            {/* Expire Date */}
            {t("login-expire")}
          </label>
          <div className="input-group mb-3" style={{ width: "100%" }}>
            <span
              style={{
                width: "15%",
                backgroundColor: "#EFF2F5",
                borderRight: "none",
              }}
              className="input-group-text"
              id="basic-addon1"
            >
              {" "}
              <FontAwesomeIcon icon={faCalendarAlt} />{" "}
            </span>

            <div style={{ width: "70%", backgroundColor: "#EFF2F5" }}>
              <DatePicker
                className="form-control"
                placeholderText={t("kyc-select-expire")}
                id="dtp-dob"
                autoComplete="off"
                dateFormat="dd/MM/yyyy"
                showYearDropdown
                yearDropdownItemNumber={93}
                minDate={new Date(minExpireDate.toString())}
                scrollableYearDropdown
                selected={expireDate}
                onChange={(date) => {
                  setExpireDate(date);
                }}
              />
            </div>
            <span
              style={{
                width: "15%",
                backgroundColor: "#EFF2F5",
                borderLeft: "none",
              }}
              className="input-group-text"
              id="basic-addon1"
            >
              <MdKeyboardArrowDown style={{ fontSize: 18, color: "#697D95" }} />{" "}
            </span>
            {expireDate == null ? (
              <span style={{ color: "red", fontSize: 12 }}>
                {/* Please select passport expire date */}
                {t("kyc-select-expire")}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>

        <div style={{ marginLeft: 26, fontWeight: "bold", fontsize: 14 }}>
          {/* Photo with face */}
          {t("login-photo")}
        </div>
        {/* IMG  */}
        <div
          style={{ display: "flex", marginTop: 10, justifyContent: "center", zIndex: -1 }}
        >
          <div style={{ marginRight: 10, marginLeft: 10 }}>
            <img
              style={{
                height: 172,
                width: 172,
              }}
              src="/assets/images/kyc_example.png"
              className="Add_photo-image"
            />
          </div>
          <div className="image-wrapper">
            <MyCustomCamera
              labelIdle={'<img src="/assets/images/icon8-add.png" />'}
              folder="images"
              handleRes={(e) => {
                setIsFaceImage(true);
                setFaceImage(e);
              }}
            />
            {faceImage != "" && isFaceImage === false ? (
              <img src={faceImage} width="100%" />
            ) : (
              <span />
            )}
          </div>
        </div>

        <div
          style={{
            marginLeft: 26,
            fontWeight: "bold",
            fontsize: 14,
            marginTop: 24,
          }}
        >
          {/* Passport Picture */}
          {t("login-passport-pic")}
        </div>
        <div
          style={{ display: "flex", marginTop: 10, justifyContent: "center" }}
        >
          <div style={{ marginRight: 10, marginLeft: 10 }}>
            <img
              style={{
                height: 172,
                width: 172,
              }}
              src="/assets/images/Kyc_passport.png"
              className="Add_photo-image"
            />
          </div>
          <div className="image-wrapper">
            <MyCustomCamera
              labelIdle={'<img src="/assets/images/icon8-add.png" />'}
              folder="images"
              handleRes={(e) => {
                setIsIdCardImage(true);
                setIdCardImage(e);
              }}
            />
            {idcardImage != "" && isIdCardImage === false ? (
              <img src={idcardImage} width="100%" />
            ) : (
              <span />
            )}
          </div>
        </div>
        <div style={{ height: 100 }} />
        <input
          style={{ marginLeft: 26, marginRight: 8 }}
          type="checkbox"
          onClick={() => setAccept(!accept)}
        />
        {/* ຂ້ອຍຍອມຮັບ */}
        {t("kyc-accept")}
        <span
          style={{
            color: "#00ADFF",
            textDecoration: "underline",
          }}
          onClick={() => _gotoKycCondition()}
        >
          {/* ເງື່ອນໄຂການບໍລິການ */}
          {t("kyc-condition")}
        </span>
        <div
          className="row"
          style={{ marginLeft: 26, marginRight: 26, marginBottom: 30 }}
        >
          <button
            onClick={() => _gotoKycSignupDetail()}
            className="btn"
            style={{
              backgroundColor: accept ? "#00ADFF" : "#DCDCDC",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              marginTop: 24,
            }}
          >
            <span></span>
            {/* ຕໍ່ໄປ */}
            {t("kyc-next")}
            <span>
              <MdArrowForwardIos style={{ fontSize: "24" }} />
            </span>
          </button>
        </div>

        <Modal
          show={showError}
          size="sm"
          onHide={() => setShowError(false)}
          centered
        >
          <Modal.Body style={{ textAlign: "center" }}>
            <RiErrorWarningFill style={{ fontSize: 60, color: POPUP_COLOR }} />
            <div>
              {/* Your age does not reach the required threshold */}
              {t("kyc-age-alert")}
            </div>
            <div>
              {t("kyc-age-old")}
              {/* must be over 18 years old */}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default KycEdit;

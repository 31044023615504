import * as _ from "lodash";

/**
 *
 * JDB QR Format Validator
 */
export const qrFormatValidator = (qrString) => {
  /**
   * First 36ditgits should start with:
   * 00020101021138680016A005266284662577
   * 00020101021238680016A005266284662577
   *
   * mk QR
   * 00020101021138670016A005266284662577
   */

  if (_.isEmpty(qrString)) return false;

  // const qrStringFirst36Ditgits = qrString.substring(0, 15);
  const qrStringFirst36Ditgits = qrString.substring(0, 11);
  if (
    qrStringFirst36Ditgits != "00020101021" &&
    qrStringFirst36Ditgits != "00020101021"
  ) {
    return false;
  }

  return true;
};


/**
 *
 * Mmoney QR Format Validator
 */
export const mmoneyQrFormatValidator = (qrString) => {
  /**
     * First 33ditgits should start with:
     * 0002010102115208MERCHANT530341855
     * 0002010102125208MERCHANT530341855
     */
    const qrStringFirst33Digits = qrString.substring(0,33);
    // console.log("qrStringFirst33Digits: ", qrStringFirst33Digits);

    if(qrStringFirst33Digits != "0002010102115208MERCHANT530341855" && 
        qrStringFirst33Digits != "0002010102125208MERCHANT530341855"
    ) {
      return false;
    }
    return true;
}

export const convertPhoneToService = (phone) => {
  let res = ''
  switch (_.first(phone)) {
    case '5':
      res = 'LTC'
      break;
    case '9':
      res = 'UNITEL'
      break;
    case '2':
      res = 'ETL'
      break;
  
    default:
      res = ''
      break;
    }
    return res;
}
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { COLOR_APP, USER_ID } from '../../../constants'
import { RESET_PIN } from '../../graphql/mutation'

function ForgotPinHint() {
    const location = useLocation()
    const history = useNavigate()
    const [hint, setHint] = useState()
    const [hintError, setHintError] = useState(false)
    const [resetPin] = useMutation(RESET_PIN)

    const newPin = new URLSearchParams(location.search).get('newPin')
    const userId = new URLSearchParams(location.search).get('userId')
    const token = new URLSearchParams(location.search).get('token')

    const saveData = async () => {
        try {
            setHintError(false)
            if (!hint) {
                setHintError(true)
            }
            if (hint) {
                // history('/home-profile-pin/forgot-pin-success', { replace: true })
                const _res = await resetPin({
                    variables: {
                        data: {
                            pinCode: newPin,
                            pinHint: hint
                        },
                        where: {
                            id: userId
                        },
                        token
                    }
                })
                if(_res) {
                history('/home-profile-pin/forgot-pin-success', { replace: true })
                }
            }
        } catch (error) {
            // console.log("error: ", error)
        }
    }

    return (
        <div>
            <center>
                <div style={{ height: 53 }} />
                <img
                    style={{
                        width: 153
                    }}
                    src="/assets/images/loca-logo.png"
                />
                <div style={{ height: 72 }} />
                <div style={{ width: "85%", textAlign: "left", color: "#4A607B" }}>Please enter the Hint message to remind you about
                    your PIN</div>
                <div style={{ height: 8 }} />
                <div style={{ width: "85%" }}>
                    <Form.Control
                        placeholder='Enter new hint...'
                        as="textarea"
                        rows={3}
                        style={{ backgroundColor: "#F1F4F5" }}
                        value={hint}
                        onChange={(e) => setHint(e.target.value)}
                    />
                    {hintError ? <div style={{ color: "red", textAlign: 'left' }}>Please input hint</div> : <div />}
                </div>

                <div style={{ height: 180 }} />
                <button className='btn' style={{
                    backgroundColor: COLOR_APP,
                    borderColor: COLOR_APP,
                    color: "white",
                    fontWeight: "bold",
                    width: "85%",
                    height: 48
                }}
                    onClick={() => saveData()}
                >Save</button>
            </center>
        </div>
    )
}

export default ForgotPinHint

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as _ from "lodash";
import { Form } from "react-bootstrap";
import { useMutation, useLazyQuery } from "@apollo/client";
import { CARD_ID, USER_ID } from "../constants";
import { UPDATE_USER } from "../pages/Home/graphql/mutation";
import { GET_USER } from "../pages/graphql/query";

function CreditCardInfo(props) {
  const history = useNavigate();
  const [cardData, setCardData] = useState();
  const { t, i18n } = useTranslation();
  const [cardCheck, setCardCheck] = useState();
  const userId = localStorage.getItem(USER_ID);

  const [addDefaultCard] = useMutation(UPDATE_USER);
  const [getUser, { data: userData }] = useLazyQuery(GET_USER);

  useEffect(() => {
    getUser({
      variables: {
        where: {
          id: userId,
        },
      },
    });
    if (!_.isEmpty(props.data)) {
      setCardData(props.data);
      if (userData) {
        setCardCheck(userData.user.defaultCard);
      } else {
        setCardCheck(props.data[0].id);
      }
    }
  }, [props.data, userData]);

  const _setCardDefault = async (cardId) => {
    try {
      const _res = await addDefaultCard({
        variables: {
          data: {
            defaultCard: cardId,
          },
          where: {
            id: userId,
          },
        },
      });
      if (_res) {
        setCardCheck(cardId);
        getUser({
          variables: {
            where: {
              id: userId,
            },
          },
        });
      }
    } catch (error) { }
  };

  const _gotoDetail = (card) => {
    localStorage.setItem(CARD_ID, card.id);
    history(
      `/home-account-carddetails?cardId=${card.cardId}&cardName=${card.cardName}&expireMonth=${card.expireMonth}&expireYear=${card.expireYear}&brand=${card.brand}`
    );
  };

  return (
    <div>
      {!_.isEmpty(cardData) ? (
        <div
          className="box-shadow-sm"
          style={{
            marginLeft: 26,
            marginRight: 26,
            // marginTop: 26,
            paddingBottom: 46,
            borderRadius: 6,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: 12,
              paddingTop: 18,
              paddingLeft: 19,
              marginBottom: 15,
            }}
          >
            {/* Credit Card */}
            {t("credit-card")}
          </div>
          {cardData.map((card, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: 16,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "90%",
                  }}
                  //       value={card.id}
                  //       name="card"
                  //       checked={
                  //         cardCheck == card.id
                  //           ? true
                  //           : false
                  //       }
                  //       onChange={(e) =>
                  //         _setCardDefault(
                  //           e.target.value
                  //         )
                  //       }

                  onClick={() => {
                    if (props.isClickAble) {
                      _gotoDetail(card);
                    } else {
                      _setCardDefault(card.id);
                    }
                  }}
                >
                  <div style={{ width: "45%", padding: 10 }}>
                    {<img style={{
                      height: card.brand == "discover" || card.brand == "diners" ? 15 : 58,
                      objectFit: "fill",
                    }}
                      src={card.brand == "visa" ? "/assets/images/VisaCard.png" :
                        card.brand == "jcb" ? "/assets/images/jcb.png" :
                          card.brand == "discover" || card.brand == "diners" ? "/assets/images/discover_diner.png" :
                            card.brand == "unionpay" ? "/assets/images/union_pay.png" :
                              card.brand == "amex" ? "/assets/images/amex.png" :
                                "/assets/images/MasterCard.png"
                      }
                      alt="" />
                    }
                  </div>

                  <div
                    value={card.id}
                    name="card"
                    checked={cardCheck == card.id ? true : false}
                    onChange={(e) => _setCardDefault(e.target.value)}
                    style={{
                      display: "flex",
                      backgroundColor: "#F9F9F9",
                      height: 58,
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "55%",
                      paddingRight: 6,
                    }}
                  >
                    <div style={{ marginLeft: 8 }}>
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: 12,
                          paddingTop: 19,
                          color: "#000000",
                        }}
                      >
                        **** **** **** {card.cardId}
                      </div>
                      <div
                        style={{
                          color: "#C4C4C4",
                          fontSize: 10,
                          fontWeight: "bold",
                          paddingTop: 5,
                        }}
                      >
                        {t("card-expire")} {card.expireMonth} /{" "}
                        {card.expireYear}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    width: "10%",
                    height: 58,
                    display: "flex",
                    backgroundColor: "#F9F9F9",
                    alignItems: "center",
                  }}
                >
                  <Form.Check
                    type="radio"
                    value={card.id}
                    name="card"
                    checked={cardCheck == card.id ? true : false}
                    onChange={(e) => _setCardDefault(e.target.value)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}

export default CreditCardInfo;

import React from 'react'
import { MdArrowBackIosNew } from 'react-icons/md'
import "./CustomAppBar.css"

function CustomAppBar({ gotoOtherPage, title }) {
  return (
    <div 
      onClick={gotoOtherPage}
      className='custom-appbar'
    >
      <MdArrowBackIosNew fontSize={24} style={{borderRadius:50}} className='back-icon'/>
      <div style={{ fontSize: 18, fontWeight: 700 }}>{title}</div>
      <div style={{ color: "transparent" }}>EXIT</div>
    </div>
  )
}

export default CustomAppBar

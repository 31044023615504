import { gql } from "@apollo/client";

export const GET_BANNERS = gql`
  query Query {
    banners {
      total
      data {
        id
        title
        note
        bannerImage
        bannerStatus
        createdAt
      }
    }
  }
`;

export const GET_BANNER = gql`
  query Query($where: BannerWhereInputOne!) {
    banner(where: $where) {
      id
      title
      note
      bannerImage
      createdAt
    }
  }
`;

export const GET_USER = gql`
  query Query($where: UserWhereInputOne!) {
    user(where: $where) {
      id
      sid
      firstName
      lastName
      countryCode
      gender
      birthday
      email
      phone
      passportNumber
      userStripeId
      profileImage
      passportIssueData
      passportExpireDate
      isPassAutoKYC
      fcmTopic
      pinCode
      pinHint
      defaultCard
      limitAmount
      approvedAt
      role
      userLang
      userStatus
      createdAt
    }
  }
`;

export const GET_LIMIT_AMOUNT = gql`
  query Query {
    limitAmounts {
      data {
        id
        maxAmount
        createdAt
      }
    }
  }
`;

export const GET_ALL_LIMIT_PAYMENT = gql`
query Query {
  limitPayments {
    data {
      id
      maxAmount
      createdBy {
        username
        id
        firstName
        lastName
      }
    }
  }
}
`;

export const GET_DETAIL_PAYMENT = gql`
query Query($where: PaymentWhereInputOne!) {
  paymentComfirm(where: $where) {
    id
    billNo
    userCreatedAt
    userApprovedAt
    cardType
    paymentToShopName
    amount
    fees
    charge
    totalFees
    total
    bankName
    bankLogoUrl
    bankTansactionDetail
    bankTansactionId
    packageAmount
    cardLast4digit
    cardHolderName
    note
    qrType
    destMerchantName
    destCurrency
    exReferenceNo
    paymentTypeId
    refNo
    createdAt
    billNumber
  }
}
`


export const GET_PAYMENTS = gql`
  query Payments(
    $where: PaymentWhereInput
    $orderBy: OrderByInput
    $limit: Int
  ) {
    payments(where: $where, orderBy: $orderBy, limit: $limit) {
      total
      data {
        id
        billNo
        userId
        locaSID
        firstName
        lastName
        email
        phone
        cardType
        cardLast4digit
        cardHolderName
        paymentToShopName
        amount
        fees
        charge
        totalFees
        total
        stripeTransactionId
        stripeTransactionDetail
        stripeCardId
        bankTansactionId
        bankTansactionDetail
        destMerchantName
        status
        qrString
        note
        refNo
        createdAt
        bankName
        bankLogoUrl
        packageAmount
        qrType
      }
    }
  }
`;

export const GET_CARDS = gql`
  query Query($where: CardWhereInput) {
    cards(where: $where) {
      total
      data {
        #  creditCard
        id
        cardId
        cardName
        expireMonth
        expireYear
        cvc
        status
        brand
        createdAt
        createdBy {
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_CARD = gql`
  query Card($where: CardWhereInputOne!) {
    card(where: $where) {
      id
      cardId
      cardName
      expireMonth
      expireYear
    }
  }
`;

export const GET_POPUPS = gql`
  query Popups {
    popups {
      total
      data {
        id
        popupImage
        note
        createdAt
      }
    }
  }
`;

export const GET_FEE = gql`
  query Fee {
    fee {
      data {
        id
        feeAmount
        charge
      }
    }
  }
`;

export const GET_HOME_POPUP = gql`
query Homepopups {
  homepopups {
    data {
      id
      engTitle
      engSecondSubTitle
      engFirstSubTitle
      icon
      laoFirstSubTitle
      laoSecondSubTitle
      isOpen
      laoTitle
      createdBy {
        username
        firstName
      }
      createdAt
      updatedBy {
        username
        firstName
      }
      updatedAt
    }
  }
}
`;

export const GET_SCAN_POPUP = gql`
query Query {
  scanpopups {
    data {
      id
      engTitle
      engFirstSubTitle
      engSecondSubTitle
      laoTitle
      laoFirstSubTitle
      laoSecondSubTitle
      icon
      isOpen
      createdBy {
        firstName
      }
      createdAt
      updatedAt
      updatedBy {
        firstName
      }
    }
  }
}
`;

import { faArrowLeft, faDeleteLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CLICK_COLOR, COLOR_APP, USER_ID } from "../../constants";
import { BsCircle, BsCircleFill } from "react-icons/bs";
import { IoIosLock } from "react-icons/io";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_FEE, GET_USER } from "./graphql/query";
import { CREATE_PAYMENT, UPDATE_USER, MMONEY_COMFIRM_PAYMENT } from "./graphql/mutation";
import Loading from "../../components/Loading";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import swal from "sweetalert";

import * as _ from "lodash";
import "./Home.css";
import { numberWithCommas } from "../../constants/ShowFormat";
import { HEADER_HEIGHT } from "../../constants";

import { PIN_COMPARE } from "./graphql/mutation";
import CustomAppBar from "../../components/CustomAppBar";

function HomeQrPaymentPin() {
  //variables
  const history = useNavigate();
  const location = useLocation();
  const [pinCompare] = useMutation(PIN_COMPARE);

  const userId = localStorage.getItem(USER_ID);
  const [count, setCount] = useState(0);
  let cardId = new URLSearchParams(location.search).get("cardId");
  let cardName = new URLSearchParams(location.search).get("cardName");
  let sum = new URLSearchParams(location.search).get("sum");
  let expireMonth = new URLSearchParams(location.search).get("expireMonth");
  let expireYear = new URLSearchParams(location.search).get("expireYear");
  let price = new URLSearchParams(location.search).get("price");
  let code = new URLSearchParams(location.search).get("code");
  let id = new URLSearchParams(location.search).get("id");
  let brand = new URLSearchParams(location.search).get("brand");
  let bankLogoUrl = new URLSearchParams(location.search).get("bankLogoUrl");
  let bankName = new URLSearchParams(location.search).get("bankName");
  let billNumber = new URLSearchParams(location.search).get("billNumber");
  let _feeAmount = new URLSearchParams(location.search).get("feeAmount");
  let lat = new URLSearchParams(location.search).get("lat");
  let lng = new URLSearchParams(location.search).get("lng");
  let bankTansactionId = new URLSearchParams(location.search).get("bankTansactionId");
  if (_.isEmpty(billNumber)) billNumber = ""




  // bank info qr
  let jdbTransactionId = new URLSearchParams(location.search).get(
    "jdbTransactionId"
  );
  let jdbAuthToken = new URLSearchParams(location.search).get("jdbAuthToken");
  let destBankCode = new URLSearchParams(location.search).get("destBankCode");
  let destBankId = new URLSearchParams(location.search).get("destBankId");
  let destCurrency = new URLSearchParams(location.search).get("destCurrency");
  let mmoneyKey = new URLSearchParams(location.search).get("mmoneyKey");
  let mmoneyToken = new URLSearchParams(location.search).get("mmoneyToken");
  let merchantMobile = new URLSearchParams(location.search).get("merchantMobile");

  // get destMerchantId from location state to prevent urlencoding
  let destMerchantId = location.state.destMerchantId

  let destMerchantName = new URLSearchParams(location.search).get(
    "destMerchantName"
  );
  let exReferenceNo = new URLSearchParams(location.search).get("exReferenceNo");
  let paymentTypeId = new URLSearchParams(location.search).get("paymentTypeId");
  let qrType = new URLSearchParams(location.search).get("qrType");

  const description = localStorage.getItem("DESCRIPTION");
  const [realPrice, setRealPrice] = useState();
  const [totalFeesString, setTotalFeesString] = useState();
  const [pinCode, setPinCode] = useState("");
  const [wrong, setWrong] = useState(false);
  const { t, i18n } = useTranslation();

  const [createPayment] = useMutation(CREATE_PAYMENT);
  const [createMMomeyPayment] = useMutation(MMONEY_COMFIRM_PAYMENT);
  const [createLoading, setCreateLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [arrayButton, setArrayButton] = useState();
  const [feeData, setFeeData] = useState();
  const [buttonClick, setButtonClick] = useState({
    button0: false,
    button1: false,
    button2: false,
    button3: false,
    button4: false,
    button5: false,
    button6: false,
    button7: false,
    button8: false,
    button9: false,
    button10: false,
  });
  const [updateUser] = useMutation(UPDATE_USER);

  const [getUser, { data: userQueryData }] = useLazyQuery(
    GET_USER
  );

  const [getFee, { data: feeQueryData }] = useLazyQuery(GET_FEE);

  useEffect(() => {
    getUser({
      variables: {
        where: {
          id: userId,
        },
      },
    });
    getFee();
    let myStr = shuffleButton();
    setArrayButton(myStr);
  }, []);

  // console.log('-------------------->',userQueryData);

  useEffect(() => {
    if (feeQueryData) {
      const feesData = feeQueryData.fee.data[0];
      setFeeData(feesData);

      const { feeAmount, charge } = feesData;
      const totalFees = calAllFees({ price: realPrice, feeAmount, charge });

      setTotalFeesString(numberWithCommas(parseFloat(totalFees).toFixed(2)));
    }
  }, [feeQueryData]);

  const calAllFees = ({ price, feeAmount, charge }) => {
    return (parseInt(price) || 0) * parseFloat(feeAmount) + parseInt(charge);
  };

  useEffect(() => {
    // let priceStr = price.split(" ");
    // var priceValue = parseFloat(priceStr[1].replace(/,/g, ""));
    var priceValue = parseFloat(price.replace(/,/g, ""));
    setRealPrice(priceValue);
  }, [price]);

  useEffect(() => {
    setTimeout(() => {
      setButtonClick({ button0: false });
    }, 100);
    setTimeout(() => {
      setButtonClick({ button0: false });
    }, 100);
    if (count == 4) {
      pinCompareReq(pinCode);
    }
  }, [count]);

  const pinCompareReq = async (pinCode) => {
    try {
      const pinCompareRes = await pinCompare({
        variables: {
          pinCode: pinCode,
        },
      });
      const resData = pinCompareRes["data"];
      if (_.isEmpty(resData)) return;

      const pinCompareResult = resData["pinCompare"]["success"];
      if (!pinCompareResult) {
        setTimeout(() => {
          setWrong(true);
          setCount(0);
          setPinCode("");
        }, 500);
        return;
      }

      // Do a payment
      if (mmoneyKey && mmoneyToken) {
        mmoneyPayment();
      } else {

        handlePayment();
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const mmoneyPayment = async () => { 
    setCreateLoading(true);
    let totalPrice =
      (parseInt(realPrice) || 0) +
      (parseInt(realPrice) || 0) * parseFloat(feeData.feeAmount) +
      parseInt(feeData.charge);
    let vat = totalPrice - realPrice;
    try {
    

      // card
      const cardType = brand;
      const note = description;
      const qrString = code;
      const stripeCardId = id;
      const cardLast4digit = cardId;
      const cardHolderName = cardName;

      //   price
      const amount = parseInt(realPrice);
      const total = parseInt(totalPrice);
      const fees = parseFloat(feeData.feeAmount);
      const charge = parseFloat(feeData.charge);

      const data = {
        
        pinCode: pinCode,
        cardType: cardType,
        
        lat: parseFloat(lat),
        long: parseFloat(lng),

        cardLast4digit: cardLast4digit,
        cardHolderName: cardHolderName,
        amount: amount,
        fees: fees,
        charge: charge,
        total: total,
        stripeCardId: stripeCardId,
        note: note,
        qrString: qrString,
        bankName: "MMONEY",
        destMerchantName: destMerchantName,
        qrType: qrType,
        mmoneyToken: mmoneyToken,
        mmoneyKey: mmoneyKey,
        bankLogoUrl: bankLogoUrl,
        bankTansactionId: bankTansactionId,
      };
      // console.log('------------>',data);

      const _res = await createMMomeyPayment({
        variables: {
          data: data,
        },
      });
      // console.log('data result', _res);
      if (_res.data) {
        setCreateLoading(false);
        if (_res.data.confirmMmoneyQr.id) {
          _updateUserLimitAmount();
          history(
            `/homeqr-payment-confirm-to-paysuccess?price=${realPrice}&createdAt=${_res.data.confirmMmoneyQr.createdAt}&cardId=${cardId}&expireMonth=${expireMonth}&expireYear=${expireYear}&description=${description}&id=${_res.data.confirmMmoneyQr.id}&brand=${brand}&vat=${vat}&destMerchantName=${destMerchantName}&bankLogoUrl=${bankLogoUrl}&refNo=${_res.data.confirmMmoneyQr.bankTansactionId}`
          );
        } else {
          setCreateLoading(false);
          setPaymentError(true);
          history(
            `/home-payment-error?price=${realPrice}&createdAt=${_res.data.confirmMmoneyQr.createdAt}&cardId=${cardId}&expireMonth=${expireMonth}&expireYear=${expireYear}&id=${_res.data.confirmMmoneyQr.id}&brand=${brand}&vat=${vat}&destMerchantName=${destMerchantName}&bankLogoUrl=${bankLogoUrl}`
          );
        }
      }

      // console.log( '------------->',paymentReqData);
      // _goBackConfirmtoPay();
    } catch (error) {
      console.log(error);
    }
  }

  const handlePayment = async () => {
    setCreateLoading(true);
    let totalPrice =
      (parseInt(realPrice) || 0) +
      (parseInt(realPrice) || 0) * parseFloat(feeData.feeAmount) +
      parseInt(feeData.charge);
    let vat = totalPrice - realPrice;
    try {
      // user
      const user = userQueryData["user"];
      const locaSID = user["sid"];
      const firstName = user["firstName"];
      const lastName = user["lastName"];
      const email = user["email"];
      const phone = user["phone"];

      const userStripeId = user["userStripeId"];
      const countryCode = user["countryCode"];
      const passportNumber = user["passportNumber"] ? user["passportNumber"] : null;
      const gender = user["gender"];
      const userCreatedAt = user["createdAt"];
      const userApprovedAt = user["approvedAt"];
      const userStatus = user["userStatus"];
      const birthday = user["birthday"];
      const isPassAutoKYC = user["isPassAutoKYC"];

      // card
      const cardType = brand;
      const note = description;
      const qrString = code;
      const stripeCardId = id;
      const cardLast4digit = cardId;
      const cardHolderName = cardName;

      //   price
      const amount = parseInt(realPrice);
      const total = parseInt(totalPrice);
      const fees = parseFloat(feeData.feeAmount);
      const charge = parseFloat(feeData.charge);

      const paymentReqData = {
        pinCode: pinCode,
        locaSID: locaSID,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        userStripeId: userStripeId,
        countryCode: countryCode,
        passportNumber: passportNumber,
        gender: gender,
        userCreatedAt: userCreatedAt,
        userApprovedAt: userApprovedAt,
        userStatus: userStatus,
        birthday: birthday,
        isPassAutoKYC: isPassAutoKYC,
        lat: parseFloat(lat),
        long: parseFloat(lng),

        cardType: cardType,
        cardLast4digit: cardLast4digit,
        cardHolderName: cardHolderName,
        amount: amount,
        fees: fees,
        charge: charge,
        total: total,
        stripeCardId: stripeCardId,
        qrString: qrString,
        note: note,

        // Bank
        bankAuthToken: jdbAuthToken,
        requestId: jdbTransactionId,
        destBankCode: destBankCode,
        destBankId: destBankId,
        destCurrency: destCurrency,
        destMerchantId: destMerchantId,
        destMerchantName: destMerchantName,
        exReferenceNo: exReferenceNo,
        paymentTypeId: paymentTypeId,
        qrType: qrType,
        bankName: bankName,
        bankLogoUrl: bankLogoUrl,
        billNumber: billNumber,
        feeAmount: parseFloat(_feeAmount),
      };

      const _res = await createPayment({
        variables: {
          data: paymentReqData,
        },
      });
      if (_res.data) {
        setCreateLoading(false);
        if (_res.data.createPayment.id) {
          _updateUserLimitAmount();

          const billNo = _res.data.createPayment.billNo;
          const refNo = _res.data.createPayment.refNo;
          history(
            `/homeqr-payment-confirm-to-paysuccess?price=${realPrice}&createdAt=${_res.data.createPayment.createdAt}&cardId=${cardId}&expireMonth=${expireMonth}&expireYear=${expireYear}&description=${description}&id=${_res.data.createPayment.id}&brand=${brand}&vat=${vat}&billNo=${billNo}&destMerchantName=${destMerchantName}&refNo=${refNo}&bankLogoUrl=${bankLogoUrl}`
          );
        } else {
          setCreateLoading(false);
          setPaymentError(true);
          history(
            `/home-payment-error?price=${realPrice}&createdAt=${_res.data.createPayment.createdAt}&cardId=${cardId}&expireMonth=${expireMonth}&expireYear=${expireYear}&id=${_res.data.createPayment.id}&brand=${brand}&vat=${vat}&destMerchantName=${destMerchantName}&bankLogoUrl=${bankLogoUrl}`
          );
        }
      }
    } catch (error) {
      setCreateLoading(false);
      let errMsg = error.message;
      let { title, detail } = errorPaymentHandlingString(errMsg);
      // alert(t(errMsgString));
      swal(t(title), t(detail));
      history(`/home-add-creditcard`);
      return;
    }
  };

  const STRIPE_ERROR = "STRIPE_ERROR";
  const BANK_ERROR = "BANK_ERROR";
  const errorPaymentHandlingString = (errMsg) => {
    if (errMsg === STRIPE_ERROR) {
      return { title: "stripe-error-title", detail: "stripe-error-detail" };
    } else if (errMsg === BANK_ERROR) {
      return { title: "bank-error-title", detail: "bank-error-detail" };
    } else if (errMsg == "CARD_IS_REQUIRED_3D_SECURE") {
      return { title: 'card_not_support', detail: "require_secure_error" }
    }

    return { title: "error-gateway-title", detail: "error-gateway-detail" };
  };

  const _updateUserLimitAmount = async () => {
    if (sum === null) return;
    try {
      const _res = await updateUser({
        variables: {
          data: {
            limitAmount: parseInt(sum),
          },
          where: {
            id: userId,
          },
        },
      });
      if (_res) {
      }
    } catch (error) { }
  };

  const shuffleButton = () => {
    let array = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  };

  //routes
  const _goBackConfirmtoPay = () => {
    history(-1);
  };

  const _goBackToHome = () => {
    localStorage.removeItem("DESCRIPTION");
    history(`/home-add-creditcard`);
  };

  if (createLoading) {
    return <Loading />;
  }

  return (
    <div className="wrapper">
      {/* <div className="home-header">
        <FontAwesomeIcon
          style={{
            marginLeft: 10,
            fontSize: "18",
            fontWeight: "bold",
          }}
          icon={faArrowLeft}
          onClick={() => _goBackConfirmtoPay()}
        />
      </div> */}

      <CustomAppBar
        gotoOtherPage={_goBackConfirmtoPay}
        title={"LOCA PAY"}
      />

      <div style={{ height: 10 }} />

      {/* Body */}
      <div>
        <div style={{ textAlign: "center" }}>
          <IoIosLock fontSize={50} />
        </div>

        <div style={{ fontSize: 17, fontWeight: "bold", textAlign: "center", marginTop: 10 }}>
          {/* Please input PIN */}
          {t("setting-please")}
        </div>
        {/* <div style={{ textAlign: "center", marginTop: 4 }}>
          {t("pay-to")} {destMerchantName}
        </div>
        <div style={{ textAlign: "center", color: "red", fontSize: 24 }}>
          {price}
        </div>
        <div style={{ textAlign: "center", color: "red", fontSize: 16 }}>
          <span>{t("payment-fee")}: </span>
          {totalFeesString}
        </div> */}

        <div style={{ height: 10 }} />
        <div style={{ display: "flex", justifyContent: "center" }}>
          {count >= 1 ? (
            <BsCircleFill
              fontSize={14}
              style={{ marginRight: 20 }}
              color={COLOR_APP}
            />
          ) : (
            <BsCircle
              fontSize={14}
              style={{ marginRight: 20 }}
              color={COLOR_APP}
            />
          )}
          {count >= 2 ? (
            <BsCircleFill
              fontSize={14}
              style={{ marginRight: 20 }}
              color={COLOR_APP}
            />
          ) : (
            <BsCircle
              fontSize={14}
              style={{ marginRight: 20 }}
              color={COLOR_APP}
            />
          )}
          {count >= 3 ? (
            <BsCircleFill
              fontSize={14}
              style={{ marginRight: 20 }}
              color={COLOR_APP}
            />
          ) : (
            <BsCircle
              fontSize={14}
              style={{ marginRight: 20 }}
              color={COLOR_APP}
            />
          )}
          {count >= 4 ? (
            <BsCircleFill fontSize={14} color={COLOR_APP} />
          ) : (
            <BsCircle fontSize={14} color={COLOR_APP} />
          )}
        </div>
        <div style={{ textAlign: "center", marginTop: 8, color: "#999999" }}>
          {t("setting-hint")}: {userQueryData ? userQueryData.user.pinHint : ""}
        </div>
        {wrong ? (
          <div style={{ textAlign: "center", color: "red" }}>
            {/* You pin is not correct */}
            {t("setting-not-correct")}
          </div>
        ) : (
          <div />
        )}

        <div style={{ height: 14 }} />
        {arrayButton ? (
          <div
            style={{ position: "absolute", width: "100%", marginBottom: 20 }}
          >
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <div
                className="pin-button"
                style={{
                  backgroundColor: buttonClick.button0 ? CLICK_COLOR : "",
                }}
                onClick={() => {
                  if (count <= 4) {
                    setCount(count + 1);
                    setButtonClick({ button0: true });
                  }
                  setPinCode(pinCode + `${arrayButton[0]}`);
                }}
              >
                {arrayButton[0]}
              </div>
              <div
                className="pin-button"
                style={{
                  backgroundColor: buttonClick.button1 ? CLICK_COLOR : "",
                }}
                onClick={() => {
                  if (count <= 4) {
                    setCount(count + 1);
                    setButtonClick({ button1: true });
                  }
                  setPinCode(pinCode + `${arrayButton[1]}`);
                }}
              >
                {arrayButton[1]}
              </div>
              <div
                className="pin-button"
                style={{
                  backgroundColor: buttonClick.button2 ? CLICK_COLOR : "",
                }}
                onClick={() => {
                  if (count <= 4) {
                    setCount(count + 1);
                    setButtonClick({ button2: true });
                  }
                  setPinCode(pinCode + `${arrayButton[2]}`);
                }}
              >
                {arrayButton[2]}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: 11,
              }}
            >
              <div
                className="pin-button"
                style={{
                  backgroundColor: buttonClick.button3 ? CLICK_COLOR : "",
                }}
                onClick={() => {
                  if (count <= 4) {
                    setCount(count + 1);
                    setButtonClick({ button3: true });
                  }
                  setPinCode(pinCode + `${arrayButton[3]}`);
                }}
              >
                {arrayButton[3]}
              </div>
              <div
                className="pin-button"
                style={{
                  backgroundColor: buttonClick.button4 ? CLICK_COLOR : "",
                }}
                onClick={() => {
                  if (count <= 4) {
                    setCount(count + 1);
                    setButtonClick({ button4: true });
                  }
                  setPinCode(pinCode + `${arrayButton[4]}`);
                }}
              >
                {arrayButton[4]}
              </div>
              <div
                className="pin-button"
                style={{
                  backgroundColor: buttonClick.button5 ? CLICK_COLOR : "",
                }}
                onClick={() => {
                  if (count <= 4) {
                    setCount(count + 1);
                    setButtonClick({ button5: true });
                  }
                  setPinCode(pinCode + `${arrayButton[5]}`);
                }}
              >
                {arrayButton[5]}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: 11,
              }}
            >
              <div
                className="pin-button"
                style={{
                  backgroundColor: buttonClick.button6 ? CLICK_COLOR : "",
                }}
                onClick={() => {
                  if (count <= 4) {
                    setCount(count + 1);
                    setButtonClick({ button6: true });
                  }
                  setPinCode(pinCode + `${arrayButton[6]}`);
                }}
              >
                {arrayButton[6]}
              </div>
              <div
                className="pin-button"
                style={{
                  backgroundColor: buttonClick.button7 ? CLICK_COLOR : "",
                }}
                onClick={() => {
                  if (count <= 4) {
                    setCount(count + 1);
                    setButtonClick({ button7: true });
                  }
                  setPinCode(pinCode + `${arrayButton[7]}`);
                }}
              >
                {arrayButton[7]}
              </div>
              <div
                className="pin-button"
                style={{
                  backgroundColor: buttonClick.button8 ? CLICK_COLOR : "",
                }}
                onClick={() => {
                  if (count <= 4) {
                    setCount(count + 1);
                    setButtonClick({ button8: true });
                  }
                  setPinCode(pinCode + `${arrayButton[8]}`);
                }}
              >
                {arrayButton[8]}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: 11,
              }}
            >
              <div style={{ height: 88, width: 88 }}></div>
              <div
                className="pin-button"
                style={{
                  backgroundColor: buttonClick.button9 ? CLICK_COLOR : "",
                }}
                onClick={() => {
                  if (count <= 4) {
                    setCount(count + 1);
                    setButtonClick({ button9: true });
                  }
                  setPinCode(pinCode + `${arrayButton[9]}`);
                }}
              >
                {arrayButton[9]}
              </div>
              <div
                className="pin-button"
                style={{
                  backgroundColor: buttonClick.button10 ? CLICK_COLOR : "",
                }}
                onClick={() => {
                  setButtonClick({ button10: true });
                  if (count > 0) {
                    setCount(count - 1);
                  }
                  const newCode = pinCode.slice(0, -1);
                  setPinCode(newCode);
                }}
              >
                <FontAwesomeIcon icon={faDeleteLeft} />
              </div>
            </div>
          </div>
        ) : (
          <div />
        )}
        <Modal
          show={paymentError}
          size="sm"
          centered
        >
          <Modal.Body style={{ textAlign: "center" }}>
            <img src="/assets/images/payment_failed.png" alt="" width={135} />
            <div
              style={{
                marginTop: 16,
                fontWeight: "bold",
                fontSize: 18,
                marginBottom: 16,
              }}
            >
              {t("payment-failed")}!
            </div>
            <Button
              style={{
                backgroundColor: COLOR_APP,
                border: "none",
                width: "90%",
              }}
              onClick={() => _goBackToHome()}
            >
              {t("payment-back")}
            </Button>
          </Modal.Body>
        </Modal>

        <Modal show={show} size="sm" onHide={() => setShow(false)} centered>
          <Modal.Body style={{ textAlign: "center" }}>
            <AiOutlineCloseCircle
              style={{ fontSize: 60, color: "red", marginBottom: 20 }}
            />
            <div style={{ color: "red" }}>{t("payment-in-correct")}!</div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default HomeQrPaymentPin;

import {
  faArrowLeft,
  faCircle,
  faDeleteLeft,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function HomeQrPaymentConfirmToPayPin() {
  return (
    <div className="wrapper">
      {" "}
      {/* Navbar  */}
      <Link to="/homeaccount">
        <div
          style={{
            display: "flex",
            height: 49,
          }}
        >
          <FontAwesomeIcon
            style={{
              marginLeft: 18,
              marginTop: 17,
              height: 20,
              width: 16,
              fontWeight: "bold",
              color: "#252A31",
            }}
            icon={faArrowLeft}
          />
        </div>
      </Link>
      {/* --- */}
      <center style={{ marginTop: 26, fontSize: 17, fontWeight: "bold" }}>
        Please input PIN{" "}
      </center>
      <div style={{ marginTop: 50 }}>
        <center>
          {" "}
          <FontAwesomeIcon
            style={{ height: 40, width: 40, color: "#00B0FF" }}
            icon={faLock}
          />
          <div style={{ marginTop: 50 }}>
            <FontAwesomeIcon
              style={{
                height: 15,
                width: 15,
                color: "#00B0FF",
                marginRight: 20,
              }}
              icon={faCircle}
            />
            <FontAwesomeIcon
              style={{
                height: 15,
                width: 15,
                color: "#00B0FF",
                marginRight: 20,
              }}
              icon={faCircle}
            />
            <FontAwesomeIcon
              style={{
                height: 15,
                width: 15,
                color: "#00B0FF",
                marginRight: 20,
              }}
              icon={faCircle}
            />
            <FontAwesomeIcon
              style={{ height: 15, width: 15, color: "#00B0FF" }}
              icon={faCircle}
            />

            <faBsFillCircleFill />
            <FontAwesomeIcon icon="fa-regular fa-circle" />
          </div>
        </center>
      </div>
      {/* PIN  */}
      <div style={{ marginTop: 70 }}>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div
            style={{
              height: 88,
              width: 88,
              backgroundColor: "#E6E6E6",
              borderRadius: 44,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: 24,
            }}
          >
            1
          </div>
          <div
            style={{
              height: 88,
              width: 88,
              backgroundColor: "#E6E6E6",
              borderRadius: 44,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: 24,
            }}
          >
            2
          </div>
          <div
            style={{
              height: 88,
              width: 88,
              backgroundColor: "#E6E6E6",
              borderRadius: 44,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: 24,
            }}
          >
            3
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: 11,
          }}
        >
          <div
            style={{
              height: 88,
              width: 88,
              backgroundColor: "#E6E6E6",
              borderRadius: 44,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: 24,
            }}
          >
            4
          </div>
          <div
            style={{
              height: 88,
              width: 88,
              backgroundColor: "#E6E6E6",
              borderRadius: 44,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: 24,
            }}
          >
            5
          </div>
          <div
            style={{
              height: 88,
              width: 88,
              backgroundColor: "#E6E6E6",
              borderRadius: 44,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: 24,
            }}
          >
            6
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: 11,
          }}
        >
          <div
            style={{
              height: 88,
              width: 88,
              backgroundColor: "#E6E6E6",
              borderRadius: 44,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: 24,
            }}
          >
            7
          </div>
          <div
            style={{
              height: 88,
              width: 88,
              backgroundColor: "#E6E6E6",
              borderRadius: 44,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: 24,
            }}
          >
            8
          </div>
          <div
            style={{
              height: 88,
              width: 88,
              backgroundColor: "#E6E6E6",
              borderRadius: 44,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: 24,
            }}
          >
            9
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: 11,
          }}
        >
          <div style={{ height: 88, width: 88 }}></div>
          <div
            style={{
              height: 88,
              width: 88,
              backgroundColor: "#E6E6E6",
              borderRadius: 44,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: 24,
            }}
          >
            0
          </div>
          <div
            style={{
              height: 88,
              width: 88,
              backgroundColor: "#E6E6E6",
              borderRadius: 44,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: 24,
            }}
          >
            <FontAwesomeIcon icon={faDeleteLeft} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeQrPaymentConfirmToPayPin;

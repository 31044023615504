import React, { useState } from "react";

import { FilePond, registerPlugin } from "react-filepond";

import "filepond/dist/filepond.min.css";

// import MobileDetect from "mobile-detect";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { IMAGE_SERVER_API, MAX_FILE_SIZE } from "../constants";

const ALLOW_FILE_TYPE = ["image/*"];

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginImageTransform
);

// Our app
function MyCustomCamera({ labelIdle, folder, handleRes }) {
  const [files, setFiles] = useState([]);

//   let mbDetect = new MobileDetect(window.navigator.userAgent);

  return (
    <div className="App">
      <FilePond
        // image transform
        imageTransformOutputQuality={50}
        imageTransformOutputMimeType={"image/jpeg"}
        // validation
        allowFileTypeValidation={true}
        acceptedFileTypes={ALLOW_FILE_TYPE}
        captureMethod="camera"
        credits={null}
        allowFileSizeValidation={true}
        maxFileSize={MAX_FILE_SIZE}
        labelMaxFileSize={`Maximum file size is ${MAX_FILE_SIZE}`}
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={false}
        maxFiles={1}
        name="files"
        labelIdle={labelIdle}
        server={{
          url: IMAGE_SERVER_API,
          process: {
            url: `/upload?folder=${folder}`,
            method: "POST",
            withCredentials: false,
            onload: (response) => {
              const _res = JSON.parse(response)

              // handleRes(_res?.image);
              if (_res.image != undefined || _res.image != "") {
                handleRes(_res.image);
              }
              return response.key;
            },
            onerror: (response) => response.data,
            ondata: (formData) => {
              return formData;
            },
          },
        }}
      />
    </div>
  );
}
export default MyCustomCamera;

export const showGender = (gender) => {
    if(gender == "FEMALE") {
        return 'ຍິງ'
    }else if(gender == "MALE") {
        return 'ຊາຍ'
    } else if(gender == "NOT_SPECIFIED") {
        return '-'
    }
}

function clearNumber(value = '') {
    return value.replace(/\D+/g, '');
  }

export const formatExpirationDate = (value) => {
    const clearValue = clearNumber(value);
  
    if (clearValue.length >= 3) {
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }
  
    return clearValue;
  }
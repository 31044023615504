import { useMutation, useLazyQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { AMERICAN_EXPRESS_COLOR, COLOR_APP, DISCOVER_DINER_COLOR, JCB_COLOR, MASTERCARD_COLOR, POPUP_COLOR, UNIONPAY_COLOR, USER_ID, VISA_CARD } from '../constants';
import { GET_USER } from '../pages/graphql/query';
import { UPDATE_USER } from '../pages/Home/graphql/mutation';
import { DELETE_CARD } from '../pages/graphql/mutation'
import * as _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RiErrorWarningFill } from 'react-icons/ri';
import { useNavigate } from 'react-router';
import Loading from './Loading'

function CreditCardDefault(props) {
    const history = useNavigate()
    const [cardData, setCardData] = useState();
    const [cardCheck, setCardCheck] = useState();
    const userId = localStorage.getItem(USER_ID);
    const [showDelete, setShowDelete] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [loading, setLoading] = useState(false)
    const [showChangeCard, setShowchangeCard] = useState(false)
    const [popupText, setPopupText] = useState();

    const { t, i18n } = useTranslation();

    const [addDefaultCard] = useMutation(UPDATE_USER);
    const [deleteCard] = useMutation(DELETE_CARD);
    const [getUser, { data: userData }] = useLazyQuery(GET_USER);

    useEffect(() => {
        getUser({
            variables: {
                where: {
                    id: userId,
                },
            },
        });
        if (!_.isEmpty(props.data)) {
            // console.log("props.data", props.data);
            setCardData(props.data);
            if (userData) {
                // console.log("userData", userData.user.defaultCard);
                setCardCheck(userData.user.defaultCard);
            } else {
                setCardCheck(props.data[0].id);
            }
        }
    }, [props.data, userData]);


    const _removeCard = async () => {
        try {
            setPopupText(`${t("remove_success")}`)
            setShowDelete(false)
            setLoading(true);
            const _res = await deleteCard({
                variables: {
                    where: {
                        id: deleteId,
                    },
                },
            });

            if (_res.data.deleteCard) {
                setLoading(false);
                setShowchangeCard(true)
                setTimeout(() => {
                    setShowchangeCard(false)
                    window.location.reload();
                }, 2000);
            }
        } catch (error) { }
    }

    const _setCardDefault = async (cardId) => {
        try {
            setPopupText(`${t("default_card_change")}`)
            const _res = await addDefaultCard({
                variables: {
                    data: {
                        defaultCard: cardId,
                    },
                    where: {
                        id: userId,
                    },
                },
            });
            if (_res) {
                setCardCheck(cardId);
                getUser({
                    variables: {
                        where: {
                            id: userId,
                        },
                    },
                });
                setShowchangeCard(true)
                setTimeout(() => {
                    setShowchangeCard(false);
                }, 2000);
            }
        } catch (error) { }
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div style={{ marginTop: 21 }}>
            {
                cardData ? <center>
                    {
                        cardData.map((card, index) => {
                            return (
                                <div key={index}>
                                    <div style={{
                                        width: "85%",
                                        backgroundColor: card.brand == "mastercard"
                                            ? MASTERCARD_COLOR
                                            : card.brand == "amex"
                                                ? AMERICAN_EXPRESS_COLOR
                                                : card.brand == "unionpay"
                                                    ? UNIONPAY_COLOR
                                                    : card.brand == "jcb"
                                                        ? JCB_COLOR
                                                        : card.brand == "discover" || card.brand == "diners"
                                                            ? DISCOVER_DINER_COLOR
                                                            :
                                                            VISA_CARD,
                                        borderRadius: 12,
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                        paddingBottom: 20,
                                        marginBottom: 25,
                                        color: "white"
                                    }}
                                        onClick={() => _setCardDefault(card.id)}
                                    >
                                        <div style={{ height: 11 }} />
                                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 50 }}>
                                            {cardCheck == card.id ? <div style={{ display: "flex", alignItems: "center" }}>
                                                <FontAwesomeIcon icon={faCheckCircle} fontSize={24} color={COLOR_APP} style={{ float: "left", marginLeft: 6 }} />
                                                <div style={{ color: COLOR_APP, fontWeight: "bold", fontSize: 14, marginLeft: 7 }}>Default card</div>
                                            </div> :
                                                <div />}
                                            <div
                                                style={{ padding: "1px 4px", borderRadius: 2 }}
                                                onClick={(e) => { 
                                                    e.stopPropagation()
                                                    
                                                    console.log("card: ", card)
                                                    // setDeleteId(card.id);
                                                    // setShowDelete(true);
                                                    props.setShowNotDeleteCard(true);
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faTrash} style={{ fontSize: 18 }} color='#fff' />
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-start", }}>
                                            {card.brand == "mastercard" ? <img src="assets/images/mastercards.png" alt="" width={60} /> :
                                                card.brand == "amex" ? <img src="assets/images/amex.png" alt="" width={60} /> :
                                                    card.brand == "unionpay" ? <img src="assets/images/union_pay.png" alt="" width={60} /> :
                                                        card.brand == "jcb" ? <img src="assets/images/jcb.png" alt="" width={60} /> :
                                                            card.brand == "discover" || card.brand == "diners" ? <img src="assets/images/discover_diner.png" alt="" width={120} /> :
                                                                <img src='assets/images/visa.png' alt='' width={60} />}
                                        </div>
                                        <div
                                            style={{
                                                marginTop: 12,
                                                fontSize: 17,
                                                color: "white",
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                fontWeight: 700
                                            }}
                                        >
                                            <div>****</div>
                                            <div>****</div>
                                            <div>****</div>
                                            <div>{card.cardId}</div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            color: "white",
                                            fontSize: 12, fontWeight: 400
                                        }}>
                                            <div style={{ marginRight: 4 }}>{`${t("expires")}:`}</div>
                                            <div>{card.expireMonth}/
                                                {card.expireYear}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </center> : <div />
            }
            <Modal
                show={showDelete}
                size="sm"
                onHide={() => setShowDelete(false)}
                centered
            >
                <Modal.Body style={{ textAlign: "center" }}>
                    <RiErrorWarningFill style={{ fontSize: 80, color: COLOR_APP }} />
                    <div style={{ color: "#EB5757", marginBottom: 8 }}>
                        {t("ask_delete")} <br /> {t("remove_card")}?
                    </div>
                    <div style={{ color: "#697D95", fontSize: 14 }}>{t("action_cant_recovered")}</div>
                    <div style={{ marginTop: 30, display: "flex", justifyContent: "space-evenly" }}>
                        <Button style={{
                            width: 125,
                            height: 44,
                            backgroundColor: "white",
                            color: "#EB5757",
                            borderColor: "white",
                            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
                            borderRadius: 6
                        }}
                            onClick={() => _removeCard()}
                        >{t("delete")}</Button>
                        <Button style={{
                            width: 125,
                            height: 44,
                            borderRadius: 6,
                            backgroundColor: COLOR_APP,
                            borderColor: COLOR_APP,
                        }}
                            onClick={() => setShowDelete(false)}
                        >{t("card-cancle")}</Button>
                    </div>
                    <div style={{ height: 20 }} />
                </Modal.Body>
            </Modal>

            <Modal
                show={showChangeCard}
                size="sm"
                centered
            >
                <Modal.Body style={{ textAlign: "center" }}>
                    <FontAwesomeIcon icon={faCheckCircle} fontSize={80} color={"#0DCD5A"} />
                    <div style={{ marginTop: 20, marginBottom: 10 }}>
                        {popupText}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default CreditCardDefault
